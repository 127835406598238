import React, { useContext, useEffect, useState, useRef } from "react";
import classNames from "../cdbalanceAgency.module.scss";
import styles from "./ac.module.scss";

import { IoMdClose, IoMdSearch } from "react-icons/io";

import arrowDown from "./asset/arrowDown.svg";
import calendarIcon from "./asset/calendar.svg";
import calenderIcon from "../../../../assest/images/icons/calender.svg";
import uploadIcon from "./asset/upload.svg";
import Axios from "axios";
import { uploadFileFunc, uploadImageFunc } from "../../../../utils/imageUpload";
import { BankContext } from "../../../../context/Context";
import flatpickr from "flatpickr";

const AddTransaction = ({ setShowAddTxn, showAddTxn }) => {
  const { setUpdateLoading, globalHrId } = useContext(BankContext);

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [addEmail, setAddEmail] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [affiliate, setAffiliate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [noOfEmployee, setNoOfEmployee] = useState("");
  const [associate, setAssociate] = useState("");
  const [teamLead, setTeamLead] = useState("");
  const [verticalHead, setVerticalHead] = useState("");
  const [businessHead, setBusinessHead] = useState("");

  const source = ["Online", "Offline", "Affiliate"];

  // useEffect(() => {
  //   Axios.get(
  //     `https://insurance.apimachine.com/insurance/policy/get/quote?email_id=${showUploadQRC?.lead_email}&next_Step=History`
  //   ).then(({ data }) => {
  //     console.log(data, "kjwegdkjwefjwge");
  //     setAllQuotes(data.data);
  //   });
  // }, []);

  // const handleUploadQCR = () => {
  //   if (selectedQuote && qcrName && qcrNotes && uploadFileQCR) {
  //     setLoading(true);
  //     Axios.post(
  //       `https://insurance.apimachine.com/insurance/policy/create/rfq`,
  //       {
  //         quote_id: selectedQuote?.quote_id,
  //         rfq_pdf: uploadFileQCR,
  //         name: qcrName,
  //         notes: qcrNotes,
  //       }
  //     ).then(({ data }) => {
  //       if (data.status) {
  //         // setShowUploadQRC(false);
  //         // setLoading(false);
  //         // setQcrName("");
  //         // setQcrNotes("");
  //         // setUploadFileQCR("");
  //         // setUploadFileQCRName("");
  //       }
  //     });
  //   }
  // };
  const [policyNumbers, setPolicyNumbers] = useState([]);
  const [selectedPolicyNo, setSelectedPolicyNo] = useState(null);
  const [showAllPolicies, setShowAllPolicies] = useState(false);

  const [policyDate, setPolicyDate] = useState(null);
  const [txnType, setTxnType] = useState(null);
  const [txnAmount, setTxnAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState();
  const [txnId, setTxnId] = useState("");
  const [cdBalId, setCdBalId] = useState(null);

  const getPolicyNumbers = () => {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/policy?hr_id=${showAddTxn?.hr_id}`
    ).then(({ data }) => {
      if (data.status) {
        setPolicyNumbers(data.data);
      }
    });
  };

  const getCdBalId = () => {
    Axios.get(
      `https://insurance.apimachine.com/insurance/cdBalance/hr/get?hr_id=${showAddTxn?.hr_id}`
    ).then(({ data }) => {
      if (data.status) {
        setCdBalId(data.data[0]?.cdBalance_id);
      }
    });
  };

  useEffect(() => {
    getPolicyNumbers();
    getCdBalId();
  }, []);

  const handleAddTxn = () => {
    setLoading(true);
    let bodyData;
    if (txnType === "Credit") {
      bodyData = {
        amount: txnAmount,
        transacted_for: "Manual",
        credit: "yes",
        payment_mode: paymentMode,
        payment_reference_number: txnId,
        date_of_event: policyDate,
        policy_number:
          selectedPolicyNo === "nopolicy"
            ? ""
            : selectedPolicyNo?.policy_number,
      };
    } else {
      bodyData = {
        amount: txnAmount,
        transacted_for: "Manual",
        debit: "yes",
        payment_mode: paymentMode,
        payment_reference_number: txnId,
        date_of_event: policyDate,
        policy_number:
          selectedPolicyNo === "nopolicy"
            ? ""
            : selectedPolicyNo?.policy_number,
      };
    }
    if (bodyData?.amount) {
      Axios.put(
        `https://insurance.apimachine.com/insurance/cdBalance/hr/manual?cdBalance_id=${cdBalId}`,
        bodyData
      ).then(({ data }) => {
        if (data.status) {
          setLoading(false);
          setShowAddTxn(null);
        }
      });
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={classNames.overlay}
        onClick={(e) => {
          setShowAddTxn(null);
          //   setShowQuoteDetails(null);
          //   setConfData(null);
          //   setSelectedQuoteTab("Overview");
          //   setSelectedConfTab("Health");
        }}
      ></div>
      <div className={styles.quoteDetailsWrapper} style={{ width: "50vw" }}>
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => {
            setShowAddTxn(null);
            // setConfData(null);
            // setSelectedQuoteTab("Overview");
            // setSelectedConfTab("Health");
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>Add Transaction</div>
          <div className={styles.rowWrapper1}>
            {!showAllPolicies ? (
              <div
                className={styles.dropdown}
                onClick={(e) => setShowAllPolicies(!showAllPolicies)}
              >
                <div>
                  {selectedPolicyNo === "nopolicy"
                    ? "This transaction is not related to a policy"
                    : selectedPolicyNo?.policy_number
                    ? selectedPolicyNo?.policy_number
                    : "Select policy number"}
                </div>
                <img
                  src={arrowDown}
                  alt=""
                  onClick={(e) => setShowAllPolicies(false)}
                />
              </div>
            ) : (
              <div className={styles.dropdown1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                  onClick={(e) => setShowAllPolicies(!showAllPolicies)}
                >
                  <div>Select policy number</div>
                  <img src={arrowDown} alt="" />
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    height: "110px",
                    overflowY: "scroll",
                  }}
                >
                  {policyNumbers.map((item, index) => (
                    <div
                      className={styles.individualQuote}
                      onClick={(e) => {
                        setSelectedPolicyNo(item);
                        setShowAllPolicies(false);
                      }}
                    >
                      <div></div>
                      <div>{item?.policy_number}</div>
                    </div>
                  ))}
                </div>
                <div
                  className={styles.noPolicy}
                  onClick={(e) => {
                    setSelectedPolicyNo("nopolicy");
                    setShowAllPolicies(false);
                  }}
                >
                  This transaction is not related to a policy
                </div>
              </div>
            )}
          </div>

          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup}>
              <div>Select date</div>
              <EachInputDatePicker
                placeholder="Select Date"
                stateValue={policyDate}
                setState={setPolicyDate}
              />
            </div>
            <div className={styles.inputGroup}></div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup1}>
              <div>Type of transaction</div>
              <div className={styles.leadSourceWrapper}>
                {["Credit", "Debit"].map((item) => (
                  <div
                    className={
                      txnType === item
                        ? styles.leadSourceSelected
                        : styles.leadSource
                    }
                    onClick={(e) => {
                      setTxnType(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Amount</div>
              <input
                type="text"
                placeholder="0.00"
                value={txnAmount}
                onChange={(event) => {
                  if (/^-?\d+$/.test(event?.target?.value)) {
                    setTxnAmount(event?.target?.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    setTxnAmount("");
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup1}>
              <div>Payment mode</div>
              <div className={styles.leadSourceWrapper}>
                {["Cheque", "EFT", "Other"].map((item) => (
                  <div
                    className={
                      paymentMode === item
                        ? styles.leadSourceSelected
                        : styles.leadSource
                    }
                    onClick={(e) => {
                      setPaymentMode(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Transaction ID</div>
              <input
                type="text"
                placeholder="Affiliate.."
                value={txnId}
                onChange={(e) => setTxnId(e.target.value)}
              />
            </div>
          </div>

          <div
            onClick={(e) => {
              if (policyDate && txnType && txnAmount && paymentMode && txnId) {
                handleAddTxn();
              }
            }}
            className={styles.addBtn}
            style={{
              opacity:
                policyDate && txnType && txnAmount && paymentMode && txnId
                  ? 1
                  : 0.5,
            }}
          >
            {loading ? "Adding Lead.." : "Add"}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTransaction;

export const EachInputDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (datepickerRef.current) {
      const options = {
        dateFormat: "d-m-Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          console.log("Selected start date:", dateStr, datepickerRef.current);
          setState(`${dateStr}`);
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div className={classNames.eachInputDatePicker} style={{ display: "flex" }}>
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        value={stateValue}
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
      />
      <img
        src={calenderIcon}
        alt="calenderIcon"
        className={classNames.calendarIcon}
        style={{ marginLeft: "-30px" }}
      />
    </div>
  );
};
