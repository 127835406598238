import React from "react";
import classNames from "./details.module.scss";

// import { AiOutlineArrowLeft } from "react-icons/ai";
import {
  BsFillPersonFill,
  BsFillPersonPlusFill,
  BsArrowRightShort,
} from "react-icons/bs";

const EmployeeDetails = () => {
  return (
    <div className={classNames.employeeBenefits}>
      {/* <div className={classNames.backBtn}>
        <AiOutlineArrowLeft />
      </div> */}
      <div className={classNames.title}>Basic Details</div>
      <div className={classNames.policyDetails}>
        <div className={classNames.totalSum}>
          <div>Floater Sum Insured</div>
          <div>₹ 3 Lakh</div>
        </div>
        <div className={classNames.duration}>
          <div>Starting 22 Nov 2022</div>
          <div>
            See Benefits <BsArrowRightShort />
          </div>
        </div>
      </div>
      <div className={classNames.desc}>
        This is a floater sum insured. A floater is a type of sum insured that
        provides coverage to more than one member of a family at the same time.
        Simply put, it’s a single insurance cover for the entire family.
      </div>
      <div className={classNames.allBenefits}>
        <div className={classNames.eachBenefit}>
          <div className={classNames.content}>
            <div className={classNames.title}>Please Note</div>
            <div className={classNames.desc}>
              The employee can add 4(2 child, 1 spouse, 1 parent) dependents.
            </div>
          </div>
        </div>
        <div className={classNames.title}>
          Please review your enrolled dependents.
        </div>
        <div className={classNames.eachBenefit}>
          <div className={classNames.suitIcon}>
            <BsFillPersonFill />
          </div>
          <div className={classNames.content}>
            <div className={classNames.title}>Test user</div>
            <div className={classNames.desc}>
              Self • 8181818181 • 10/10/1997
            </div>
          </div>
        </div>
        <div className={classNames.eachBenefit}>
          <div className={classNames.suitIcon}>
            <BsFillPersonFill />
          </div>
          <div className={classNames.content}>
            <div className={classNames.title}>Abhishek Sawant</div>
            <div className={classNames.desc}>Son • 7208788639 • 09/09/2000</div>
          </div>
        </div>
        <div className={classNames.eachBenefit}>
          <div className={classNames.suitIcon}>
            <BsFillPersonPlusFill />
          </div>
          <div className={classNames.content}>
            <div className={classNames.addBtn}>Add Spouse</div>
          </div>
        </div>
        <div className={classNames.eachBenefit}>
          <div className={classNames.suitIcon}>
            <BsFillPersonPlusFill />
          </div>
          <div className={classNames.content}>
            <div className={classNames.addBtn}>Add Child</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
