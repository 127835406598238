import React, { useEffect, useRef, useState } from "react";
import classNames from "./enrollmentformvisista.module.scss";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

import fullLogo from "../../assest/images/fullLogo.svg";
import rahaWhiteLogo from "../../assest/images/logos/rahawhite.svg";
import calenderIcon from "../../assest/images/icons/calender.svg";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import useWindowDimensions from "../../utils/WindowSize";
import Axios from "axios";
import { predefinedToast } from "../../utils/toast";
import { useContext } from "react";
import { BankContext } from "../../context/Context";
import { LoadingAnimationRahaLogo } from "../../components/Custom";

import iobLogo from "../../assest/images/logos/iob.svg";
import premiumTable from "../../assest/images/prelogin/premiumtable.jpg";
import enrollmentVisista from "../../assest/images/logos/enrollmentvisista.svg";
import enrollmentVisistaBG from "../../assest/images/prelogin/vivistaformbg.png";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const EnrollmentFormVisista = () => {
  const { width, height } = useWindowDimensions();
  const { history } = useHistory();
  const { status } = useParams();
  const { updateLoading, setUpdateLoading, reqValue, setreqValue } =
    useContext(BankContext);
  const [totalPremiumAmount, setTotalPremiumAmount] = useState(0);
  const [totalPremiumAmountDisplay, setTotalPremiumAmountDisplay] =
    useState("");
  const [baseAmount, setBaseAmount] = useState("");
  const [baseAmountSpouse, setBaseAmountSpouse] = useState("");
  const [redirectPage, setRedirectPage] = useState(false);

  const [dependentsProfile, setDependentsProfile] = useState({
    name: "",
    date_of_birth: "",
    gender: "",
    relationship: "",
    email_id: "",
    phone_number: "",
    association_id: "",
    sum_insured: "",
  });
  const [enrollmentData, setEnrollmentData] = useState({
    policy_type: "Base Policy",
    member_type: "Employee Only",
    sum_insured: "",
    name: "",
    date_of_birth: "MM-DD-YYYY",
    gender: "",
    relationship: "",
    email_id: "",
    phone_number: "",
    association_id: "",
    insurance_premium: "",
    dependents_data: [],
  });

  async function getPremiumAmount() {
    if (
      enrollmentData?.member_type == "Employee Only" &&
      baseAmount?.base_policy_amount == "3,00,000.00"
    ) {
      setTotalPremiumAmount(26000);
      setTotalPremiumAmountDisplay("26,000.00");
    } else if (
      enrollmentData?.member_type == "Employee Only" &&
      baseAmount?.base_policy_amount == "5,00,000.00"
    ) {
      setTotalPremiumAmount(36000);
      setTotalPremiumAmountDisplay("36,000.00");
    } else if (
      enrollmentData?.member_type == "Employee + Spouse" &&
      baseAmount?.base_policy_amount == "3,00,000.00" &&
      baseAmountSpouse?.base_policy_amount == "3,00,000.00"
    ) {
      setTotalPremiumAmount(52000);
      setTotalPremiumAmountDisplay("52,000.00");
    } else if (
      enrollmentData?.member_type == "Employee + Spouse" &&
      baseAmount?.base_policy_amount == "5,00,000.00" &&
      baseAmountSpouse?.base_policy_amount == "5,00,000.00"
    ) {
      setTotalPremiumAmount(72000);
      setTotalPremiumAmountDisplay("72,000.00");
    } else if (
      enrollmentData?.member_type == "Employee + Spouse" &&
      (baseAmount?.base_policy_amount == "3,00,000.00" ||
        baseAmount?.base_policy_amount == "5,00,000.00") &&
      (baseAmountSpouse?.base_policy_amount == "3,00,000.00" ||
        baseAmountSpouse?.base_policy_amount == "5,00,000.00")
    ) {
      setTotalPremiumAmount(62000);
      setTotalPremiumAmountDisplay("62,000.00");
    }

    // setTotalPremiumAmount(premiumAmount);

    // if (
    //   enrollmentData?.member_type == "Employee Only" &&
    //   premiumAmount?.length > 0
    // ) {
    //   setTotalPremiumAmount(premiumAmount[0]?.selfPremium);
    // } else if (
    //   enrollmentData?.member_type == "Employee + Spouse" &&
    //   premiumAmount?.length > 0
    // ) {
    //   setTotalPremiumAmount(premiumAmount[0]?.bothPremium);
    // }
    // console.log(premiumAmount, "premiumAmount");
  }

  useEffect(() => {
    getPremiumAmount();
  }, [enrollmentData, baseAmount, baseAmountSpouse]);

  function submitIOB() {
    setUpdateLoading(true);

    enrollmentData.insurance_premium = totalPremiumAmount;
    enrollmentData.sum_insured = baseAmount?.base_policy_amount;

    dependentsProfile.sum_insured = baseAmountSpouse?.base_policy_amount;

    let selfObj = {
      name: enrollmentData?.name,
      date_of_birth: enrollmentData?.date_of_birth,
      gender: enrollmentData?.gender,
      relationship: enrollmentData?.relationship,
      email_id: enrollmentData?.email_id,
      phone_number: enrollmentData?.phone_number,
      association_id: enrollmentData?.association_id,
      sum_insured: baseAmount?.base_policy_amount,
    };

    if (enrollmentData?.member_type == "Employee + Spouse") {
      enrollmentData.dependents_data = [selfObj, dependentsProfile];
    } else {
      enrollmentData.dependents_data = [selfObj];
    }

    Axios.post(
      "https://insurance.apimachine.com/insurance/iob/transaction/initiate",
      {
        totalamt: totalPremiumAmount,
        user_id: enrollmentData?.association_id,
        user_email: enrollmentData?.email_id,
      }
    )
      .then((response) => {
        console.log(response?.data, "submitIOB payment gateway response");
        // setUpdateLoading(false);
        setreqValue(response?.data?.data);

        if (response?.data?.data) {
          // predefinedToast(response?.data?.message);
          setRedirectPage(true);
        } else {
          setUpdateLoading(false);
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "submitIOB payment gateway error");
        predefinedToast(error?.message);
        setUpdateLoading(false);
      });

    // Axios.post(
    //   "https://insurance.apimachine.com/insurance/general/iob/add",
    //   enrollmentData
    // )
    //   .then((response) => {
    //     // console.log(response?.data, "submitIOB response");
    //     if (response?.data?.status) {
    //       setUpdateLoading(false);
    //       predefinedToast(response?.data?.message);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 3000);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error?.message, "submitIOB error");
    //     predefinedToast(error?.message);
    //     setUpdateLoading(false);
    //   });

    console.log(enrollmentData, "Enrollment data final");
  }

  useEffect(() => {
    if (redirectPage) {
      autoFormSubmit();
    }
  }, [redirectPage]); // Run once on component mount

  const autoFormSubmit = () => {
    const reqjsonValue = JSON.stringify(reqValue);

    if (reqjsonValue.trim().length > 0) {
      document.getElementById("msg").innerHTML =
        "Processing your transaction. Please Wait.....";
      document.getElementById("form1").submit();
    } else {
      document.getElementById("msg").innerHTML =
        "Server Error. Please contact your website administrator!";
    }
  };

  const txn_initiation_url = "https://www.iobnet.co.in/iobpay/apitxninit.do";

  return (
    <div className={classNames.enrollmentFormVisista}>
      <div className={classNames.banner}>
        <img src={enrollmentVisista} alt="enrollmentVisista" />
      </div>
      <div className={classNames.enrollmentForm}>
        <div
          className={classNames.leftPanel}
          style={{ display: width < 800 ? "none" : "" }}
        >
          <div className={classNames.headTitle}>
            PREMIUM CHART FOR GMC ON INDIVIDUAL SUM INSURED BASIS (Including
            GST)
          </div>
          <img src={premiumTable} alt="premiumTable" />

          <div style={{ width: "100%" }}>
            <SelectTag
              title="Member Type"
              name="member_type"
              allItems={["Employee Only", "Employee + Spouse"]}
              selectedValue={enrollmentData}
              setSelectedValue={setEnrollmentData}
            />

            <div className={classNames.multipleInputsWithTitle}>
              <div className={classNames.title}>Sum Insured</div>
              <div
                className={`${classNames.multipleInputs} ${
                  width < 800 ? classNames.multipleInputsMobile : ""
                }`}
                style={{
                  flexDirection: width < 800 ? "column" : "",
                  alignItems: width < 800 ? "flex-start" : "",
                }}
              >
                {enrollmentData?.policy_type == "Base Policy" ||
                enrollmentData?.policy_type == "Both" ? (
                  <CustomDropdown
                    dropdown={[
                      "3,00,000.00",
                      "5,00,000.00",
                      // "7,00,000.00",
                      // "9,00,000.00",
                      // "10,00,000.00",
                    ]}
                    name="base_policy_amount"
                    stateValue={baseAmount}
                    setState={setBaseAmount}
                    title={"Employee"}
                  />
                ) : (
                  ""
                )}
                {enrollmentData?.policy_type == "Base Policy" &&
                enrollmentData?.member_type == "Employee + Spouse" ? (
                  <CustomDropdown
                    dropdown={[
                      "3,00,000.00",
                      "5,00,000.00",
                      // "7,00,000.00",
                      // "9,00,000.00",
                      // "10,00,000.00",
                    ]}
                    name="base_policy_amount"
                    stateValue={baseAmountSpouse}
                    setState={setBaseAmountSpouse}
                    title={"Spouse"}
                  />
                ) : (
                  ""
                )}
                {enrollmentData?.policy_type == "Super Top-up Policy" ||
                enrollmentData?.policy_type == "Both" ? (
                  <CustomDropdown
                    dropdown={[""]}
                    name="allbrokerages"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                    title={"Super Top-up policy"}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className={classNames.title} style={{ marginTop: "3rem" }}>
              Disclaimer: Please review the details and confirm to proceed. By
              confirming, you agree to proceed with payment. Payment details
              with the transaction reference number will be emailed to you.
            </div>
            <div className={classNames.priceDiv}>
              <div>Insurance Premium</div>
              <div>
                INR{" "}
                {totalPremiumAmountDisplay ? totalPremiumAmountDisplay : "0.00"}
                /- <span>Including GST</span>
              </div>
            </div>
            {/* <div
              className={`${classNames.multipleInputs} ${
                width < 800 ? classNames.multipleInputsMobile : ""
              }`}
              style={{ marginTop: "1rem" }}
            >
              <EachInput
                title="OTP"
                placeholder="Enter OTP.."
                name="otp"
                stateValue={enrollmentData}
                setState={setEnrollmentData}
              />
              <div
                className={classNames.normalBtn}
                style={{ width: width < 800 ? "max-content" : "" }}
              >
                Get OTP
              </div>
            </div> */}
          </div>
        </div>
        <div
          className={classNames.rightPanel}
          style={{
            width: width < 800 ? "100%" : "",
            borderRadius: width < 800 ? "0" : "",
            padding: width < 800 ? "10rem 1rem" : "",
            paddingTop: width < 800 ? "0" : "",
          }}
        >
          {updateLoading ? (
            <LoadingAnimationRahaLogo
              height={"80%"}
              position="absolute"
              opacity="0.6"
            />
          ) : (
            ""
          )}
          {width < 800 ? (
            <div className={classNames.headPanel}>
              <img src={rahaWhiteLogo} alt="rahaWhiteLogo" />
            </div>
          ) : (
            ""
          )}

          <div className={classNames.personalDetails}>
            {enrollmentData?.member_type == "Employee Only" ||
            enrollmentData?.member_type == "Employee + Spouse" ? (
              <>
                <div className={classNames.title}>Personal Details</div>
                <div
                  className={`${classNames.multipleInputs} ${
                    width < 800 ? classNames.multipleInputsMobile : ""
                  }`}
                  style={{ marginTop: "1rem" }}
                >
                  <EachInput
                    title="Name"
                    placeholder="Enter Name.."
                    name="name"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                  />
                  <EachInputDatePicker
                    title="Date of Birth"
                    placeholder="Click To Select"
                    name="date_of_birth"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                  />
                  <EachInput
                    title="Age"
                    placeholder="Enter age.."
                    name="age"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                  />
                  <EachInput
                    title="Roll Number"
                    placeholder="Enter Roll Number.."
                    name="rollnumber"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                  />
                  <CustomDropdown
                    dropdown={["Self", "Spouse"]}
                    name="relationship"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                    title={"Relationship"}
                  />
                  <CustomDropdown
                    dropdown={["Male", "Female"]}
                    name="gender"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                    title={"Gender"}
                  />
                </div>
                {/* <div
                  className={`${classNames.multipleInputs} ${
                    width < 800 ? classNames.multipleInputsMobile : ""
                  }`}
                  style={{ marginTop: "1rem" }}
                >
                  <EachInput
                    title="Email ID"
                    placeholder="Enter address.."
                    name="email_id"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                  />
                  <EachInput
                    title="Phone Number"
                    placeholder="Enter address.."
                    name="phone_number"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                  />
                  <EachInput
                    title="Associate ID"
                    placeholder="Enter address.."
                    name="association_id"
                    stateValue={enrollmentData}
                    setState={setEnrollmentData}
                  />
                </div> */}
              </>
            ) : (
              ""
            )}
            {enrollmentData?.member_type == "Employee + Spouse" ? (
              <>
                <div className={classNames.title} style={{ marginTop: "3rem" }}>
                  Dependent Details
                </div>
                <div
                  className={`${classNames.multipleInputs} ${
                    width < 800 ? classNames.multipleInputsMobile : ""
                  }`}
                  style={{ marginTop: "1rem" }}
                >
                  <EachInput
                    title="Name"
                    placeholder="Enter Name.."
                    name="name"
                    stateValue={dependentsProfile}
                    setState={setDependentsProfile}
                  />
                  <EachInputDatePicker
                    title="Date of Birth"
                    placeholder="Click To Select"
                    name="date_of_birth"
                    stateValue={dependentsProfile}
                    setState={setDependentsProfile}
                  />
                  <EachInput
                    title="Age"
                    placeholder="Enter Age.."
                    name="age"
                    stateValue={dependentsProfile}
                    setState={setDependentsProfile}
                  />
                  <EachInput
                    title="Roll No"
                    placeholder="Enter Roll No.."
                    name="age"
                    stateValue={dependentsProfile}
                    setState={setDependentsProfile}
                  />

                  <CustomDropdown
                    dropdown={["Self", "Spouse"]}
                    name="relationship"
                    stateValue={dependentsProfile}
                    setState={setDependentsProfile}
                    title={"Relationship"}
                  />
                  <CustomDropdown
                    dropdown={["Male", "Female"]}
                    name="gender"
                    stateValue={dependentsProfile}
                    setState={setDependentsProfile}
                    title={"Gender"}
                  />
                </div>
                {/* <div
                  className={`${classNames.multipleInputs} ${
                    width < 800 ? classNames.multipleInputsMobile : ""
                  }`}
                  style={{ marginTop: "1rem" }}
                >
                  <EachInput
                    title="Email ID"
                    placeholder="Enter address.."
                    name="email_id"
                    stateValue={dependentsProfile}
                    setState={setDependentsProfile}
                  />
                  <EachInput
                    title="Phone Number"
                    placeholder="Enter address.."
                    name="phone_number"
                    stateValue={dependentsProfile}
                    setState={setDependentsProfile}
                  />
                  <EachInput
                    title="Associate ID"
                    placeholder="Enter address.."
                    name="association_id"
                    stateValue={dependentsProfile}
                    setState={setDependentsProfile}
                  />
                </div> */}
              </>
            ) : (
              ""
            )}
            <div className={classNames.visistaInputs}>
              <div className={classNames.title}>Address 1</div>
              <EachInput
                placeholder="Enter Address 1.."
                name="address1"
                stateValue={dependentsProfile}
                setState={setDependentsProfile}
              />
            </div>
            <div className={classNames.visistaInputs}>
              <div className={classNames.title}>Address 2</div>
              <EachInput
                placeholder="Enter Address 2.."
                name="address2"
                stateValue={dependentsProfile}
                setState={setDependentsProfile}
              />
            </div>
            <div className={classNames.visistaInputs}>
              <div className={classNames.visistaInputs}>
                <div className={classNames.title}>City</div>
                <EachInput
                  placeholder="Enter City.."
                  name="City"
                  stateValue={dependentsProfile}
                  setState={setDependentsProfile}
                />
              </div>
              <div className={classNames.visistaInputs}>
                <div className={classNames.title}>State</div>
                <EachInput
                  placeholder="Enter State.."
                  name="State"
                  stateValue={dependentsProfile}
                  setState={setDependentsProfile}
                />
              </div>
            </div>
            <div className={classNames.visistaInputs}>
              <div className={classNames.title}>Pincode</div>
              <EachInput
                placeholder="Enter Pincode.."
                name="pincode"
                stateValue={dependentsProfile}
                setState={setDependentsProfile}
              />
            </div>
            <div className={classNames.visistaInputs}>
              <div className={classNames.title}>Email</div>
              <EachInput
                placeholder="Enter Email.."
                name="email"
                stateValue={dependentsProfile}
                setState={setDependentsProfile}
              />
            </div>
            <div className={classNames.visistaInputs}>
              <div className={classNames.title}>Mobile</div>
              <EachInput
                placeholder="+91"
                name="countrycode"
                stateValue={dependentsProfile}
                setState={setDependentsProfile}
                widthh="maxwidth"
              />
              <EachInput
                placeholder="Enter Mobile Number.."
                name="mobilenumber"
                stateValue={dependentsProfile}
                setState={setDependentsProfile}
              />
            </div>
            <div
              className={classNames.submitBtn}
              // onClick={submitIOB}
            >
              Submit
            </div>
          </div>
          {/* <div
            className={classNames.bottomBanner}
            style={{
              width: width < 800 ? "100%" : "",
              padding: width < 800 ? "1rem" : "",
              borderRadius: width < 800 ? "0" : "",
            }}
          >
            <div className={classNames.priceDiv}>
              <div>
                INR{" "}
                {totalPremiumAmountDisplay ? totalPremiumAmountDisplay : "0.00"}
                /-
              </div>
              <div>Insurance Premium (Including GST)</div>
            </div>
            <div className={classNames.submitBtn} onClick={submitIOB}>
              Submit
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default EnrollmentFormVisista;

const SelectTag = ({
  title,
  name,
  allItems,
  selectedValue,
  setSelectedValue,
}) => {
  return (
    <div className={classNames.customRadio}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.radioParent}>
        {allItems?.map((eachItem, index) => (
          <div key={eachItem + index} className={classNames.eachRadioBtn}>
            <input
              type="radio"
              id={eachItem}
              name={name}
              value={eachItem}
              checked={selectedValue[name] == eachItem}
              onChange={() => {
                setSelectedValue((prev) => {
                  return { ...prev, [name]: eachItem };
                });
                console.log(selectedValue, "selectedValue");
              }}
            />
            <label htmlFor={eachItem}>{eachItem}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

const CustomDropdown = ({
  title,
  subtitle,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{ minWidth: isOpen ? "250px" : "", zIndex: isOpen ? "4" : "" }}
    >
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{ pointerEvents: dropdown ? "" : "none" }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOption
              ? selectedOption
              : title == "Employee" || title == "Spouse"
              ? "Select Coverage"
              : title
              ? "Select " + title
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {/* <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li> */}
            {title === "All Countries"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState(eachitem);
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                      >
                        {eachitem}
                      </li>
                    );
                  })}
          </ul>
        )}
      </div>
    </div>
  );
};

const EachInput = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  widthh,
}) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
        style={{ width: widthh ? "70px" : "" }}
      />
    </div>
  );
};

const EachInputDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (
      (title?.toLowerCase()?.includes("birth") ||
        placeholder?.toLowerCase()?.includes("birth")) &&
      datepickerRef.current
    ) {
      const currentDate = new Date();

      const minDate = new Date(currentDate);
      const maxDate = new Date(currentDate);

      // Calculate minDate for a 80-year-old person
      minDate.setFullYear(currentDate.getFullYear() - 80);

      // Calculate maxDate for a 25-year-old person
      maxDate.setFullYear(currentDate.getFullYear() - 25);

      const options = {
        dateFormat: "d/m/Y",
        // Other options here
        minDate: minDate,
        maxDate: maxDate,
        onChange: function (selectedDates, dateStr, instance) {
          setState({ ...stateValue, [name]: dateStr });
        },
      };

      flatpickr(datepickerRef.current, options);
    } else if (datepickerRef.current) {
      const options = {
        dateFormat: "d/m/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          setState({ ...stateValue, [name]: dateStr });
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, [datepickerRef, name, stateValue, setState]);

  return (
    <div className={classNames.eachInputDatePicker}>
      <div className={classNames.title}>{title}</div>
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        value={stateValue[name]}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event.target.value });
        }}
      />
      <img
        src={calenderIcon}
        alt="calenderIcon"
        className={classNames.calendarIcon}
      />
    </div>
  );
};
