import React, { useContext, useEffect, useState, useRef } from "react";
import classNames from "../dashboard.module.scss";
import styles from "./ap.module.scss";

import flatpickr from "flatpickr";

import { IoMdClose, IoMdCloseCircleOutline, IoMdSearch } from "react-icons/io";

import arrowDown from "./asset/arrowDown.svg";
import calendarIcon from "./asset/calendar.svg";
import calenderIcon from "../../../../assest/images/icons/calender.svg";
import uploadIcon from "./asset/upload.svg";
import Axios from "axios";
import { uploadFileFunc, uploadImageFunc } from "../../../../utils/imageUpload";
import { BankContext } from "../../../../context/Context";

import { ReactComponent as AddIcon } from "../../../../assest/images/allicons/tableaction/add.svg";

const AssignRole = ({ showAssignPopup, setShowAssignPopup, getAllLeads }) => {
  const { setUpdateLoading, globalHrId } = useContext(BankContext);
  const [showAllRoleOwner, setShowAllRoleOwner] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAssignRole = () => {
    setLoading(true);

    Axios.put(
      `https://api-dev.rahainsure.com/api/v1/raha-lead/mapping/update/${showAssignPopup?.foundData?._id}`,
      {
        user_role_mapping_id: selectedRole?._id,
        user_id: selectedRole?.user_id?._id,
      }
    ).then(({ data }) => {
      if (data.status) {
        setLoading(false);
        setShowAssignPopup(null);
        getAllLeads();
      }
    });
  };

  const getAllRole = () => {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/raha-user/role/mapping?role_id=${showAssignPopup?.foundData?.role_id?._id}`
    ).then(({ data }) => {
      if (data.status) {
        setAllRoles(data.data);
      }
    });
  };

  useEffect(() => {
    console.log(showAssignPopup, "lehflwheflwhflwhfl");
    if (showAssignPopup) {
      getAllRole();
    }
  }, [showAssignPopup]);

  return (
    <>
      <div
        className={classNames.overlay}
        onClick={(e) => {
          setShowAssignPopup(false);
        }}
      ></div>
      <div className={styles.quoteDetailsWrapper} style={{ width: "50vw" }}>
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => {
            setShowAssignPopup(false);
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>Assign Role</div>
          <div className={styles.rowWrapper1}>
            <div style={{ fontWeight: 700 }}>What role are you assigning</div>
            <div
              className={styles.inputGroup}
              style={{
                border: "1px solid #e7e7e7",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div>
                {showAssignPopup?.foundData?.role_id?.role_designation} |{" "}
                {showAssignPopup?.foundData?.role_id?.role_type}
              </div>
              <div style={{ fontWeight: "400" }}>
                {showAssignPopup?.foundData?.role_id?._id}
              </div>
            </div>
          </div>

          <div className={styles.rowWrapper1}>
            <div style={{ fontWeight: 700 }}>For which user</div>
            <div
              className={styles.inputGroup}
              style={{
                border: "1px solid #e7e7e7",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div>
                {showAssignPopup?.fullData?.first_name}&nbsp;
                {showAssignPopup?.fullData?.last_name} |{" "}
                {showAssignPopup?.fullData?.company_name}
              </div>
              <div style={{ fontWeight: "400" }}>
                {showAssignPopup?.foundData?._id}
              </div>
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div style={{ fontWeight: 700 }}>Select the new role owner</div>
            {!showAllRoleOwner ? (
              <div
                className={styles.dropdown}
                onClick={(e) => setShowAllRoleOwner(!showAllRoleOwner)}
              >
                <div>
                  {selectedRole
                    ? selectedRole?.user_id?.user_email_id
                    : "Select role owner"}
                </div>
                <img
                  src={arrowDown}
                  alt=""
                  onClick={(e) => setShowAllRoleOwner(false)}
                />
              </div>
            ) : (
              <div className={styles.dropdown1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                  onClick={(e) => setShowAllRoleOwner(!showAllRoleOwner)}
                >
                  <div>Select Quote</div>
                  <img src={arrowDown} alt="" />
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    height: "150px",
                    overflowY: "scroll",
                  }}
                >
                  {allRoles?.map((item, index) => (
                    <div
                      className={styles.individualQuote}
                      onClick={(e) => {
                        setSelectedRole(item);
                        setShowAllRoleOwner(false);
                      }}
                    >
                      {/* <div>Name</div> */}
                      <div>{item?.user_id?.user_email_id}</div>
                      <div>
                        {item?._id} | {item?.role_id?._id}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Terms */}

          <div className={styles.rowWrapper1}>
            <div
              onClick={(e) => {
                handleAssignRole();
              }}
              className={styles.addBtn}
              style={{
                opacity: true ? 1 : 0.5,
              }}
            >
              {loading ? "Saving.." : "Save"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignRole;
