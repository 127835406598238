import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  PureComponent,
  useCallback,
} from "react";
import classNames from "./crm.module.scss";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import moment from "moment";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Axios from "axios";
import { BankContext } from "../../../context/Context";
import CustomCheckbox, { CopyBtn } from "../../../components/Custom";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import NavbarV2 from "../../../components/NavbarV2";

import { ReactComponent as FilterIcon } from "../../../assest/images/allicons/filter.svg";
import { ReactComponent as SortArrows } from "../../../assest/images/allicons/sort.svg";

//action buttons icons
import { ReactComponent as EditIcon } from "../../../assest/images/allicons/tableaction/edit.svg";
import { ReactComponent as EyeIcon } from "../../../assest/images/allicons/tableaction/eye1.svg";
import { ReactComponent as FileIcon } from "../../../assest/images/allicons/tableaction/file.svg";
import { ReactComponent as UploadIcon } from "../../../assest/images/allicons/tableaction/upload.svg";
import { ReactComponent as QrcIcon } from "../../../assest/images/allicons/tableaction/qrc.svg";
import { ReactComponent as KeyIcon1 } from "../../../assest/images/allicons/tableaction/key1.svg";
import { ReactComponent as DownloadIcon } from "../../../assest/images/allicons/tableaction/download.svg";
import { ReactComponent as EmailIcon } from "../../../assest/images/allicons/tableaction/email.svg";
import { ReactComponent as BandaidIcon } from "../../../assest/images/allicons/tableaction/bandaid.svg";
import { ReactComponent as LockIcon } from "../../../assest/images/allicons/tableaction/lock.svg";
import { ReactComponent as DependentsIcon } from "../../../assest/images/allicons/tableaction/dependents.svg";
import { ReactComponent as AddIcon } from "../../../assest/images/allicons/tableaction/add.svg";
import { ReactComponent as TrashIcon } from "../../../assest/images/allicons/tableaction/trash.svg";
import { ReactComponent as PeoplesIcon } from "../../../assest/images/allicons/tableaction/peoples.svg";
import { ReactComponent as PeopleAddIcon } from "../../../assest/images/allicons/tableaction/peopleadd.svg";
import { ReactComponent as PeopleAddRemoveIcon } from "../../../assest/images/allicons/tableaction/peopleaddremove.svg";
import { ReactComponent as KeyIcon } from "../../../assest/images/allicons/tableaction/key.svg";
import { ReactComponent as SingleAddIcon } from "../../../assest/images/allicons/tableaction/singleadd.svg";
import { ReactComponent as BulkAddIcon } from "../../../assest/images/allicons/tableaction/bulkadd.svg";
import { ReactComponent as ReregisterIcon } from "../../../assest/images/allicons/tableaction/reregister.svg";
import { ReactComponent as DeleteIcon } from "../../../assest/images/allicons/tableaction/delete.svg";
import arrowRight1 from "../../../assest/images/allicons/tableaction/arrowRight1.svg";
import healthDownload from "../../../assest/images/allicons/tableaction/healthDownload.svg";
import { useHistory } from "react-router-dom";
import {
  MdKeyboardDoubleArrowUp,
  MdKeyboardDoubleArrowDown,
} from "react-icons/md";
import { IoIosTrendingUp } from "react-icons/io";

import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdClose, IoMdSearch } from "react-icons/io";
import donutGraph from "./dummygraph/donutRound.svg";
import barGraph from "./dummygraph/barGraph.svg";

import expandIcon from "../../../assest/images/icons/expand.svg";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { crmMainScore } from "../../../assest/data/associates/crm";
import ClientOnboardingForm from "./tabs/ClientOnboardingForm";
import { getData } from "../../../APIs";
import NavbarV3 from "../../../components/NavbarV3";
import DropdownUi from "./DropdownUi";
import UploadQCR from "./UploadQCR";
import AddCorporateLead, { CostCenter } from "./AddCorporateLead";
import { logoutToSameLogin } from "../../../assest/functions";
import { allFollowUpStatus } from "../../../assest/data/constData";

ChartJS.register(ArcElement, Tooltip, Legend);

const fetchUsersAllCompaniesDropdown = (setUsersAllCompany, email) => {
  Axios.get(
    `https://insurance.apimachine.com/insurance/corporate/get?owner_email=${email}`
  )
    .then((response) => {
      // console.log(response, "Users allCompanies dropdown response");
      if (response?.data?.status) {
        setUsersAllCompany(response?.data?.data);
      }
    })
    .catch((error) => {
      console.log(error?.message, "UsersAllCompanies dropdown error");
    });
};

const CRMAssociate = () => {
  const history = useHistory();
  const {
    email,
    popularAction,
    setPopularAction,
    actionBtns,
    setActionBtns,
    globalHrId,
    setUpdateLoading,
    globalSelectedPolicy,
    addDependentSidebar,
    setAddDependentSidebar,
    lastSelectedEmployee,
    setLastSelectedEmployee,
  } = useContext(BankContext);

  const [selectedProfileNav, setSelectedProfileNav] = useState("CRM");

  const [filterOpen, setFilterOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [usersAllCompany, setUsersAllCompany] = useState("");
  const [usersAllCompanySelected, setUsersAllCompanySelected] = useState("");

  const [usersAllStates, setUsersAllStates] = useState("");
  const [usersAllStatesSelected, setUsersAllStatesSelected] = useState("");
  const [toggleSelectedTable, setToggleSelectedTable] = useState("Simplified");

  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [allLeads, setAllLeads] = useState("");
  const [allLeadsAPI, setAllLeadsAPI] = useState("");
  const [allLeadsLoading, setAllLeadsLoading] = useState("");
  const [allBrokeragesTotalProfile, setAllBrokeragesTotalProfile] =
    useState("");

  const [allLeadsNew, setAllLeadsNew] = useState([]);
  const [allLeadsLoadingNew, setAllLeadsLoadingNew] = useState(true);
  const [selectedLead, setSelectedLead] = useState(null);
  const dropdownRef = useRef();
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [allQuotes, setAllQuotes] = useState([]);
  const [showQuoteDetails, setShowQuoteDetails] = useState(null);
  const [selectedQuoteTab, setSelectedQuoteTab] = useState("Overview");
  const quoteTabs = [
    "Overview",
    "Configurations",
    "Members",
    "Pre-Enrolment Tracking",
    "RFQ’s",
  ];
  const confTabs = ["Health", "Accidental", "Life"];
  const [selectedConfTab, setSelectedConfTab] = useState("Health");
  const [confData, setConfData] = useState(null);

  const [enrollData, setEnrollData] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState("");

  const [showUploadQRC, setShowUploadQRC] = useState(false);
  const [rfqData, setRfqData] = useState([]);

  const [showNote, setShowNote] = useState(null);
  const [showOnBoardDropDown, setShowOnBoardDropDown] = useState(false);

  const [showAddCorporate, setShowAddCorporate] = useState(false); // default (false)

  const [crmStat, setCrmStat] = useState(null);
  const [showDeleteConf, setShowDeleteConf] = useState(null);

  const onboardDropDownList = [
    // {
    //   ddName: "New individual lead",
    //   ddLink: "/app/agency/individuallead",
    // },
    {
      ddName: "Create Lead",
      ddLink: "",
    },
    // {
    //   ddName: "New lead & client",
    //   ddLink: "/app/agency/newleadclient",
    // },
    // {
    //   ddName: "On-Boaard Client",
    //   ddLink: "/app/agency/hrclient",
    // },
    // {
    //   ddName: "Convert lead to client",
    //   ddLink: null,
    // },
  ];

  //imported from store
  const [allSelectedPolicyClaims, setSelectedPolicyClaims] = useState([]);
  const [allSelectedPolicyClaimsLoading, setSelectedPolicyClaimsLoading] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [queriedFilter, setQueriedFilter] = useState("beneficiarY_NAME");
  const [refreshData, setRefreshData] = useState(false);

  const handleDelete = (item) => {
    console.log(item, "kjwegfkwgefkwjgefkjgwekfjg");
    Axios.put(
      `https://insurance.apimachine.com/insurance/policy/delete/quote?quote_id=${item?.quote_id}`
    ).then(({ data }) => {
      if (data.status) {
        setShowDeleteConf(false);
        getAllQuotes();
      }
    });
  };

  const getConfiguration = (data1) => {
    Axios.get(
      `https://insurance.apimachine.com/insurance/policy/getQuotation?quote_id=${showQuoteDetails?.quote_id}&insurance_type=${data1}`
    ).then(({ data }) => {
      console.log(data, data1, "kjhkgkjhfjhfuj");
      if (data.status) {
        setConfData(data.data[0]);
      } else {
        setConfData(null);
      }
    });
  };

  const getEnrollData = () => {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/employee-spreadsheet/get/quote/progress?quote_id=${showQuoteDetails?.quote_id}`
    )
      .then(({ data }) => {
        if (data.status) {
          console.log(data, "868768687687687768");
          setEnrollData(data.data[0]);
        } else {
          setEnrollData(null);
        }
      })
      .catch((err) => {
        setEnrollData(null);
      });
  };

  const getRFQData = () => {
    Axios.get(
      `https://insurance.apimachine.com/insurance/policy/get/rfq?quote_id=${showQuoteDetails?.quote_id}`
    ).then(({ data }) => {
      if (data.status) {
        setRfqData(data.data);
      }
    });
  };

  useEffect(() => {
    if (selectedQuoteTab === "Configurations") {
      getConfiguration(selectedConfTab);
    } else if (selectedQuoteTab === "Pre-Enrolment Tracking") {
      getEnrollData();
    } else if (selectedQuoteTab === "RFQ’s") {
      getRFQData();
    }
  }, [selectedQuoteTab, selectedConfTab]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSelectedLead(null);
    }
  };

  const getAllQuotes = () => {
    Axios.get(
      `https://insurance.apimachine.com/insurance/policy/get/quote?email_id=${selectedQuote?.lead_email}`
    ).then(({ data }) => {
      if (data?.data?.length > 0) {
        setAllQuotes(data.data);
        window.scrollTo(document.body.scrollWidth, document.body.scrollHeight);

        if (showQuoteDetails) {
          let filterRes = data?.data?.filter((eachItem, index) => {
            return eachItem?._id === showQuoteDetails?._id;
          });
          if (filterRes?.length > 0) {
            setShowQuoteDetails(filterRes[0]);
          }
        }
      } else {
        setAllQuotes([]);
        setSelectedQuote(null);
      }
    });
  };

  useEffect(() => {
    if (selectedQuote) {
      getAllQuotes();
    }
  }, [selectedQuote, refreshData]);

  useEffect(() => {
    if (selectedQuote) {
      getAllQuotes();
    }
  }, [refreshData]);

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getTableData = () => {
    if (
      localStorage.getItem("loggedInUserRoleId") &&
      localStorage.getItem("loggedInUserId")
    ) {
      setAllLeadsLoadingNew(true);

      Axios.get(
        `https://api-dev.rahainsure.com/api/v1/raha-lead/mapping/project/?role_id=${localStorage.getItem(
          "loggedInUserRoleId"
        )}&user_id=${localStorage.getItem(
          "loggedInUserId"
        )}&quote_status=In-Process`
      )
        .then(({ data }) => {
          if (data.status) {
            setAllLeadsNew(data.data);
            setAllLeadsLoadingNew(false);
          } else {
            setAllLeadsLoadingNew(false);
            setAllLeadsNew([]);
          }
        })
        .catch((err) => {
          setAllLeadsLoadingNew(false);
          setAllLeadsNew([]);
        });
    }
  };

  const handleClosePreEnroll = () => {
    Axios.put(
      `https://insurance.apimachine.com/insurance/policy/update/quote?quote_id=${showQuoteDetails?.quote_id}`,

      {
        completed_Step: "Pre-Enrollment Tracking",
        next_Step: "History",
      }
    ).then(({ data }) => {
      if (data.status) {
        setRefreshData((prev) => !prev);
      }
    });
  };

  const getCRMStat = () => {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/raha-lead/mapping/statistics/?role_id=${localStorage.getItem(
        "loggedInUserRoleId"
      )}&user_id=${localStorage.getItem("loggedInUserId")}`
    )
      .then(({ data }) => {
        if (data.status && data?.data?.length > 0) {
          setCrmStat(data.data[0]);
        }
      })
      .catch((error) => {
        console.log(error?.message, "get CRM stats error");
      });
  };

  useEffect(() => {
    getCRMStat();
    getTableData();
  }, [
    localStorage.getItem("loggedInUserRoleId"),
    localStorage.getItem("loggedInUserId"),
  ]);

  useEffect(() => {
    // console.log("API is being called");
    setAllLeads("");
    setAllLeadsAPI("");
    setAllBrokeragesTotalProfile("");
    setAllLeadsLoading(true);

    const fetchAllBrokerages = () => {
      let URL = "";
      let body = {
        project: {
          lead_email: 1,
          lead_id: 1,
          lead_type: 1,
          follow_up_status: 1,
          first_name: 1,
          last_name: 1,
          phone_number: 1,
          user_employee_id: 1,
        },
      };
      // if (selectedProfileNav === "Management") {
      //   URL = `https://insurance.apimachine.com/insurance/corporate/get?onlyHr=true&owner_email=${email}`;
      // } else
      if (
        selectedProfileNav == "Employees" &&
        globalHrId &&
        toggleSelectedTable == "Simplified"
      ) {
        URL = `https://insurance.apimachine.com/insurance/lead/get/projected?hr_id=${globalHrId}&follow_up_status=formFilledOut`;
      } else if (
        selectedProfileNav == "Employees" &&
        globalHrId &&
        toggleSelectedTable == "Advanced"
      ) {
        URL = `https://insurance.apimachine.com/insurance/lead/get?hr_id=${globalHrId}&follow_up_status=formFilledOut`;
      } else if (
        selectedProfileNav == "Pending Employees" &&
        globalHrId &&
        toggleSelectedTable == "Simplified"
      ) {
        URL = `https://insurance.apimachine.com/insurance/lead/get/projected?hr_id=${globalHrId}&follow_up_status=formNotFilled`;
      } else if (selectedProfileNav == "Pending Employees" && globalHrId) {
        URL = `https://insurance.apimachine.com/insurance/lead/get?hr_id=${globalHrId}&follow_up_status=formNotFilled`;
      } else {
      }

      if (toggleSelectedTable == "Simplified") {
        Axios.post(URL, body)
          .then((response) => {
            console.log(response, "Simplified HR response");
            if (response?.data?.status) {
              setAllLeads(response?.data?.data);
              setAllLeadsAPI(response?.data?.data);
              setAllBrokeragesTotalProfile(response?.data?.total_profiles);

              if (
                selectedProfileNav == "Companies" &&
                response?.data?.data?.length > 0
              ) {
                const outputArray = response?.data?.data?.map(
                  ({ branchAddressState }) => ({
                    branchAddressState,
                  })
                );
                const uniqueArray = outputArray.filter(
                  (obj, index, self) =>
                    index ===
                    self.findIndex(
                      (o) => o.branchAddressState === obj.branchAddressState
                    )
                );
                // console.log(outputArray, uniqueArray, "Simplified HR response");
                setUsersAllStates(uniqueArray);
              }
            }
            setAllLeadsLoading(false);
          })
          .catch((error) => {
            console.log(error?.message, "Simplified HR error");
            setAllLeadsLoading(false);
          });
      } else {
        Axios.get(URL)
          .then((response) => {
            if (response?.data?.status) {
              setAllLeads(response?.data?.data);
              setAllLeadsAPI(response?.data?.data);
              setAllBrokeragesTotalProfile(response?.data?.total_profiles);

              if (
                selectedProfileNav == "Companies" &&
                response?.data?.data?.length > 0
              ) {
                const outputArray = response?.data?.data?.map(
                  ({ branchAddressState }) => ({
                    branchAddressState,
                  })
                );
                const uniqueArray = outputArray.filter(
                  (obj, index, self) =>
                    index ===
                    self.findIndex(
                      (o) => o.branchAddressState === obj.branchAddressState
                    )
                );
                // console.log(outputArray, uniqueArray, "dropdown");
                setUsersAllStates(uniqueArray);
              }
            }
            setAllLeadsLoading(false);
          })
          .catch((error) => {
            console.log(error?.message, "Advanced HR error");
            setAllLeadsLoading(false);
          });
      }
    };

    fetchAllBrokerages();
  }, [selectedProfileNav, email, toggleSelectedTable]);

  useEffect(() => {
    fetchUsersAllCompaniesDropdown(setUsersAllCompany, email);
  }, [selectedProfileNav]);

  useEffect(() => {
    if (allLeadsAPI?.length > 0 && Array.isArray(allLeadsAPI)) {
      setAllBrokeragesTotalProfile("");
      let res = allLeadsAPI?.filter((row) => {
        const { displayName, branchAddressState, ...newObject } = row;

        // Creating a new object with the extracted properties
        if (selectedProfileNav == "Companies") {
          const separateObject = { branchAddressState };
          // Convert the row object values to an array and check if any value matches the search query
          const values = Object?.values(separateObject);
          const searchQuery = usersAllStatesSelected?.toLowerCase();

          // Check if the selected country matches the object's country (if selected)
          const countryMatches =
            !searchQuery ||
            values.some(
              (value) =>
                typeof value === "string" &&
                searchQuery.toLowerCase().includes(value?.toLowerCase())
            );

          return countryMatches;
          //  && brokerageMatches;
        } else {
        }
      });
      setAllBrokeragesTotalProfile(res.length);
      setAllLeads(res);
    }
  }, [usersAllCompanySelected, usersAllStatesSelected]);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  function onCloseSidebar() {
    if (sidebarOpen) {
      setSidebarOpen((prev) => !prev);
    }
  }

  useEffect(() => {
    if (
      actionBtns?.length > 0 &&
      Array.isArray(actionBtns) &&
      Array.isArray(allLeads)
    ) {
      let response = allLeads?.filter(
        (eachLead) =>
          eachLead?.user_employee_id == actionBtns[actionBtns?.length - 1]
      );
      // console.log(response, "see dependents");
      if (response?.length > 0) {
        setLastSelectedEmployee(response[0]);
      }
    }
  }, [actionBtns, addDependentSidebar]);

  const filterItems = [
    "Active",
    "Invitation Send",
    "Invitation Expired",
    "Pending",
    "Disabled",
    "Deleted",
  ];

  const filterDropdownRef = useRef(null);

  const conditionalTabData = () => {
    if (selectedQuoteTab === "Overview") {
      return (
        <>
          <div className={classNames.lableHeader}>Requesting quote for</div>
          <div className={classNames.quoteSection3}>
            {showQuoteDetails?.what_are_you_looking_for.map((item2) => (
              <div>{item2}</div>
            ))}
          </div>

          {showQuoteDetails?.external_policy_health ||
          showQuoteDetails?.external_policy_accidental ||
          showQuoteDetails?.external_policy_life ? (
            <>
              <div className={classNames.lableHeader}>
                Has existing policy for
              </div>
              <div className={classNames.quoteSection4}>
                {showQuoteDetails?.external_policy_health && (
                  <div className={classNames.section41}>
                    <div style={{ paddingBottom: "10px" }}>Health</div>
                    <img
                      onClick={(e) => {
                        window.open(
                          showQuoteDetails?.external_policy_health_doc,
                          "_blank"
                        );
                      }}
                      src={healthDownload}
                      alt=""
                      style={{
                        width: "22px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
                {showQuoteDetails?.external_policy_accidental && (
                  <div className={classNames.section41}>
                    <div style={{ paddingBottom: "10px" }}>Accident</div>
                    <img
                      onClick={(e) => {
                        window.open(
                          showQuoteDetails?.external_policy_accidental_doc,
                          "_blank"
                        );
                      }}
                      src={healthDownload}
                      alt=""
                      style={{
                        width: "22px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
                {showQuoteDetails?.external_policy_life && (
                  <div className={classNames.section41}>
                    <div style={{ paddingBottom: "10px" }}>Life</div>
                    <img
                      onClick={(e) => {
                        window.open(
                          showQuoteDetails?.external_policy_life_doc,
                          "_blank"
                        );
                      }}
                      src={healthDownload}
                      alt=""
                      style={{
                        width: "22px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
                {/* <div className={classNames.section42}>Accident</div> */}
              </div>
            </>
          ) : (
            ""
          )}
        </>
      );
    } else if (selectedQuoteTab === "Configurations") {
      return (
        <>
          <div className={classNames.quoteSection3}>
            {confTabs.map((tabItem) => (
              <div
                onClick={(e) => setSelectedConfTab(tabItem)}
                className={
                  selectedConfTab === tabItem
                    ? classNames.selectedConfTab
                    : classNames.confTab
                }
              >
                {tabItem}
              </div>
            ))}
          </div>
          {selectedConfTab === "Health" ? (
            confData ? (
              <div>
                <div className={classNames.confDataWrapper}>
                  <div>Covered Persons</div>
                  <div>
                    {confData?.covered_persons.map((cp) => (
                      <span>{cp}, </span>
                    ))}
                  </div>
                </div>
                {confData?.coverage_type && (
                  <div className={classNames.confDataWrapper}>
                    <div>{confData?.coverage_type} Sum Insured</div>
                    <div>
                      {confData?.sum_insured?.map((cp, index) => (
                        <span>
                          {cp}
                          {confData?.sum_insured.length !== index + 1 ? (
                            <span style={{ paddingRight: "5px" }}>,</span>
                          ) : (
                            ""
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                {confData?.roomtype && (
                  <div className={classNames.confDataWrapper}>
                    <div>Room details</div>
                    <div>0.5 Of Premium</div>
                  </div>
                )}
                {confData?.day1coverage && (
                  <div className={classNames.confDataWrapper}>
                    <div>Day 1 coverage</div>
                    <div>Applicable</div>
                  </div>
                )}
                {confData?.maternity && (
                  <div className={classNames.confDataWrapper}>
                    <div>Maternity</div>
                    <div>Applicable</div>
                  </div>
                )}
              </div>
            ) : (
              <div className={classNames.noInsurance}>
                This quote has not requested for Health insurance
              </div>
            )
          ) : selectedConfTab === "Accidental" ? (
            <div>
              {confData ? (
                <div className={classNames.confDataWrapper1}>
                  <div className={classNames.title}>Graded sum insured</div>
                  <div>
                    {confData?.sum_insured.map((cp, index) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div className={classNames.label}>
                          {index === 0 ? "A" : index === 1 ? "B" : "C"}
                        </div>
                        <div className={classNames.value}>{cp}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className={classNames.noInsurance}>
                  This quote has not requested for Accidental insurance
                </div>
              )}
            </div>
          ) : (
            <div className={classNames.noInsurance}>
              This quote has not requested for Life insurance
            </div>
          )}
        </>
      );
    } else if (selectedQuoteTab === "Members") {
      return (
        <div className={classNames.confDataWrapper}>
          <div>Type of Pre-Enrolment</div>
          <div>
            Employee plus spouse, 1 child up to 25 years // not connected to api
          </div>
        </div>
      );
    } else if (selectedQuoteTab === "Pre-Enrolment Tracking") {
      return enrollData ? (
        <div className={classNames.enrollWrapper}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SemiCircleProgressBar
              percentage={enrollData?.percentage?.toFixed(2)}
              // percentage={85}
              showPercentValue
              strokeWidth={25}
              // diameter={200}
            />
            <div className={classNames.btnWrapper}>
              <div
                onClick={() => {
                  if (globalHrId) {
                    window?.open(
                      `https://insurance.apimachine.com/insurance/hr/share/pending/?${globalHrId}`,
                      "_blank"
                    );
                  }
                }}
              >
                Download Data
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => handleClosePreEnroll()}
              >
                Close Pre-Enrolment
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
              <div className={classNames.enrollBox}>
                <div>Total employees</div>
                <div>{enrollData?.totalUsers}</div>
                {/* <div>100</div> */}
              </div>
              <div className={classNames.enrollBox}>
                <div>Pending users</div>
                <div>{enrollData?.pendingUser}</div>
                {/* <div>10</div> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 20,
                flexWrap: "wrap",
                paddingTop: "20px",
              }}
            >
              <div className={classNames.enrollBox}>
                <div>Logged-in users</div>
                <div>{enrollData?.acceptedUser}</div>
                {/* <div>11</div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classNames.noInsurance}>
          This quote hasn’t initiated pre-enrolment
        </div>
      );
    } else if (selectedQuoteTab === "RFQ’s") {
      return rfqData ? (
        <div className={classNames.tableWrapper1}>
          <div className={classNames.tableHeader1}>
            <div>S No</div>
            <div>&nbsp;</div>
            <div>Requested Submitted</div>
            <div>Date Submitted</div>
            <div>Request ID</div>
            <div>Status</div>
          </div>
          <div style={{ height: "27vh", overflowY: "scroll" }}>
            {rfqData?.map((item2, index2) => (
              <div style={{ borderBottom: "1px solid #e7e7e7" }}>
                <div className={classNames.rfqRow}>
                  <div>{index2 + 1}</div>
                  <div>
                    <EditIcon />
                  </div>
                  <div>{item2?.name ? item2?.name : "N/A"}</div>
                  <div>
                    {new Date(item2?.createdAt).toUTCString()?.substring(4, 16)}
                  </div>
                  <div>{item2?.rfq_id}</div>
                  <div className={classNames.rfqStatusWrapper}>
                    <div
                      className={classNames.rfqStatus}
                      style={{
                        backgroundColor:
                          item2?.rfq_status === "Uploaded"
                            ? "#7B61FF"
                            : item2?.rfq_status === "resumbit"
                            ? "#D59445"
                            : item2?.rfq_status === "accept"
                            ? "#2EC697"
                            : item2?.rfq_status === "reject"
                            ? "#F40D0D"
                            : "#2EC697",
                      }}
                    >
                      {item2?.rfq_status === "Uploaded"
                        ? "Under Review"
                        : item2?.rfq_status === "resumbit"
                        ? "Requested New RFQ"
                        : item2?.rfq_status === "accept"
                        ? "Accepted"
                        : item2?.rfq_status === "reject"
                        ? "Rejected"
                        : ""}
                    </div>
                    {showNote && showNote?._id === item2?._id ? (
                      <IoMdClose
                        style={{ fontSize: "20px" }}
                        onClick={(e) => {
                          if (showNote?._id === item2?._id) {
                            setShowNote(null);
                          } else {
                            setShowNote(item2);
                          }
                        }}
                      />
                    ) : (
                      <EyeIcon
                        onClick={(e) => {
                          if (showNote?._id === item2?._id) {
                            setShowNote(null);
                          } else {
                            setShowNote(item2);
                          }
                        }}
                        style={{
                          width: "24px",
                          height: "24px",
                          opacity: item2?.notes ? 1 : 0.5,
                        }}
                      />
                    )}
                    <FileIcon
                      style={{
                        width: "24px",
                        height: "24px",
                        opacity: item2.rfq_pdf ? 1 : 0.5,
                      }}
                      onClick={(e) => {
                        if (item2.rfq_pdf) {
                          window.open(item2.rfq_pdf, "_blank");
                        }
                      }}
                    />
                  </div>
                </div>
                {showNote && showNote?._id === item2?._id && (
                  <div style={{ padding: "10px 20px" }}>
                    <div className={classNames.notesHeader}>Notes</div>
                    <div className={classNames.notesText}>
                      {showNote?.notes}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={classNames.noInsurance}>
          This quote hasn’t initiated RFQ's
        </div>
      );
    }
  };

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [selectedTab, setSelectedTab] = useState("");
  const [selectedTabPremium, setSelectedTabPremium] = useState("All Time");
  const [selectedTabType, setSelectedTabType] = useState("Active");
  const [selectedTabSortBy, setSelectedTabSortBy] = useState("Newest");
  const [selectedTabRate, setSelectedTabRate] = useState("All Time");
  const [selectedTabRevenue, setSelectedTabRevenue] = useState("All Time");
  const [selectedTabSales, setSelectedTabSales] = useState("All Time");
  const [detailedOverview, setDetailedOverview] = useState(false);
  const selectedItemTable = useRef(null);

  useEffect(() => {
    if (selectedTabSortBy === "Newest") {
      const sortedArrayHighToLow = allLeadsNew.slice().reverse();
      setAllLeadsNew(sortedArrayHighToLow);
    } else if (selectedTabSortBy === "Oldest") {
      const sortedArrayLowToHigh = allLeadsNew.slice().reverse();
      setAllLeadsNew(sortedArrayLowToHigh);
    }
  }, [selectedTabSortBy]);

  const filterItemsDashboard = [
    {
      title: "Edit Client Details",
      icon: EditIcon,
      clickable: false,
      subMenu: "",
    },
    {
      title: "Initiate Pre-Enrollment",
      icon: PeoplesIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Single Entry/Bulk Upload",
      icon: PeopleAddIcon,
      clickable: true,
      subMenu: [
        {
          title: "Single Entry",
          icon: SingleAddIcon,
          clickable: false,
          subMenu: "",
          // link: "/app/coverfi/claims",
        },
        {
          title: "Bulk Upload",
          icon: BulkAddIcon,
          clickable: false,
          subMenu: "",
          // link: "/app/coverfi/claims",
        },
        {
          title: "Re-Register",
          icon: ReregisterIcon,
          clickable: false,
          subMenu: "",
          // link: "/app/coverfi/claims",
        },
      ],
    },
    {
      title: "Download Health Cards",
      icon: DownloadIcon,
      clickable: false,
    },
    {
      title: "Email Health Cards",
      icon: EmailIcon,
      clickable: false,
      subMenu: "",
    },
    {
      title: "Initiate Endorsements",
      icon: PeopleAddRemoveIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Initiate A Claim",
      icon: BandaidIcon,
      clickable: true,
      subMenu: [
        {
          title: "Group Health Insurance",
          icon: "",
          clickable: false,
          subMenu: "",
          // link: "/app/coverfi/claims",
        },
        {
          title: "Group Personal Accident Insurance",
          icon: "",
          clickable: false,
          subMenu: "",
          // link: "/app/coverfi/claims",
        },
        {
          title: "Group Term Life Insurance",
          icon: "",
          clickable: false,
          subMenu: "",
          // link: "/app/coverfi/claims",
        },
      ],
    },
    {
      title: "Enable/Disable Account",
      icon: LockIcon,
      clickable: false,
      subMenu: "",
    },
    {
      title: "Reset Password",
      icon: KeyIcon,
      clickable: false,
      subMenu: "",
    },
  ];

  const data = [
    { name: "QCR  Approved", value: crmStat?.rfq_Accept },
    { name: "QCR In Process", value: crmStat?.rfq_Uploaded },
    { name: "QCR Resubmission", value: crmStat?.rfq_Resubmit },
    { name: "QCR Rejected", value: crmStat?.rfq_Reject },
  ];

  const COLORS = ["#2EC697", "#339AF0", "#FF922B", "#EA0F0F"];

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <text
          textAnchor="middle"
          x={cx}
          y={cy + 30}
          fill="#333"
        >{`${value}`}</text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  async function allClientsTeamLead() {
    let response = await getData(
      `https://insurance.apimachine.com/insurance/general/getHRsByDesignations?reference_email=${email}`
    );
    if (response?.length > 0) {
      setSelectedPolicyClaims(response);
    }
    console.log(response, "all clients team lead");
  }

  useEffect(() => {
    if (
      localStorage.getItem("loggedInUserRoleId") &&
      localStorage.getItem("loggedInUserId")
    ) {
      allClientsTeamLead();
    }
  }, [
    localStorage.getItem("loggedInUserRoleId"),
    localStorage.getItem("loggedInUserId"),
  ]);

  function getStatusFunc(funcValue) {
    switch (funcValue) {
      case "formNotFilled":
        return "In Process";
      case "formFilledOut":
        return "In Process";
      case "leadInquired":
        return "In Process";
      case "inConversation":
        return "In Process";
      case "HRAccountLoggedIn":
        return "In Process";
      case "HRConfiguredForm":
        return "Pre-Enrollment";
      case "formsPartiallyFilled":
        return "Pre-Enrollment";
      case "formsCompletelyFilled":
        return "QCR In Process";
      case "quotesUploaded":
        return "QCR Submitted";
      case "proposalFrozen":
        return "Terms Accepted";
      case "activeClient_T":
        return "Payment Completed";
      case "activeClient_P":
        return "Payment Completed";
      case "activeClient_H":
        return "Payment Completed";
      case "clientLost":
        return "Client Lost";

      default:
        break;
    }
  }

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div
      className={classNames.profile}
      onClick={(event) => {
        if (
          addDependentSidebar &&
          !event.target.closest("#addDependentSidebar")
        ) {
          console.log(event.target, ": clicked id");
          setAddDependentSidebar(false);
        }
      }}
    >
      <NavbarV3
        dropdownItems={[{ title: "CRM", clickable: true }]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setAllBrokeragesSearch}
        typee="v3"
      />
      <div className={classNames.brokerageDetails}>
        {selectedProfileNav === "ClientOnboardingForm" ? (
          <ClientOnboardingForm />
        ) : (
          <>
            <div
              className={classNames.tableContainer}
              style={{
                height: "max-content",
                border: "none",
                background: "transparent",
              }}
            >
              <div className={classNames.graphBoxes}>
                <div>
                  <div className={classNames.headerDiv}>
                    <div
                      className={classNames.title}
                      style={{ display: "block" }}
                    >
                      <div className={classNames.miniTitle}>
                        Overall Premium
                      </div>
                      <div className={classNames.valueTitle}>
                        ₹{crmStat?.premiumAmount?.toFixed(2)}
                        {/* <span>
                          {" "}
                          <IoIosTrendingUp />
                          13.02%
                        </span> */}
                      </div>
                    </div>
                    <div className={classNames.otherOptions}>
                      <CustomDropdown
                        buttonText={selectedTab ? selectedTab : "All Time"}
                        // color="var(--main-color)"
                        dropdownItems={[
                          { title: "Last 30 days", clickable: true },
                          { title: "Last 60 days", clickable: true },
                          { title: "Last 90 days", clickable: true },
                          { title: "Last 6 months", clickable: true },
                          { title: "Last 12 months", clickable: true },
                        ]}
                        stateValue={selectedTabPremium}
                        setState={setSelectedTabPremium}
                        typee={"v3"}
                      />
                    </div>
                  </div>
                  <div className={classNames.canvasDiv}>
                    <img src={barGraph} alt="barGraph" />
                    <div className={classNames.lockedDiv}>
                      <div className={classNames.opacityDiv}></div>
                      <LockIcon />
                    </div>
                  </div>
                </div>
                <div>
                  <div className={classNames.headerDiv}>
                    <div className={classNames.title}>
                      Client On-Boarding Journey
                    </div>
                    {/* <div className={classNames.otherOptions}>
                      <CustomDropdown
                        buttonText={
                          selectedTab ? selectedTab : "Last 12 months"
                        }
                        // color="var(--main-color)"
                        dropdownItems={[
                          { title: "Last 30 days", clickable: true },
                          { title: "Last 60 days", clickable: true },
                          { title: "Last 90 days", clickable: true },
                          { title: "Last 6 months", clickable: true },
                          { title: "Last 12 months", clickable: true },
                        ]}
                        stateValue={selectedTab}
                        setState={setSelectedTab}
                        typee={"v3"}
                      />
                    </div> */}
                  </div>
                  <div
                    className={classNames.canvasDiv}
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <img src={donutGraph} alt="donutGraph" /> */}
                    {/* <div style={{ width: "70%" }}> */}

                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={"50%"} height={"50%"}>
                        <Pie
                          activeIndex={activeIndex}
                          activeShape={renderActiveShape}
                          onMouseEnter={onPieEnter}
                          data={data}
                          cx="50%"
                          cy="50%"
                          innerRadius={50}
                          outerRadius={70}
                          fill="#8884d8"
                          paddingAngle={5}
                          dataKey="value"
                        >
                          {Array.isArray(data) && data?.length > 0
                            ? data.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ))
                            : ""}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                    {/* </div> */}
                    <div style={{ width: "30%" }}></div>
                    <div style={{ width: "80%" }}>
                      <div className={classNames.legendWrapper}>
                        <div
                          className={classNames.legendBox}
                          style={{
                            background: "#2EC697",
                          }}
                        ></div>
                        <div className={classNames.legendText}>
                          QCR Approved ({crmStat?.rfq_Accept})
                        </div>
                      </div>
                      <div className={classNames.legendWrapper}>
                        <div
                          className={classNames.legendBox}
                          style={{
                            background: "#339AF0",
                          }}
                        ></div>
                        <div className={classNames.legendText}>
                          QCR In Process ({crmStat?.rfq_Uploaded})
                        </div>
                      </div>
                      <div className={classNames.legendWrapper}>
                        <div
                          className={classNames.legendBox}
                          style={{
                            background: "#FF922B",
                          }}
                        ></div>
                        <div className={classNames.legendText}>
                          QCR Resubmission ({crmStat?.rfq_Resubmit})
                        </div>
                      </div>
                      <div className={classNames.legendWrapper}>
                        <div
                          className={classNames.legendBox}
                          style={{
                            background: "#EA0F0F",
                          }}
                        ></div>
                        <div className={classNames.legendText}>
                          QCR Rejected ({crmStat?.rfq_Reject})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames.overallScore}
              style={{ marginTop: "2rem" }}
            >
              {crmMainScore?.map((eachItem, index) => {
                return (
                  <div
                    className={classNames.eachScoreCRM}
                    key={eachItem?.title + index}
                  >
                    <div>
                      <div className={classNames.detailDiv}>
                        <div className={classNames.scoreIconDiv}>
                          {eachItem?.icon}
                        </div>
                        <div className={classNames.descDiv}>
                          <div className={classNames.title}>
                            {eachItem?.title}
                          </div>
                          <div className={classNames.score}>
                            {eachItem?.title !== "Conversion Rate" ? "₹" : ""}
                            {eachItem?.title === "Total Premium"
                              ? crmStat?.premiumAmount?.toFixed(2)
                              : eachItem?.title === "Conversion Rate"
                              ? `${crmStat?.rfq_conversion_rate?.toFixed(2)}%`
                              : eachItem?.title === "Booked Revenue"
                              ? crmStat?.brokerCommission?.toFixed(2)
                              : eachItem?.title === "Wellness Sales"
                              ? "--"
                              : 0}
                          </div>
                        </div>
                      </div>
                      {/* <img src={expandIcon} alt="expandIcon" /> */}
                    </div>
                    <div>
                      {/* {index !== 1 && (
                        <CustomDropdown
                          buttonText={
                            index === 0
                              ? selectedTabPremium
                              : index === 1
                              ? selectedTabRate
                              : index === 2
                              ? selectedTabRevenue
                              : index === 3
                              ? selectedTabSales
                              : "All Time"
                          }
                          // color="var(--main-color)"
                          dropdownItems={[
                            { title: "Last 30 days", clickable: true },
                            { title: "Last 60 days", clickable: true },
                            { title: "Last 90 days", clickable: true },
                            { title: "Last 6 months", clickable: true },
                            { title: "Last 12 months", clickable: true },
                          ]}
                          stateValue={
                            index === 0
                              ? selectedTabPremium
                              : index === 1
                              ? selectedTabRate
                              : index === 2
                              ? selectedTabRevenue
                              : index === 3
                              ? selectedTabSales
                              : selectedTab
                          }
                          setState={
                            index === 0
                              ? setSelectedTabPremium
                              : index === 1
                              ? setSelectedTabRate
                              : index === 2
                              ? setSelectedTabRevenue
                              : index === 3
                              ? setSelectedTabSales
                              : setSelectedTab
                          }
                          typee={"v3"}
                        />
                      )} */}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className={classNames.tableContainer}
              style={{ height: selectedQuote ? "100%" : "calc(80% - 2rem)" }}
            >
              <div className={classNames.headContainer}>
                <div className={classNames.title}>Leads List</div>
                <div className={classNames.filterButtons}>
                  <div className={classNames.leftContainer}>
                    <div
                      className={classNames.buttons}
                      onClick={() => {
                        // setSelectedProfileNav("ClientOnboardingForm");
                        setShowOnBoardDropDown(true);
                      }}
                    >
                      Onboard Lead
                    </div>
                    <div className={classNames.buttons}>Export</div>
                    {showOnBoardDropDown && (
                      <>
                        {/* <div
                          className={classNames.overlay}
                          onClick={(e) => {
                            setShowOnBoardDropDown(false);
                          }}
                        ></div> */}
                        <div className={classNames.onboarddd}>
                          {onboardDropDownList.map((item) => (
                            <div
                              onClick={(e) => {
                                if (item.ddName === "Create Lead") {
                                  setShowOnBoardDropDown(false);
                                  setShowAddCorporate("Create Lead");
                                } else {
                                  if (item?.ddLink) {
                                    history.push(item?.ddLink);
                                  }
                                }
                              }}
                            >
                              {item?.ddName}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                  <div className={classNames.rightContainer}>
                    <div className={classNames.searchDiv}>
                      <IoMdSearch />
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(event) =>
                          setSearchQuery(event?.target?.value)
                        }
                      />
                    </div>
                    <CustomDropdown
                      buttonText={selectedTab ? selectedTab : "View: Active"}
                      // color="var(--main-color)"
                      dropdownItems={allFollowUpStatus}
                      stateValue={selectedTabType}
                      setState={setSelectedTabType}
                      typee="v3"
                      stateVal="value"
                    />
                    <CustomDropdown
                      buttonText={selectedTab ? selectedTab : "Sort By: Newest"}
                      // color="var(--main-color)"
                      dropdownItems={[
                        { title: "Newest", clickable: true },
                        { title: "Oldest", clickable: true },
                      ]}
                      stateValue={selectedTabSortBy}
                      setState={setSelectedTabSortBy}
                      typee={"v3"}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames.proactiveDetails}>
                <div className={classNames.otherGrids}>
                  <div className={classNames.trackerTable}>
                    <div
                      className={classNames.tableParent}
                      style={{ position: "relative" }}
                    >
                      <div
                        className={classNames.tableTitles}
                        style={{ width: "100%", position: "sticky" }}
                      >
                        <div></div>
                        <div>Customer Name</div>
                        <div>Company</div>
                        <div>Phone Number</div>
                        <div>Email</div>
                        <div>City</div>
                        <div>Status</div>
                      </div>

                      <div
                        className={classNames.tableBody}
                        style={{
                          width: "100%",
                          overflowY: "scroll",
                          height: "calc(100% - 40px)",
                        }}
                      >
                        {allLeadsLoadingNew ? (
                          <div className={classNames.eachItem}>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                          </div>
                        ) : (
                          allLeadsNew
                            ?.filter((row) => {
                              if (selectedTabType) {
                                if (
                                  selectedTabType?.toLowerCase() ===
                                  row?.follow_up_status?.toLowerCase()
                                ) {
                                  // Convert the row object values to an array and check if any value matches the search query
                                  const values = Object?.values(row);
                                  const searchQueryy =
                                    searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                                  return values?.some((value) => {
                                    if (typeof value === "string") {
                                      return value
                                        ?.toLowerCase()
                                        ?.includes(searchQueryy);
                                    }
                                    return false;
                                  });
                                } else {
                                  return false;
                                }
                              } else {
                                // Convert the row object values to an array and check if any value matches the search query
                                const values = Object?.values(row);
                                const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                                return values?.some((value) => {
                                  if (typeof value === "string") {
                                    return value
                                      ?.toLowerCase()
                                      ?.includes(searchQueryy);
                                  }
                                  return false;
                                });
                              }
                            })
                            ?.map((eachItem, index) => {
                              return (
                                <>
                                  <div
                                    className={classNames.eachItem}
                                    key={"checkarray" + index}
                                    style={{
                                      borderBottom: !selectedQuote
                                        ? "1px solid #e7e7e7"
                                        : "",
                                    }}
                                  >
                                    <div className={classNames.verticalDots}>
                                      {actionBtns?.includes(
                                        "checkarrayThreeDots" + index
                                      ) ||
                                      selectedLead?.lead_id ===
                                        eachItem?.lead_id ||
                                      (selectedQuote &&
                                        selectedQuote.lead_id ===
                                          eachItem.lead_id) ? (
                                        <IoMdClose
                                          style={{ fontSize: "20px" }}
                                          onClick={() => {
                                            setSelectedLead(null);
                                            setSelectedQuote(null);
                                          }}
                                        />
                                      ) : (
                                        <BsThreeDotsVertical
                                          onClick={(e) => {
                                            // e.preventDefault();
                                            // e.stopPropagation();
                                            setSelectedLead(eachItem);
                                          }}
                                          // onClick={() => {
                                          //   setActionBtns(
                                          //     "checkarrayThreeDots" + index
                                          //   );
                                          // }}
                                        />
                                      )}

                                      {actionBtns?.includes(
                                        "checkarrayThreeDots" + index
                                      ) && (
                                        <div
                                          className={classNames.dropdownContent}
                                          ref={selectedItemTable}
                                        >
                                          {filterItemsDashboard?.map(
                                            (eachItem, index) => {
                                              return (
                                                <div
                                                  className={
                                                    classNames.eachFilterItem
                                                  }
                                                  key={eachItem?.title + index}
                                                  style={{
                                                    pointerEvents:
                                                      eachItem?.clickable
                                                        ? ""
                                                        : "none",
                                                    opacity: eachItem?.clickable
                                                      ? ""
                                                      : "0.5",
                                                  }}
                                                  onClick={() => {
                                                    if (
                                                      eachItem?.clickable &&
                                                      eachItem?.function
                                                    ) {
                                                      eachItem?.function();
                                                    }
                                                    setActionBtns([]);
                                                  }}
                                                >
                                                  <span>
                                                    {eachItem.icon && (
                                                      <eachItem.icon />
                                                    )}
                                                    {eachItem?.title}
                                                  </span>{" "}
                                                  {eachItem?.subMenu && (
                                                    <IoMdArrowDropdown />
                                                  )}
                                                  {eachItem?.subMenu?.length >
                                                    0 &&
                                                    Array.isArray(
                                                      eachItem?.subMenu
                                                    ) && (
                                                      <div
                                                        className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                      >
                                                        {eachItem?.subMenu?.map(
                                                          (
                                                            eachItemSub,
                                                            index
                                                          ) => {
                                                            return (
                                                              <div
                                                                className={
                                                                  classNames.eachFilterItem
                                                                }
                                                                key={
                                                                  eachItemSub?.title +
                                                                  index
                                                                }
                                                                style={{
                                                                  pointerEvents:
                                                                    eachItemSub?.clickable
                                                                      ? ""
                                                                      : "none",
                                                                  opacity:
                                                                    eachItemSub?.clickable
                                                                      ? ""
                                                                      : "0.5",
                                                                  justifyContent:
                                                                    "flex-start",
                                                                  gap: "10px",
                                                                }}
                                                                onClick={() => {
                                                                  history.push(
                                                                    eachItemSub?.link
                                                                  );
                                                                }}
                                                              >
                                                                <span>
                                                                  {eachItemSub.icon && (
                                                                    <eachItemSub.icon />
                                                                  )}
                                                                </span>
                                                                {
                                                                  eachItemSub?.title
                                                                }{" "}
                                                                {eachItemSub?.subMenu && (
                                                                  <IoMdArrowDropdown />
                                                                )}
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                    </div>

                                    <div className={classNames.singleFields}>
                                      {eachItem?.first_name}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.company_name?.length > 20
                                        ? eachItem?.company_name?.substring(
                                            0,
                                            20
                                          ) + "..."
                                        : eachItem?.company_name}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.phone_number}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.lead_email}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.city ? eachItem?.city : "-"}
                                    </div>
                                    <div
                                      className={classNames.singleFields}
                                      style={{
                                        padding: "0px",
                                        backgroundColor:
                                          eachItem?.follow_up_status ==
                                            "proposalFrozen" ||
                                          eachItem?.follow_up_status ==
                                            "activeClient_T" ||
                                          eachItem?.follow_up_status ==
                                            "activeClient_P" ||
                                          eachItem?.follow_up_status ==
                                            "activeClient_H"
                                            ? "#2EC697"
                                            : eachItem?.follow_up_status ==
                                              "formsCompletelyFilled"
                                            ? "#339AF0"
                                            : eachItem?.follow_up_status ==
                                              "quotesUploaded"
                                            ? "#FF922B"
                                            : eachItem?.follow_up_status ==
                                                "HRConfiguredForm" ||
                                              eachItem?.follow_up_status ==
                                                "formsPartiallyFilled"
                                            ? "#FFCD71"
                                            : eachItem?.follow_up_status ==
                                                "HRAccountLoggedIn" ||
                                              eachItem?.follow_up_status ==
                                                "inConversation" ||
                                              eachItem?.follow_up_status ==
                                                "leadInquired" ||
                                              eachItem?.follow_up_status ==
                                                "formFilledOut" ||
                                              eachItem?.follow_up_status ==
                                                "formNotFilled"
                                            ? "#972CF1"
                                            : "",
                                        color: "white",
                                        fontWeight: 700,
                                        fontSize: "12px",
                                        width: "150px",
                                        height: "29px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {getStatusFunc(
                                        eachItem?.follow_up_status
                                      )}
                                    </div>

                                    {selectedLead &&
                                      selectedLead.lead_id ===
                                        eachItem.lead_id && (
                                        <div
                                          className={classNames.singleFields}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              position: "absolute",
                                              left: 45,
                                              marginTop: "15vh",
                                              zIndex: 1,
                                              display: "flex",
                                            }}
                                            // ref={dropdownRef}
                                          >
                                            <DropdownUi
                                              setSelectedMenu={setSelectedMenu}
                                              selectedLead={selectedLead}
                                              setSelectedLead={setSelectedLead}
                                              setSelectedQuote={
                                                setSelectedQuote
                                              }
                                              setShowUploadQRC={
                                                setShowUploadQRC
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                  {selectedMenu === "See Quotes" && (
                                    <div>
                                      {selectedQuote &&
                                        selectedQuote.lead_id ===
                                          eachItem.lead_id && (
                                          <div
                                            style={{
                                              borderBottom: selectedMenu
                                                ? "1px solid #e7e7e7"
                                                : "",
                                            }}
                                          >
                                            {allQuotes?.map((item, index) => (
                                              <div
                                                onClick={(e) => {
                                                  if (!showDeleteConf) {
                                                    setShowQuoteDetails(item);
                                                  }
                                                }}
                                                className={
                                                  classNames.quoteWrapper
                                                }
                                              >
                                                <div>Quote {index + 1}</div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "20px",
                                                  }}
                                                >
                                                  <div>
                                                    {item?.completed_Step}
                                                  </div>
                                                  <img
                                                    src={arrowRight1}
                                                    alt=""
                                                  />
                                                  <div>{item?.next_Step}</div>
                                                  <DeleteIcon
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setShowDeleteConf(item);
                                                    }}
                                                  />
                                                  {showDeleteConf?._id ===
                                                    item?._id &&
                                                    showDeleteConf?.completed_Step ===
                                                      item?.completed_Step && (
                                                      <div
                                                        style={{
                                                          position: "absolute",
                                                          right: 0,
                                                          zIndex: 9999,
                                                        }}
                                                        onClick={(e) => {
                                                          // e.stopPropagation();
                                                        }}
                                                      >
                                                        <div
                                                          className={
                                                            classNames.overlay1
                                                          }
                                                          onClick={(e) => {
                                                            setShowDeleteConf(
                                                              null
                                                            );
                                                          }}
                                                        ></div>
                                                        <div
                                                          className={
                                                            classNames.delConfirm
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              classNames.popupTitle
                                                            }
                                                          >
                                                            Are you sure you
                                                            want to delete Quote{" "}
                                                            {index + 1}
                                                          </div>
                                                          <div
                                                            className={
                                                              classNames.popupOption
                                                            }
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              handleDelete(
                                                                showDeleteConf
                                                              );
                                                            }}
                                                          >
                                                            Yes
                                                          </div>
                                                          <div
                                                            className={
                                                              classNames.popupOption
                                                            }
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              setShowDeleteConf(
                                                                null
                                                              );
                                                            }}
                                                          >
                                                            No
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                    </div>
                                  )}
                                </>
                              );
                            })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames.paginationBar}>
                <div className={classNames.paginationMsg}>
                  Showing {allLeadsNew.length} entries
                </div>
                {/* <div className={classNames.allPagination}>
                  {currentPage == 1
                    ? Array.from({ length: 4 })?.map((eachItem, index) => {
                        return (
                          <div
                            key={"pagebtn" + index + currentPage}
                            className={`${classNames.eachBtn} ${
                              currentPage + index == currentPage
                                ? classNames.eachBtnCurrent
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentPage(currentPage + index);
                            }}
                          >
                            {currentPage + index}
                          </div>
                        );
                      })
                    : Array.from({ length: 4 })?.map((eachItem, index) => {
                        return (
                          <div
                            key={"pagebtn" + index + currentPage}
                            className={`${classNames.eachBtn} ${
                              currentPage + index - 1 == currentPage
                                ? classNames.eachBtnCurrent
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentPage(currentPage + index - 1);
                            }}
                          >
                            {currentPage + index - 1}
                          </div>
                        );
                      })}
                </div> */}
              </div>
            </div>
            {showQuoteDetails && (
              <>
                <div
                  className={classNames.overlay}
                  onClick={(e) => {
                    setShowQuoteDetails(null);
                    setConfData(null);
                    setSelectedQuoteTab("Overview");
                    setSelectedConfTab("Health");
                  }}
                ></div>
                <div className={classNames.quoteDetailsWrapper}>
                  <IoMdClose
                    style={{
                      fontSize: "20px",
                      position: "absolute",
                      right: 10,
                      top: 10,
                    }}
                    onClick={() => {
                      setShowQuoteDetails(null);
                      setConfData(null);
                      setSelectedQuoteTab("Overview");
                      setSelectedConfTab("Health");
                    }}
                  />
                  <div className={classNames.quoteTitle}>Quote Details</div>
                  <div className={classNames.quoteSection1}>
                    <div style={{ width: "50%" }}>
                      <div className={classNames.itemWrapper}>
                        <div className={classNames.lableStyle}>Quote ID</div>
                        <div className={classNames.valueStyle}>
                          {showQuoteDetails?.quote_id}
                        </div>
                      </div>
                      <div className={classNames.itemWrapper}>
                        <div className={classNames.lableStyle}>
                          Initiation Date
                        </div>
                        <div className={classNames.valueStyle}>
                          {showQuoteDetails?.date}
                        </div>
                      </div>
                      {/* <div className={classNames.itemWrapper}>
                        <div className={classNames.lableStyle}>
                          Existing Policy
                        </div>
                        <div className={classNames.valueStyle}>True</div>
                      </div> */}
                    </div>
                    <div style={{ width: "50%" }}>
                      <div className={classNames.itemWrapper}>
                        <div className={classNames.lableStyle}>
                          Previous Step
                        </div>
                        <div className={classNames.valueStyle}>
                          {showQuoteDetails?.completed_Step}
                        </div>
                      </div>
                      <div className={classNames.itemWrapper}>
                        <div className={classNames.lableStyle}>Next Step</div>
                        <div className={classNames.valueStyle}>
                          {showQuoteDetails?.next_Step}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames.quoteSection2}>
                    {quoteTabs.map((tabItem) => (
                      <div
                        onClick={(e) => {
                          if (tabItem !== "Members") {
                            setSelectedQuoteTab(tabItem);
                          }
                        }}
                        className={
                          selectedQuoteTab === tabItem
                            ? classNames.selectedDiv
                            : ""
                        }
                        style={{ opacity: tabItem === "Members" ? 0.5 : 1 }}
                      >
                        {tabItem}
                      </div>
                    ))}
                  </div>

                  {conditionalTabData()}
                </div>
              </>
            )}
            {console.log("reenders")}
            {showUploadQRC && (
              <UploadQCR
                showUploadQRC={showUploadQRC}
                setShowUploadQRC={setShowUploadQRC}
              />
            )}
          </>
        )}

        {showAddCorporate?.title === "Cost Center" ? (
          <CostCenter
            showAddCorporate={showAddCorporate}
            setShowAddCorporate={setShowAddCorporate}
          />
        ) : showAddCorporate === "Create Lead" ? (
          <AddCorporateLead setShowAddCorporate={setShowAddCorporate} />
        ) : (
          ""
        )}
        {/* {showUploadQRC && (
          <UploadQCR
            showUploadQRC={showUploadQRC}
            setShowUploadQRC={setShowUploadQRC}
          />
        )} */}
      </div>
    </div>
  );
};

export default CRMAssociate;

export const SidebarMenu = ({
  position,
  onClose,
  openedItem,
  selectedItemMain,
}) => {
  const sidebarRef = useRef(null);
  const { popularAction } = useContext(BankContext);

  const [allDependents, setAllDependents] = useState([]);
  const [allDependentsLoading, setAllDependentsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [scrollLocked, setScrollLocked] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose(); // Call the onClose function to change the state
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    setAllDependentsLoading(true);
    Axios.get(
      `https://insurance.apimachine.com/insurance/dependent/get?user_email=${openedItem}`
    )
      .then((response) => {
        setAllDependents(response?.data?.data);
        setAllDependentsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Get all dependents error");
        setAllDependentsLoading(false);
      });
  }, [popularAction, openedItem]);

  return (
    <div
      className={classNames.sidebarMenu}
      style={{
        right: position ? "0" : "",
      }}
      ref={sidebarRef}
    >
      <div className={classNames.dependentsContainer}>
        <div className={classNames.title}>
          Dependents For {selectedItemMain?.first_name}
        </div>
        <div className={classNames.allDependents}>
          {allDependentsLoading ? (
            <div className={classNames.eachDependent}>
              <div>
                <Skeleton height={25} width={80} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
            </div>
          ) : allDependents?.length > 0 ? (
            allDependents?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachDependent}
                  key={eachItem?.dependent_name + index}
                >
                  <div>{eachItem?.dependent_name}</div>
                  <div>{eachItem?.relationship}</div>
                  <div>{eachItem?.primary_phone_number}</div>
                </div>
              );
            })
          ) : (
            <div>No items</div>
          )}
        </div>
      </div>
    </div>
  );
};

const CustomDropdown = ({
  width,
  color,
  buttonText,
  dropdownItems,
  stateValue,
  setState,
  typee,
  stateVal,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={classNames.customDropdown}
      style={{
        width: width ? width : "",
        color: color ? color : "",
        overflow: isActive ? "" : "hidden",
      }}
      ref={dropdownRef}
    >
      <div
        className={classNames.dropdownBtn}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <div>
          {selectedOption
            ? selectedOption
            : stateValue
            ? stateValue
            : buttonText}
        </div>
        <IoMdArrowDropdown
          style={{
            fontSize: "1.2rem",
            rotate: isActive ? "180deg" : "",
          }}
        />
      </div>

      <div
        className={classNames.dropdownContent}
        style={{
          display: isActive ? "" : "none",
          zIndex: isActive ? "7" : "",
          height: "max-content",
        }}
      >
        {dropdownItems?.length > 0 && typee == "v3"
          ? dropdownItems?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachContent}
                  key={eachItem?.title + index}
                  onClick={() => {
                    setSelectedOption(eachItem?.title);
                    if (eachItem?.clickable && stateVal) {
                      setState(eachItem[stateVal]);
                    } else if (eachItem?.clickable) {
                      setState(eachItem?.title);
                    }
                    setIsActive(false);
                  }}
                  style={{
                    cursor: eachItem?.clickable ? "" : "not-allowed",
                  }}
                >
                  {eachItem?.title}
                </div>
              );
            })
          : dropdownItems?.length > 0 &&
            dropdownItems?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachContent}
                  key={eachItem + index}
                  onClick={() => {
                    setSelectedOption(eachItem);
                    setState(eachItem);
                    setIsActive(false);
                  }}
                >
                  {eachItem}
                </div>
              );
            })}
      </div>
    </div>
  );
};
