import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./cdbalanceAgency.module.scss";

import moment from "moment";

import {
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoIosArrowDown,
} from "react-icons/io";
import Axios from "axios";
import { BankContext } from "../../../context/Context";
import CustomCheckbox, { CopyBtn } from "../../../components/Custom";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import NavbarV2 from "../../../components/NavbarV2";

import { ReactComponent as FilterIcon } from "../../../assest/images/allicons/filter.svg";
import { ReactComponent as SortArrows } from "../../../assest/images/allicons/sort.svg";

//action buttons icons
import { ReactComponent as EditIcon } from "../../../assest/images/allicons/tableaction/edit.svg";
// import { ReactComponent as EyeIcon } from "../../../assest/images/allicons/tableaction/eye1.svg";
import { ReactComponent as WebIcon } from "../../../assest/images/allicons/tableaction/web.svg";
import { ReactComponent as EyeIcon } from "../../../assest/images/allicons/tableaction/eye.svg";
import { ReactComponent as DownloadIcon } from "../../../assest/images/allicons/tableaction/download.svg";
import { ReactComponent as EmailIcon } from "../../../assest/images/allicons/tableaction/email.svg";
import { ReactComponent as BandaidIcon } from "../../../assest/images/allicons/tableaction/bandaid.svg";
import { ReactComponent as LockIcon } from "../../../assest/images/allicons/tableaction/lock.svg";
import { ReactComponent as DependentsIcon } from "../../../assest/images/allicons/tableaction/dependents.svg";
import { ReactComponent as AddIcon } from "../../../assest/images/allicons/tableaction/add.svg";
import { ReactComponent as TrashIcon } from "../../../assest/images/allicons/tableaction/trash.svg";
import { ReactComponent as DeleteIcon } from "../../../assest/images/allicons/tableaction/delete.svg";
import { useHistory } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdClose, IoMdSearch } from "react-icons/io";
import barGraph from "./dummygraph/barGraph.svg";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import NavbarV3 from "../../../components/NavbarV3";
import AddTransaction from "./AddTransaction";
import UploadComInfo from "./UploadComInfo";
import ClientDetails from "./ClientDetails";
import { logoutToSameLogin } from "../../../assest/functions";
import {
  BulkEntryEnrollmentForm,
  MembersDetailsFormEnrollmentForm,
  MembersFormsEnrollmentForm,
  MembersReviewDetailsEnrollmentForm,
} from "./popups";
import HealthCards from "./HealthCards";
import PolicyDetails from "./PolicyDetails";
import { predefinedToast } from "../../../utils/toast";
import { EachCustomDropdown } from "../../../components/Custom/Input";
import { ReactComponent as adminResetIcon } from "../../../assest/images/allicons/tableaction/adminResetIcon.svg";
import AdminResetPassword from "../../../components/AssociatesCommonComponents/AdminResetPassword";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

ChartJS.register(ArcElement, Tooltip, Legend);

const CDBalanceAgency = () => {
  const history = useHistory();
  const {
    email,
    popularAction,
    setPopularAction,
    actionBtns,
    setActionBtns,
    globalHrId,
    setUpdateLoading,
    globalSelectedPolicy,
    addDependentSidebar,
    setAddDependentSidebar,
    lastSelectedEmployee,
    setLastSelectedEmployee,
    usersDataGlobal,
  } = useContext(BankContext);

  const [selectedProfileNav, setSelectedProfileNav] = useState("CD Balance");

  const [filterOpen, setFilterOpen] = useState(false);

  const [usersAllCompany, setUsersAllCompany] = useState("");
  const [usersAllCompanySelected, setUsersAllCompanySelected] = useState("");

  const [usersAllStates, setUsersAllStates] = useState("");
  const [usersAllStatesSelected, setUsersAllStatesSelected] = useState("");
  const [toggleSelectedTable, setToggleSelectedTable] = useState("Simplified");

  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [allLeads, setAllLeads] = useState("");
  const [allLeadsAPI, setAllLeadsAPI] = useState("");
  const [allLeadsLoading, setAllLeadsLoading] = useState("");
  const [allBrokeragesTotalProfile, setAllBrokeragesTotalProfile] =
    useState("");
  const [addPolicyToEmployee, setAddPolicyToEmployee] = useState([]);

  //imported from store
  const [allSelectedPolicyClaims, setSelectedPolicyClaims] = useState([]);
  const [allSelectedPolicyClaimsLoading, setSelectedPolicyClaimsLoading] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  // const [selectedEmployeeLoading, setSelectedEmployeeLoading] = useState(false);

  useEffect(() => {
    if (allLeadsAPI?.length > 0 && Array.isArray(allLeadsAPI)) {
      setAllBrokeragesTotalProfile("");
      let res = allLeadsAPI?.filter((row) => {
        const { displayName, branchAddressState, ...newObject } = row;

        // Creating a new object with the extracted properties
        if (selectedProfileNav == "Companies") {
          const separateObject = { branchAddressState };
          // Convert the row object values to an array and check if any value matches the search query
          const values = Object?.values(separateObject);
          const searchQuery = usersAllStatesSelected?.toLowerCase();

          // Check if the selected country matches the object's country (if selected)
          const countryMatches =
            !searchQuery ||
            values.some(
              (value) =>
                typeof value === "string" &&
                searchQuery.toLowerCase().includes(value?.toLowerCase())
            );

          return countryMatches;
          //  && brokerageMatches;
        } else {
        }
      });
      setAllBrokeragesTotalProfile(res.length);
      setAllLeads(res);
    }
  }, [usersAllCompanySelected, usersAllStatesSelected]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  function onCloseSidebar() {
    if (sidebarOpen) {
      setSidebarOpen((prev) => !prev);
    }
  }

  useEffect(() => {
    if (
      actionBtns?.length > 0 &&
      Array.isArray(actionBtns) &&
      Array.isArray(allLeads)
    ) {
      let response = allLeads?.filter(
        (eachLead) =>
          eachLead?.user_employee_id == actionBtns[actionBtns?.length - 1]
      );
      // console.log(response, "see dependents");
      if (response?.length > 0) {
        setLastSelectedEmployee(response[0]);
      }
    }
  }, [actionBtns, addDependentSidebar]);

  const filterItems = [
    "Active",
    "Invitation Send",
    "Invitation Expired",
    "Pending",
    "Disabled",
    "Deleted",
  ];

  const filterDropdownRef = useRef(null);

  const [allLeadsNew, setAllLeadsNew] = useState([]);
  const [totalProfiles, setTotalProfiles] = useState(0);
  const [allLeadsLoadingNew, setAllLeadsLoadingNew] = useState(false);
  const getTableData = () => {
    setAllLeadsLoadingNew(true);
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/raha-lead/mapping/project/?role_id=${localStorage.getItem(
        "loggedInUserRoleId"
      )}&user_id=${localStorage.getItem("loggedInUserId")}`
    ).then(({ data }) => {
      console.log(data, "kjewfkjwfkjgwekfgwkefg");
      setAllLeadsNew(data.data);
      setTotalProfiles(data?.total_profiles);
      setAllLeadsLoadingNew(false);
    });
  };

  const [topStats, setTopStats] = useState(null);

  const getTopStats = () => {
    console.log(
      `https://api-dev.rahainsure.com/api/v1/raha-lead/mapping/statistics/?role_id=${localStorage.getItem(
        "loggedInUserRoleId"
      )}&user_id=${localStorage.getItem("loggedInUserId")}`,
      "kjehdkjwhekfgwekfg"
    );
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/raha-lead/mapping/statistics/?role_id=${localStorage.getItem(
        "loggedInUserRoleId"
      )}&user_id=${localStorage.getItem("loggedInUserId")}`
    ).then(({ data }) => {
      if (data.status) {
        setTopStats(data.data[0]);
      }
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("loggedInUserRoleId") &&
      localStorage.getItem("loggedInUserId")
    ) {
      getTableData();
      getTopStats();
    }
  }, [
    localStorage.getItem("loggedInUserRoleId"),
    localStorage.getItem("loggedInUserId"),
  ]);

  const [selectedLead, setSelectedLead] = useState(null);
  const [showEndorsements, setShowEndorsements] = useState(null);
  const [endTableData, setEndTableData] = useState([]);
  const [refreshEndTableData, setRefreshEndTableData] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [selectedHrId, setSelectedHrId] = useState(null);
  const [endorsementsRequestData, setEndorsementsRequestData] = useState("");
  const [currentPageEmployee, setCurrentPageEmployee] = useState(1);
  const [endorsementSearchQuery, setEndorsementSearchQuery] = useState("");

  async function getCDBalanceID(selectedUserMail) {
    try {
      let response = await Axios.get(
        `https://insurance.apimachine.com/insurance/user/getuserallprofile?user_email=${selectedUserMail}`
      );
      console.log(response, "getCDBalanceID response");
      return await response?.data?.data?.hrCBBalanceDetails?.cdBalance_id;
    } catch (error) {
      console.log(error?.message, "getCDBalanceID error");
    }
  }

  async function getTransactionHistory() {
    try {
      let response = await Axios.get(
        `https://insurance.apimachine.com/insurance/cdBalance/hr/get/transaction?cdBalance_id=${usersDataGlobal?.hrCBBalanceDetails?.cdBalance_id}`
      );
      if (response?.data?.data?.length > 0) {
        setTransactionHistory(response?.data?.data);
      } else {
        setTransactionHistory([]);
      }
      console.log(response, "getTransactionHistory response", selectedLead);
    } catch (error) {
      console.log(error?.message, "getTransactionHistory error");
    }
  }

  async function getEndorsmentRequests(selectedLead, type) {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/employee-spreadsheet/hr-associate-request?company_id=${selectedLead?.company_id}&action_type=${type}`
    )
      .then(({ data }) => {
        if (data.status && data?.data?.length > 0) {
          setEndorsementsRequestData({ data: data?.data, type: type });
          console.log(data, "getEndorsmentRequests");
        }
      })
      .catch((error) => {
        console.log("GetEndorsmentRequests error", error?.message);
      });
  }

  const getEndorsmentDetails = (selectedLead) => {
    Axios.get(
      `https://insurance.apimachine.com/insurance/user/getuserallprofile?user_email=${selectedLead?.lead_email}`
    ).then(({ data }) => {
      if (data.status) {
        setSelectedHrId(data.data.hrDetails?.hr_id);
        getEndorsmentTableData(data.data.hrDetails.hrCompanyDetails._id);
      }
    });
  };

  const getEndorsmentTableData = (id) => {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/client-user/employee/${id}?skip=${
        currentPageEmployee === 1 ? 0 : (currentPageEmployee - 1) * 20
      }&limit=20&search=${
        endorsementSearchQuery ? endorsementSearchQuery?.toLowerCase() : ""
      }`
    ).then(({ data }) => {
      if (data.status) {
        console.log(data.data, "webFKJWGEFKJGWKJEFGJWEGF");
        setEndTableData(data.data);
      } else {
        setEndTableData([]);
      }
    });
  };

  useEffect(() => {
    if (selectedLead) {
      getEndorsmentDetails(selectedLead);
    }
  }, [refreshEndTableData, currentPageEmployee, endorsementSearchQuery]);

  const [showInitiateDropDown, setShowInitiateDropDown] = useState(false);
  const initiateDropDownList = [
    // {
    //   ddName: "Employee verifies data",
    //   enabled: true,
    // },
    // {
    //   ddName: "Employee fills data",
    //   enabled: true,
    // },
    {
      ddName: "Single",
      enabled: true,
    },
    {
      ddName: "Upload Spreadsheet",
      enabled: true,
    },
  ];
  const [showEmpVerify, setShowEmpVerify] = useState(false);
  //single enrollment states
  const [enrollmentFormSingle, setEnrollmentFormSingle] = useState({});
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [latestSelectedTypes, setLatestSelectedTypes] = useState("");

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [selectedTab, setSelectedTab] = useState("");
  const [selectedTabDate, setSelectedTabDate] = useState("Newest");
  const [selectedTabPremium, setSelectedTabPremium] = useState("1 Month");
  const [selectedTabRate, setSelectedTabRate] = useState("1 Month");
  const [selectedTabRevenue, setSelectedTabRevenue] = useState("1 Month");
  const [selectedTabSales, setSelectedTabSales] = useState("1 Month");
  const [detailedOverview, setDetailedOverview] = useState(false);
  const selectedItemTable = useRef(null);
  const [showPopup, setShowPopup] = useState(null);
  const [showAddTxn, setShowAddTxn] = useState(null);
  const [transactionHistorySearchQuery, setTransactionHistorySearchQuery] =
    useState("");
  const [endoresementStatusUpdate, setEndorsementStatusUpdate] = useState("");

  //function

  async function updateEndorsementRequestStatus(employeeID, updateStatus) {
    try {
      let response = await Axios.put(
        `https://api-dev.rahainsure.com/api/v1/employee-spreadsheet/hr-associate-request/update/${employeeID}`,
        {
          process_status: updateStatus,
        }
      );
      if (response?.data?.status) {
        predefinedToast("Updated endorsement request status");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      console.log(error?.message, "Update endorsements request error");
    }
  }

  useEffect(() => {
    if (endoresementStatusUpdate && selectedEmployee?._id) {
      updateEndorsementRequestStatus(
        selectedEmployee?._id,
        endoresementStatusUpdate
      );
    }
  }, [endoresementStatusUpdate]);

  useEffect(() => {
    if (selectedTab === "Highest CD Balance") {
      const sortedArrayHighToLow = allLeadsNew.slice().reverse();
      setAllLeadsNew(sortedArrayHighToLow);
    } else if (selectedTab === "Lowest CD Balance") {
      const sortedArrayLowToHigh = allLeadsNew.slice().reverse();
      setAllLeadsNew(sortedArrayLowToHigh);
    }
  }, [selectedTab]);

  const [selectedMenu, setSelectedMenu] = useState(null);

  const filterItemsDashboard = [
    {
      title: "See transaction history",
      icon: EyeIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "See Employees",
      icon: EyeIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Addition Endorsements",
      icon: EyeIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Deletion Endorsements",
      icon: EyeIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Add transaction",
      icon: EditIcon,
      clickable: true,
      subMenu: "",
    },
    // {
    //   title: "See rules",
    //   icon: DownloadIcon,
    //   clickable: true,
    //   subMenu: "",
    // },
  ];

  const endorsmentMenu = [
    {
      title: "See User Details",
      icon: WebIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Add policy",
      icon: AddIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "See policy",
      icon: EyeIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Download Health Cards",
      icon: DownloadIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Initiate A Claim",
      icon: BandaidIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Remove a policy",
      icon: DeleteIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Admin reset password",
      icon: adminResetIcon,
      clickable: true,
      subMenu: "",
    },
    // {
    //   title: "Enable/Disable Account",
    //   icon: LockIcon,
    //   clickable: true,
    //   subMenu: "",
    // },
    // {
    //   title: "Reset Password",
    //   icon: KeyIcon,
    //   clickable: true,
    //   subMenu: "",
    // },
  ];

  const [allPoliciesForLoggedInHr, setAllPoliciesForLoggedInHr] = useState([]);
  //functions
  const getPolicies = () => {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/policy?hr_id=${selectedLead?.hr_id}`
    ).then(({ data }) => {
      if (data.status) {
        console.log(data, "allPoliciesForLoggedInHr");
        if (data?.data?.length > 0) {
          setAllPoliciesForLoggedInHr(data.data);
        }
      } else {
        setAllPoliciesForLoggedInHr([]);
      }
    });
  };

  //rendering

  useEffect(() => {
    //reverse array

    if (selectedTabDate === "Oldest") {
      let reverseArray = transactionHistory
        ?.slice() // create a shallow copy of the filtered array
        ?.reverse(); // reverse the order of the array

      setTransactionHistory(reverseArray);
    } else if (selectedTabDate === "Newest") {
      let reverseArray = transactionHistory
        ?.slice() // create a shallow copy of the filtered array
        ?.reverse(); // reverse the order of the array

      setTransactionHistory(reverseArray);
    }
  }, [selectedTabDate]);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <>
      <div
        className={classNames.profile}
        onClick={(event) => {
          if (
            addDependentSidebar &&
            !event.target.closest("#addDependentSidebar")
          ) {
            console.log(event.target, ": clicked id");
            setAddDependentSidebar(false);
          }
        }}
      >
        <NavbarV3
          dropdownItems={[{ title: "CRM", clickable: true }]}
          selectedTab={selectedProfileNav}
          setSelectedTab={setSelectedProfileNav}
          setSearchQuery={setAllBrokeragesSearch}
          typee="v3"
        />
        <div className={classNames.brokerageDetails}>
          <div
            className={classNames.tableContainer}
            style={{
              height: "max-content",
              border: "none",
              background: "transparent",
              paddingBottom: "1rem",
              // display: actionBtns?.length > 0 ? "" : "none",
            }}
          >
            <div className={classNames.graphBoxes}>
              <div>
                <div className={classNames.detailDiv}>
                  <div className={classNames.balanceParent}>
                    <div className={classNames.title}>CD Balance</div>
                    <div className={classNames.balanceDiv}>
                      ₹ {topStats?.totalCdBalance?.toFixed(2)}
                    </div>
                    {/* <div className={classNames.growthDiv}>
                    <IoIosTrendingUp />
                    <span> +5% (Over last quarter)</span>
                  </div> */}
                    {/* <div className={classNames.btnContainer}>Add</div> */}
                  </div>
                  <div className={classNames.countDivs}>
                    {/* <div className={classNames.sampledropdown}>
                    This Month <IoIosArrowDown />
                  </div> */}
                    <div>
                      <div className={classNames.title}>Total Lives</div>
                      <div className={classNames.count}>
                        {topStats?.total_lives}
                      </div>
                    </div>
                    <div style={{ borderRight: "1px solid #e1e1e1" }}></div>
                    <div>
                      <div className={classNames.title}>Total HR’s</div>
                      <div className={classNames.count}>
                        {topStats?.totalHrCount}
                      </div>
                    </div>
                    <div style={{ borderRight: "1px solid #e1e1e1" }}></div>
                    <div>
                      <div className={classNames.title}>Total Policies</div>
                      <div className={classNames.count}>
                        {topStats?.policyCount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <div className={classNames.headerDiv}>
                  <div style={{ paddingRight: "10px" }}>
                    CD Balance Progression
                  </div>
                  <div className={classNames.otherOptions}>
                    <CustomDropdown
                      buttonText={selectedTab ? selectedTab : "All Time"}
                      // color="var(--main-color)"
                      dropdownItems={[
                        { title: "Last 30 days", clickable: true },
                        { title: "Last 60 days", clickable: true },
                        { title: "Last 90 days", clickable: true },
                        { title: "Last 6 months", clickable: true },
                        { title: "Last 12 months", clickable: true },
                      ]}
                      stateValue={selectedTab}
                      setState={setSelectedTab}
                      typee={"v3"}
                    />
                  </div>
                </div>
                <div className={classNames.canvasDiv}>
                  <img src={barGraph} alt="barGraph" />
                  <div className={classNames.lockedDiv} style={{ zIndex: "7" }}>
                    <div className={classNames.opacityDiv}></div>
                    <LockIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames.tableContainer} style={{ marginTop: "0" }}>
            <div className={classNames.headContainer}>
              <div className={classNames.title}>Clients List</div>
              <div className={classNames.filterButtons}>
                <div className={classNames.leftContainer}>
                  {/* <div className={classNames.buttons}>Define Rules</div> */}
                  <div className={classNames.buttons}>Export</div>
                </div>
                <div className={classNames.rightContainer}>
                  <div className={classNames.searchDiv}>
                    <IoMdSearch />
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(event) =>
                        setAllBrokeragesSearch(event?.target?.value)
                      }
                    />
                  </div>

                  <CustomDropdown
                    buttonText={selectedTab ? selectedTab : "Sort By:"}
                    // color="var(--main-color)"
                    dropdownItems={[
                      { title: "Highest CD Balance", clickable: true },
                      { title: "Lowest CD Balance", clickable: true },
                    ]}
                    width={"200px"}
                    stateValue={selectedTab}
                    setState={setSelectedTab}
                    typee={"v3"}
                  />
                </div>
              </div>
            </div>
            <div className={classNames.proactiveDetails}>
              <div className={classNames.otherGrids}>
                <div className={classNames.trackerTable}>
                  <div className={classNames.tableParent}>
                    <div className={classNames.tableTitles}>
                      <div>
                        {/* <CustomCheckbox
                        actionBtns={actionBtns}
                        setActionBtns={setActionBtns}
                        id={"test id"}
                        name={"test id name"}
                      /> */}
                      </div>

                      <div>Customer Name</div>
                      <div>Company</div>
                      <div>Policies</div>
                      <div>Employees</div>
                      <div>Endorsements</div>
                      <div>CD Balance</div>
                    </div>
                    <div className={classNames.tableBody}>
                      {allLeadsLoadingNew ? (
                        <div className={classNames.eachItem}>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>

                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                        </div>
                      ) : (
                        allLeadsNew
                          ?.filter((eachFilter, index) => {
                            return (
                              eachFilter?.first_name
                                ?.toLowerCase()
                                ?.includes(
                                  allBrokeragesSearch?.toLowerCase()
                                ) ||
                              eachFilter?.company_name
                                ?.toLowerCase()
                                ?.includes(allBrokeragesSearch?.toLowerCase())
                            );
                          })
                          ?.map((eachItem, index) => {
                            return (
                              <div
                                className={classNames.eachItem}
                                key={"checkarray" + index}
                                style={{
                                  // borderBottom: !selectedLead
                                  //   ? "1px solid #e7e7e7"
                                  //   : "",
                                  borderBottom: "1px solid #e1e1e1",
                                }}
                              >
                                <div className={classNames.verticalDots}>
                                  {actionBtns?.includes(
                                    "checkarrayThreeDots" + index
                                  ) ||
                                  selectedLead?.lead_id ===
                                    eachItem?.lead_id ? (
                                    <IoMdClose
                                      style={{ fontSize: "20px" }}
                                      onClick={() => {
                                        setSelectedLead(null);
                                        setSelectedLead("");
                                      }}
                                    />
                                  ) : (
                                    <BsThreeDotsVertical
                                      onClick={(e) => {
                                        // e.preventDefault();
                                        // e.stopPropagation();
                                        setSelectedLead(eachItem);
                                      }}
                                      // onClick={() => {
                                      //   setActionBtns(
                                      //     "checkarrayThreeDots" + index
                                      //   );
                                      // }}
                                    />
                                  )}

                                  {selectedLead?.lead_id ===
                                    eachItem?.lead_id && (
                                    <div
                                      className={classNames.dropdownContent}
                                      ref={selectedItemTable}
                                    >
                                      {filterItemsDashboard?.map(
                                        (eachItem, index) => {
                                          return (
                                            <div
                                              className={
                                                classNames.eachFilterItem
                                              }
                                              key={eachItem?.title + index}
                                              style={{
                                                pointerEvents:
                                                  eachItem?.clickable
                                                    ? ""
                                                    : "none",
                                                opacity: eachItem?.clickable
                                                  ? ""
                                                  : "0.5",
                                              }}
                                              onClick={async () => {
                                                if (
                                                  eachItem?.clickable &&
                                                  eachItem?.function
                                                ) {
                                                  eachItem?.function();
                                                }
                                                setActionBtns([]);
                                                if (
                                                  eachItem.title ===
                                                  "Addition Endorsements"
                                                ) {
                                                  setEndorsementsRequestData(
                                                    ""
                                                  );
                                                  getEndorsmentRequests(
                                                    selectedLead,
                                                    "Addition"
                                                  );
                                                } else if (
                                                  eachItem.title ===
                                                  "Deletion Endorsements"
                                                ) {
                                                  setEndorsementsRequestData(
                                                    ""
                                                  );
                                                  getEndorsmentRequests(
                                                    selectedLead,
                                                    "Removal"
                                                  );
                                                } else if (
                                                  eachItem.title ===
                                                  "See Employees"
                                                ) {
                                                  getPolicies();
                                                  setEndTableData([]);
                                                  getEndorsmentDetails(
                                                    selectedLead
                                                  );
                                                } else if (
                                                  eachItem.title ===
                                                  "Add transaction"
                                                ) {
                                                  setShowAddTxn(selectedLead);
                                                } else if (
                                                  eachItem.title ===
                                                  "See transaction history"
                                                ) {
                                                  setTransactionHistory([]);
                                                  let cdBalanceID =
                                                    await getCDBalanceID(
                                                      selectedLead?.lead_email
                                                    );
                                                  if (cdBalanceID) {
                                                    getTransactionHistory(
                                                      cdBalanceID
                                                    );
                                                  }
                                                }
                                                setShowPopup(null);
                                                // setSelectedLead(null);
                                              }}
                                            >
                                              <span>
                                                {eachItem.icon && (
                                                  <eachItem.icon />
                                                )}
                                                {eachItem?.title}
                                              </span>{" "}
                                              {eachItem?.subMenu && (
                                                <IoMdArrowDropdown />
                                              )}
                                              {eachItem?.subMenu?.length > 0 &&
                                                Array.isArray(
                                                  eachItem?.subMenu
                                                ) && (
                                                  <div
                                                    className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                  >
                                                    {eachItem?.subMenu?.map(
                                                      (eachItemSub, index) => {
                                                        return (
                                                          <div
                                                            className={
                                                              classNames.eachFilterItem
                                                            }
                                                            key={
                                                              eachItemSub?.title +
                                                              index
                                                            }
                                                            style={{
                                                              pointerEvents:
                                                                eachItemSub?.clickable
                                                                  ? ""
                                                                  : "none",
                                                              opacity:
                                                                eachItemSub?.clickable
                                                                  ? ""
                                                                  : "0.5",
                                                              justifyContent:
                                                                "flex-start",
                                                              gap: "10px",
                                                            }}
                                                            onClick={() => {
                                                              history.push(
                                                                eachItemSub?.link
                                                              );
                                                            }}
                                                          >
                                                            <span>
                                                              {eachItemSub.icon && (
                                                                <eachItemSub.icon />
                                                              )}
                                                            </span>
                                                            {eachItemSub?.title}{" "}
                                                            {eachItemSub?.subMenu && (
                                                              <IoMdArrowDropdown />
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                                {/* <div>
                              <CustomCheckbox
                                actionBtns={actionBtns}
                                setActionBtns={setActionBtns}
                                id={"checkarrayCdbalanceClientList" + index}
                                name={"checkarray name" + index}
                              />
                            </div> */}
                                <div className={classNames.singleFields}>
                                  {eachItem?.first_name}
                                </div>
                                <div className={classNames.singleFields}>
                                  {eachItem?.company_name}
                                </div>
                                <div className={classNames.singleFields}>
                                  {eachItem?.policies_count
                                    ? eachItem?.policies_count
                                    : 0}
                                </div>
                                <div className={classNames.singleFields}>
                                  {eachItem?.employee_count
                                    ? eachItem?.employee_count
                                    : 0}
                                </div>
                                <div className={classNames.singleFields}>
                                  {eachItem?.employee_endorsement_added
                                    ? eachItem?.employee_endorsement_added
                                    : 0}
                                </div>
                                <div
                                  className={classNames.singleFields}
                                  // style={{ padding: "0" }}
                                >
                                  ₹ {eachItem?.cd_balance?.toFixed(2)}
                                </div>
                              </div>
                            );
                          })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.paginationBar}>
              <div className={classNames.paginationMsg}>
                Showing {allLeadsNew.length} entries
              </div>
              {/* <div className={classNames.allPagination}>
              {currentPage == 1
                ? Array.from({ length: 4 })?.map((eachItem, index) => {
                    return (
                      <div
                        key={"pagebtn" + index + currentPage}
                        className={`${classNames.eachBtn} ${
                          currentPage + index == currentPage
                            ? classNames.eachBtnCurrent
                            : ""
                        }`}
                        onClick={() => {
                          setCurrentPage(currentPage + index);
                        }}
                      >
                        {currentPage + index}
                      </div>
                    );
                  })
                : Array.from({ length: 4 })?.map((eachItem, index) => {
                    return (
                      <div
                        key={"pagebtn" + index + currentPage}
                        className={`${classNames.eachBtn} ${
                          currentPage + index - 1 == currentPage
                            ? classNames.eachBtnCurrent
                            : ""
                        }`}
                        onClick={() => {
                          setCurrentPage(currentPage + index - 1);
                        }}
                      >
                        {currentPage + index - 1}
                      </div>
                    );
                  })}
            </div> */}
            </div>
          </div>
          {showAddTxn && (
            <AddTransaction
              setShowAddTxn={setShowAddTxn}
              showAddTxn={showAddTxn}
            />
          )}
          {showEmpVerify === "singleentryenrollmentform" && selectedHrId ? (
            <div className={classNames.dropdownContent}>
              <MembersFormsEnrollmentForm
                setState={setShowEmpVerify}
                enrollmentFormSingle={enrollmentFormSingle}
                setEnrollmentFormSingle={setEnrollmentFormSingle}
                selectedTypesPreEnrollmentSingle={selectedTypes}
                setSelectedTypesPreEnrollmentSingle={setSelectedTypes}
                latestSelectedTypes={latestSelectedTypes}
                setLatestSelectedTypes={setLatestSelectedTypes}
                selectedHrId={selectedHrId}
              />
            </div>
          ) : showEmpVerify === "singleentrymemberdetailsenrollmentform" &&
            selectedHrId ? (
            <div className={classNames.dropdownContent}>
              <MembersDetailsFormEnrollmentForm
                setState={setShowEmpVerify}
                enrollmentFormSingle={enrollmentFormSingle}
                setEnrollmentFormSingle={setEnrollmentFormSingle}
                selectedHrId={selectedHrId}
              />
            </div>
          ) : showEmpVerify === "singleentrymemberreviewenrollmentform" &&
            selectedHrId ? (
            <div className={classNames.dropdownContent}>
              <MembersReviewDetailsEnrollmentForm
                setState={setShowEmpVerify}
                enrollmentFormSingle={enrollmentFormSingle}
                setEnrollmentFormSingle={setEnrollmentFormSingle}
                selectedHrId={selectedHrId}
              />
            </div>
          ) : showEmpVerify === "bulkentryenrollmentform" && selectedHrId ? (
            <div className={classNames.dropdownContent}>
              <BulkEntryEnrollmentForm
                setState={setShowEmpVerify}
                selectedHrId={selectedHrId}
              />
            </div>
          ) : showEmpVerify ? (
            <UploadComInfo
              setShowEmpVerify={setShowEmpVerify}
              selectedHrId={selectedHrId}
            />
          ) : (
            ""
          )}
          {/* endorsement requests */}
          <div
            className={classNames.tableContainer}
            style={{
              display: endorsementsRequestData?.data?.length > 0 ? "" : "none",
            }}
          >
            <div className={classNames.headContainer}>
              <div className={classNames.title}>
                {endorsementsRequestData?.type === "Addition"
                  ? "Addition Endorsement Requests"
                  : endorsementsRequestData?.type === "Removal"
                  ? "Deletion Endorsement Requests"
                  : ""}
                <span
                  onClick={() => {
                    setEndorsementsRequestData([]);
                  }}
                >
                  X
                </span>
              </div>
              <div
                className={classNames.filterButtons}
                style={{ justifyContent: "flex-end" }}
              >
                <div className={classNames.rightContainer}>
                  <div className={classNames.searchDiv}>
                    <IoMdSearch />
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(event) => {
                        setEndorsementSearchQuery(event?.target?.value);
                      }}
                    />
                  </div>

                  <CustomDropdown
                    buttonText={
                      selectedTab ? selectedTab : "Sort By: Request Date"
                    }
                    // color="var(--main-color)"
                    dropdownItems={[]}
                    stateValue={selectedTab}
                    setState={setSelectedTab}
                    typee={"v3"}
                  />
                </div>
              </div>
            </div>
            <div className={classNames.proactiveDetails}>
              <div className={classNames.otherGrids}>
                <div className={classNames.trackerTable}>
                  <div className={classNames.tableParent}>
                    <div
                      className={classNames.tableTitles}
                      style={{
                        gridTemplateColumns: "15% 15% 15% 15% 12.5% 10% 17.5%",
                      }}
                    >
                      <div>Employee Name</div>
                      <div>ID</div>
                      <div>Email</div>
                      <div>Phone</div>
                      <div>Policy Number</div>
                      <div>Date</div>
                      <div>Status</div>
                    </div>
                    <div className={classNames.tableBody}>
                      {allSelectedPolicyClaimsLoading ? (
                        <div
                          className={classNames.eachItem}
                          style={{
                            gridTemplateColumns:
                              "15% 15% 15% 15% 12.5% 10% 17.5%",
                          }}
                        >
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                        </div>
                      ) : (
                        endorsementsRequestData?.data
                          ?.filter((eachItem) => {
                            return (
                              eachItem?.employee_id?.name
                                ?.toLowerCase()
                                ?.includes(
                                  endorsementSearchQuery?.toLowerCase()
                                ) ||
                              eachItem?.employee_id?.user_employee_id
                                ?.toLowerCase()
                                ?.includes(
                                  endorsementSearchQuery?.toLowerCase()
                                )
                            );
                          })
                          ?.map((eachItem, index) => {
                            return (
                              <>
                                <div
                                  className={classNames.eachItem}
                                  key={"checkarray" + index}
                                  style={{
                                    borderBottom:
                                      selectedMenu === "See User Details" &&
                                      selectedEmployee?._id === eachItem?._id
                                        ? ""
                                        : "1px solid #e1e1e1",
                                    gridTemplateColumns:
                                      "15% 15% 15% 15% 12.5% 10% 17.5%",
                                  }}
                                >
                                  <div
                                    className={`${classNames.singleFields} ${classNames.singleFieldsCenter}`}
                                  >
                                    {eachItem?.employee_id?.name}
                                  </div>
                                  <div
                                    className={`${classNames.singleFields} ${classNames.singleFieldsCenter}`}
                                  >
                                    {eachItem?.employee_id?.user_employee_id}
                                  </div>
                                  <div
                                    className={`${classNames.singleFields} ${classNames.singleFieldsCenter}`}
                                  >
                                    {eachItem?.employee_id?.user_company_email}
                                  </div>
                                  <div
                                    className={`${classNames.singleFields} ${classNames.singleFieldsCenter}`}
                                  >
                                    {eachItem?.employee_id?.phone_number
                                      ? eachItem?.employee_id?.phone_number !==
                                        null
                                        ? eachItem?.employee_id?.phone_number
                                        : "null"
                                      : "null"}
                                  </div>
                                  <div
                                    className={`${classNames.singleFields} ${classNames.singleFieldsCenter}`}
                                  >
                                    {eachItem?.policy_id?.policy_number}
                                  </div>
                                  <div
                                    className={`${classNames.singleFields} ${classNames.singleFieldsCenter}`}
                                  >
                                    {endorsementsRequestData?.type == "Addition"
                                      ? eachItem?.date_of_joining
                                      : endorsementsRequestData?.type ==
                                        "Removal"
                                      ? eachItem?.date_of_leaving
                                      : ""}
                                  </div>
                                  <div
                                    className={`${classNames.singleFields} ${classNames.singleFieldsCenter}`}
                                    style={{
                                      pointerEvents:
                                        eachItem?.process_status?.toLowerCase() ==
                                        "pending"
                                          ? ""
                                          : "none",
                                      overflow: "unset",
                                    }}
                                    onClick={() =>
                                      setSelectedEmployee(eachItem)
                                    }
                                  >
                                    <CustomDropdown
                                      buttonText={eachItem?.process_status}
                                      // color="var(--main-color)"
                                      dropdownItems={[
                                        {
                                          title: "Approved",
                                          clickable: true,
                                        },
                                        {
                                          title: "Rejected",
                                          clickable: true,
                                        },
                                      ]}
                                      stateValue={endoresementStatusUpdate}
                                      setState={setEndorsementStatusUpdate}
                                      typee={"v3"}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.paginationBar}>
              <div className={classNames.paginationMsg}>
                Showing {endorsementsRequestData?.data?.length} entries
              </div>
            </div>
          </div>
          {/* employee list */}
          <div
            className={classNames.tableContainer}
            style={{ display: endTableData.length > 0 ? "" : "none" }}
          >
            <div className={classNames.headContainer}>
              <div className={classNames.title}>
                Employees List{" "}
                <span
                  onClick={() => {
                    setEndTableData([]);
                  }}
                >
                  X
                </span>
              </div>
              <div className={classNames.filterButtons}>
                <div className={classNames.leftContainer}>
                  <div
                    className={classNames.buttons}
                    onClick={(e) => {
                      setShowInitiateDropDown(true);
                    }}
                  >
                    Initiate
                  </div>
                  <div>
                    <EachCustomDropdown
                      title="Add Policy To All"
                      dropdown={allPoliciesForLoggedInHr}
                      name="policy"
                      stateValue={addPolicyToEmployee}
                      setState={setAddPolicyToEmployee}
                      typee="addPolicyToAll"
                      selectedEmployee={selectedEmployee}
                      allEmployees={endTableData}
                      refreshPage={setRefreshEndTableData}
                    />
                  </div>
                  <div>
                    <EachCustomDropdown
                      title="Bulk Assign"
                      dropdown={allPoliciesForLoggedInHr}
                      name="policyComplete"
                      stateValue={addPolicyToEmployee}
                      setState={setAddPolicyToEmployee}
                      typee="addPolicyToAll"
                      selectedEmployee={selectedEmployee}
                      allEmployees={endTableData}
                      refreshPage={setRefreshEndTableData}
                    />
                  </div>

                  {showInitiateDropDown && (
                    <>
                      <div
                        className={classNames.overlay}
                        onClick={(e) => {
                          setShowInitiateDropDown(false);
                        }}
                      ></div>
                      <div className={classNames.onboarddd}>
                        {initiateDropDownList.map((item) => (
                          <div
                            onClick={(e) => {
                              if (item.ddName === "Employee verifies data") {
                                setShowInitiateDropDown(false);
                                setShowEmpVerify(true);
                              } else if (item.ddName === "Single") {
                                setShowInitiateDropDown(false);
                                setShowEmpVerify("singleentryenrollmentform");
                              } else if (item.ddName === "Upload Spreadsheet") {
                                setShowInitiateDropDown(false);
                                setShowEmpVerify("bulkentryenrollmentform");
                              }
                            }}
                          >
                            {item?.ddName}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                <div className={classNames.rightContainer}>
                  <div className={classNames.searchDiv}>
                    <IoMdSearch />
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(event) => {
                        setEndorsementSearchQuery(event?.target?.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.proactiveDetails}>
              <div className={classNames.otherGrids}>
                <div className={classNames.trackerTable}>
                  <div className={classNames.tableParent}>
                    <div
                      className={classNames.tableTitles}
                      style={{
                        gridTemplateColumns:
                          "5% 15% 15% 15% 15% 12.5% 10% 12.5%",
                      }}
                    >
                      <div></div>
                      <div>Employee Name</div>
                      <div>ID</div>
                      <div>Email</div>
                      <div>Phone</div>
                      <div>Type</div>
                      <div>Policies</div>
                      <div>Status</div>
                    </div>
                    <div className={classNames.tableBody}>
                      {allSelectedPolicyClaimsLoading ? (
                        <div
                          className={classNames.eachItem}
                          style={{
                            gridTemplateColumns:
                              "5% 15% 15% 15% 15% 12.5% 10% 12.5%",
                          }}
                        >
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                        </div>
                      ) : (
                        endTableData
                          ?.filter((eachItem) => {
                            return (
                              eachItem?.name
                                ?.toLowerCase()
                                ?.includes(
                                  endorsementSearchQuery?.toLowerCase()
                                ) ||
                              eachItem?.user_employee_id
                                ?.toLowerCase()
                                ?.includes(
                                  endorsementSearchQuery?.toLowerCase()
                                )
                            );
                          })
                          ?.map((eachItem, index) => {
                            return (
                              <>
                                <div
                                  className={classNames.eachItem}
                                  key={"checkarray" + index}
                                  style={{
                                    borderBottom:
                                      selectedMenu === "See User Details" &&
                                      selectedEmployee?._id === eachItem?._id
                                        ? ""
                                        : "1px solid #e7e7e7",

                                    gridTemplateColumns:
                                      "5% 15% 15% 15% 15% 12.5% 10% 12.5%",
                                  }}
                                >
                                  <div className={classNames.verticalDots}>
                                    {actionBtns?.includes(
                                      "checkarrayThreeDotsCDBalance" + index
                                    ) ||
                                    selectedEmployee?._id === eachItem?._id ? (
                                      <IoMdClose
                                        onClick={() => {
                                          setActionBtns([]);
                                          setSelectedEmployee(null);
                                          setShowMenu(false);
                                          setSelectedMenu(null);
                                        }}
                                      />
                                    ) : (
                                      <BsThreeDotsVertical
                                        onClick={() => {
                                          setActionBtns(
                                            "checkarrayThreeDots" + index
                                          );
                                          setSelectedEmployee(eachItem);
                                          setShowMenu(!showMenu);
                                        }}
                                      />
                                    )}

                                    {selectedEmployee?._id === eachItem?._id &&
                                      showMenu && (
                                        <div
                                          className={classNames.dropdownContent}
                                          ref={selectedItemTable}
                                        >
                                          {endorsmentMenu?.map(
                                            (eachItem, index) => {
                                              return (
                                                <div
                                                  className={
                                                    classNames.eachFilterItem
                                                  }
                                                  key={eachItem?.title + index}
                                                  style={{
                                                    pointerEvents:
                                                      eachItem?.clickable
                                                        ? ""
                                                        : "none",
                                                    opacity: eachItem?.clickable
                                                      ? ""
                                                      : "0.5",
                                                  }}
                                                  onClick={() => {
                                                    setSelectedMenu(
                                                      eachItem.title
                                                    );
                                                    setShowMenu(false);
                                                  }}
                                                >
                                                  <span>
                                                    {eachItem.icon && (
                                                      <eachItem.icon />
                                                    )}
                                                    {eachItem?.title}
                                                  </span>{" "}
                                                  {eachItem?.subMenu && (
                                                    <IoMdArrowDropdown />
                                                  )}
                                                  {eachItem?.subMenu?.length >
                                                    0 &&
                                                    Array.isArray(
                                                      eachItem?.subMenu
                                                    ) && (
                                                      <div
                                                        className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                      >
                                                        {eachItem?.subMenu?.map(
                                                          (
                                                            eachItemSub,
                                                            index
                                                          ) => {
                                                            return (
                                                              <div
                                                                className={
                                                                  classNames.eachFilterItem
                                                                }
                                                                key={
                                                                  eachItemSub?.title +
                                                                  index
                                                                }
                                                                style={{
                                                                  pointerEvents:
                                                                    eachItemSub?.clickable
                                                                      ? ""
                                                                      : "none",
                                                                  opacity:
                                                                    eachItemSub?.clickable
                                                                      ? ""
                                                                      : "0.5",
                                                                  justifyContent:
                                                                    "flex-start",
                                                                  gap: "10px",
                                                                }}
                                                                onClick={() => {
                                                                  history.push(
                                                                    eachItemSub?.link
                                                                  );
                                                                }}
                                                              >
                                                                <span>
                                                                  {eachItemSub.icon && (
                                                                    <eachItemSub.icon />
                                                                  )}
                                                                </span>
                                                                {
                                                                  eachItemSub?.title
                                                                }{" "}
                                                                {eachItemSub?.subMenu && (
                                                                  <IoMdArrowDropdown />
                                                                )}
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                  </div>

                                  <div className={classNames.singleFields}>
                                    {eachItem?.name}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.user_employee_id}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.user_company_email}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.phone_number
                                      ? eachItem?.phone_number !== null
                                        ? eachItem?.phone_number
                                        : "null"
                                      : "null"}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.endorsement == true ||
                                    eachItem?.endorsement == "True" ||
                                    eachItem?.endorsement == "true"
                                      ? "Endorsement"
                                      : "Original"}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.policies_count}
                                  </div>
                                  <div
                                    className={classNames.statusBtn}
                                    style={{
                                      backgroundColor:
                                        eachItem?.working_status === "active"
                                          ? "#2EC697"
                                          : eachItem?.working_status ===
                                            "invited"
                                          ? "#FF922B"
                                          : eachItem?.working_status ===
                                            "invited"
                                          ? "#DF0404"
                                          : "",
                                    }}
                                  >
                                    {eachItem?.working_status}
                                  </div>

                                  {/* <div
                                className={classNames.singleFields}
                                style={{ pointerEvents: "none" }}
                              >
                                <CustomDropdown
                                  buttonText={"Addition"}
                                  // color="var(--main-color)"
                                  dropdownItems={[]}
                                  stateValue={selectedTab}
                                  setState={setSelectedTab}
                                  typee={"v3"}
                                />
                              </div>
                              <div
                                className={classNames.singleFields}
                                style={{ pointerEvents: "none" }}
                              >
                                <CustomDropdown
                                  buttonText={"Under Process"}
                                  // color="var(--main-color)"
                                  dropdownItems={[]}
                                  stateValue={selectedTab}
                                  setState={setSelectedTab}
                                  typee={"v3"}
                                />
                              </div> */}
                                </div>
                                {selectedMenu === "See User Details" &&
                                selectedEmployee?._id === eachItem?._id ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderBottom:
                                        selectedMenu === "See User Details" &&
                                        selectedEmployee?._id === eachItem?._id
                                          ? "1px solid #e7e7e7"
                                          : "",
                                    }}
                                  >
                                    <ClientDetails
                                      selectedEmployee={selectedEmployee}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {selectedMenu === "Add policy" &&
                                selectedEmployee?._id === eachItem?._id ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderBottom:
                                        selectedMenu === "Add policy" &&
                                        selectedEmployee?._id === eachItem?._id
                                          ? "1px solid #e1e1e1"
                                          : "",
                                    }}
                                  >
                                    <PolicyDetails
                                      selectedLead={selectedLead}
                                      selectedEmployee={selectedEmployee}
                                      setRefresh={() => {
                                        setRefreshEndTableData((prev) => !prev);
                                        setSelectedMenu("");
                                        setSelectedEmployee("");
                                      }}
                                      seeDetails={false}
                                      type="addPolicy"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {selectedMenu === "See policy" &&
                                selectedEmployee?._id === eachItem?._id ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderBottom:
                                        selectedMenu === "See policy" &&
                                        selectedEmployee?._id === eachItem?._id
                                          ? "1px solid #e1e1e1"
                                          : "",
                                    }}
                                  >
                                    <PolicyDetails
                                      selectedLead={selectedLead}
                                      selectedEmployee={selectedEmployee}
                                      seeDetails={true}
                                      selectedHrId={selectedHrId}
                                      type="seePolicy"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {selectedMenu === "Remove a policy" &&
                                selectedEmployee?._id === eachItem?._id ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderBottom:
                                        selectedMenu === "Remove a policy" &&
                                        selectedEmployee?._id === eachItem?._id
                                          ? "1px solid #e1e1e1"
                                          : "",
                                    }}
                                  >
                                    <PolicyDetails
                                      selectedLead={selectedLead}
                                      selectedEmployee={selectedEmployee}
                                      seeDetails={true}
                                      setRefresh={() => {
                                        setRefreshEndTableData((prev) => !prev);
                                        setSelectedMenu("");
                                        setSelectedEmployee("");
                                      }}
                                      type="removePolicy"
                                      selectedHrId={selectedHrId}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {selectedMenu === "Download Health Cards" &&
                                selectedEmployee?._id === eachItem?._id ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderBottom:
                                        selectedMenu ===
                                          "Download Health Cards" &&
                                        selectedEmployee?._id === eachItem?._id
                                          ? "1px solid #e1e1e1"
                                          : "",
                                    }}
                                  >
                                    <HealthCards
                                      selectedLead={selectedLead}
                                      selectedEmployee={selectedEmployee}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.paginationBar}>
              <div className={classNames.paginationMsg}>
                Showing {endTableData?.length} entries
              </div>
              <div className={classNames.allPagination}>
                <div
                  key={"pagebtnleft"}
                  className={`${classNames.eachBtn} `}
                  style={{
                    pointerEvents: currentPageEmployee <= 1 ? "none" : "",
                    opacity: currentPageEmployee <= 1 ? "0.5" : "",
                  }}
                  onClick={() => {
                    setCurrentPageEmployee(currentPageEmployee - 1);
                  }}
                >
                  <FaAngleLeft />
                </div>
                {currentPageEmployee == 1
                  ? Array.from({ length: 4 })?.map((eachItem, index) => {
                      return (
                        <div
                          key={"pagebtn" + index + currentPageEmployee}
                          className={`${classNames.eachBtn} ${
                            currentPageEmployee + index == currentPageEmployee
                              ? classNames.eachBtnCurrent
                              : ""
                          }`}
                          onClick={() => {
                            setCurrentPageEmployee(currentPageEmployee + index);
                          }}
                          style={{
                            pointerEvents:
                              endTableData?.length < 20 &&
                              index > currentPageEmployee - 1
                                ? "none"
                                : "",
                            opacity:
                              endTableData?.length < 20 &&
                              index > currentPageEmployee - 1
                                ? "0.5"
                                : "",
                          }}
                        >
                          {currentPageEmployee + index}
                        </div>
                      );
                    })
                  : Array.from({ length: 4 })?.map((eachItem, index) => {
                      return (
                        <div
                          key={"pagebtn" + index + currentPageEmployee}
                          className={`${classNames.eachBtn} ${
                            currentPageEmployee + index - 1 ==
                            currentPageEmployee
                              ? classNames.eachBtnCurrent
                              : ""
                          }`}
                          onClick={() => {
                            console.log(
                              currentPageEmployee + index - 1,
                              "Clicking next num"
                            );

                            if (endTableData?.length < 20) {
                            } else {
                              setCurrentPageEmployee(
                                currentPageEmployee + index - 1
                              );
                            }
                          }}
                          style={{
                            pointerEvents:
                              endTableData?.length < 20 &&
                              index > currentPageEmployee - 2
                                ? "none"
                                : "",
                            opacity:
                              endTableData?.length < 20 &&
                              index > currentPageEmployee - 2
                                ? "0.5"
                                : "",
                          }}
                        >
                          {currentPageEmployee + index - 1}
                        </div>
                      );
                    })}
                <div
                  key={"pagebtnright"}
                  className={`${classNames.eachBtn} `}
                  style={{
                    pointerEvents:
                      endTableData?.length < 20
                        ? "none"
                        : currentPageEmployee >= 1
                        ? ""
                        : "none",
                    opacity:
                      endTableData?.length < 20
                        ? "0.5"
                        : currentPageEmployee >= 1
                        ? ""
                        : "0.5",
                  }}
                  onClick={() => {
                    setCurrentPageEmployee(currentPageEmployee + 1);
                  }}
                >
                  <FaAngleRight />
                </div>
              </div>
            </div>
          </div>
          {/* CD Balance History table */}
          <div
            className={classNames.tableContainer}
            style={{ display: transactionHistory.length > 0 ? "" : "none" }}
          >
            <div className={classNames.headContainer}>
              <div className={classNames.title}>
                CD Balance Transaction History
                <span
                  onClick={() => {
                    setTransactionHistory([]);
                  }}
                >
                  X
                </span>
              </div>
              <div className={classNames.filterButtons}>
                <div className={classNames.leftContainer}>
                  {/* <div
                    className={classNames.buttons}
                    // onClick={(e) => {
                    //   setShowInitiateDropDown(true);
                    // }}
                  >
                    Initiate
                  </div> */}
                  <div className={classNames.buttons}>Export</div>
                  {showInitiateDropDown && (
                    <>
                      <div
                        className={classNames.overlay}
                        onClick={(e) => {
                          setShowInitiateDropDown(false);
                        }}
                      ></div>
                      <div className={classNames.onboarddd}>
                        {initiateDropDownList.map((item) => (
                          <div
                            onClick={(e) => {
                              if (item.ddName === "Employee verifies data") {
                                setShowInitiateDropDown(false);
                                setShowEmpVerify(true);
                              } else if (item.ddName === "Single") {
                                setShowInitiateDropDown(false);
                                setShowEmpVerify("singleentryenrollmentform");
                              } else if (item.ddName === "Upload Spreadsheet") {
                                setShowInitiateDropDown(false);
                                setShowEmpVerify("bulkentryenrollmentform");
                              }
                            }}
                          >
                            {item?.ddName}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                <div className={classNames.rightContainer}>
                  <div className={classNames.searchDiv}>
                    <IoMdSearch />
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(event) => {
                        setTransactionHistorySearchQuery(event?.target?.value);
                      }}
                    />
                  </div>
                  <CustomDropdown
                    buttonText={selectedTab ? selectedTab : "View: All"}
                    // color="var(--main-color)"

                    dropdownItems={
                      [
                        // {
                        //   title: "Active",
                        //   clickable: true,
                        //   backgroundColor: "#2EC697",
                        // },
                        // {
                        //   title: "Invited",
                        //   clickable: true,
                        //   backgroundColor: "#FF922B",
                        // },
                        // {
                        //   title: "Inactive",
                        //   clickable: true,
                        //   backgroundColor: "#DF0404",
                        // },
                        // { title: "Resubmit QCR", clickable: true },
                        // { title: "In Process", clickable: true },
                        // { title: "Terms Accepted", clickable: true },
                        // { title: "Payment In Process", clickable: true },
                        // { title: "Payment Completed", clickable: true },
                      ]
                    }
                    stateValue={selectedTab}
                    setState={setSelectedTab}
                    typee="v3"
                  />
                  <CustomDropdown
                    buttonText={
                      selectedTabDate
                        ? `Sort By:${selectedTabDate}`
                        : "Sort By: Join Date"
                    }
                    // color="var(--main-color)"
                    dropdownItems={[
                      { title: "Newest", clickable: true },
                      { title: "Oldest", clickable: true },
                    ]}
                    stateValue={selectedTabDate}
                    setState={setSelectedTabDate}
                    typee={"v3"}
                    isBy="date"
                  />
                </div>
              </div>
            </div>
            <div className={classNames.proactiveDetails}>
              <div className={classNames.otherGrids}>
                <div className={classNames.trackerTable}>
                  <div className={classNames.tableParent}>
                    <div className={classNames.tableTitles}>
                      <div></div>
                      <div>Date</div>
                      <div>ID</div>
                      <div>Particulars</div>
                      <div>Credit</div>
                      <div>Debit</div>
                      <div>Balance</div>
                    </div>
                    <div className={classNames.tableBody}>
                      {allSelectedPolicyClaimsLoading ? (
                        <div className={classNames.eachItem}>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                        </div>
                      ) : (
                        transactionHistory
                          ?.filter((eachItem) => {
                            return (
                              eachItem?.transaction_id
                                ?.toLowerCase()
                                ?.includes(
                                  transactionHistorySearchQuery?.toLowerCase()
                                ) ||
                              eachItem?.transacted_for
                                ?.toLowerCase()
                                ?.includes(
                                  transactionHistorySearchQuery?.toLowerCase()
                                )
                            );
                          })
                          ?.map((eachItem, index) => {
                            return (
                              <>
                                <div
                                  className={classNames.eachItem}
                                  key={"checkarray" + index}
                                  style={{
                                    borderBottom:
                                      selectedMenu === "See User Details" &&
                                      selectedEmployee?._id === eachItem?._id
                                        ? ""
                                        : "1px solid #e1e1e1",
                                  }}
                                >
                                  <div className={classNames.verticalDots}>
                                    {actionBtns?.includes(
                                      "checkarrayThreeDotsCDBalance" + index
                                    ) ||
                                    selectedEmployee?._id === eachItem?._id ? (
                                      <IoMdClose
                                        onClick={() => {
                                          setActionBtns([]);
                                          setSelectedEmployee(null);
                                          setShowMenu(false);
                                          setSelectedMenu(null);
                                        }}
                                      />
                                    ) : (
                                      <BsThreeDotsVertical
                                        onClick={() => {
                                          setActionBtns(
                                            "checkarrayThreeDots" + index
                                          );
                                          setSelectedEmployee(eachItem);
                                          setShowMenu(!showMenu);
                                        }}
                                      />
                                    )}
                                    {/* hidden for now */}
                                    {/* {selectedEmployee?._id === eachItem?._id &&
                                      showMenu && (
                                        <div
                                          className={classNames.dropdownContent}
                                          ref={selectedItemTable}
                                        >
                                          {endorsmentMenu?.map(
                                            (eachEndorsementItem, index) => {
                                              return (
                                                <div
                                                  className={
                                                    classNames.eachFilterItem
                                                  }
                                                  key={
                                                    eachEndorsementItem?.title +
                                                    index
                                                  }
                                                  style={{
                                                    pointerEvents:
                                                      eachEndorsementItem?.clickable
                                                        ? ""
                                                        : "none",
                                                    opacity:
                                                      eachEndorsementItem?.clickable
                                                        ? ""
                                                        : "0.5",
                                                  }}
                                                  onClick={() => {
                                                    setSelectedMenu(
                                                      eachEndorsementItem.title
                                                    );

                                                    setShowMenu(false);
                                                  }}
                                                >
                                                  <span>
                                                    {eachEndorsementItem.icon && (
                                                      <eachEndorsementItem.icon />
                                                    )}
                                                    {eachEndorsementItem?.title}
                                                  </span>{" "}
                                                  {eachEndorsementItem?.subMenu && (
                                                    <IoMdArrowDropdown />
                                                  )}
                                                  {eachEndorsementItem?.subMenu
                                                    ?.length > 0 &&
                                                    Array.isArray(
                                                      eachEndorsementItem?.subMenu
                                                    ) && (
                                                      <div
                                                        className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                      >
                                                        {eachEndorsementItem?.subMenu?.map(
                                                          (
                                                            eachItemSub,
                                                            index
                                                          ) => {
                                                            return (
                                                              <div
                                                                className={
                                                                  classNames.eachFilterItem
                                                                }
                                                                key={
                                                                  eachItemSub?.title +
                                                                  index
                                                                }
                                                                style={{
                                                                  pointerEvents:
                                                                    eachItemSub?.clickable
                                                                      ? ""
                                                                      : "none",
                                                                  opacity:
                                                                    eachItemSub?.clickable
                                                                      ? ""
                                                                      : "0.5",
                                                                  justifyContent:
                                                                    "flex-start",
                                                                  gap: "10px",
                                                                }}
                                                                onClick={() => {
                                                                  history.push(
                                                                    eachItemSub?.link
                                                                  );
                                                                }}
                                                              >
                                                                <span>
                                                                  {eachItemSub.icon && (
                                                                    <eachItemSub.icon />
                                                                  )}
                                                                </span>
                                                                {
                                                                  eachItemSub?.title
                                                                }{" "}
                                                                {eachItemSub?.subMenu && (
                                                                  <IoMdArrowDropdown />
                                                                )}
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )} */}
                                  </div>

                                  <div className={classNames.singleFields}>
                                    {eachItem?.date?.split(",")?.length > 0
                                      ? eachItem?.date?.split(",")[0]
                                      : eachItem?.date}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.transaction_id}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.transacted_for}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.credit == "yes"
                                      ? Number(eachItem?.amount)?.toFixed(2)
                                      : "0.00"}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.debit == "yes"
                                      ? Number(eachItem?.amount)?.toFixed(2)
                                      : "0.00"}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.updated_cd_balance
                                      ? Number(
                                          eachItem?.updated_cd_balance
                                        )?.toFixed(2)
                                      : ""}
                                  </div>
                                </div>
                                {selectedMenu === "See User Details" &&
                                selectedEmployee?._id === eachItem?._id ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderBottom:
                                        selectedMenu === "See User Details" &&
                                        selectedEmployee?._id === eachItem?._id
                                          ? "1px solid #e1e1e1"
                                          : "",
                                    }}
                                  >
                                    <ClientDetails
                                      selectedEmployee={selectedEmployee}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {selectedMenu === "Add policy" &&
                                selectedEmployee?._id === eachItem?._id ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderBottom:
                                        selectedMenu === "Add policy" &&
                                        selectedEmployee?._id === eachItem?._id
                                          ? "1px solid #e1e1e1"
                                          : "",
                                    }}
                                  >
                                    <PolicyDetails
                                      selectedLead={selectedLead}
                                      selectedEmployee={selectedEmployee}
                                      setRefresh={() => {
                                        setRefreshEndTableData((prev) => !prev);
                                        setSelectedMenu("");
                                        setSelectedEmployee("");
                                      }}
                                      seeDetails={false}
                                      type="addPolicy"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {selectedMenu === "See policy" &&
                                selectedEmployee?._id === eachItem?._id ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderBottom:
                                        selectedMenu === "See policy" &&
                                        selectedEmployee?._id === eachItem?._id
                                          ? "1px solid #e1e1e1"
                                          : "",
                                    }}
                                  >
                                    <PolicyDetails
                                      selectedLead={selectedLead}
                                      selectedEmployee={selectedEmployee}
                                      seeDetails={true}
                                      selectedHrId={selectedHrId}
                                      type="seePolicy"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {selectedMenu === "Remove a policy" &&
                                selectedEmployee?._id === eachItem?._id ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderBottom:
                                        selectedMenu === "Remove a policy" &&
                                        selectedEmployee?._id === eachItem?._id
                                          ? "1px solid #e1e1e1"
                                          : "",
                                    }}
                                  >
                                    <PolicyDetails
                                      selectedLead={selectedLead}
                                      selectedEmployee={selectedEmployee}
                                      seeDetails={true}
                                      setRefresh={() => {
                                        setRefreshEndTableData((prev) => !prev);
                                        setSelectedMenu("");
                                        setSelectedEmployee("");
                                      }}
                                      type="removePolicy"
                                      selectedHrId={selectedHrId}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {selectedMenu === "Download Health Cards" &&
                                selectedEmployee?._id === eachItem?._id ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderBottom:
                                        selectedMenu ===
                                          "Download Health Cards" &&
                                        selectedEmployee?._id === eachItem?._id
                                          ? "1px solid #e1e1e1"
                                          : "",
                                    }}
                                  >
                                    <HealthCards
                                      selectedLead={selectedLead}
                                      selectedEmployee={selectedEmployee}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.paginationBar}>
              <div className={classNames.paginationMsg}>
                Showing {transactionHistory?.length} entries
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedMenu === "Admin reset password" && (
        <AdminResetPassword
          popup={selectedMenu}
          setPopup={setSelectedMenu}
          selectedRow={selectedEmployee}
          setSelectedRow={setSelectedEmployee}
          from="cdbalance"
        />
      )}
    </>
  );
};

export default CDBalanceAgency;

export const SidebarMenu = ({
  position,
  onClose,
  openedItem,
  selectedItemMain,
}) => {
  const sidebarRef = useRef(null);
  const { popularAction } = useContext(BankContext);

  const [allDependents, setAllDependents] = useState([]);
  const [allDependentsLoading, setAllDependentsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [scrollLocked, setScrollLocked] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose(); // Call the onClose function to change the state
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    setAllDependentsLoading(true);
    Axios.get(
      `https://insurance.apimachine.com/insurance/dependent/get?user_email=${openedItem}`
    )
      .then((response) => {
        // console.log(
        //   response?.data?.data,
        //   "get all dependents response",
        //   openedItem
        // );
        setAllDependents(response?.data?.data);
        setAllDependentsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Get all dependents error");
        setAllDependentsLoading(false);
      });
  }, [popularAction, openedItem]);

  return (
    <div
      className={classNames.sidebarMenu}
      style={{
        right: position ? "0" : "",
      }}
      ref={sidebarRef}
    >
      <div className={classNames.dependentsContainer}>
        <div className={classNames.title}>
          Dependents For {selectedItemMain?.first_name}
        </div>
        <div className={classNames.allDependents}>
          {allDependentsLoading ? (
            <div className={classNames.eachDependent}>
              <div>
                <Skeleton height={25} width={80} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
            </div>
          ) : allDependents?.length > 0 ? (
            allDependents?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachDependent}
                  key={eachItem?.dependent_name + index}
                >
                  <div>{eachItem?.dependent_name}</div>
                  <div>{eachItem?.relationship}</div>
                  <div>{eachItem?.primary_phone_number}</div>
                </div>
              );
            })
          ) : (
            <div>No items</div>
          )}
        </div>
      </div>
    </div>
  );
};

const CustomDropdown = ({
  width,
  color,
  buttonText,
  dropdownItems,
  stateValue,
  setState,
  typee,
  isBy,
}) => {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={classNames.customDropdown}
      style={{
        width: width ? width : "",
        color: color ? color : "",
        overflow: isActive ? "" : "hidden",
      }}
      ref={dropdownRef}
    >
      <div
        className={classNames.dropdownBtn}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <div>
          {isBy == "date" && stateValue
            ? `Sort By: ${stateValue}`
            : stateValue
            ? stateValue
            : buttonText}
        </div>
        <IoMdArrowDropdown
          style={{
            fontSize: "1.2rem",
            rotate: isActive ? "180deg" : "",
          }}
        />
      </div>

      <div
        className={classNames.dropdownContent}
        style={{
          display: isActive ? "" : "none",
          zIndex: isActive ? "7" : "",
          height: "max-content",
          // width: "280px",
        }}
      >
        {dropdownItems?.length > 0 && typee == "v3"
          ? dropdownItems?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachContent}
                  key={eachItem?.title + index}
                  onClick={() => {
                    if (eachItem?.clickable) {
                      setState(eachItem?.title);
                    }
                    setIsActive(false);
                  }}
                  style={{
                    cursor: eachItem?.clickable ? "" : "not-allowed",
                    backgroundColor: eachItem?.backgroundColor,
                    color: eachItem?.backgroundColor ? "white" : "",
                    fontWeight: eachItem?.backgroundColor ? 450 : "",
                    margin: eachItem?.backgroundColor ? "5px" : "",
                    borderRadius: eachItem?.backgroundColor ? "5px" : "",
                    padding: "10px 0.8rem",
                    // width: eachItem?.backgroundColor ?
                  }}
                >
                  {eachItem?.title}
                </div>
              );
            })
          : dropdownItems?.length > 0 &&
            dropdownItems?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachContent}
                  key={eachItem + index}
                  onClick={() => {
                    setState(eachItem);
                    setIsActive(false);
                  }}
                >
                  {eachItem}
                </div>
              );
            })}
      </div>
    </div>
  );
};
