import React from "react";
import classNames from "./brands.module.scss";

//assets

import emrigreenIcon from "../../../assest/images/allbrands/emrigreen.svg";
import nccIcon from "../../../assest/images/allbrands/ncc.svg";
import oneplusIcon from "../../../assest/images/allbrands/oneplus.svg";
import nmtIcon from "../../../assest/images/allbrands/nmt.svg";
import novotelIcon from "../../../assest/images/allbrands/novotel.svg";
import metropolisIcon from "../../../assest/images/allbrands/metropolis.svg";
import mygroupIcon from "../../../assest/images/allbrands/mygroup.svg";
import heritageIcon from "../../../assest/images/allbrands/heritage.svg";

import brand1 from "../../../assest/images/newbrands/Adtya Hospotals.jpg";
import brand2 from "../../../assest/images/newbrands/Aizant.jpg";
import brand3 from "../../../assest/images/newbrands/AMB.png";
import brand4 from "../../../assest/images/newbrands/Asian Cinema.jpg";
import brand5 from "../../../assest/images/newbrands/Basavatarakam.png";
import brand6 from "../../../assest/images/newbrands/BGR.jpg";
import brand7 from "../../../assest/images/newbrands/BiologicalE.png";
import brand8 from "../../../assest/images/newbrands/BSCPL.png";
import brand9 from "../../../assest/images/newbrands/CallHealth.png";
import brand10 from "../../../assest/images/newbrands/Chennai Silks.jpg";
import brand11 from "../../../assest/images/newbrands/Conneqt.jpg";
import brand12 from "../../../assest/images/newbrands/CTrlS.png";
import brand13 from "../../../assest/images/newbrands/ECI.jpg";
import brand14 from "../../../assest/images/newbrands/Elico.png";
import brand15 from "../../../assest/images/newbrands/Fernandez.jpg";
import brand16 from "../../../assest/images/newbrands/GAR.png";
import brand17 from "../../../assest/images/newbrands/GKC.jpg";
import brand18 from "../../../assest/images/newbrands/GRICL.jpg";
import brand19 from "../../../assest/images/newbrands/GVKEMRI.jpg";
import brand20 from "../../../assest/images/newbrands/IBPIL.png";
import brand21 from "../../../assest/images/newbrands/IJM.jpg";
import brand22 from "../../../assest/images/newbrands/IKF Finacne.jpg";
import brand23 from "../../../assest/images/newbrands/IKP.png";
import brand24 from "../../../assest/images/newbrands/ILean.png";
import brand25 from "../../../assest/images/newbrands/IMT.jpg";
import brand26 from "../../../assest/images/newbrands/IndiaLand.png";
import brand27 from "../../../assest/images/newbrands/Kamineni.jpg";
import brand28 from "../../../assest/images/newbrands/KEM Hospitals.jpg";
import brand29 from "../../../assest/images/newbrands/Lansum.jpg";
import brand30 from "../../../assest/images/newbrands/MyHomeGroup.jpg";
import brand31 from "../../../assest/images/newbrands/NavaBhrath.jpg";
import brand32 from "../../../assest/images/newbrands/NC.jpg";
import brand33 from "../../../assest/images/newbrands/NCLGroup.jpg";
import brand34 from "../../../assest/images/newbrands/NDR.png";
import brand35 from "../../../assest/images/newbrands/Neuland.jpg";
import brand36 from "../../../assest/images/newbrands/NextSphere.jpg";
import brand37 from "../../../assest/images/newbrands/Nisg.jpg";
import brand38 from "../../../assest/images/newbrands/Novotel.png";
import brand39 from "../../../assest/images/newbrands/OnPlus.jpg";
import brand40 from "../../../assest/images/newbrands/Oppo.png";
import brand41 from "../../../assest/images/newbrands/OTSI.png";
import brand42 from "../../../assest/images/newbrands/Ozontel.png";
import brand43 from "../../../assest/images/newbrands/ParkHyatt.png";
import brand44 from "../../../assest/images/newbrands/Poulomi.jpg";
import brand45 from "../../../assest/images/newbrands/PrimEra.png";
import brand46 from "../../../assest/images/newbrands/PVR Projects.jpg";
import brand47 from "../../../assest/images/newbrands/RSB.png";
import brand48 from "../../../assest/images/newbrands/SnehaChikens.png";
import brand49 from "../../../assest/images/newbrands/Sri Gopikrishna.png";
import brand50 from "../../../assest/images/newbrands/Suresh Productions.jpg";
import brand51 from "../../../assest/images/newbrands/Sushee.png";
import brand52 from "../../../assest/images/newbrands/Suven.jpg";
import brand53 from "../../../assest/images/newbrands/The FERM.png";
import brand54 from "../../../assest/images/newbrands/UMSL.jpg";
import brand55 from "../../../assest/images/newbrands/Usha Mullapudi.jpg";
import brand56 from "../../../assest/images/newbrands/Valuememomtum.png";
import brand57 from "../../../assest/images/newbrands/Vasudha Pharma.png";
import brand58 from "../../../assest/images/newbrands/Vertex.png";
import brand59 from "../../../assest/images/newbrands/Vignans.jpg";
import brand60 from "../../../assest/images/newbrands/Vijay Krishna Spices.png";
import brand61 from "../../../assest/images/newbrands/Virchow.jpg";
import brand62 from "../../../assest/images/newbrands/VSoft.png";

import { Link } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Brands = () => {
  // create an array of all brands
  const allBrands = [
    brand1,
    brand2,
    brand3,
    brand4,
    brand5,
    brand6,
    brand7,
    brand8,
    brand9,
    brand10,
    brand11,
    brand12,
    brand13,
    brand14,
    brand15,
    brand16,
    brand17,
    brand18,
    brand19,
    brand20,
    brand21,
    brand22,
    brand23,
    brand24,
    brand25,
    brand26,
    brand27,
    brand28,
    brand29,
    brand30,
    brand31,

    brand32,
    brand33,
    brand34,
    brand35,
    brand36,
    brand37,
    brand38,
    brand39,
    brand40,
    brand41,
    brand42,
    brand43,
    brand44,
    brand45,
    brand46,
    brand47,
    brand48,
    brand49,
    brand50,
    brand51,
    brand52,
    brand53,
    brand54,
    brand55,
    brand56,
    brand57,
    brand58,
    brand59,
    brand60,
    brand61,
    brand62,
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={classNames.brands}>
      <div className={classNames.brandsContainer}>
        <div className={classNames.title}>
          We Work With The Best Brands In The Country
        </div>
        <div style={{ marginTop: "90px", marginBottom: "90px" }}>
          <Carousel
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="all .5 ease-in-out"
            transitionDuration={500}
            // centerMode={true}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            focusOnSelect={true}
            infinite={true}
            // centerMode={true}
            // itemClass="image-item"
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
          >
            {allBrands.map((item, index) => (
              <div>
                <img
                  src={item}
                  alt={emrigreenIcon}
                  style={{
                    width: "200px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ))}
            {/* <div>
              <img src={emrigreenIcon} alt={emrigreenIcon} />
            </div>
            <div>
              <img src={nccIcon} alt={nccIcon} />
            </div>
            <div>
              <img src={oneplusIcon} alt={oneplusIcon} />
            </div>
            <div>
              <img src={nmtIcon} alt={nmtIcon} />
            </div>
            <div>
              <img src={novotelIcon} alt={novotelIcon} />
            </div>
            <div>
              <img src={metropolisIcon} alt={metropolisIcon} />
            </div>
            <div>
              <img src={mygroupIcon} alt={mygroupIcon} />
            </div>
            <div>
              <img src={heritageIcon} alt={heritageIcon} />
            </div> */}
          </Carousel>
        </div>

        {/* <div className={classNames.allBrands}>
          
        </div> */}
      </div>
      {/* <div className={classNames.demoContainer}>
        <div className={classNames.content}>
          <div className={classNames.para}>
            Invest in your team's well-being, health and productivity today!
          </div>
          <Link className={classNames.btn} to="/getquote">
            Request A Quote
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default Brands;
