import React, { useContext, useEffect, useState } from "react";
import classNames from "../crm.module.scss";
import styles from "./ac.module.scss";

import { IoMdClose, IoMdSearch } from "react-icons/io";

import arrowDown from "./asset/arrowDown.svg";
import calendarIcon from "./asset/calendar.svg";
import uploadIcon from "./asset/upload.svg";
import Axios from "axios";
import { uploadFileFunc, uploadImageFunc } from "../../../../utils/imageUpload";
import { BankContext } from "../../../../context/Context";
import { predefinedToast } from "../../../../utils/toast";
import { EachCustomDropdown } from "../../../../components/Custom/Input";

const AddCorporateLead = ({ setShowAddCorporate }) => {
  const { setUpdateLoading, email } = useContext(BankContext);

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [addEmail, setAddEmail] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [affiliate, setAffiliate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [noOfEmployee, setNoOfEmployee] = useState("");
  const [associate, setAssociate] = useState(email);
  const [teamLead, setTeamLead] = useState("");
  const [verticalHead, setVerticalHead] = useState("");
  const [businessHead, setBusinessHead] = useState("");

  const source = ["Online", "Offline", "Affiliate"];

  const [emailValidation, setEmailValidation] = useState(false);

  function isEmailAlreadyExist(email) {
    Axios.get(
      `https://comms.globalxchange.io/user/profile/data/get?email=${email}`
    )
      .then((response) => {
        console.log(response.data?.count, "is EmailAlreadyExist response");
        if (response?.data?.count) {
          if (!emailValidation) {
            console.log("positive response");
            setEmailValidation((prev) => !prev);
          }
        } else {
          if (emailValidation) {
            console.log("negative response");
            setEmailValidation((prev) => !prev);
          }
        }
      })
      .catch((error) => {
        console.log(error?.message, "is email exist API error");
      });
  }

  const handleAddLead = () => {
    setLoading(true);
    Axios.post(`https://insurance.apimachine.com/insurance/lead/create`, {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNo,
      reference_email: associate,
      lead_email: addEmail,
      lead_source: leadSource,
      lead_type: "company",
      external_lead: false,
      company_name: companyName,
      company_size: noOfEmployee,
      vertical_head: verticalHead,
      business_head: businessHead,
      team_lead: teamLead,
      added_from: "Associate",
    }).then(({ data }) => {
      console.log(data, "Add new lead response");
      if (data.status) {
        setLoading(false);
        setShowAddCorporate({ title: "Cost Center", data: data?.data?.data });
        predefinedToast("Added lead successfully!");
      } else {
        predefinedToast("Error while adding new lead");
      }
    });
  };

  return (
    <>
      <div
        className={classNames.overlay}
        onClick={(e) => {
          if (!loading) {
            setShowAddCorporate(false);
          }
          //   setShowQuoteDetails(null);
          //   setConfData(null);
          //   setSelectedQuoteTab("Overview");
          //   setSelectedConfTab("Health");
        }}
      ></div>
      <div className={styles.quoteDetailsWrapper} style={{ width: "50vw" }}>
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => {
            setShowAddCorporate(false);
            // setConfData(null);
            // setSelectedQuoteTab("Overview");
            // setSelectedConfTab("Health");
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>Add new lead</div>
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup}>
              <div>First name</div>
              <input
                type="text"
                placeholder="First Name.."
                value={firstName}
                onChange={(e) => {
                  const onlyLetters = /^[A-Za-z\s\.]+$/;

                  if (
                    (onlyLetters?.test(e?.target?.value) ||
                      e?.target?.value === "") &&
                    e?.target?.value.length <= 30
                  ) {
                    setFirstName(e.target.value);
                  }
                }}
              />
            </div>
            <div className={styles.inputGroup}>
              <div>Last name</div>
              <input
                type="text"
                placeholder="Last Name.."
                value={lastName}
                onChange={(e) => {
                  const onlyLetters = /^[A-Za-z\s\.]+$/;

                  if (
                    (onlyLetters?.test(e?.target?.value) ||
                      e?.target?.value === "") &&
                    e?.target?.value.length <= 30
                  ) {
                    setLastName(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup}>
              <div>Phone number</div>
              <div className={styles.validationDiv}>
                <input
                  type="text"
                  placeholder="Phone number.."
                  value={phoneNo}
                  onChange={(e) => {
                    if (
                      e?.target?.value.length <= 10 &&
                      /^-?\d+$/.test(e?.target?.value)
                    ) {
                      setPhoneNo(e.target.value);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (
                      event?.target?.value.length == 1 &&
                      event.key === "Backspace"
                    ) {
                      setPhoneNo("");
                    }
                  }}
                />
                <div
                  className={
                    phoneNo?.length === 10
                      ? styles.validatedDot
                      : styles.validationDot
                  }
                ></div>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div>Email</div>
              <div className={styles.validationDiv}>
                <input
                  type="text"
                  placeholder="Email.."
                  value={addEmail}
                  onChange={(e) => {
                    isEmailAlreadyExist(e?.target?.value);
                    if (e?.target?.value.length <= 50) {
                      setAddEmail(e.target.value);
                    }
                  }}
                />
                <div
                  className={
                    emailValidation
                      ? styles.validationDot
                      : !addEmail?.length <= 50 &&
                        !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{1,3}$/?.test(
                          addEmail
                        )
                      ? styles.validationDot
                      : styles.validatedDot
                  }
                ></div>
              </div>
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup1}>
              <div>Lead source</div>
              <div className={styles.leadSourceWrapper}>
                {source.map((item) => (
                  <div
                    className={
                      leadSource === item
                        ? styles.leadSourceSelected
                        : styles.leadSource
                    }
                    onClick={(e) => {
                      setLeadSource(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Select affiliate</div>
              <input
                type="text"
                placeholder="Affiliate.."
                value={affiliate}
                onChange={(e) => setAffiliate(e.target.value)}
              />
            </div>
          </div> */}
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Company name</div>
              <input
                type="text"
                placeholder="Company name.."
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>No of employees</div>
              <input
                type="text"
                placeholder="0"
                value={noOfEmployee}
                onChange={(e) => {
                  if (
                    e?.target?.value.length <= 5 &&
                    /^-?\d+$/.test(e?.target?.value)
                  ) {
                    setNoOfEmployee(e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    setNoOfEmployee("");
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Select associate</div>
              <input
                type="text"
                placeholder="Associate.."
                value={associate}
                onChange={(e) => setAssociate(e.target.value)}
              />
            </div>
          </div>

          {/* <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Select team lead</div>
              <input
                type="text"
                placeholder="Team lead.."
                value={teamLead}
                onChange={(e) => setTeamLead(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Select vertical head</div>
              <input
                type="text"
                placeholder="Vertical head.."
                value={verticalHead}
                onChange={(e) => setVerticalHead(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Select business head</div>
              <input
                type="text"
                placeholder="Business head.."
                value={businessHead}
                onChange={(e) => setBusinessHead(e.target.value)}
              />
            </div>
          </div> */}
          <div
            onClick={(e) => {
              if (
                firstName &&
                lastName &&
                phoneNo &&
                addEmail &&
                leadSource &&
                // affiliate &&
                companyName &&
                noOfEmployee &&
                associate
                // &&
                // teamLead &&
                // verticalHead &&
                // businessHead
              ) {
                handleAddLead();
              }
            }}
            className={styles.addBtn}
            style={{
              opacity: loading
                ? 0.5
                : firstName &&
                  lastName &&
                  phoneNo &&
                  addEmail &&
                  leadSource &&
                  // affiliate &&
                  companyName &&
                  noOfEmployee &&
                  associate
                ? 1
                : 0.5,
              pointerEvents: loading
                ? "none"
                : firstName &&
                  lastName &&
                  phoneNo &&
                  addEmail &&
                  leadSource &&
                  // affiliate &&
                  companyName &&
                  noOfEmployee &&
                  associate
                ? ""
                : "none",
            }}
          >
            {loading ? "Adding Lead.." : "Add"}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCorporateLead;

export const CostCenter = ({ showAddCorporate, setShowAddCorporate }) => {
  //constant

  const allRoles = [
    { title: "Sales Associate", name: "salesAssociate" },
    { title: "Service Associate", name: "serviceAssociate" },
    { title: "Team Leader", name: "teamLeader" },
    { title: "Vertical Head", name: "verticalHead" },
    { title: "Business Head", name: "businessHead" },
  ];

  const [loading, setLoading] = useState(false);
  const [costCenter, setCostCenter] = useState([]);
  const [costCenterRoles, setCostCenterRoles] = useState({});
  const [localRefresh, setLocalRefresh] = useState(false);

  const [associateDropdown, setAssociateDropdown] = useState([]);
  const [teamleadDropdown, setTeamleadDropdown] = useState([]);
  const [verticalheadDropdown, setverticalheadDropdown] = useState([]);
  const [businessheadDropdown, setBusinessheadDropdown] = useState([]);

  //functions

  async function getPossibleRoles(roleID, setState) {
    try {
      let response = await Axios.get(
        `https://api-dev.rahainsure.com/api/v1/raha-user/role/mapping?role_id=${roleID}`
      );
      if (response?.data?.data) {
        setState(response?.data?.data);
      }
      console.log(response?.data?.data, "Get possible roles response");
    } catch (error) {
      console.log(error?.message, "Get possible roles error");
    }
  }

  async function getCostingCenter(leadID) {
    try {
      let response = await Axios.get(
        `https://api-dev.rahainsure.com/api/v1/raha-lead/mapping/?lead_id=${leadID}`
      );
      if (response?.data?.data?.length > 0) {
        let res = response?.data?.data?.filter((eachRoleType) => {
          return eachRoleType?.role_id?.role_type == "Sales";
        });
        setCostCenter(res);

        res.forEach((eachItem, i) => {
          if (eachItem?.role_id?.role_designation === "Associates") {
            console.log(`Dropdown values coming is associates`, res);
            getPossibleRoles(eachItem?.role_id?._id, setAssociateDropdown);
          }
          if (eachItem?.role_id?.role_designation === "Team Leader") {
            console.log(`Dropdown values coming is Team Leader`, res);
            getPossibleRoles(eachItem?.role_id?._id, setTeamleadDropdown);
          }
          if (eachItem?.role_id?.role_designation === "Vertical Head") {
            console.log(`Dropdown values coming is Vertical Head`, res);
            getPossibleRoles(eachItem?.role_id?._id, setverticalheadDropdown);
          }
          if (eachItem?.role_id?.role_designation === "Business Head") {
            console.log(`Dropdown values coming is Business Head`, res);
            getPossibleRoles(eachItem?.role_id?._id, setBusinessheadDropdown);
          }
        });
      }
      console.log(response, "Get costing center response");
    } catch (error) {
      console.log(error?.message, "Get costing center error");
    }
  }

  //rendering

  useEffect(() => {
    localStorage.setItem(
      "costingCenterInsertionID",
      showAddCorporate?.data?.insertedId
    );
    if (
      showAddCorporate?.data?.insertedId ||
      localStorage.getItem("costingCenterInsertionID")
    ) {
      getCostingCenter(
        showAddCorporate?.data?.insertedId
          ? showAddCorporate?.data?.insertedId
          : localStorage.getItem("costingCenterInsertionID")
      );
    }
  }, [localRefresh]);

  return (
    <div className={styles.costCenter}>
      <div
        className={classNames.overlay}
        onClick={(e) => {
          if (!loading) {
            setShowAddCorporate(false);
          }
        }}
      ></div>
      <div className={styles.contentContainer}>
        <div className={styles.table}>
          <div className={styles.header}>Cost Center</div>
          <div className={styles.allRoles}>
            {costCenter?.map((eachItem, index) => {
              return (
                <div
                  key={eachItem?.role_id?.role_designation + index}
                  style={{
                    pointerEvents: eachItem?.user_id?.user_first_name
                      ? "none"
                      : "",
                  }}
                >
                  <div className={styles.role}>
                    {eachItem?.role_id?.role_designation}
                  </div>
                  <EachCustomDropdown
                    dropdown={
                      eachItem?.role_id?.role_designation === "Associates"
                        ? associateDropdown
                        : eachItem?.role_id?.role_designation === "Team Leader"
                        ? teamleadDropdown
                        : eachItem?.role_id?.role_designation ===
                          "Vertical Head"
                        ? verticalheadDropdown
                        : eachItem?.role_id?.role_designation ===
                          "Business Head"
                        ? businessheadDropdown
                        : []
                    }
                    name={"name"}
                    placeholder="Select name"
                    stateValue={
                      eachItem?.user_id?.user_first_name
                        ? {
                            name: eachItem?.user_id?.user_first_name,
                          }
                        : costCenterRoles
                    }
                    setState={setCostCenterRoles}
                    typee="costCenter"
                    option={
                      eachItem?.user_id?.user_first_name ? "alreadySet" : ""
                    }
                    typeee={
                      eachItem?.user_id?.user_first_name ? "removeArrow" : ""
                    }
                    apiVal={eachItem?._id}
                    setLocalRefresh={setLocalRefresh}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={styles.submitBtn}
          onClick={() => setShowAddCorporate(false)}
        >
          Finish
        </div>
      </div>
    </div>
  );
};
