import React, { useState, useEffect } from "react";
import styles from "./policyDetails.module.scss";
import Axios from "axios";
//assets
import { IoIosSettings } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { EditNewRaterCard } from "../AddPolicy";

const PolicyDetails = ({ selectedRow, setPopup }) => {
  const [selectedUserPlocies, setSelectedUserPlocies] = useState([]);
  const [openRaterCardDetails, setOpenRaterCardDetails] = useState("");
  const [editRaterCard, setEditRaterCard] = useState(false);
  const getPolicies = () => {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/policy?hr_id=${selectedRow?.hr_id}`
    ).then(({ data }) => {
      if (data.status) {
        setSelectedUserPlocies(data.data);
      }
    });
  };

  useEffect(() => {
    if (selectedRow) {
      getPolicies();
    }
  }, [selectedRow]);

  return (
    <div>
      <div className={styles.tabWrapper}>
        {selectedUserPlocies.map((item) => (
          <div className={styles.tabItem}>
            <div className={styles.row1}>
              <img
                src={item?.insurance_company_id?.insurance_company_logo_url}
                alt=""
                className={styles.compLogo}
              />
              <div className={styles.compDetails}>
                <div>{item?.policy_type}</div>
                <div>
                  Policy By:{" "}
                  {item?.insurance_company_id?.insurance_company_name}
                </div>
              </div>
            </div>
            <div className={styles.row2}>
              <div>Start Date: {item?.start_date}</div>
              <div>Policy Number: {item?.policy_number}</div>
            </div>
            <div className={styles.row2}>
              <div>Valid Till: {item?.end_date}</div>
              <div>Rater Card Per {item?.rate_card}</div>
            </div>
            <div className={styles.row3}>
              <div>Sum Insured</div>
              <div className={styles.innerCardWrapper}>
                {item?.policy_rate_card?.map((item1, index) => (
                  <div className={styles.innerCard}>
                    {item1?._id == editRaterCard ? (
                      <div>
                        <EditNewRaterCard
                          setEditRaterCard={setEditRaterCard}
                          existingData={item1}
                        />
                      </div>
                    ) : (
                      <div>
                        <div>
                          {item1?.policy_grade}
                          <div
                            className={styles.rulesBtn}
                            onClick={() => {
                              console.log(item1, "Selected policy rater card");
                              if (openRaterCardDetails) {
                                setOpenRaterCardDetails("");
                              } else {
                                setOpenRaterCardDetails(item1?._id);
                              }
                            }}
                          >
                            {item1?._id == openRaterCardDetails
                              ? "Close Rater Card Rules"
                              : "See Rater Card Details"}
                          </div>
                          <div>
                            <IoIosSettings
                              onClick={() => setEditRaterCard(item1?._id)}
                            />
                          </div>
                          <div>
                            <MdDelete />
                          </div>
                        </div>
                        <div>{item1?.sum_insured}</div>
                      </div>
                    )}
                    {item1?._id == openRaterCardDetails &&
                    Array.isArray(item1?.age_ranges) &&
                    item1?.age_ranges?.length > 0
                      ? item1?.age_ranges?.map((eachAge, i) => {
                          return (
                            <div className={styles.rulesBox} key={eachAge?._id}>
                              <div>
                                <div className={styles.title}>Min Age</div>
                                <div className={styles.valueDiv}>
                                  {eachAge?.min_age}
                                </div>
                              </div>
                              <div>
                                <div className={styles.title}>Max Age</div>
                                <div className={styles.valueDiv}>
                                  {eachAge?.max_age}
                                </div>
                              </div>
                              <div>
                                <div className={styles.title}>
                                  Rater Card Amount
                                </div>
                                <div className={styles.valueDiv}>
                                  {eachAge?.rate_card_amount?.toFixed(2)}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                ))}
                <div
                  className={styles.addRaterCardBtn}
                  onClick={() => {
                    setPopup({
                      title: "Add new rater card",
                      selectedpolicy: item,
                    });
                  }}
                >
                  Add Rater Card Rules
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PolicyDetails;
