import { ReactComponent as ProactiveIcon } from "../../assest/images/icons/dashboard/proactive.svg";
import { ReactComponent as ClaimsIcon } from "../../assest/images/icons/dashboard/claims.svg";
import { ReactComponent as CoverageIcon } from "../../assest/images/icons/dashboard/coverage.svg";
import { ReactComponent as WalletIcon } from "../../assest/images/icons/dashboard/wallet.svg";
import { ReactComponent as HospitalsIcon } from "../../assest/images/icons/dashboard/hospitals.svg";
import { ReactComponent as DependentsIcon } from "../../assest/images/icons/dashboard/dependents.svg";
import { ReactComponent as WellnessIcon } from "../../assest/images/icons/dashboard/wellness.svg";
import { ReactComponent as EngageIcon } from "../../assest/images/icons/dashboard/engage.svg";
import { ReactComponent as CabinetIcon } from "../../assest/images/icons/dashboard/cabinet.svg";
import { ReactComponent as rahastoreIcon } from "../../assest/images/icons/dashboard/rahastore.svg";
import { ReactComponent as pluginIcon } from "../../assest/images/icons/dashboard/plugin.svg";
import { ReactComponent as rewardsIcon } from "../../assest/images/icons/dashboard/rewards.svg";
import { ReactComponent as o2RewardsIcon } from "../../assest/images/icons/dashboard/o2rewards.svg";
import { ReactComponent as brokeragesIcon } from "../../assest/images/icons/dashboard/brokerages.svg";
import { ReactComponent as agentsIcon } from "../../assest/images/icons/dashboard/agents.svg";
import { ReactComponent as AgentsNewIcon } from "../../assest/images/icons/dashboard/agentsnew.svg";
import { ReactComponent as CrmIcon } from "../../assest/images/icons/dashboard/crm.svg";
import { ReactComponent as LeadsIcon } from "../../assest/images/icons/dashboard/leads.svg";
import { ReactComponent as LegacyIcon } from "../../assest/images/icons/dashboard/legacy.svg";
import { ReactComponent as growIcon } from "../../assest/images/icons/dashboard/grow.svg";
import { ReactComponent as AssociatesCRMIcon } from "../../assest/images/icons/dashboard/associatesCRM.svg";
import { ReactComponent as EmployeesIcon } from "../../assest/images/icons/dashboard/employees.svg";
import { ReactComponent as HrIcon } from "../../assest/images/icons/dashboard/hr.svg";
import { ReactComponent as ShareIcon } from "../../assest/images/icons/dashboard/share.svg";
import { ReactComponent as RebootIcon } from "../../assest/images/icons/dashboard/reboot.svg";

export const employeeAppSidebar = [
  {
    name: "Overview",
    image: <ProactiveIcon />,
    link: "/app/rahax/overview",
  },
  {
    name: "Dependents",
    image: <DependentsIcon />,
    link: "/app/rahax/dependents",
  },
  { name: "Claims", image: <CoverageIcon />, link: "/app/rahax/claims" },
  { name: "Hospitals", image: <HospitalsIcon />, link: "/app/rahax/hospitals" },
  { name: "Wellness", image: <WellnessIcon />, link: "/app/rahax/wellness" },
  // { name: "Engage", image: <EngageIcon />, link: "/app/agency/engage" },
  // { name: "Broadcast", image: <ShareIcon />, link: "/app/agency/broadcast" },
  // { name: "Legacy", image: <RebootIcon />, link: "/app/agency/legacy" },
];

export const associatesAppSidebar = [
  {
    name: "Dashboard",
    image: <ProactiveIcon />,
    link: "/app/agency/dashboard",
  },
  { name: "CRM", image: <HrIcon />, link: "/app/agency/crm" },
  {
    name: "CD Balance",
    image: <CoverageIcon />,
    link: "/app/agency/cdbalance",
  },
  { name: "Claims", image: <CoverageIcon />, link: "/app/agency/claims" },
  {
    name: "Hospitals",
    image: <HospitalsIcon />,
    link: "/app/agency/hospitals",
  },
  // { name: "Claims", image: <HospitalsIcon />, link: "/app/agency/claims" },
  // { name: "Hospitals", image: <WalletIcon />, link: "/app/agency/hospitals" },
  // { name: "Wellness", image: <WellnessIcon />, link: "/app/agency/wellness" },
  // { name: "Engage", image: <EngageIcon />, link: "/app/agency/engage" },
  // { name: "Broadcast", image: <ShareIcon />, link: "/app/agency/broadcast" },
  // { name: "Legacy", image: <RebootIcon />, link: "/app/agency/legacy" },
];

export const teamleadAppSidebar = [
  {
    name: "Dashboard",
    image: <ProactiveIcon />,
    link: "/app/teamlead/dashboard",
  },
  { name: "CRM", image: <HrIcon />, link: "/app/teamlead/crm" },
  {
    name: "CD Balance",
    image: <CoverageIcon />,
    link: "/app/teamlead/cdbalance",
  },
  { name: "Claims", image: <CoverageIcon />, link: "/app/teamlead/claims" },
  {
    name: "Hospitals",
    image: <HospitalsIcon />,
    link: "/app/teamlead/hospitals",
  },
  // { name: "Claims", image: <HospitalsIcon />, link: "/app/teamlead/claims" },
  // { name: "Engage", image: <EngageIcon />, link: "/app/teamlead/engage" },
  // { name: "Broadcast", image: <ShareIcon />, link: "/app/teamlead/broadcast" },
  // { name: "Legacy", image: <RebootIcon />, link: "/app/teamlead/legacy" },
];

export const verticalheadAppSidebar = [
  {
    name: "Dashboard",
    image: <ProactiveIcon />,
    link: "/app/verticalhead/dashboard",
  },
  { name: "CRM", image: <HrIcon />, link: "/app/verticalhead/crm" },
  {
    name: "CD Balance",
    image: <CoverageIcon />,
    link: "/app/verticalhead/cdbalance",
  },
  { name: "Claims", image: <CoverageIcon />, link: "/app/verticalhead/claims" },
  {
    name: "Hospitals",
    image: <HospitalsIcon />,
    link: "/app/verticalhead/hospitals",
  },
  // {
  //   name: "Claims",
  //   image: <HospitalsIcon />,
  //   link: "/app/verticalhead/claims",
  // },

  // { name: "Engage", image: <EngageIcon />, link: "/app/verticalhead/engage" },
  // {
  //   name: "Broadcast",
  //   image: <ShareIcon />,
  //   link: "/app/verticalhead/broadcast",
  // },
  // { name: "Legacy", image: <RebootIcon />, link: "/app/verticalhead/legacy" },
];

export const businessheadAppSidebar = [
  {
    name: "Dashboard",
    image: <ProactiveIcon />,
    link: "/app/businesshead/dashboard",
  },
  { name: "CRM", image: <HrIcon />, link: "/app/businesshead/crm" },
  {
    name: "CD Balance",
    image: <CoverageIcon />,
    link: "/app/businesshead/cdbalance",
  },
  { name: "Claims", image: <CoverageIcon />, link: "/app/businesshead/claims" },
  {
    name: "Hospitals",
    image: <HospitalsIcon />,
    link: "/app/businesshead/hospitals",
  },
  // {
  //   name: "Claims",
  //   image: <HospitalsIcon />,
  //   link: "/app/businesshead/claims",
  // },

  // { name: "Engage", image: <EngageIcon />, link: "/app/businesshead/engage" },
  // {
  //   name: "Broadcast",
  //   image: <ShareIcon />,
  //   link: "/app/businesshead/broadcast",
  // },
  // { name: "Legacy", image: <RebootIcon />, link: "/app/businesshead/legacy" },
];

export const affiliatesAppSidebar = [
  {
    name: "Dashboard",
    image: <ProactiveIcon />,
    link: "/app/affiliates/dashboard",
  },
  { name: "CRM", image: <HrIcon />, link: "/app/affiliates/crm" },
  {
    name: "CD Balance",
    image: <CoverageIcon />,
    link: "/app/affiliates/cdbalance",
  },
  { name: "Claims", image: <CoverageIcon />, link: "/app/affiliates/claims" },
  {
    name: "Hospitals",
    image: <HospitalsIcon />,
    link: "/app/affiliates/hospitals",
  },
  // {
  //   name: "Claims",
  //   image: <HospitalsIcon />,
  //   link: "/app/affiliates/claims",
  // },

  // { name: "Engage", image: <EngageIcon />, link: "/app/affiliates/engage" },
  // {
  //   name: "Broadcast",
  //   image: <ShareIcon />,
  //   link: "/app/affiliates/broadcast",
  // },
  // { name: "Legacy", image: <RebootIcon />, link: "/app/affiliates/legacy" },
];
