import React, { useContext, useEffect, useState } from "react";
import classNames from "./membersforms.module.scss";
import CustomCheckbox from "../../../../components/Custom";
import {
  EachCustomDatePicker,
  EachCustomDropdown,
  EachCustomInput,
} from "../../../../components/Custom/Input";
import { ReactComponent as HeartIcon } from "../../../../assest/images/icons/heart.svg";
import { ReactComponent as DownloadIcon } from "../../../../assest/images/employer/members/download.svg";
import { ReactComponent as UploadIcon } from "../../../../assest/images/employer/members/upload.svg";
import { ReactComponent as InitiatedtIcon } from "../../../../assest/images/employer/members/initiated.svg";
import { ReactComponent as PhoneIcon } from "../../../../assest/images/icons/phone.svg";
import { ReactComponent as MailIcon } from "../../../../assest/images/icons/mail.svg";
import { predefinedToast } from "../../../../utils/toast";
import UploadIconn from "../../../../assest/images/employer/members/upload.svg";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { BankContext } from "../../../../context/Context";

const MembersForms = ({ setState }) => {
  const [selectedTypes, setSelectedTypes] = useState([]);
  return (
    <div className={classNames.membersForms}>
      <div className={classNames.title}>
        Select Insurance company and Sum insured
      </div>
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"healthForm"}
            name={"healthForm name"}
            type="array"
          />
        </div>
        <div>Group Health Insurance</div>
        <div className={classNames.relativeDiv}>
          <EachCustomDropdown
            buttonText={selectedTypes ? selectedTypes : "Sum"}
            // color="var(--main-color)"
            title="Sum"
            dropdownItems={[]}
            name="Sum"
            placeholder="Sum"
            stateValue={selectedTypes}
            setState={setSelectedTypes}
            typee="obj"
          />
        </div>
      </div>
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalAccidentInsurance"}
            name={"personalAccidentInsurance name"}
            type="array"
          />
        </div>
        <div>Group Personal Accident Insurance</div>
        <div className={classNames.relativeDiv}>
          <EachCustomDropdown
            buttonText={selectedTypes ? selectedTypes : "Sum"}
            // color="var(--main-color)"
            title="Sum"
            dropdownItems={[]}
            name="Sum"
            placeholder="Sum"
            stateValue={selectedTypes}
            setState={setSelectedTypes}
            typee="obj"
          />
        </div>
      </div>
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalAccidentForm"}
            name={"personalAccidentForm name"}
            type="array"
          />
        </div>
        <div>Group Personal Accident Insurance</div>
        <div className={classNames.relativeDiv}>
          <EachCustomDropdown
            buttonText={selectedTypes ? selectedTypes : "Sum"}
            // color="var(--main-color)"
            title="Sum"
            dropdownItems={[]}
            name="Sum"
            placeholder="Sum"
            stateValue={selectedTypes}
            setState={setSelectedTypes}
            typee="obj"
          />
        </div>
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("")}>Back</button>
        <button onClick={() => setState("singleentrymemberdetails")}>
          Next
        </button>
      </div>
    </div>
  );
};

export default MembersForms;

export const MembersDetailsForm = ({ setState }) => {
  const [memberDetailsForm, setMemberDetailsForm] = useState("");
  return (
    <div className={classNames.membersDetailsForm}>
      <div className={classNames.title}>Add Member Details</div>
      <div className={classNames.formFields}>
        <EachCustomInput
          placeholder="Employee Name"
          name="first_name"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
        />
        <EachCustomInput
          placeholder="Employee ID"
          name="first_name"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
        />
        <EachCustomDatePicker
          //   title="Policy valid until"
          placeholder="Date of Birth"
          // dropdown={usersDropdown}
          name="startDate"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
          //   mandatory={clientOnboardingForm ? true : false}
        />
        <EachCustomDropdown
          buttonText={memberDetailsForm ? memberDetailsForm : "Sum"}
          // color="var(--main-color)"
          dropdownItems={["Male", "Female"]}
          name="gender"
          placeholder="Gender"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
          typee="single"
        />
        <EachCustomDatePicker
          //   title="Policy valid until"
          placeholder="Date of Joining"
          // dropdown={usersDropdown}
          name="startDate"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
          //   mandatory={clientOnboardingForm ? true : false}
        />
        <EachCustomInput
          placeholder="Designation"
          name="first_name"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
        />
        <EachCustomInput
          placeholder="Email ID"
          name="first_name"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
        />
        <EachCustomInput
          placeholder="Phone number"
          name="first_name"
          stateValue={memberDetailsForm}
          setState={setMemberDetailsForm}
        />
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("singleentry")}>Back</button>
        <button onClick={() => setState("MemberDetailsAddDependent")}>
          Next
        </button>
      </div>
    </div>
  );
};

export const MemberDetailsAddDependent = ({ setState }) => {
  const [memberDetailsForm, setMemberDetailsForm] = useState({
    name: "",
    dob: "",
    relation: "",
  });
  const [dependentList, setDependentList] = useState([]);

  useEffect(() => {
    setMemberDetailsForm({ name: "", dob: "", relation: "" });
  }, [dependentList]);

  return (
    <div className={classNames.memberAddDependent}>
      <div className={classNames.title}>Add Member Details</div>
      {Array(dependentList?.length >= 0 ? dependentList?.length + 1 : 1)
        .fill()
        .map((eachItem, index) => {
          return (
            <div className={classNames.allDependents} key={eachItem + index}>
              <EachCustomInput
                placeholder="Name"
                name="name"
                stateValue={memberDetailsForm}
                setState={setMemberDetailsForm}
              />
              <EachCustomDatePicker
                //   title="Policy valid until"
                placeholder="Date of Birth"
                // dropdown={usersDropdown}
                name="dob"
                stateValue={memberDetailsForm}
                setState={setMemberDetailsForm}
                //   mandatory={clientOnboardingForm ? true : false}
              />
              <EachCustomDropdown
                buttonText={memberDetailsForm ? memberDetailsForm : "Relation"}
                // color="var(--main-color)"
                dropdownItems={["Self", "Spouse"]}
                name="relation"
                placeholder="Relation"
                stateValue={memberDetailsForm}
                setState={setMemberDetailsForm}
                typee="single"
              />
            </div>
          );
        })}
      <div
        className={classNames.addDependentBtn}
        onClick={() => {
          setDependentList((prev) => {
            return [...prev, memberDetailsForm];
          });
        }}
      >
        <HeartIcon />
        Add another dependent
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("singleentrymemberdetails")}>
          Back
        </button>
        <button onClick={() => setState("MembersReviewDetails")}>Next</button>
      </div>
    </div>
  );
};

export const MembersReviewDetails = ({ setState }) => {
  const [memberDetailsForm, setMemberDetailsForm] = useState("");
  return (
    <div className={classNames.membersDetailsForm}>
      <div className={classNames.title}>Review employee details</div>
      <div className={classNames.subTitle}>Insurance details</div>
      <div className={classNames.detailsContainer}>
        <div>
          <div>Group Health insurance Policy</div>
          <div>INR 5,00,000</div>
        </div>
        <div></div>
      </div>
      <div className={classNames.detailsContainer}>
        <div>
          <div>Group Accidental insurance Policy</div>
          <div>INR 5,00,000</div>
        </div>
      </div>
      <div className={classNames.subTitle}>Member details</div>
      <div className={classNames.detailsContainer}>
        <div>
          <div>Shorupan Pirakaspathy</div>
          <div>09/09/2023</div>
        </div>
        <div>Employee</div>
      </div>
      <div className={classNames.detailsContainer}>
        <div>
          <div>Shorupan Pirakaspathy</div>
          <div>09/09/2023</div>
        </div>
        <div>Wife</div>
      </div>

      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("MemberDetailsAddDependent")}>
          Back
        </button>
        <button onClick={() => setState("MembersReviewCompleted")}>Next</button>
      </div>
    </div>
  );
};

export const MembersReviewCompleted = ({ setState }) => {
  return (
    <div className={classNames.bulkUploadMemberUpdated}>
      <div className={classNames.title} onClick={() => setState([])}>
        X
      </div>
      <div className={classNames.bulkDocuments}>
        <div>
          <InitiatedtIcon />
          <span>Members Updated</span>
          <div className={classNames.diffTypes}>
            <div>Added: 1</div>
            <div>Deleted: 0</div>
            <div>Updated: 0</div>
          </div>
          <div className={classNames.balanceContainer}>
            <div>CD Balance</div>
            <div>INR 45,000.00</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BulkUpload = ({ setState }) => {
  return (
    <div className={classNames.bulkUpload}>
      <div className={classNames.title}>Bulk Upload</div>
      <div className={classNames.bulkDocuments}>
        <div>
          <DownloadIcon />
          <span>Download Template</span>
        </div>
        <div>
          <UploadIcon />
          <span>Upload Template</span>
        </div>
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("")}>Back</button>
        <button onClick={() => setState("bulkentryupdated")}>Next</button>
      </div>
    </div>
  );
};

export const BulkUploadMemberUpdated = ({ setState }) => {
  return (
    <div className={classNames.bulkUploadMemberUpdated}>
      <div className={classNames.title} onClick={() => setState([])}>
        X
      </div>
      <div className={classNames.bulkDocuments}>
        <div>
          <InitiatedtIcon />
          <span>Members Updated</span>
          <div className={classNames.diffTypes}>
            <div>Added: 1</div>
            <div>Deleted: 0</div>
            <div>Updated: 0</div>
          </div>
          <div className={classNames.balanceContainer}>
            <div>CD Balance</div>
            <div>INR 45,000.00</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PreEnrollmentForm = ({ setState }) => {
  const [selectedTypes, setSelectedTypes] = useState([
    "allThreeInsuranceTypes",
  ]);
  return (
    <div className={classNames.membersForms}>
      <div className={classNames.title} style={{ marginBottom: "1rem" }}>
        Step 1: Select Insurance Policy
      </div>
      <div
        className={classNames.eachInsurance}
        style={{ pointerEvents: "none", opacity: "0.5" }}
      >
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"healthForm"}
            name={"healthForm name"}
            type="array"
          />
        </div>
        <div>Group Health Insurance</div>
      </div>
      <div
        className={classNames.eachInsurance}
        style={{ pointerEvents: "none", opacity: "0.5" }}
      >
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalAccidentInsurance"}
            name={"personalAccidentInsurance name"}
            type="array"
          />
        </div>
        <div>Group Personal Accident Insurance</div>
      </div>
      <div
        className={classNames.eachInsurance}
        style={{ pointerEvents: "none", opacity: "0.5" }}
      >
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"personalLifeInsurance"}
            name={"personalLifeInsurance name"}
            type="array"
          />
        </div>
        <div>Group Personal Life Insurance</div>
      </div>
      <div className={classNames.eachInsurance}>
        <div className={classNames.relativeDiv}>
          <CustomCheckbox
            actionBtns={selectedTypes}
            setActionBtns={setSelectedTypes}
            id={"allThreeInsuranceTypes"}
            name={"allThreeInsuranceTypes name"}
            type="array"
          />
        </div>
        <div>All three insurance types</div>
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("")}>Back</button>
        <button onClick={() => setState("PreEnrollmentDownloads")}>Next</button>
      </div>
    </div>
  );
};

export const PreEnrollmentDownloads = ({ setState }) => {
  const history = useHistory();
  const { globalHrId, setUpdateLoading } = useContext(BankContext);
  const [uploadFileSelected, setUploadFileSelected] = useState("");
  const [uploadFileSelectedName, setUploadFileSelectedName] = useState("");

  function uploadEnrollmentViaPreEnrollment() {
    setUpdateLoading(true);
    const formData = new FormData();
    formData.append(
      "hr_id",
      globalHrId
        ? globalHrId
        : localStorage.getItem("globalUserHrIdRaha")
        ? localStorage.getItem("globalUserHrIdRaha")
        : ""
    );
    formData.append("file", uploadFileSelected);

    Axios.post(
      "https://api-dev.rahainsure.com/api/v1//employee-spreadsheet/upload-endorsement",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        console.log(response, "Uploaded endorsements via pre enrolment");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setUpdateLoading(false);
          setState("PreEnrollmentUpdated");
          // setTimeout(() => {
          //   if (window?.location?.pathname?.includes("/agency")) {
          //     history.push("/app/agency/leads");
          //   } else {
          //     history.push("/app/coverfi/members");
          //   }
          //   // window.location.reload();
          // }, 3000);
        } else {
          setUpdateLoading(false);
          predefinedToast(response?.data?.message);
          setState("PreEnrollmentDownloads");
        }
      })
      .catch((error) => {
        console.log(
          error?.message,
          "Uploaded endorsements via pre enrolment error"
        );
        setState("PreEnrollmentDownloads");
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(corporateLead, "corporateLead agency API");
  }

  return (
    <div className={classNames.bulkUpload}>
      <div className={classNames.title}>Upload communication info</div>
      <div className={classNames.bulkDocuments}>
        <div
          onClick={() => {
            window.open(
              "https://insurance.apimachine.com/insurance/general/files/fb0947f0-8f25-4118-8c86-62aff6c57a98.xlsx",
              "_blank"
            );
          }}
        >
          <DownloadIcon />
          <span>Download Template</span>
        </div>
        <div>
          {/* <UploadIcon /> */}
          <EachInputFileUpload
            title={
              uploadFileSelectedName
                ? uploadFileSelectedName
                : "Upload Template"
            }
            icon={UploadIconn}
            stateValue={uploadFileSelected}
            setState={setUploadFileSelected}
            setName={setUploadFileSelectedName}
          />
          {/* <span>Upload Template</span> */}
        </div>
      </div>
      <div className={classNames.btnsContainer}>
        <button onClick={() => setState("PreEnrollmentForm")}>Back</button>
        <button onClick={uploadEnrollmentViaPreEnrollment}>Next</button>
      </div>
    </div>
  );
};

export const PreEnrollmentUpdated = ({ setState }) => {
  return (
    <div className={classNames.bulkUploadMemberUpdated}>
      <div className={classNames.title} onClick={() => setState([])}>
        X
      </div>
      <div className={classNames.bulkDocuments}>
        <div>
          <InitiatedtIcon />
          <span>Enrolment Initiated</span>
          <div className={classNames.diffTypes} style={{ padding: "0 3rem" }}>
            <div>Added: 1</div>
            {/* <div>Deleted: 0</div>
            <div>Updated: 0</div> */}
          </div>
          <div className={classNames.balanceContainer}>
            <div>CD Balance</div>
            <div>INR 45,000.00</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormUpdateCompleted = ({ setState }) => {
  return (
    <div className={classNames.formUpdateCompleted}>
      <div className={classNames.title} onClick={() => setState(false)}>
        X
      </div>
      <div className={classNames.bulkDocuments}>
        <div>
          <InitiatedtIcon />
          <span style={{ fontWeight: "500" }}>
            Thank you for your request. We will be of your assistance at every
            step of the way
          </span>
          <span>
            Shortly you will find a confirmation in your email with next steps.
          </span>
          <div className={classNames.contactUs}>
            <div className={classNames.title}>Contact Us</div>
            <div className={classNames.contactDiv}>
              <div>
                Speak to Team Lead
                <div className={classNames.iconDiv}>
                  <PhoneIcon />
                  <MailIcon />
                </div>
              </div>
              <div>
                Speak to Associate
                <div className={classNames.iconDiv}>
                  <PhoneIcon />
                  <MailIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//custom inputs

export const EachInputFileUpload = ({
  title,
  icon,
  stateValue,
  setState,
  setName,
}) => {
  const handleFileChange = (event) => {
    if (event?.target?.files?.length > 0) {
      setName(event?.target?.files[0]?.name);
      setState(event?.target?.files[0]);
    }
  };

  return (
    <div className={classNames.eachInput}>
      <label
        className={classNames.fileAction}
        htmlFor="fileUpload"
        onClick={handleFileChange}
      >
        <img src={icon} alt="downloadIcon" />
        <span>{title}</span>
      </label>
      <input
        type="file"
        id="fileUpload"
        accept=".xls,.xlsx"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
};
