import React, { useContext } from "react";
import classNames from "./careers.module.scss";
import Navbar from "../../components/navbar";
import MobileNavbar from "../../components/navbar/mobile";
import { useEffect } from "react";
import { useState } from "react";
import { BankContext } from "../../context/Context";

const Careers = () => {
  const { isScrolled, setIsScrolled } = useContext(BankContext);

  useEffect(() => {
    const homeElement = document.getElementById("careerContainer");

    const handleScroll = () => {
      const scrollPosition = homeElement.scrollTop;
      const containerHeight = homeElement.clientHeight;
      const scrollThreshold = containerHeight * 0.5; // 50% of the container height

      if (scrollPosition >= scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    homeElement.addEventListener("scroll", handleScroll);

    return () => {
      homeElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {window.innerWidth > 768 ? (
        <Navbar isScrolled={isScrolled} />
      ) : (
        <MobileNavbar />
      )}

      <div className={classNames.careerContainer} id="careerContainer">
        <div className={classNames.leftSide}>&nbsp;</div>
        <div className={classNames.rightSide}>
          <div>
            <div className={classNames.subTitle}>Looking For A Job?</div>
            <div className={classNames.title}>
              Join The Greatest Team
              <br />
              Protecting Teams
            </div>
            <div className={classNames.text}>
              A promising career awaits you. Unleash the world of
              <br /> opportunities at Raha.
              <br />
              <br />
              Please forward your CV to{" "}
              <a
                href="mailto:hr@rahainsure.com"
                style={{ color: "#5F6163", fontWeight: 700 }}
              >
                HR@rahainsure.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
