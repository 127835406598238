import React, { useContext, useEffect, useState } from "react";
import "./preloginpolicies.css";
import classNames from "./preloginpolicies.module.scss";
import useWindowDimensions from "../../utils/WindowSize";

import Navbar from "../../components/navbar";
import HomeMobile from "../../components/mobile/home";
import { useLocation } from "react-router-dom";
import MobileNavbar from "../../components/navbar/mobile";
import { BankContext } from "../../context/Context";

const PreLoginPolicies = () => {
  const { isScrolled, setIsScrolled } = useContext(BankContext);

  useEffect(() => {
    const homeElement = document.getElementById("preloginpolicies");

    const handleScroll = () => {
      const scrollPosition = homeElement.scrollTop;
      const containerHeight = homeElement.clientHeight;
      const scrollThreshold = containerHeight * 0.5; // 50% of the container height

      if (scrollPosition >= scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    homeElement.addEventListener("scroll", handleScroll);

    return () => {
      homeElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { width, height } = useWindowDimensions();
  const location = useLocation();

  return (
    <div className="preloginpolicies" id="preloginpolicies">
      {width > 800 ? (
        <>
          {window.innerWidth > 800 ? (
            <Navbar isScrolled={isScrolled} />
          ) : (
            <MobileNavbar />
          )}
          {location?.pathname?.includes("/shipdeliverypolicy") ? (
            <ShipAndDelivery />
          ) : (
            <RefundAndCancellation />
          )}
        </>
      ) : (
        <>
          <MobileNavbar />
          {location?.pathname?.includes("/shipdeliverypolicy") ? (
            <ShipAndDelivery />
          ) : (
            <RefundAndCancellation />
          )}
        </>
        // <HomeMobile />
      )}
    </div>
  );
};

export default PreLoginPolicies;

const ShipAndDelivery = () => {
  return (
    <div className={classNames.shipAndDelivery}>
      <div className={classNames.title}>Ship & Delivery Policy</div>
      <div className={classNames.desc}>
        At Raha, we are committed to delivering services to our clients
        efficiently, reliably, and with the utmost transparency. This Shipping
        and Delivery Policy outlines our procedures for the delivery of our
        services to ensure a seamless and secure experience for our clients.
      </div>
      <ol className={classNames.allPolicies}>
        <li className={classNames.eachPolicy}>
          <span>Service Delivery Method:</span>
          <ul>
            <li>
              We primarily provide our services electronically through our
              secure online platform. Our services are accessible via the
              internet, and we offer digital delivery methods for all relevant
              documents, such as policy documents, invoices, and reports.
            </li>
          </ul>
        </li>
        <li className={classNames.eachPolicy}>
          <span>Delivery Timeframe:</span>
          <ul>
            <li>
              The delivery timeframe for our services may vary based on the
              specific service or product you have chosen. The estimated
              delivery time will be communicated to you during the ordering
              process. We make every effort to ensure that services are
              delivered in a timely manner, and any delays will be communicated
              to you promptly.
            </li>
          </ul>
        </li>
        <li className={classNames.eachPolicy}>
          <span>Confirmation of Service Delivery:</span>
          <ul>
            <li>
              Once your order has been processed, you will receive a
              confirmation email or notification. This communication will
              include details regarding the service or product you have
              purchased and any relevant delivery information.
            </li>
          </ul>
        </li>
        <li className={classNames.eachPolicy}>
          <span>Digital Documents:</span>
          <ul>
            <li>
              For services that involve the issuance of documents such as
              policies or reports, these documents will be provided in digital
              format. You can access and download them securely through your
              Raha online account.
            </li>
          </ul>
        </li>
        <li className={classNames.eachPolicy}>
          <span>Non-Physical Services:</span>
          <ul>
            <li>
              Raha primarily offers non-physical services, which do not involve
              shipping, transportation, or physical delivery of goods.
            </li>
          </ul>
        </li>
        <li className={classNames.eachPolicy}>
          <span>Exceptional Circumstances:</span>
          <ul>
            <li>
              In the rare event of exceptional circumstances that may affect
              service delivery, such as technical issues or unforeseen delays,
              we will make every effort to notify you promptly and resolve the
              issue as efficiently as possible.
            </li>
          </ul>
        </li>
        <li className={classNames.eachPolicy}>
          <span>Communication Channels:</span>
          <ul>
            <li>
              For any inquiries, updates, or concerns related to service
              delivery, please contact our customer support team at
              grievences@rahainsure.com. We are here to assist you and provide
              you with information about the status of your service delivery.
            </li>
          </ul>
        </li>
        <li className={classNames.eachPolicy}>
          <span>Service Quality Assurance:</span>
          <ul>
            <li>
              Raha is dedicated to ensuring the highest quality of service. If
              you have any concerns about the quality or delivery of our
              services, please contact us immediately. We are committed to
              addressing any issues promptly and to your satisfaction.
            </li>
          </ul>
        </li>
        <li className={classNames.eachPolicy}>
          <span>Changes to this Policy:</span>
          <ul>
            <li>
              We reserve the right to amend this Shipping and Delivery Policy at
              any time. Updates to the policy will be posted on our website, and
              the new policy will apply to all services and products from the
              date of posting.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

const RefundAndCancellation = () => {
  return (
    <div className={classNames.refundAndCancellation}>
      <div className={classNames.contentDiv}>
        <div className={classNames.title}>CANCELLATION & REFUND POLICY</div>
        <div className={classNames.subTitle}>Policy for Services</div>
        <table className={classNames.threeColumnTable}>
          <thead>
            <tr>
              <th>Scenario</th>
              <th>Cancellation Policy</th>
              <th>Refund Policy</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Customer cancels prior to service delivery Prior to service
                delivery, customer may request for cancellation by calling our
                Call Centre or through the online customer portal. No
                cancellation charges will apply. If payment has been collected
                prior to service delivery,100% refund will be made to the
                respective payment instrument used by the customer.
              </td>
              <td>
                Customer cancels prior to service delivery Prior to service
                delivery, customer may request for cancellation by calling our
                Call Centre or through the online customer portal. No
                cancellation charges will apply. If payment has been collected
                prior to service delivery,100% refund will be made to the
                respective payment instrument used by the customer.
              </td>
              <td style={{ padding: 0 }}>
                <div>
                  Customer cancels prior to service delivery Prior to service
                  delivery, customer may request for cancellation by calling our
                  Call Centre or through the online customer portal. No
                  cancellation charges will apply. If payment has been collected
                  prior to service delivery,100% refund will be made to the
                  respective payment instrument used by the customer.
                </div>
                <div>
                  If payment is yet to be collected, there is no refund.
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Raha through Its Vendor Partners initiates cancellation /
                rescheduling prior to service delivery. Note: Raha with its
                Vendor Partners will make all arrangements to deliver services
                at the scheduled time. In case we are unable to do so, we will
                request for a mutually agreeable time
              </td>
              <td>
                Raha through Its Vendor Partners initiates cancellation /
                rescheduling prior to service delivery. Note: Raha with its
                Vendor Partners will make all arrangements to deliver services
                at the scheduled time. In case we are unable to do so, we will
                request for a mutually agreeable time. If the customer disagrees
                on the rescheduling, then request for cancellation is confirmed.
                If payment has been collected, 100% refund will be made to the
                respective payment instrument used by the customer.
              </td>
              <td style={{ padding: 0 }}>
                <div>
                  Raha through Its Vendor Partners initiates cancellation /
                  rescheduling prior to service delivery. Note: Raha with its
                  Vendor Partners will make all arrangements to deliver services
                  at the scheduled time. In case we are unable to do so, we will
                  request for a mutually agreeable time. If the customer
                  disagrees on the rescheduling, then request for cancellation
                  is confirmed. If payment has been collected, 100% refund will
                  be made to the respective payment instrument used by the
                  customer.
                </div>
                <div>
                  If payment is yet to be collected, there is no refund.
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Customer cancels after service delivery has commenced. Note: At
                the discretion of Raha, a customer can be denied services in
                cases where the customer cancels the service without any valid
                reason.
              </td>
              <td>
                At the customer’s request, Raha with the help of Vendor Partners
                will cancel the order and stop the service delivery.
              </td>
              <td style={{ padding: 0 }}>
                <div>
                  If payment has been collected, 100% refund will be made to the
                  respective payment instrument used by the customer.
                </div>
                <div>
                  If payment is yet to be collected, there is no refund.
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Customer is dissatisfied with the service Not applicable At the
                discretion of Raha with its Vendor Partner, customer will be
                offered an option of repeating the service.
              </td>
              <td>Not applicable</td>
              <td>
                At the discretion of Raha with its Vendor Partner, customer will
                be offered an option of repeating the service.
              </td>
            </tr>
            <tr>
              <td>Incorrect billing </td>
              <td>Not applicable</td>
              <td>
                If the bill is raised for a higher amount, then the excess
                amount will be made to the respective payment instrument used by
                the customer. If the bill is raised for a lesser amount, the
                customer will be requested to make the required payment.
              </td>
            </tr>
          </tbody>
        </table>
        <div className={classNames.subTitle}>Definition: </div>
        <p>
          'Refund' means an action of giving back to the Customer the amount
          paid by Customer (excluding any Raha wallet balance) if the product
          ordered at Raha portal by the customer is returned by the Customer AND
          if such return is due to any one or more of the below four (4) factors
          ONLY:
        </p>
        <ol>
          <li>Product(s) / Services delivered do not match your order;</li>
          <li>
            Product(s) / Services delivered are past or near to its expiry date
            (only medicines with an expiry date of less than 03 months as on
            date of delivery shall be considered as near expiry);
          </li>
          <li>
            Product(s) / Services delivered were damaged in transit (do not to
            accept any product which has a tampered seal).
          </li>
          <li>
            Product(s) / Services delivered were spurious (do not accept any
            product which is tampered) or defective item.
          </li>
        </ol>
        <p>
          Any refund request arising apart from the above four conditions shall
          be accepted only on sole discretion of Raha.
        </p>
        <p>
          Provided, that for the following classes of items refund shall be made
          ONLY if the above conditions are satisfied:
        </p>
        <table className={classNames.threeColumnTable}>
          <thead>
            <tr>
              <th>Categories</th>
              <th>
                Type of Products (These are indicative of the class and is not
                an exhaustive or all-inclusive list)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Baby Care</td>
              <td>
                Bottle Nipples, Breast Nipple Care, Breast Pumps, Diapers, Ear
                Syringes, Nappy, Wet Reminder, Wipes and Wipe Warmers
              </td>
            </tr>
            <tr>
              <td>Supplements & Wellness Products</td>
              <td>Health Drinks, Health Supplements</td>
            </tr>
            <tr>
              <td>Medical & Surgical devices</td>
              <td>
                Medical & Surgical devices Glucometer/Strip, Lancet, Healthcare
                Devices and Kits, Surgical, B.P Monitors, Pregnancy Kits
              </td>
            </tr>
            <tr>
              <td>Personal Care</td>
              <td>Sexual wellness, Cosmetics, Sanitary and Personal Hygiene</td>
            </tr>
            <tr>
              <td>Injections and Speciality Medicines</td>
              <td>
                Insulins, Vials, Ampoules, Injections, Vaccines, Pen fills and
                all other Product requiring cold storage, or medicines that fall
                under the category of speciality medicines.
              </td>
            </tr>
            <tr>
              <td>OTC and Ayurveda Products</td>
              <td>Herbal Preparations, Sprays, Soaps and all general items.</td>
            </tr>
          </tbody>
        </table>
        <p>
          If the product that you have received is damaged, then do not accept
          the delivery of that product. In case after opening the package, you
          discover that the product is damaged, the same may be returned for
          refund.
        </p>
        <p>
          In the aforesaid unlikely situations, if there is something wrong with
          the order, Raha will be pleased to assist and resolve your concern.
          You may raise a refund request with Raha’s Vendor Partner customer
          care within 07 (Seven) days from the day of receipt of the product.
          Raha reserves the right to reject the refund request, if the customer
          reaches out to Raha after 7 days of receiving of order.
        </p>
        <p>
          In the event of frivolous and unjustified complaints regarding the
          quality and content of the products, Rahareserves the right to pursue
          necessary legal actions against you, including denial of future
          service, and you will be solely liable for all costs incurred by Raha
          in this regard.
        </p>
        <p className={classNames.subTitle}>
          Refunds are subject to the below conditions:
        </p>
        <ol>
          <li>Any wrong ordering of product doesn’t qualify for Refund;</li>
          <li>
            Batch number of the product being returned should match as mentioned
            on the invoice;
          </li>
          <li>
            Refund requests arising due to change in prescription do not qualify
            for Replacement;
          </li>
          <li>
            Product being returned should only be in their original
            manufacturer's packaging i.e. with original price tags, labels,
            bar-code and invoice; and
          </li>
          <li>
            Partially consumed strips or products do not qualify for Refund,
            only fully unopened strips or products can be returned.
          </li>
          <li>
            Cold chain products (vial, injection, vaccines/ any other cold chain
            products) do not qualify for refund.
          </li>
        </ol>
        <p className={classNames.subTitle}>Refund Process:</p>
        <ol>
          <li>
            For Refund intimation, please visit us at www.rahainsure.com or
            alternatively contact our customer care at 91 9100 098 491.
          </li>
          <li>
            Raha customer care team will verify the claim made by the customer
            within 72 (seventy-two) business hours from the time of receipt of
            complaint.
          </li>
          <li>
            Once the claim is verified as genuine and reasonable, Raha will
            initiate the collection of products to be returned.
          </li>
          <li>
            The customer will be required to pack the products in original
            manufacturer’s packaging.
          </li>
          <li>
            All products claimed to be damaged need to be returned to invoicing
            vendor who will confirm if the product is damaged. You are requested
            to take pictures of any damaged product that you have received.
          </li>
          <li>
            Raha shall initiate the refund only once it has received
            confirmation from the vendor concerned in respect of the contents of
            the product relating to that refund.
          </li>
          <li>
            Customer must retain the invoice till completion of refund process.
          </li>
        </ol>
        <p>
          In all the above cases, if the claim is found to be valid, Refund will
          be made as mentioned below:
        </p>
        <ol>
          <li>
            Order placed through online wallet will be credited to the wallet;
            and
          </li>
          <li>
            Order placed through cash on delivery will be refunded through fund
            transfer to customer bank account.
          </li>
          <li>
            Order placed through credit/ debit card will be refunded through
            fund transfer to source bank account.
          </li>
          <li>
            Refund if any, will be initiated within 10 (Ten) working days from
            date of receipt of the returned products by Raha.
          </li>
        </ol>
        <table className={classNames.threeColumnTable}>
          <thead>
            <tr>
              <th>Mode Of Payment</th>
              <th>Mode Of refund</th>
              <th>Timeframe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cash on delivery</td>
              <td>NEFT to bank account</td>
              <td>Within 10 working days</td>
            </tr>
            <tr>
              <td>Credit/debit card/net banking</td>
              <td>NEFT to bank account</td>
              <td>Within 10 working days</td>
            </tr>
            <tr>
              <td>Raha wallet</td>
              <td>Raha wallet</td>
              <td>10 working days</td>
            </tr>
          </tbody>
        </table>
        <p>
          While we regret any inconvenience caused by this time frame, it is the
          bank's policy that delays the refund timing and we have no control
          over that.
        </p>
        <p className={classNames.subTitle}>Cancellation Policy:</p>
        <ol>
          <li>
            Customer can cancel the order through Web: www.rahainsure.com, or by
            call at 91 9100 098 491.
          </li>
          <li>
            No cancellation fee if customer request for cancellation before
            order is confirmed.
          </li>
          <li>
            Cancellation fee will be applicable if order is cancelled after the
            order is confirmed.
          </li>
        </ol>
        <table className={classNames.threeColumnTable}>
          <thead>
            <tr>
              <th>Stage of order cancellation</th>
              <th>CANCELLATION FEE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Order Booked</td>
              <td>Order Booked No cancellation Fee</td>
            </tr>
            <tr>
              <td>Order Confirmed</td>
              <td>Rs 21</td>
            </tr>
            <tr>
              <td>Order Dispatched</td>
              <td>Rs 80</td>
            </tr>
            <tr>
              <td>Order Reschedule once order dispatched</td>
              <td>Rs 80</td>
            </tr>
          </tbody>
        </table>
        <p className={classNames.subTitle}>Note:</p>
        <p>
          In case of COD, cancellation fees would be added to the cost of the
          next purchase. Until all negative amounts are paid Raha reserves its
          rights to deny service to the customer.
        </p>
        <p>
          For all prepaid order, Cancellation fee will be deducted from order
          total and remaining amount will be refunded as per refund policy.
        </p>
        <p>
          Proportionate refund is given for the balance period / services not
          rendered, if the cancellation is requested during the service
          period/tenure at the discretion of Raha.
        </p>
        <p className={classNames.subTitle}>Mode of Refund:</p>
        <p>
          The mode of refund depends on the mode of payment used at the time of
          booking the service.
        </p>
        <table className={classNames.threeColumnTable}>
          <thead>
            <tr>
              <th>Payment Mode</th>
              <th>Refund Mode</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Credit Card / Debit Card / Net Banking / Digital Wallet</td>
              <td>
                Credit Card / Debit Card / Net Banking / Digital Wallet Refund
                will be processed to the same account used at the time of
                payment. 7-10 business days
              </td>
              <td>7-10 business days</td>
            </tr>
            <tr>
              <td>Cash on Delivery</td>
              <td>
                Cash on Delivery Bank transfer to the bank account specified by
                the customer 7-10 business days
              </td>
              <td>7-10 business days</td>
            </tr>
          </tbody>
        </table>
        <p>
          Note: The time given above is only indicative, it may change as per
          the b
        </p>
      </div>
    </div>
  );
};
