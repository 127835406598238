import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./agents.module.scss";

import Axios from "axios";
import { BankContext } from "../../context/Context";
import CustomCheckbox, { CopyBtn } from "../../components/Custom";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import NavbarV2 from "../../components/NavbarV2";

import { ReactComponent as FilterIcon } from "../../assest/images/allicons/filter.svg";
import { ReactComponent as SortArrows } from "../../assest/images/allicons/sort.svg";
import NavbarV3 from "../../components/NavbarV3";
import { logoutToSameLogin } from "../../assest/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Agents = () => {
  const history = useHistory();
  const { email, popularAction, setPopularAction, actionBtns, setActionBtns } =
    useContext(BankContext);

  const [selectedProfileNav, setSelectedProfileNav] = useState("Associates");
  const [filterOpen, setFilterOpen] = useState(false);

  const [allBrokeragesDropdownSelected, setAllBrokeragesDropdownSelected] =
    useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [allBrokerages, setAllBrokerages] = useState("");
  const [allBrokeragesAPI, setAllBrokeragesAPI] = useState("");

  const [allBrokeragesLoading, setAllBrokeragesLoading] = useState(true);

  useEffect(() => {
    setAllBrokerages("");
    setAllBrokeragesAPI("");
    setAllBrokeragesLoading(true);

    const fetchAllBrokerages = () => {
      let URL;
      if (selectedProfileNav === "Associates") {
        URL = `https://insurance.apimachine.com/insurance/agent/get?brokerage_email=${email}`;
      } else {
        URL = `https://insurance.apimachine.com/insurance/brokerage/get?brokerage_email=${email}`;
      }

      Axios.get(URL)
        .then((response) => {
          console.log(response, "all brokerages response");
          if (response?.data?.status && response?.data?.data?.length > 0) {
            if (
              selectedProfileNav == "Team Leaders" &&
              response?.data?.data[0]?.team_lead
            ) {
              setAllBrokerages(response?.data?.data[0]?.team_lead);
              setAllBrokeragesAPI(response?.data?.data[0]?.team_lead);
            } else if (
              selectedProfileNav == "Vertical Heads" &&
              response?.data?.data[0]?.vertical_head
            ) {
              setAllBrokerages(response?.data?.data[0]?.vertical_head);
              setAllBrokeragesAPI(response?.data?.data[0]?.vertical_head);
            } else if (
              selectedProfileNav == "Business Heads" &&
              response?.data?.data[0]?.business_head
            ) {
              setAllBrokerages(response?.data?.data[0]?.business_head);
              setAllBrokeragesAPI(response?.data?.data[0]?.business_head);
            } else if (selectedProfileNav == "Associates") {
              setAllBrokerages(response?.data?.data);
              setAllBrokeragesAPI(response?.data?.data);
            } else {
              setAllBrokerages([]);
              setAllBrokeragesAPI([]);
            }
          }
          setAllBrokeragesLoading(false);
        })
        .catch((error) => {
          console.log(error?.message, "app dropdown error");
          setAllBrokeragesLoading(false);
        });
    };

    fetchAllBrokerages();
  }, [selectedProfileNav, email]);

  useEffect(() => {
    if (allBrokeragesAPI?.length > 0 && Array?.isArray(allBrokeragesAPI)) {
      let res = allBrokeragesAPI?.filter((row) => {
        // Convert the row object values to an array and check if any value matches the search query
        const values = Object?.values(row);
        const searchQuery = allBrokeragesDropdownSelected?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

        return values?.some((value) => {
          if (typeof value === "string") {
            return value?.toLowerCase()?.includes(searchQuery);
          }
          return false;
        });
      });

      setAllBrokerages(res);
    }
  }, [allBrokeragesDropdownSelected]);

  const filterItems = [];

  const filterDropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      <NavbarV3
        dropdownItems={[
          "Associates",
          "Team Leaders",
          "Vertical Heads",
          "Business Heads",
        ]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setSearchQuery}
      />
      <div className={classNames.brokerageDetails}>
        <div className={classNames.dropdownItems}>
          <>
            <div className={classNames.downloadListBtn}>Download This List</div>
            <div className={classNames.inviteBtn}>Add Associate</div>
          </>
        </div>
        <div className={classNames.allBrokerages}>
          <div className={classNames.nav}>
            <div className={classNames.filterBtn} ref={filterDropdownRef}>
              <FilterIcon
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              />
              {filterOpen && (
                <div className={classNames.dropdownContent}>
                  {filterItems?.map((eachItem, index) => {
                    return (
                      <div
                        className={classNames.eachFilterItem}
                        key={eachItem + index}
                      >
                        {eachItem}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div
              className={classNames.actionBtn}
              style={{
                display: popularAction
                  ? "none"
                  : actionBtns?.length > 0
                  ? ""
                  : "none",
              }}
            >
              <div>Edit</div>
              <div
                onClick={() => {
                  setPopularAction("deleteassociate");
                }}
              >
                Delete
              </div>
            </div>
          </div>
          {selectedProfileNav == "Associates" ? (
            <TableAssociates
              allBrokerages={allBrokerages}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allBrokeragesLoading={allBrokeragesLoading}
            />
          ) : (allBrokerages?.length > 0 &&
              selectedProfileNav == "Team Leaders" &&
              typeof allBrokerages[0] !== "object") ||
            (allBrokerages?.length > 0 &&
              selectedProfileNav == "Vertical Heads" &&
              typeof allBrokerages[0] !== "object") ||
            (allBrokerages?.length > 0 &&
              selectedProfileNav == "Business Heads" &&
              typeof allBrokerages[0] !== "object") ? (
            <TableOthers
              allBrokerages={allBrokerages}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allBrokeragesLoading={allBrokeragesLoading}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Agents;

const TableAssociates = ({
  allBrokerages,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allBrokeragesLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allBrokerages ? "" : "none" }}
    >
      <table>
        <thead>
          <tr>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>
              Name <SortArrows />
            </th>
            <th>
              Email <SortArrows />
            </th>
            <th>
              Phone Number <SortArrows />
            </th>
            <th>
              Status <SortArrows />
            </th>
          </tr>
        </thead>
        <tbody>
          {allBrokeragesLoading ? (
            <tr key={"loading"}>
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : (
            allBrokerages?.length > 0 &&
            allBrokerages
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr key={row?.brokerage_name + index}>
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.agent_id}
                      name={row?.first_name + " " + row?.last_name}
                    />
                  </td>

                  <td>
                    {row?.first_name
                      ? row?.first_name
                      : "" + row?.last_name
                      ? row?.last_name
                      : ""}
                    <CopyBtn
                      copyText={
                        row?.first_name
                          ? row?.first_name
                          : "" + row?.last_name
                          ? row?.last_name
                          : ""
                      }
                    />
                  </td>
                  <td>
                    {row?.agent_email ? row?.agent_email : ""}
                    <CopyBtn
                      copyText={row?.agent_email ? row?.agent_email : ""}
                    />
                  </td>
                  <td>
                    {row?.phone_number ? row?.phone_number : ""}
                    <CopyBtn
                      copyText={row?.phone_number ? row?.phone_number : ""}
                    />
                  </td>
                  <td>
                    {row?.status ? row?.status : ""}
                    <CopyBtn copyText={row?.status ? row?.status : ""} />
                  </td>
                </tr>
              ))
          )}
        </tbody>
      </table>
    </div>
  );
};

const TableOthers = ({
  allBrokerages,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allBrokeragesLoading,
}) => {
  console.log("all brokerages others:", allBrokerages);
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allBrokerages ? "" : "none" }}
    >
      <table>
        <thead>
          <tr>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>
              Name <SortArrows />
            </th>
            <th>
              Email <SortArrows />
            </th>
            <th>
              Phone Number <SortArrows />
            </th>
            <th>
              Status <SortArrows />
            </th>
          </tr>
        </thead>
        <tbody>
          {allBrokeragesLoading ? (
            <tr key={"loading"}>
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : Array.isArray(allBrokerages) && allBrokerages?.length > 0 ? (
            allBrokerages
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr key={row + index}>
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row}
                      name={row}
                    />
                  </td>

                  <td></td>
                  <td>
                    {row ? row : ""}
                    <CopyBtn copyText={row ? row : ""} />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};
