import React, { useContext, useEffect, useState } from "react";
import classNames from "../dashboard.module.scss";
import styles from "./iq.module.scss";

import { IoMdClose } from "react-icons/io";
import uploadIcon from "./asset/upload.svg";
import Axios from "axios";
import { uploadFileFunc, uploadImageFunc } from "../../../../utils/imageUpload";
import { BankContext } from "../../../../context/Context";
import {
  getCompanyIDGlobal,
  getCurrentDate,
  parseAddress,
} from "../../../../assest/functions";
import { FaCircleCheck } from "react-icons/fa6";
import { predefinedToast } from "../../../../utils/toast";
import {
  EachCustomDropdown,
  EachCustomInput,
  EachCustomSelectOne,
  EachCustomTextArea,
} from "../../../../components/Custom/Input";
import { allIndustry } from "../../../../assest/data/industry";
import uploading from "../../../../assest/images/icons/uploading.svg";
import dummyProfile from "../../../../assest/images/icons/dummyProfile.svg";
import { ReactComponent as DownloadIcon } from "../../../../assest/images/employer/members/download.svg";
import uploadFileIcon from "../../../../assest/images/icons/uploadfileIcon.svg";
import { ValidateSpreadsheetComponent } from "../../../Employer/Overview/tabs";

const InitiateQuote = ({ setPopup, selectedRow }) => {
  const [uploadFileQCR, setUploadFileQCR] = useState("");
  const [uploadFileQCRName, setUploadFileQCRName] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [uploadFileQCR1, setUploadFileQCR1] = useState("");
  const [uploadFileQCR2, setUploadFileQCR2] = useState("");
  const [uploadFileQCRName1, setUploadFileQCRName1] = useState("");
  const [uploadFileQCRName2, setUploadFileQCRName2] = useState("");

  const [intIns, setIntIns] = useState([]);
  const [haveIns, setHaveIns] = useState([]);

  const btns = ["Health", "Accidental", "Life"];

  const [localCompanyID, setLocalCompanyID] = useState("");

  async function getcompanyId() {
    let id = await getCompanyIDGlobal(selectedRow?.lead_email);
    setLocalCompanyID(id);
  }

  useEffect(() => {
    if (selectedRow?.lead_email) {
      getcompanyId();
    }
  }, [selectedRow?.lead_email]);

  const handleUploadImg = async (e, item) => {
    let resFile = uploadFileFunc(
      e,
      item === "Health"
        ? setUploadFileQCRName
        : item === "Accidental"
        ? setUploadFileQCRName1
        : setUploadFileQCRName2,
      setLoadingUpload,
      "name"
    );
    console.log(await resFile, item, "res file link await");
    if (item === "Health") {
      setUploadFileQCR(await resFile);
    } else if (item === "Accidental") {
      setUploadFileQCR1(await resFile);
    } else if (item === "Life") {
      setUploadFileQCR2(await resFile);
    }
  };

  const handleUploadQuote = () => {
    setLoading(true);

    Axios.post(
      `https://insurance.apimachine.com/insurance/policy/create/quote`,
      {
        email_id: selectedRow?.lead_email,
        what_are_you_looking_for: intIns,
        external_policy_health: haveIns.find((o) => o === "Health")
          ? true
          : false,
        external_policy_health_doc: uploadFileQCR ? uploadFileQCR : "",
        external_policy_accidental: haveIns.find((o) => o === "Accidental")
          ? true
          : null,
        external_policy_accidental_doc: uploadFileQCR1 ? uploadFileQCR1 : "",
        external_policy_life: haveIns.find((o) => o === "Life") ? true : false,
        external_policy_life_doc: uploadFileQCR2 ? uploadFileQCR2 : "",
        company_id: localCompanyID,
      }
    ).then(({ data }) => {
      if (data.status) {
        setPopup(false);
        setLoading(false);
      }
    });
  };

  const handleAddIns = (item) => {
    if (intIns.find((o) => o === item)) {
      setIntIns(intIns.filter((o) => o !== item));
    } else {
      setIntIns([...intIns, item]);
    }
  };

  const handleHaveIns = (item) => {
    if (haveIns.find((o) => o === item)) {
      setHaveIns(haveIns.filter((o) => o !== item));
    } else {
      setHaveIns([...haveIns, item]);
    }
  };

  return (
    <>
      <div
        className={styles.overlay}
        onClick={(e) => {
          setPopup(null);
          setHaveIns([]);
          setIntIns([]);
          //   setShowQuoteDetails(null);
          //   setConfData(null);
          //   setSelectedQuoteTab("Overview");
          //   setSelectedConfTab("Health");
        }}
      ></div>
      <div className={styles.quoteDetailsWrapper} style={{ width: "40vw" }}>
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => {
            setPopup(null);
            setHaveIns([]);
            setIntIns([]);
            // setConfData(null);
            // setSelectedQuoteTab("Overview");
            // setSelectedConfTab("Health");
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>Initiate new quote</div>
          <div className={styles.iqTitle}>
            What types of insurance are they interested in?
          </div>
          <div className={styles.iqcardWrapper}>
            {btns.map((item) => (
              <div
                onClick={(e) =>
                  handleAddIns(item !== "Accident" ? item : "Accidental")
                }
                className={
                  intIns.find((o) => o === item)
                    ? styles.selectedBtn
                    : styles.notSelectedBtn
                }
              >
                {item !== "Accidental" ? item : "Accident"}
              </div>
            ))}
          </div>
          {intIns.length > 0 && (
            <>
              <div className={styles.iqTitle} style={{ paddingTop: "20px" }}>
                What types of insurance do they already have?
              </div>
              <div className={styles.iqcardWrapper}>
                {intIns.map((item) => (
                  <div
                    onClick={(e) => handleHaveIns(item)}
                    className={
                      haveIns.find((o) => o === item)
                        ? styles.selectedBtn
                        : styles.notSelectedBtn
                    }
                  >
                    {item !== "Accidental" ? item : "Accident"}
                  </div>
                ))}
              </div>
            </>
          )}
          {haveIns.length > 0 && intIns.length > 0 && (
            <>
              <div className={styles.iqTitle} style={{ paddingTop: "20px" }}>
                Please upload existing policies if any
              </div>
              <div style={{ display: "flex", gap: "20px" }}>
                {haveIns.find((o) => o === "Health") && (
                  <div className={styles.uploadContainer}>
                    <label
                      className={classNames.uploadDocument}
                      style={{ padding: "3rem" }}
                      htmlFor="fileUploadRFQ"
                    >
                      <div className={styles.upldTxt}>
                        {!loadingUpload
                          ? uploadFileQCRName?.name
                            ? uploadFileQCRName?.name
                            : "Health"
                          : "Uploading..."}
                        {/* {!loadingUpload
                          ? uploadFileQCRName?.name
                            ? uploadFileQCRName?.name
                            : item
                          : "Uploading..."} */}
                      </div>
                      <img src={uploadIcon} alt="" className={styles.upldImg} />

                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleUploadImg(e, "Health");
                        }}
                        id="fileUploadRFQ"
                      />
                    </label>
                  </div>
                )}
                {haveIns.find((o) => o === "Accidental") && (
                  <div className={styles.uploadContainer}>
                    <label
                      className={classNames.uploadDocument}
                      style={{ padding: "3rem" }}
                      htmlFor="fileUploadRFQ1"
                    >
                      <div className={styles.upldTxt}>
                        {!loadingUpload
                          ? uploadFileQCRName1?.name
                            ? uploadFileQCRName1?.name
                            : "Accident"
                          : "Uploading..."}
                      </div>
                      <img src={uploadIcon} alt="" className={styles.upldImg} />

                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleUploadImg(e, "Accidental");
                        }}
                        id="fileUploadRFQ1"
                      />
                    </label>
                  </div>
                )}
                {haveIns.find((o) => o === "Life") && (
                  <div className={styles.uploadContainer}>
                    <label
                      className={classNames.uploadDocument}
                      style={{ padding: "3rem" }}
                      htmlFor="fileUploadRFQ2"
                    >
                      <div className={styles.upldTxt}>
                        {!loadingUpload
                          ? uploadFileQCRName2?.name
                            ? uploadFileQCRName2?.name
                            : "Life"
                          : "Uploading..."}
                      </div>
                      <img src={uploadIcon} alt="" className={styles.upldImg} />

                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleUploadImg(e, "Life");
                        }}
                        id="fileUploadRFQ2"
                      />
                    </label>
                  </div>
                )}
              </div>
            </>
          )}

          <div
            onClick={(e) => {
              handleUploadQuote();
            }}
            className={styles.addBtn}
          >
            {loading ? "Adding QCR.." : "Add"}
          </div>
        </div>
      </div>
    </>
  );
};

export default InitiateQuote;

export const CompleteRegistration = ({
  setPopup,
  selectedRow,
  setSelectedRow,
  setSelectedMenu,
}) => {
  const { setActionBtns } = useContext(BankContext);
  const [localRefresh, setLocalRefresh] = useState(false);
  const [leadProfile, setLeadProfile] = useState("");
  const [allProfileStatus, setAllProfileStatus] = useState("");
  const [createHRProfile, setCreateHRProfile] = useState("");
  const [employeesfunc, setemployeesfunc] = useState("");
  const [hrProfileDetails, setHRProfileDetails] = useState({
    country: "India",
  });

  //functions

  async function createNewUserProfile(selectedMail) {
    try {
      let response = await Axios.post(
        `https://insurance.apimachine.com/insurance/user/create`,
        {
          user_email: selectedMail,
          lead_id: leadProfile?.lead_id,
        }
      );
      if (response?.data?.status) {
        predefinedToast("Created new user profile successfully!");
        setLocalRefresh((prev) => !prev);
      } else {
        predefinedToast(response?.data?.message);
      }
      console.log("create New User Profile", response);
    } catch (error) {
      console.log(error?.message, "create New User Profile status");
    }
  }

  async function getLeadProfileStatus(selectedMail) {
    try {
      let response = await Axios.get(
        `https://insurance.apimachine.com/insurance/lead/get?lead_email=${selectedMail}`
      );
      if (
        Array.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        setLeadProfile(response?.data?.data[0]);
      }
      console.log("Get lead profile status response", response);
    } catch (error) {
      console.log(error?.message, "Get lead profile status");
    }
  }

  async function profileCompletionStatus(selectedMail) {
    try {
      let response = await Axios.get(
        `https://insurance.apimachine.com/insurance/user/getuserallprofile?user_email=${selectedMail}`
      );
      if (response?.data?.message == "Lead is not converted to User") {
        setAllProfileStatus("create profile");
      } else {
        setAllProfileStatus(response?.data?.data);
      }
      console.log("Profile completion status response", response);
    } catch (error) {
      console.log(error?.message, "Profile completion status error");
    }
  }

  async function createHRProfileFunc() {
    let obj = {
      ...hrProfileDetails,
      country: "India",
      user_id: allProfileStatus?.userDetails?.user_id,
    };
    try {
      let response = await Axios.post(
        "https://insurance.apimachine.com/insurance/user/create/companyandhr",
        obj
      );
      if (response?.data?.status) {
        predefinedToast("Created new HR & company profile successfully!");
        setLocalRefresh((prev) => !prev);
        setCreateHRProfile("");
      } else {
        predefinedToast(response?.data?.message);
      }
    } catch (error) {
      console.log(error?.message, "createHRProfileFunc error");
    }
  }

  function employeesFunc() {
    if (employeesfunc == "employeesUpload") {
      setemployeesfunc("");
    } else {
      setemployeesfunc("employeesUpload");
    }
  }

  //renderings

  useEffect(() => {
    if (selectedRow?.lead_email) {
      getLeadProfileStatus(selectedRow?.lead_email);
      profileCompletionStatus(selectedRow?.lead_email);
    }
  }, []);

  useEffect(() => {
    if (selectedRow?.lead_email) {
      profileCompletionStatus(selectedRow?.lead_email);
    }
  }, [localRefresh]);

  useEffect(() => {
    console.log(hrProfileDetails, "hrProfileDetails");
  }, [hrProfileDetails]);

  return (
    <>
      <div
        className={styles.overlay}
        onClick={(e) => {
          setPopup(null);
          setSelectedRow("");
          setActionBtns([]);
          setSelectedMenu(null);
        }}
      ></div>
      <div className={styles.quoteDetailsWrapper} style={{ width: "600px" }}>
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 25,
          }}
          onClick={() => {
            setPopup(null);
            setSelectedRow("");
            setActionBtns([]);
            setSelectedMenu(null);
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>Complete Profile Registration</div>
          {createHRProfile == "createHRProfile" ? (
            <CreateHRAndCompanyProfile
              hrProfileDetails={hrProfileDetails}
              setHRProfileDetails={setHRProfileDetails}
              createHRProfileFunc={createHRProfileFunc}
            />
          ) : (
            <div className={styles.allProfilesStatus}>
              <div className={!leadProfile?._id ? styles.notSelected : ""}>
                <span>Lead profile created</span>
                {leadProfile?._id && <FaCircleCheck />}
              </div>
              <div
                className={
                  allProfileStatus === "create profile"
                    ? ""
                    : !allProfileStatus?.userDetails?._id
                    ? styles.notSelected
                    : ""
                }
              >
                <span>
                  {allProfileStatus === "create profile"
                    ? "User profile not created"
                    : "User profile created"}
                </span>
                {allProfileStatus === "create profile" ? (
                  <div
                    className={styles.createBtn}
                    onClick={() => {
                      if (selectedRow?.lead_email) {
                        createNewUserProfile(selectedRow?.lead_email);
                      }
                    }}
                  >
                    Create
                  </div>
                ) : allProfileStatus?.userDetails?._id ? (
                  <FaCircleCheck />
                ) : (
                  ""
                )}
              </div>
              <div
                className={
                  !allProfileStatus?.hrDetails?._id &&
                  allProfileStatus?.userDetails?._id
                    ? ""
                    : !allProfileStatus?.hrDetails?._id
                    ? styles.notSelected
                    : ""
                }
              >
                <span>HR profile created</span>

                {allProfileStatus?.hrDetails?._id ? (
                  <FaCircleCheck />
                ) : (
                  <div
                    className={styles.createBtn}
                    onClick={() => {
                      if (allProfileStatus?.userDetails?.user_id) {
                        setCreateHRProfile("createHRProfile");
                      }
                    }}
                  >
                    Create
                  </div>
                )}
              </div>
              <div
                className={
                  !allProfileStatus?.hrDetails?.hrCompanyDetails?._id
                    ? styles.notSelected
                    : ""
                }
              >
                <span>Company profile created</span>
                {allProfileStatus?.hrDetails?.hrCompanyDetails?._id && (
                  <FaCircleCheck />
                )}
              </div>
              {/* upload btns */}
              {leadProfile?._id &&
                allProfileStatus?.userDetails?._id &&
                allProfileStatus?.hrDetails?._id &&
                allProfileStatus?.hrDetails?.hrCompanyDetails?._id && (
                  <>
                    <div style={{ flexDirection: "column" }}>
                      <div className={styles.parentWithFunc}>
                        <span>Employees</span>
                        <div
                          className={styles.createBtn}
                          onClick={employeesFunc}
                        >
                          {employeesfunc == "employeesUpload"
                            ? "Close"
                            : "Upload"}
                        </div>
                      </div>
                      {employeesfunc == "employeesUpload" && (
                        <BulkEntryEnrollmentForm
                          setState={setemployeesfunc}
                          selectedHrId={allProfileStatus?.hrDetails?.hr_id}
                        />
                      )}
                    </div>
                    <div
                    // className={
                    //   !allProfileStatus?.hrDetails?.hrCompanyDetails?._id
                    //     ? styles.notSelected
                    //     : ""
                    // }
                    >
                      <span>Policies</span>
                      <div className={styles.createBtn}>Add</div>
                    </div>
                  </>
                )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const CreateHRAndCompanyProfile = ({
  hrProfileDetails,
  setHRProfileDetails,
  createHRProfileFunc,
}) => {
  const [uploadImageLoadingIcon, setUploadImageLoadingIcon] = useState(false);
  const [uploadImageLoadingLogo, setUploadImageLoadingLogo] = useState(false);

  async function uploadImage(e, name, setLoadingState) {
    let resImage = uploadImageFunc(
      e,
      setHRProfileDetails,
      setLoadingState,
      name
    );
    // setHRProfileDetails(async (prev) => {
    //   return { ...prev, [name]: await resImage };
    // });
    console.log(await resImage, "resImage await icon");
    console.log(`resImage await icon ${name}`);
  }

  function getPlaceFromPincodeEntry(pincode) {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/google/geo-location/${pincode}`
    )
      .then(async (response) => {
        if (response?.data?.data?.length > 0) {
          let address = await parseAddress(
            response?.data?.data[0]?.formatted_address
          );

          setHRProfileDetails((prev) => {
            return {
              ...prev,
              city: address?.city,
              state: address?.state,
            };
          });
        }
      })
      .catch((error) => {
        console.log(error?.message, "getPlaceFromPincode error");
      });
  }

  //rendering

  useEffect(() => {
    if (hrProfileDetails?.pin_code) {
      getPlaceFromPincodeEntry(hrProfileDetails?.pin_code);
    } else if (!hrProfileDetails?.pin_code) {
      setHRProfileDetails((prev) => {
        return {
          ...prev,
          city: "",
          state: "",
        };
      });
    }
  }, [hrProfileDetails?.pin_code]);

  return (
    <div className={styles.allProfilesStatus}>
      <div className={styles.flexDiv}>
        <EachCustomInput
          title="Company name"
          placeholder="Name.."
          name="company_name"
          stateValue={hrProfileDetails}
          setState={setHRProfileDetails}
          validation={{ type: "company_name_validation" }}
        />
        <EachCustomInput
          title="Legal name"
          placeholder="Legal name..."
          name="legal_name"
          stateValue={hrProfileDetails}
          setState={setHRProfileDetails}
          validation={{ type: "company_name_validation" }}
        />
      </div>
      <div className={styles.flexDiv}>
        <EachCustomInput
          title="Address"
          placeholder="Address..."
          name="address"
          stateValue={hrProfileDetails}
          setState={setHRProfileDetails}
        />
        <EachCustomInput
          title="City"
          placeholder="City..."
          name="city"
          stateValue={hrProfileDetails}
          setState={setHRProfileDetails}
          validation={{ type: "name_validation" }}
        />
      </div>
      <div className={styles.flexDiv}>
        <EachCustomInput
          title="State"
          placeholder="State..."
          name="state"
          stateValue={hrProfileDetails}
          setState={setHRProfileDetails}
          validation={{ type: "name_validation" }}
        />
        <EachCustomInput
          title="Pincode"
          placeholder="Pincode..."
          name="pin_code"
          stateValue={hrProfileDetails}
          setState={setHRProfileDetails}
          validation={{ type: "pincode_validation" }}
        />
      </div>
      <div className={styles.flexDiv}>
        <div className={styles.singleDiv}>
          <EachCustomInput
            title="Website"
            placeholder="Website..."
            name="website"
            stateValue={hrProfileDetails}
            setState={setHRProfileDetails}
          />
        </div>
      </div>
      <div className={styles.flexDiv}>
        <div className={styles.singleDiv}>
          <div className={styles.title}>Industry</div>
          <EachCustomDropdown
            placeholder="Select an industry"
            dropdown={allIndustry}
            name="industry"
            stateValue={hrProfileDetails}
            setState={setHRProfileDetails}
            typee="single"
          />
        </div>
      </div>
      <div className={styles.flexDiv}>
        <div className={styles.singleDiv}>
          <div className={styles.title}>Description</div>
          <EachCustomTextArea
            // title="Description"
            placeholder="Describe the company"
            name="description"
            stateValue={hrProfileDetails}
            setState={setHRProfileDetails}
          />
        </div>
      </div>
      <div className={styles.inputContainers}>
        <div className={styles.imageUpload}>
          {uploadImageLoadingIcon ? (
            <img
              src={uploading}
              alt="uploading"
              className={styles.uploadingimg}
            />
          ) : hrProfileDetails?.icon ? (
            <>
              <img
                src={hrProfileDetails?.icon}
                alt="companyIcon"
                onClick={() =>
                  document
                    .getElementById("profileUpdateImgPlanBQuoteeeee")
                    ?.click()
                }
              />
              <input
                className={styles.uploadNewPicPlanB}
                type="file"
                onChange={(e) => {
                  uploadImage(e, "icon", setUploadImageLoadingIcon);
                }}
                accept="image/*"
                id="profileUpdateImgPlanBQuoteeeee"
                style={{ display: "none" }}
              />
            </>
          ) : (
            <label
              className={styles.imageUploadLabel}
              htmlFor="profileUpdateImgPlanBQuoteeeee"
            >
              <img src={dummyProfile} alt="uploadIcon" />
              <input
                className={styles.uploadNewPicPlanB}
                type="file"
                onChange={(e) => {
                  uploadImage(e, "icon", setUploadImageLoadingIcon);
                }}
                accept="image/*"
                id="profileUpdateImgPlanBQuoteeeee"
              />
            </label>
          )}
          <div className={styles.title}>Company Icon</div>
        </div>
        <div className={styles.imageUpload}>
          {uploadImageLoadingLogo ? (
            <img
              src={uploading}
              alt="uploading"
              className={styles.uploadingimgLogo}
            />
          ) : hrProfileDetails?.logo ? (
            <>
              <img
                src={hrProfileDetails?.logo}
                alt="companyLogo"
                className={styles.uploadingimgLogo}
                onClick={() =>
                  document
                    .getElementById("profileUpdateImgPlanBQuoteeee")
                    ?.click()
                }
              />
              <input
                className={styles.uploadNewPicPlanB}
                type="file"
                onChange={(e) => {
                  uploadImage(e, "logo", setUploadImageLoadingLogo);
                }}
                accept="image/*"
                id="profileUpdateImgPlanBQuoteeee"
                style={{ display: "none" }}
              />
            </>
          ) : (
            <label
              className={`${styles.imageUploadLabel} ${styles.imageUploadLabelLogo}`}
              htmlFor="profileUpdateImgPlanBQuoteeee"
            >
              <img src={dummyProfile} alt="uploadLogo" />
              <input
                className={styles.uploadNewPicPlanB}
                type="file"
                onChange={(e) => {
                  uploadImage(e, "logo", setUploadImageLoadingLogo);
                }}
                accept="image/*"
                id="profileUpdateImgPlanBQuoteeee"
              />
            </label>
          )}
          <div className={styles.title}>Company Logo</div>
        </div>
      </div>
      <div className={styles.submitBtn} onClick={createHRProfileFunc}>
        Submit
      </div>
    </div>
  );
};

export const BulkEntryEnrollmentForm = ({ setState, selectedHrId }) => {
  const [uploadedSheetValidationErrors, setUploadedSheetValidationErrors] =
    useState("");
  const [quoteReviewForm, setQuoteReviewForm] = useState("");
  const [downloadSpreadsheet, setDownloadSpreadsheet] = useState("");
  const [fileName, setFileName] = useState("");
  const [memberDetailsFormLoading, setMemberDetailsFormLoading] = useState("");

  console.log(selectedHrId, "selectedHrId BulkEntryEnrollmentForm");
  //functions

  async function fetchDownloadLinks() {
    try {
      let response = await Axios.get(
        `https://api-dev.rahainsure.com/api/v1/employee-spreadsheet`
      );
      if (
        response?.data?.data?.length > 0 &&
        Array.isArray(response?.data?.data)
      ) {
        setDownloadSpreadsheet(response?.data?.data[0]);
      }
      console.log(response, "fetch spreadsheet");
    } catch (error) {
      console.log(error?.message, "fetch spreadsheet error");
    }
  }

  async function getDataFillsAndValidate(document) {
    try {
      let url =
        quoteReviewForm?.enrollmentType === "Without dependents"
          ? "https://api-dev.rahainsure.com/api/v1/employee-spreadsheet/validate"
          : `https://api-dev.rahainsure.com/api/v1/employee-spreadsheet/validate-with-dependents`;

      const formData = new FormData();
      formData.append("file", document);

      let response = await Axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (
        Array?.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        setUploadedSheetValidationErrors(response?.data?.data[0]);
      }

      console.log(response?.data?.data, "getDataFills response");
    } catch (error) {
      console.log(error?.message, "Employee fills data error");
    }
  }

  function uploadEnrollmentViaPreEnrollment() {
    let url;

    if (quoteReviewForm?.enrollmentType === "Without dependents") {
      url =
        "https://api-dev.rahainsure.com/api/v1//employee-spreadsheet/upload";
    } else {
      url =
        "https://api-dev.rahainsure.com/api/v1/employee-spreadsheet/upload-with-dependents";
    }

    setMemberDetailsFormLoading(true);
    const formData = new FormData();
    formData.append("hr_id", selectedHrId);
    formData.append("file", quoteReviewForm?.document);
    formData.append("request_type", "Renewal");

    Axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response, "Uploaded endorsements via pre enrolment bulk");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setMemberDetailsFormLoading(false);
          setState("");
        } else {
          setMemberDetailsFormLoading(false);
          predefinedToast(response?.data?.message);
          setState("");
        }
      })
      .catch((error) => {
        console.log(
          error?.message,
          "Uploaded endorsements via pre enrolment error"
        );
        // setState("PreEnrollmentDownloads");
        setMemberDetailsFormLoading(false);
        predefinedToast(error?.message);
      });
    // console.log(corporateLead, "corporateLead agency API");
  }

  const hasItems = (arr) => arr.length > 0;
  const fieldsWithItems = Object.entries(uploadedSheetValidationErrors).filter(
    ([_, value]) => hasItems(value)
  );

  //rendering

  useEffect(() => {
    fetchDownloadLinks();
  }, []);

  useEffect(() => {
    if (quoteReviewForm?.document) {
      setQuoteReviewForm((prev) => {
        return { ...prev, document: "" };
      });
      setUploadedSheetValidationErrors("");
      setFileName("");
    }
  }, [quoteReviewForm?.enrollmentType]);

  return (
    <div className={styles.bulkUpload}>
      {memberDetailsFormLoading && (
        <div className={styles.loadingImage}>
          <img
            src="https://dev.rahainsure.com/static/media/fullLogo.df0a0076d6cad8f382f51058e69ad539.svg"
            alt="loadingImage"
          />
        </div>
      )}
      <div className={styles.title}>Upload communication info</div>
      <div className={styles.fieldsContainer}>
        <div className={styles.singleInput}>
          <EachCustomDropdown
            title="Select type of enrolment"
            dropdown={["With dependents", "Without dependents"]}
            name="enrollmentType"
            stateValue={quoteReviewForm}
            setState={setQuoteReviewForm}
            typee="single"
          />
        </div>
      </div>
      <div className={styles.bulkDocuments}>
        <div
          onClick={() => {
            if (
              quoteReviewForm?.enrollmentType === "Without dependents" &&
              downloadSpreadsheet?.employee_pre_enrollment
            ) {
              window.open(
                downloadSpreadsheet?.employee_pre_enrollment,
                "_blank"
              );
            } else if (
              quoteReviewForm?.enrollmentType === "With dependents" &&
              downloadSpreadsheet?.employee_and_dependent_pre_enrollment
            ) {
              window.open(
                downloadSpreadsheet?.employee_and_dependent_pre_enrollment,
                "_blank"
              );
            }
          }}
        >
          <DownloadIcon />
          <span>Download Template File</span>
        </div>

        <div
          style={{
            pointerEvents: quoteReviewForm?.enrollmentType ? "" : "none",
          }}
        >
          <label
            htmlFor={"profileUpdateImgPlanBdocument"}
            className={styles.uploadFileDiv}
            style={{ maxWidth: "100%" }}
          >
            <img src={uploadFileIcon} alt="uploadFileIcon" />
            <div
              style={{
                paddingTop: "1rem",
                textOverflow: fileName ? "ellipse" : "",
                overflow: fileName ? "hidden" : "",
                whiteSpace: fileName ? "nowrap" : "",
              }}
            >
              {fileName ? (
                fileName
              ) : (
                <span>
                  <div>Upload</div>
                  <div>Completed File</div>
                </span>
              )}
            </div>
            <div className={styles.uploadFile}>
              <input
                className={styles.uploadNewPicPlanB}
                type="file"
                style={{ display: "none" }}
                onChange={(event) => {
                  console.log(event?.target?.files, "files list");
                  if (event?.target?.files?.length > 0) {
                    setFileName(event?.target?.files[0]?.name);
                    setQuoteReviewForm({
                      ...quoteReviewForm,
                      document: event?.target?.files[0],
                    });

                    if (quoteReviewForm?.enrollmentType === "With dependents") {
                      getDataFillsAndValidate(event?.target?.files[0]);
                    } else if (
                      quoteReviewForm?.enrollmentType === "Without dependents"
                    ) {
                      getDataFillsAndValidate(event?.target?.files[0]);
                    }
                  }
                }}
                accept="*/*"
                id={"profileUpdateImgPlanBdocument"}
              />
            </div>
          </label>
        </div>
      </div>
      {uploadedSheetValidationErrors && (
        <ValidateSpreadsheetComponent data={uploadedSheetValidationErrors} />
      )}
      <div className={styles.btnsContainer}>
        {/* <button onClick={() => setState("")}>Back</button> */}
        <button
          className={classNames.coloredBtn}
          style={{
            pointerEvents:
              // Array.isArray(fieldsWithItems) && fieldsWithItems?.length > 0
              //   ? "none"
              //   :
              quoteReviewForm?.enrollmentType && quoteReviewForm?.document
                ? ""
                : "none",
            opacity:
              // Array.isArray(fieldsWithItems) && fieldsWithItems?.length > 0
              //   ? "0.5"
              //   :
              quoteReviewForm?.enrollmentType && quoteReviewForm?.document
                ? ""
                : "0.5",
          }}
          onClick={uploadEnrollmentViaPreEnrollment}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export const AddRahaEmployee = ({ setPopup }) => {
  //constant
  const allOfflineDesignation = [
    "Super admin",
    "Managing Director",
    "Director",
    "Senior Executive",
    "General Manager",
    "Senior Manager",
    "Manager",
    "Executive",
    "Sr. Associate",
    "Associate",
  ];

  const [formLoading, setFormLoading] = useState(false);
  const [localStep, setLocalStep] = useState(1); //default 1
  const [newEmployee, setNewEmployee] = useState({});
  const [allRahaEmployees, setAllRahaEmployees] = useState([]);
  const [newUserID, setNewUserID] = useState("");
  const [allSelectedRoles, setAllSelectedRoles] = useState([
    { role: "Sales associate", id: "65a7617c488db4d75cfd6628" },
    { role: "Service associate", id: "65a76177488db4d75cfd6626" },
    { role: "Team Lead", id: "65a76187488db4d75cfd662a" },
    { role: "Business Head", id: "65a76192488db4d75cfd662c" },
    { role: "Vertical Head", id: "65a76198488db4d75cfd662e" },
    { role: "Affiliate", id: "65a76149488db4d75cfd6620" },
  ]);

  //functions

  async function getAllRahaEmployees() {
    try {
      const response = await Axios.get(
        `https://api-dev.rahainsure.com/api/v1/raha-user/`
      );
      if (response?.data?.data?.length > 0) {
        setAllRahaEmployees(response?.data?.data);
      }
      console.log(response, "Get All Raha Employee response");
    } catch (error) {
      console.log(error, "Get All Raha Employee error");
    }
  }

  async function getUserIDForEmail(userEmail) {
    try {
      const response = await Axios.get(
        `https://api-dev.rahainsure.com/api/v1/raha-user/?user_email_id=${userEmail}`
      );
      if (response?.data?.data?.length > 0) {
        setNewUserID(response?.data?.data[0]);
      }

      console.log(response, "Get user ID for email response");
    } catch (error) {
      console.log(error, "Get user ID for email error");
    }
  }

  async function assignRoleToNewUser(roleID) {
    try {
      const response = await Axios.post(
        `https://api-dev.rahainsure.com/api/v1/raha-user/role/mapping/create`,
        {
          role_id: roleID,
          user_id: newUserID?._id,
          service_start_date: getCurrentDate(),
        }
      );

      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
      } else {
        predefinedToast("Error while assigning roles to employee...");
      }

      console.log(response, "Assign Role To New User response");
    } catch (error) {
      predefinedToast("Error while assigning roles to employee...");
      console.log(error, "Assign Role To New User error");
    }
  }

  async function submitAddRahaEmployee() {
    setFormLoading(true);
    try {
      let response = await Axios.post(
        "https://api-dev.rahainsure.com/api/v1/raha-user/create",
        {
          ...newEmployee,
          user_phone_number: newEmployee?.user_phone_number
            ? [newEmployee?.user_phone_number]
            : [],
        }
      );

      if (response?.data?.status) {
        setFormLoading(false);
        predefinedToast(response?.data?.message);
        setLocalStep(2);
      } else {
        setFormLoading(false);
        predefinedToast("Error while creating employee...");
      }
    } catch (error) {
      setFormLoading(false);
      predefinedToast("Server error while creating employee...");
      console.log(error?.message, "Submit add raha employee error");
    }
    console.log(newEmployee, "submitAddRahaEmployee");
  }

  //renderings

  useEffect(() => {
    getAllRahaEmployees();
  }, []);

  useEffect(() => {
    if (localStep === 2 && newEmployee?.user_email_id) {
      getUserIDForEmail(newEmployee?.user_email_id);
    }
  }, [localStep]);

  return (
    <>
      <div
        className={styles.rahaAddContainer}
        style={{ pointerEvents: formLoading ? "none" : "" }}
      >
        <div
          className={styles.overlay}
          onClick={(e) => {
            setPopup(null);
          }}
        ></div>

        <div className={styles.addRahaEmployees}>
          {formLoading && (
            <div className={styles.loadingImage}>
              <img
                src="https://dev.rahainsure.com/static/media/fullLogo.df0a0076d6cad8f382f51058e69ad539.svg"
                alt="loadingImage"
              />
            </div>
          )}
          {localStep === 1 ? (
            <>
              <div className={styles.title}>Add Raha Employee</div>
              <div className={styles.employeeDetails}>
                <div className={styles.twoFields}>
                  <EachCustomInput
                    title="First name"
                    placeholder="First Name.."
                    name="user_first_name"
                    stateValue={newEmployee}
                    setState={setNewEmployee}
                    validation={{ type: "name_validation" }}
                  />
                  <EachCustomInput
                    title="Last name"
                    placeholder="Last name.."
                    name="user_last_name"
                    stateValue={newEmployee}
                    setState={setNewEmployee}
                    validation={{ type: "name_validation" }}
                  />
                </div>
                <div className={styles.twoFields}>
                  <EachCustomInput
                    title="Phone number"
                    placeholder="Phone number.."
                    name="user_phone_number"
                    stateValue={newEmployee}
                    setState={setNewEmployee}
                    validation={{ type: "phone_validation" }}
                  />
                  <EachCustomInput
                    title="Email"
                    placeholder="Email.."
                    name="user_email_id"
                    stateValue={newEmployee}
                    setState={setNewEmployee}
                    validation={{ type: "email" }}
                  />
                </div>
                <div className={styles.twoFields}>
                  <EachCustomSelectOne
                    title="Gender"
                    placeholder="Gender"
                    name="user_gender"
                    stateValue={newEmployee}
                    setState={setNewEmployee}
                    dropdown={["Male", "Female"]}
                  />
                  <EachCustomInput
                    title="Employee ID"
                    placeholder="Employee ID.."
                    name="user_emp_id"
                    stateValue={newEmployee}
                    setState={setNewEmployee}
                  />
                </div>
                <div>
                  <div className={styles.subTitle}>
                    Select reporting manager
                  </div>
                  <EachCustomDropdown
                    placeholder="Select reporting manager"
                    dropdown={allRahaEmployees}
                    name="user_reporting_manager_id"
                    stateValue={newEmployee}
                    setState={setNewEmployee}
                    typee="objVal"
                    objName="_id"
                    mapName={["user_first_name", "user_last_name"]}
                    mapNameType="flex"
                  />
                </div>
                <div>
                  <div className={styles.subTitle}>
                    Select offline designation
                  </div>
                  <EachCustomDropdown
                    placeholder="Select offline designation"
                    dropdown={allOfflineDesignation}
                    name="user_designation"
                    stateValue={newEmployee}
                    setState={setNewEmployee}
                    typee="single"
                  />
                </div>
                <div
                  className={styles.submitBtn}
                  style={{
                    pointerEvents: formLoading ? "none" : "",
                    opacity: formLoading ? "0.5" : "",
                  }}
                  onClick={submitAddRahaEmployee}
                >
                  Add
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.title}>Assign roles</div>
              <div className={styles.employeeDetails}>
                <div className={styles.selectMultiple}>
                  {allSelectedRoles?.map((eachRole, index) => {
                    return (
                      <div
                        key={eachRole?.role + index}
                        className={
                          eachRole?.isSelected ? styles.selectedRole : ""
                        }
                        style={{
                          pointerEvents: eachRole?.isSelected ? "none" : "",
                        }}
                        onClick={() => {
                          assignRoleToNewUser(eachRole?.id);
                          setAllSelectedRoles((prev) => {
                            // Update isSelected to true for the clicked role
                            return prev?.map((role) => {
                              if (role?.role === eachRole?.role) {
                                return { ...role, isSelected: true };
                              } else {
                                return role;
                              }
                            });
                          });
                        }}
                      >
                        {eachRole?.role}
                      </div>
                    );
                  })}
                </div>
                <div
                  className={styles.submitBtn}
                  onClick={() => setPopup(null)}
                >
                  Complete
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
