import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./wellnesstabs.module.scss";

//assets
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ordersTypeMenu } from "../../../../assest/data/employer/wellness";
import { BankContext } from "../../../../context/Context";
import redcliff from "../../../../assest/images/employer/wellness/redcliff.svg";
import headspacePoster from "../../../../assest/images/headspacePoster.svg";
import Axios from "axios";
import { predefinedToast } from "../../../../utils/toast";

const Orders = ({ setSelectedDiscoverSection, setSelectedMainTest }) => {
  const dropdownMenuRef = useRef();
  const { email } = useContext(BankContext);
  //constants
  const allSubTabs = ["All Orders", "Express", "Cancelled"];
  const [selectedSubTab, setSelectedSubTab] = useState("All Orders");
  const [dropdownActive, setDropdownActive] = useState(false);
  const [typeWellness, setTypeWellness] = useState("All Time");
  const [searchTerm, setSearchTerm] = useState("");
  const [localUserIdCart, setLocalUserIdCart] = useState("");
  const [localAllOrders, setLocalAllOrders] = useState("");
  const [localUserHRId, setLocalUserHRId] = useState("");
  const [refreshLocal, setRefreshLocal] = useState(false);

  async function getCartUserIdWithHR() {
    try {
      let response = await Axios.get(
        `https://insurance.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
      );

      // console.log(response?.data?.data, "Get Cart user ID for logged in HR");
      if (response?.data?.data?.hrDetails?.hrCompanyDetails?._id) {
        // getCompanyCartDetails(
        //   response?.data?.data?.hrDetails?.hrCompanyDetails?._id
        // );
        setLocalUserHRId(response?.data?.data?.hrDetails?._id);
        setLocalUserIdCart(
          response?.data?.data?.hrDetails?.hrCompanyDetails?._id
        );
      } else {
        predefinedToast("User ID from getCartUserIdWithHR not found!");
        console.log("User ID from getCartUserIdWithHR not found!");
      }
    } catch (error) {
      console.log(error?.message, "Get Cart user ID for logged in HR error");
    }
  }

  async function allCorporateOrders() {
    try {
      let response = await Axios.get(
        `https://api-dev.rahainsure.com/api/v1/client-user/order/?owner_id=${localUserIdCart}`
      );
      console.log(
        response,
        "allCorporateOrders",
        localUserHRId,
        localUserIdCart
      );
      setLocalAllOrders(response?.data?.data);
    } catch (error) {
      console.log(error?.message, "allCorporateOrders error");
    }
  }

  useEffect(() => {
    getCartUserIdWithHR();
    allCorporateOrders();
  }, [localUserIdCart, localUserHRId]);

  return (
    <div className={classNames.discoverTab}>
      <div className={classNames.header}>
        <div>
          <div className={classNames.subTabs}>
            {allSubTabs?.map((eachTab, index) => {
              return (
                <div
                  key={eachTab + index}
                  className={
                    selectedSubTab === eachTab
                      ? classNames.selectedTab
                      : classNames.nonSelectedTab
                  }
                  onClick={() => setSelectedSubTab(eachTab)}
                >
                  {eachTab}
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ alignItems: "flex-start" }}>
          <div className={classNames.filterDiv}>
            <div className={classNames.searchDiv}>
              <div
                className={classNames.searchDropdown}
                onClick={() => {
                  setDropdownActive((prev) => !prev);
                }}
              >
                {typeWellness}{" "}
                {!dropdownActive ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </div>
              <input
                placeholder="Search for order"
                className={classNames.inputContainer}
                value={searchTerm}
                onChange={(event) => {
                  setSearchTerm(event?.target?.value);
                }}
              />
              {dropdownActive && (
                <div
                  className={classNames.menuDropdownWellness}
                  ref={dropdownMenuRef}
                >
                  <div className={classNames.mainMenuWellness}>
                    {ordersTypeMenu?.map((eachItem, index) => {
                      return (
                        <div
                          className={classNames.eachTypewellness}
                          onClick={() => {
                            setTypeWellness(eachItem?.name);
                            setDropdownActive((prev) => !prev);
                          }}
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </div>
                  <div className={classNames.subMenu}></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.discoverOverflow}>
        <div className={classNames.ordersContainer}>
          <div className={classNames.orderDetails}>
            {localAllOrders?.length > 0
              ? localAllOrders?.map((eachItem, index) => {
                  return (
                    <div
                      className={classNames.eachOrder}
                      key={eachItem?._id + index}
                    >
                      <div
                        className={classNames.header}
                        style={{ borderBottom: "1px solid #e1e1e1" }}
                      >
                        <div>
                          <div>
                            <div className={classNames.title}>Order placed</div>
                            <div className={classNames.value}>Jan 4th 2024</div>
                          </div>
                          <div>
                            <div className={classNames.title}>Total</div>
                            <div className={classNames.value}>
                              {eachItem?.total_price
                                ? eachItem?.total_price?.toFixed(2)
                                : "0.00"}
                            </div>
                          </div>
                          <div>
                            <div className={classNames.title}>Ship To</div>
                            <div className={classNames.value}>
                              {eachItem?.address_id
                                ? eachItem?.address_id
                                : "-"}
                            </div>
                          </div>
                        </div>
                        <div className={classNames.detailsBtn}>
                          <div className={classNames.title}>
                            Order #: {eachItem?._id ? eachItem?._id : "-"}
                          </div>
                          <div className={classNames.btnsContainer}>
                            <div>View Order Details</div>
                            <div>Invoice</div>
                          </div>
                        </div>
                      </div>
                      <div className={classNames.allProducts}>
                        {eachItem?.order_items?.length > 0
                          ? eachItem?.order_items?.map((eachProduct, index) => {
                              return (
                                <div
                                  className={classNames.eachProduct}
                                  style={{
                                    borderBottom:
                                      eachItem?.order_items?.length > 1
                                        ? "1px solid #e1e1e1"
                                        : "",
                                  }}
                                >
                                  <div className={classNames.productDetails}>
                                    <div className={classNames.details}>
                                      <div>
                                        <div className={classNames.title}>
                                          In Process
                                        </div>
                                        <div
                                          className={classNames.checkoutType}
                                        >
                                          Express checkout
                                        </div>
                                      </div>
                                      <div className={classNames.product}>
                                        <img
                                          src={headspacePoster}
                                          alt="headspacePoster"
                                        />
                                        <div className={classNames.details}>
                                          <div className={classNames.title}>
                                            Thyroid Screening Panel
                                          </div>
                                          <div className={classNames.desc}>
                                            Provided By:
                                            <span>CallHealth</span>
                                          </div>
                                          <div className={classNames.price}>
                                            <span>
                                              ₹
                                              {eachProduct?.wellness_service_price
                                                ? eachProduct?.wellness_service_price
                                                : "0.00"}
                                            </span>
                                            <span>
                                              ({" "}
                                              {eachProduct?.quantity
                                                ? eachProduct?.quantity
                                                : ""}{" "}
                                              Units)
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={classNames.productBtns}>
                                      <div>See order journey</div>
                                      <div>Request refund</div>
                                      <div>Leave vendor review</div>
                                      <div>Leave product review</div>
                                    </div>
                                  </div>
                                  <div
                                    className={`${classNames.header} ${classNames.footer}`}
                                  >
                                    <div>
                                      <div>
                                        <div className={classNames.buyBtn}>
                                          Buy Again
                                        </div>
                                      </div>
                                      <div>
                                        <div className={classNames.title}>
                                          Total
                                        </div>
                                        <div className={classNames.value}>
                                          343.13
                                        </div>
                                      </div>
                                      <div>
                                        <div className={classNames.title}>
                                          Ship To
                                        </div>
                                        <div className={classNames.value}>
                                          Shorupan P
                                        </div>
                                      </div>
                                    </div>
                                    <div className={classNames.detailsBtn}>
                                      <div className={classNames.title}>
                                        Order #:{" "}
                                        {eachProduct?._id
                                          ? eachProduct?._id
                                          : "-"}
                                      </div>
                                      <div className={classNames.btnsContainer}>
                                        <div>View Order Details</div>
                                        <div>Invoice</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
          <div className={classNames.yourDetails}>
            <div className={classNames.header}>Your Favourites</div>
            <div className={classNames.favContainer}>
              <div className={classNames.eachFav}>
                <div className={classNames.imageContainer}>
                  <img src={redcliff} alt="redcliff" />
                </div>
                <div className={classNames.detailsContainer}>
                  <div className={classNames.title}>
                    Thyroid Screening Panel
                  </div>
                  <div className={classNames.provider}>
                    Provided By:
                    <span>CallHealth</span>
                  </div>
                  <div className={classNames.priceDiv}>
                    <div className={classNames.currentText}>₹100.00</div>
                    <div className={classNames.slashedPrice}>₹120.00</div>
                  </div>
                  <div className={classNames.addButton}>
                    <span
                      className={classNames.btns}
                      // onClick={() => {
                      //   if (allCartItemsLocal?.cart_id && eachProduct?._id) {
                      //     addRemoveItemCart(
                      //       allCartItemsLocal?.cart_id,
                      //       eachProduct?._id,
                      //       "Remove",
                      //       setRefreshLocal
                      //     );
                      //   } else {
                      //     predefinedToast("Cart/service id not found!");
                      //   }
                      // }}
                    >
                      -
                    </span>
                    <span>1</span>
                    <span
                      className={classNames.btns}
                      // onClick={() => {
                      //   if (allCartItemsLocal?.cart_id && eachProduct?._id) {
                      //     addRemoveItemCart(
                      //       allCartItemsLocal?.cart_id,
                      //       eachProduct?._id,
                      //       "Add",
                      //       setRefreshLocal
                      //     );
                      //   } else {
                      //     predefinedToast("Cart/service id not found!");
                      //   }
                      // }}
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>
              <div className={classNames.eachFav}>
                <div className={classNames.imageContainer}>
                  <img src={redcliff} alt="redcliff" />
                </div>
                <div className={classNames.detailsContainer}>
                  <div className={classNames.title}>
                    Thyroid Screening Panel
                  </div>
                  <div className={classNames.provider}>
                    Provided By:
                    <span>CallHealth</span>
                  </div>
                  <div className={classNames.priceDiv}>
                    <div className={classNames.currentText}>₹100.00</div>
                    <div className={classNames.slashedPrice}>₹120.00</div>
                  </div>
                  <div className={classNames.addButton}>
                    <span
                      className={classNames.btns}
                      // onClick={() => {
                      //   if (allCartItemsLocal?.cart_id && eachProduct?._id) {
                      //     addRemoveItemCart(
                      //       allCartItemsLocal?.cart_id,
                      //       eachProduct?._id,
                      //       "Remove",
                      //       setRefreshLocal
                      //     );
                      //   } else {
                      //     predefinedToast("Cart/service id not found!");
                      //   }
                      // }}
                    >
                      -
                    </span>
                    <span>1</span>
                    <span
                      className={classNames.btns}
                      // onClick={() => {
                      //   if (allCartItemsLocal?.cart_id && eachProduct?._id) {
                      //     addRemoveItemCart(
                      //       allCartItemsLocal?.cart_id,
                      //       eachProduct?._id,
                      //       "Add",
                      //       setRefreshLocal
                      //     );
                      //   } else {
                      //     predefinedToast("Cart/service id not found!");
                      //   }
                      // }}
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
