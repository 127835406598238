import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../components/navbar";
import "./disclaimer.css";
import MobileNavbar from "../../components/navbar/mobile";
import { BankContext } from "../../context/Context";

const Disclaimer = () => {
  const { isScrolled, setIsScrolled } = useContext(BankContext);

  useEffect(() => {
    const homeElement = document.querySelector(".privacy");

    const handleScroll = () => {
      const scrollPosition = homeElement.scrollTop;
      const containerHeight = homeElement.clientHeight;
      const scrollThreshold = containerHeight * 0.5; // 50% of the container height

      if (scrollPosition >= scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    homeElement.addEventListener("scroll", handleScroll);

    return () => {
      homeElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="privacy">
      {window.innerWidth > 768 ? (
        <Navbar isScrolled={isScrolled} />
      ) : (
        <MobileNavbar />
      )}
      <div className="termsContainer">
        <h1>Disclaimer for Raha Insurance Services</h1>

        <h3>Introduction</h3>
        <p>
          Raha Insurance Services, powered by Raha Fintech Private Limited,
          ("Raha"), is a registered a company incorporated under the Companies
          Act, 2013 having its registered office at Plot No 177, Phase II,
          Kamalapuri Colony, Hyderabad 500073. This disclaimer governs your use
          of the Raha website (www.rahainsure.com) (the "Website").
        </p>

        <h3>General Information</h3>
        <p>
          The information provided on this Website is intended for general
          informational purposes only. While Raha endeavors to keep the
          information accurate and up to date, we make no representations or
          warranties, express or implied, regarding the completeness, accuracy,
          reliability, or availability of the information, products, services,
          or related graphics contained on this Website. Any reliance you place
          on such information is strictly at your own risk.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          In no event shall Raha or its affiliates, officers, directors, or
          employees be liable for any loss or damage, including but not limited
          to indirect or consequential loss or damage, or any loss or damage
          arising from the loss of data or profits, directly or indirectly
          resulting from or in connection with the use of this Website.
        </p>
        <h3>Third-Party Information</h3>
        <p>
          Raha does not guarantee the accuracy or completeness of any
          third-party information provided on this Website. The content on the
          Website, including third-party information, is not to be considered as
          investment advice or any other form of recommendation, nor does it
          constitute an invitation to invest in Raha or any of its affiliates.
        </p>
        <h3>No Investment Advice</h3>
        <p>
          The information on this Website should not be construed as investment
          advice. You should consult with a qualified financial advisor or
          professional before making any investment decisions based on the
          information provided on this Website.
        </p>
        <h3>Indemnification</h3>
        <p>
          By using this Website, you agree to indemnify and hold Raha and its
          affiliates, officers, directors, and employees harmless from any harm,
          loss, or damage (whether arising in contract, tort, negligence, or
          otherwise) that may arise in connection with your use of this Website,
          including any indirect, direct, special, third-party, or consequential
          damages.
        </p>
        <h3>Changes to Disclaimer</h3>
        <p>
          Raha reserves the right to update or modify this disclaimer at any
          time without prior notice. Your continued use of the Website after any
          changes to this disclaimer constitutes your acceptance of the updated
          terms.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this disclaimer or the
          practices of this Website, please contact us at{" "}
          <a href="mailto:connect@rahainsure.com">connect@rahainsure.com</a>
        </p>
        <h3>Acceptance of Disclaimer</h3>
        <p>
          By using the Raha website, you acknowledge that you have read,
          understood, and agreed to the terms and conditions of this disclaimer.
          If you do not agree with any part of this disclaimer, please refrain
          from using the Website
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
