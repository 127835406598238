import React, { useContext, useEffect, useState } from "react";
import { BankContext } from "../../context/Context";

import classNames from "./onlogincompanyform.module.scss";
import fullLogo from "../../assest/images/fullLogoWhite.svg";
import {
  EachCustomDropdown,
  EachCustomInput,
  EachCustomTextAreaGeneral,
} from "../Custom/Input";
import { allIndustry } from "../../assest/data/industry";
import {
  ImageUploadDivIcon,
  ImageUploadDivProfilePic,
} from "../../pages/Employer/CreateNewProfile";
import Axios from "axios";
import { predefinedToast } from "../../utils/toast";
import { parseBoolean } from "../../assest/functions";

export const fetchCountryDropdown = (setCountryDropdown) => {
  Axios.get("https://insurance.apimachine.com/insurance/brokerage/getcountries")
    .then((response) => {
      console.log(response, "dropdown country response");
      if (response?.data?.status) {
        setCountryDropdown(response?.data?.data);
      }
    })
    .catch((error) => {
      console.log(error?.message, "country dropdown error");
    });
};

const fetchStateDropdown = (setStateDropdown) => {
  Axios.get(`https://insurance.apimachine.com/insurance/general/getstates`)
    .then((response) => {
      console.log(response, "state dropdown response");
      if (response?.data?.status) {
        setStateDropdown(response?.data?.data);
      }
    })
    .catch((error) => {
      console.log(error?.message, "state dropdown error");
    });
};

const OnLoginCompanyForm = () => {
  const {
    email,
    onLoginFormEmployer,
    setOnLoginFormEmployer,
    globalUserId,
    onLoginFormEmployerProfile,
    setOnLoginFormEmployerProfile,
    setDashboardToggle,
    setDashboardToggleMain,
    setDesignation,
    setGlobalHrId,
    setGlobalCompanyId,
  } = useContext(BankContext);
  const [formDetails, setFormDetails] = useState({});
  const [countryDropdown, setCountryDropdown] = useState([]);
  const [stateDropdown, setStateDropdown] = useState([]);

  //functions

  async function parseAddress(addressString) {
    const addressParts = addressString.split(", ");

    if (addressParts.length < 2) {
      // Handle invalid address format
      return null;
    }

    const city = addressParts[0];
    const statePostalCode = addressParts[1];

    const statePostalCodeParts = statePostalCode.split(" ");
    const state = statePostalCodeParts.slice(0, -1).join(" ");
    const postalCode = statePostalCodeParts.slice(-1)[0];

    const addressObject = {
      city: city,
      state: state,
      postalCode: postalCode,
    };

    return await addressObject;
  }

  function getPlaceFromPincode(pincode) {
    console.log(pincode, "pincode from function");
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/google/geo-location/${pincode}`
    )
      .then(async (response) => {
        if (response?.data?.data?.length > 0) {
          let address = await parseAddress(
            response?.data?.data[0]?.formatted_address
          );

          setFormDetails((prev) => {
            return {
              ...prev,
              company_name:
               onLoginFormEmployerProfile?.leadDetails?.company_name,
              pin_code: onLoginFormEmployerProfile?.leadDetails?.pin_code,
              country: countryDropdown?.length > 0 ? countryDropdown[0] : "",
              city: address?.city,
              state: address?.state,
              first_name: onLoginFormEmployerProfile?.leadDetails?.first_name,
              last_name: onLoginFormEmployerProfile?.leadDetails?.last_name,
            };
          });
        }
      })
      .catch((error) => {
        console.log(error?.message, "getPlaceFromPincode error");
      });
  }
  function getPlaceFromPincodeEntry(pincode) {
    console.log(pincode, "pincode from function entry");
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/google/geo-location/${pincode}`
    )
      .then(async (response) => {
        if (response?.data?.data?.length > 0) {
          let address = await parseAddress(
            response?.data?.data[0]?.formatted_address
          );

          setFormDetails((prev) => {
            return {
              ...prev,
              company_name:
                onLoginFormEmployerProfile?.leadDetails?.company_name,
              country: countryDropdown?.length > 0 ? countryDropdown[0] : "",
              city: address?.city,
              state: address?.state,
            };
          });
        }
      })
      .catch((error) => {
        console.log(error?.message, "getPlaceFromPincode error");
      });
  }

  useEffect(() => {
    if (onLoginFormEmployerProfile?.leadDetails?.pin_code) {
      getPlaceFromPincode(onLoginFormEmployerProfile?.leadDetails?.pin_code);
    } else {
      setFormDetails((prev) => {
        return {
          ...prev,
          company_name: onLoginFormEmployerProfile?.leadDetails?.company_name,
          pin_code: onLoginFormEmployerProfile?.leadDetails?.pin_code,
          country: countryDropdown?.length > 0 ? countryDropdown[0] : "",
          first_name: onLoginFormEmployerProfile?.leadDetails?.first_name,
          last_name: onLoginFormEmployerProfile?.leadDetails?.last_name,
        };
      });
    }
    fetchCountryDropdown(setCountryDropdown);
    fetchStateDropdown(setStateDropdown);
  }, [onLoginFormEmployerProfile]);

  useEffect(() => {
    if (onLoginFormEmployerProfile?.leadDetails?.pin_code) {
      getPlaceFromPincode(onLoginFormEmployerProfile?.leadDetails?.pin_code);
    } else {
      setFormDetails((prev) => {
        return {
          ...prev,
          company_name: onLoginFormEmployerProfile?.leadDetails?.company_name,
          pin_code: onLoginFormEmployerProfile?.leadDetails?.pin_code,
          country: countryDropdown?.length > 0 ? countryDropdown[0] : "",
          first_name: onLoginFormEmployerProfile?.leadDetails?.first_name,
          last_name: onLoginFormEmployerProfile?.leadDetails?.last_name,
        };
      });
    }
  }, [countryDropdown]);

  async function checkEmploymentType() {
    let employmentType = await Axios.get(
      `https://insurance.apimachine.com/insurance/user/get/designations?email=${email}`
    );
    setDesignation(employmentType?.data?.data);
    if (employmentType?.data?.data?.is_hr) {
      setDashboardToggle("Employer");
      setDashboardToggleMain("Employer");
      localStorage.setItem("dashboardToggleMain", "Employer");
      localStorage.setItem("dashboardToggle", "Employer");
      // console.log("setting as  :", "Employer");
    } else {
      setDashboardToggle("Employee");
      setDashboardToggleMain("Employee");
      localStorage.setItem("dashboardToggleMain", "Employee");
      localStorage.setItem("dashboardToggle", "Employee");
      // console.log("setting as  :", "Employee");
    }
    // console.log("Employment type check :", employmentType?.data?.data);
  }

  function getUserId() {
    Axios.get(
      `https://insurance.apimachine.com/insurance/user/getuser?user_email=${email}`
    )
      .then(async (response) => {
        if (response?.data?.data?.length > 0) {
          localStorage?.setItem(
            "globalUserIdRaha",
            response?.data?.data[0]?.user_id
          );
          let globalHRID = await getGlobalHrId(
            response?.data?.data[0]?.user_id
          );
          fetchGlobalCompanyId(response?.data?.data[0]?.user_id);
        }
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");
      });
  }

  function fetchGlobalCompanyId(userid) {
    Axios.get(
      `https://insurance.apimachine.com/insurance/hr/get?user_id=${userid}`
    )
      .then((response) => {
        console.log(response?.data?.data, "company profile response main Page");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          console.log(
            response?.data?.data[0]?.company_id,
            "company id response"
          );

          setGlobalCompanyId(response?.data?.data[0]?.company_id);
          localStorage.setItem(
            "globalCompanyIdRaha",
            response?.data?.data[0]?.company_id
          );
        }
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");

        // setAllInsuranceCompaniesLoading(false);
      });
  }

  function getGlobalHrId(userid) {
    if (userid) {
      Axios.get(
        `https://insurance.apimachine.com/insurance/hr/get?user_id=${userid}`
      )
        .then((response) => {
          console.log(response?.data, "Global HR ID response", userid);
          if (response?.data?.status && response?.data?.data?.length > 0) {
            // console.log(response?.data?.data[0]?.hr_id, "hr id response");
            setGlobalHrId(response?.data?.data[0]?.hr_id);
            localStorage.setItem(
              "globalUserHrIdRaha",
              response?.data?.data[0]?.hr_id
            );
          } else {
          }
        })
        .catch((error) => {
          console.log(error?.message, "user profile error");
          // setAllInsuranceCompaniesLoading(false);
        });
    }
  }

  function createCompanyProfile() {
    formDetails.user_id = globalUserId;
    formDetails.country = countryDropdown?.length > 0 ? countryDropdown[0] : "";
    if (!formDetails.pin_code) {
      formDetails.city = "";
      formDetails.state = "";
    }
    console.log(formDetails, "createCompanyProfile formDetails");
    Axios.post(
      `https://insurance.apimachine.com/insurance/user/create/companyandhr`,
      formDetails
    )
      .then((response) => {
        console.log(response, "Create company profile response");
        if (response?.data?.status) {
          getUserId();
          setStateDropdown(response?.data?.data);
          fetchGlobalCompanyId();
          predefinedToast("Successfully Created You're Company Profile");
          localStorage.removeItem("onLoginForm");
          setOnLoginFormEmployer(false);
          checkEmploymentType();
        } else {
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Create company profile error");
      });
  }

  useEffect(() => {
    if (formDetails?.pin_code) {
      getPlaceFromPincodeEntry(formDetails?.pin_code);
    } else if (!formDetails?.pin_code) {
      console.log("Going inside empty pincode", formDetails?.pin_code);
      setFormDetails((prev) => {
        return {
          ...prev,
          city: "",
          state: "",
        };
      });
    }
  }, [formDetails?.pin_code]);

  return (
    <div
      style={{
        display:
          onLoginFormEmployer && window.location.pathname.includes("/coverfi")
            ? "flex"
            : "none",
      }}
      className={classNames.onLoginCompanyForm}
    >
      <div className={classNames.formContainer}>
        <div className={classNames.header}>
          <img src={fullLogo} alt="fullLogo" />
        </div>
        <div className={classNames.formBody}>
          <div className={classNames.title}>
            Please confirm you’re company details
          </div>
          <div className={classNames.inputFields}>
            <EachCustomInput
              title="Company Name"
              placeholder="Enter company name"
              name="company_name"
              stateValue={formDetails}
              setState={setFormDetails}
              validation={{ length: 50, type: "length" }}
            />
            <EachCustomInput
              title="Company pincode"
              placeholder="Enter pincode"
              name="pin_code"
              stateValue={formDetails}
              setState={setFormDetails}
            />
            <div
              className={classNames.dropdownParent}
              style={{ pointerEvents: "none" }}
            >
              <div className={classNames.title}>Country</div>
              <EachCustomDropdown
                title="Country"
                placeholder="Country"
                dropdown={countryDropdown}
                name="country"
                stateValue={formDetails}
                setState={setFormDetails}
                typee="single"
                option="alreadySet"
                indexx={0}
              />
            </div>

            <EachCustomInput
              title="City"
              placeholder="Enter city"
              name="city"
              stateValue={formDetails}
              setState={setFormDetails}
            />
            {formDetails?.state ? (
              <EachCustomInput
                title="State"
                placeholder="Enter state"
                name="state"
                stateValue={formDetails}
                setState={setFormDetails}
              />
            ) : (
              <div className={classNames.dropdownParent}>
                <div className={classNames.title}>State</div>
                <EachCustomDropdown
                  title="State"
                  placeholder="State"
                  dropdown={stateDropdown}
                  name="state"
                  stateValue={formDetails}
                  setState={setFormDetails}
                  typee="objVal"
                  objName="state"
                />
              </div>
            )}

            <EachCustomInput
              title="Address"
              placeholder="Enter address"
              name="address"
              stateValue={formDetails}
              setState={setFormDetails}
            />
            <EachCustomInput
              title="Company legal name"
              placeholder="Enter legal name"
              name="legal_name"
              stateValue={formDetails}
              setState={setFormDetails}
            />
            <div className={classNames.dropdownParent}>
              <div className={classNames.title}>Industry</div>
              <EachCustomDropdown
                title="Industry"
                placeholder="Industry"
                dropdown={allIndustry}
                name="industry"
                stateValue={formDetails}
                setState={setFormDetails}
                typee="single"
              />
            </div>
            <EachCustomInput
              title="Website"
              placeholder="Enter website link"
              name="website"
              stateValue={formDetails}
              setState={setFormDetails}
            />
            <EachCustomTextAreaGeneral
              title="Describe your company"
              placeholder="Describe your company"
              name="description"
              stateValue={formDetails}
              setState={setFormDetails}
              typee="normalInput"
            />
            <div className={classNames.inputContainers}>
              <ImageUploadDivIcon
                heading="Company icon"
                name="icon"
                stateValue={formDetails}
                setState={setFormDetails}
                idd="companyProfile"
              />
              <ImageUploadDivProfilePic
                heading="Company logo"
                name="logo"
                stateValue={formDetails}
                setState={setFormDetails}
                idd="companyLogo"
              />
            </div>
            <div
              className={classNames.submitBtn}
              onClick={createCompanyProfile}
            >
              Submit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnLoginCompanyForm;
