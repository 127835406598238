import React, { useState } from "react";
import classNames from "./wellnesstabs.module.scss";
import { ImageUploadDivIcon } from "../../../Employer/CreateNewProfile";
import {
  EachCustomDatePicker,
  EachCustomDocumentUpload,
  EachCustomDocumentUploadPlain,
  EachCustomDropdown,
  EachCustomInput,
  EachCustomTextArea,
} from "../../../../components/Custom/Input";
import { FaPlusCircle } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CgSoftwareUpload } from "react-icons/cg";
import profileIcon from "../../../../assest/images/icons/profile.svg";

const ClientOnboardingForm = () => {
  const [clientOnboardingForm, setClientOnboardingForm] = useState({
    address: "",
    startDate: "",
    endDate: "",
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const handleCheckboxChange = (option) => {
    setSelectedOption(option === selectedOption ? null : option);
  };
  return (
    <div className={classNames.clientOnboardingForm}>
      <div className={classNames.title}>
        Client On-Boarding - Company Information
      </div>
      <div className={classNames.formFields}>
        <div className={classNames.basicDetails}>
          <div className={classNames.companyDetails}>
            <div className={classNames.logoUpload}>
              <span>Company Logo</span>
              <ImageUploadDivIcon
                name="icon"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
            </div>
            <div className={classNames.flexContainer}>
              <EachCustomInput
                placeholder="Company name"
                name="first_name"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
              <EachCustomInput
                placeholder="GST Number"
                name="first_name"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
            </div>
            <EachCustomTextArea
              placeholder="Address"
              name="address"
              stateValue={clientOnboardingForm}
              setState={setClientOnboardingForm}
            />
            <div className={classNames.flexContainer}>
              <EachCustomInput
                placeholder="Pin Code"
                name="pincode"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "State"
                }
                // color="var(--main-color)"
                dropdownItems={[]}
                name="state"
                placeholder="State"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.flexContainer}>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "City"
                }
                dropdownItems={[]}
                name="city"
                placeholder="City"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
              <EachCustomInput
                placeholder="Country"
                name="country"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
            </div>
            <div className={classNames.flexContainer}>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "Industry Type"
                }
                dropdownItems={[]}
                name="industry"
                placeholder="Industry Type"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "SEZ/ Non-SEZ"
                }
                dropdownItems={[]}
                name="sez"
                placeholder="SEZ/ Non-SEZ"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.subTitles}>Other Locations</div>

            <div className={classNames.flexContainer}>
              <EachCustomInput
                placeholder="City 2"
                name="city"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "SEZ/ Non-SEZ 2"
                }
                dropdownItems={[]}
                name="sez2"
                placeholder="SEZ/ Non-SEZ 2"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.flexContainer}>
              <EachCustomInput
                placeholder="City 3"
                name="city3"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "SEZ/ Non-SEZ 3"
                }
                dropdownItems={[]}
                name="sez3"
                placeholder="SEZ/ Non-SEZ 3"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
          </div>
          <div className={classNames.documentForm}>
            <div className={classNames.subTitles}>Upload KYC Documents</div>
            <div className={classNames.flexContainerThree}>
              <FaPlusCircle />
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "Document Name"
                }
                dropdownItems={[]}
                name="documentName"
                placeholder="Document Name"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
              <EachCustomDocumentUploadPlain
                name="policy_document"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                idd="health"
              />
            </div>
            <div>
              <div className={classNames.subTitles}>CD Balance Rule</div>
              <p className={classNames.desc}>
                Notify all the stakeholders when the CD balance is fails the
                defines rules.
              </p>
            </div>
            <div className={classNames.flexContainer}>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm
                    ? clientOnboardingForm
                    : "Min. Threshold %"
                }
                dropdownItems={[]}
                name="minThresholdPerc"
                placeholder="Min. Threshold %"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm
                    ? clientOnboardingForm
                    : "Max. Threshold "
                }
                dropdownItems={[]}
                name="maxThreshold"
                placeholder="Max. Threshold "
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
          </div>
        </div>
        <div className={classNames.newSection}>
          <div className={classNames.subTitles}>
            Client User Information (up to 5 users)
          </div>
          <div className={classNames.tableContainer}>
            <table className={classNames.tableParent}>
              <thead>
                <tr>
                  <th></th>
                  <th>Sr. No.</th>
                  <th>Admin Name</th>
                  <th>Designation</th>
                  <th>Email ID</th>
                  <th>Mobile No.</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className={classNames.tableBody}>
                <tr className={classNames.tableRow}>
                  <td>
                    <MdEdit />
                  </td>
                  <td>1</td>
                  <td>Betty Thomas</td>
                  <td>HR Manager</td>
                  <td>betty@starfishgp.com</td>
                  <td>+91 768559222</td>
                  <td>Invited</td>
                  <td>
                    <RiDeleteBin5Line />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={classNames.newSection}>
          <div className={classNames.subTitles}>Raha User Information</div>
          <div className={classNames.tableContainer}>
            <table className={classNames.tableParent}>
              <thead>
                <tr
                  style={{
                    gridTemplateColumns: "40px 100px repeat(6, 1fr) 40px",
                  }}
                >
                  <th></th>
                  <th>Sr. No.</th>
                  <th>User Name</th>
                  <th>Designation</th>
                  <th>Email ID</th>
                  <th>Mobile No.</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>
                    <img src={profileIcon} alt="profileIcon" />
                  </th>
                </tr>
              </thead>
              <tbody className={classNames.tableBody}>
                <tr
                  className={classNames.tableRow}
                  style={{
                    gridTemplateColumns: "40px 100px repeat(6, 1fr) 40px",
                  }}
                >
                  <td>
                    <MdEdit />
                  </td>
                  <td>1</td>
                  <td>Mohan Vamshi G</td>
                  <td>Managing Director</td>
                  <td>vamshi@visistarisk.com</td>
                  <td>+91 768559222</td>
                  <td>Super Admin</td>
                  <td>Active</td>
                  <td>
                    <CgSoftwareUpload />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={classNames.checkBox}>
          <p>Do you want to proceed adding the existing policies ?</p>
          <div>
            <label>
              <input
                type="checkbox"
                checked={selectedOption === "yes"}
                onChange={() => handleCheckboxChange("yes")}
              />
              Yes
            </label>

            <label>
              <input
                type="checkbox"
                checked={selectedOption === "no"}
                onChange={() => handleCheckboxChange("no")}
              />
              No
            </label>
          </div>
        </div>
        <div
          className={classNames.newSection}
          style={{
            background: "#f5f5f5",
            padding: "1rem",
            borderRadius: "10px",
          }}
        >
          <div className={classNames.subTitles}>Policy Information</div>
          <div className={classNames.policyFields}>
            <div className={classNames.eachField}>
              <div className={classNames.title}>Policy Type</div>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "State"
                }
                // color="var(--main-color)"
                dropdownItems={[]}
                name="state"
                placeholder="State"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>Renewal / New</div>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "State"
                }
                // color="var(--main-color)"
                dropdownItems={[]}
                name="state"
                placeholder="State"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>Insurer Name</div>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "State"
                }
                // color="var(--main-color)"
                dropdownItems={[]}
                name="state"
                placeholder="State"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>Branch</div>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "State"
                }
                // color="var(--main-color)"
                dropdownItems={[]}
                name="state"
                placeholder="State"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>Address</div>
              <EachCustomTextArea
                placeholder="Address"
                name="address"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
            </div>

            <div className={classNames.flexContainer}>
              <div className={classNames.eachFieldEqual}>
                <div className={classNames.title}>City</div>
                <EachCustomInput
                  placeholder="City"
                  name="city"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                />
              </div>
              <div className={classNames.eachFieldEqual}>
                <div className={classNames.title}>Pin Code</div>
                <EachCustomInput
                  placeholder="Pincode"
                  name="pincode"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                />
              </div>
            </div>

            <div className={classNames.flexContainer}>
              <div className={classNames.eachFieldEqual}>
                <div className={classNames.title}>Upload Logo</div>
                <ImageUploadDivIcon
                  name="icon"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                />
              </div>
              <div className={classNames.eachFieldEqual}>
                <div className={classNames.title}>Policy Location</div>
                <EachCustomDropdown
                  buttonText={
                    clientOnboardingForm ? clientOnboardingForm : "State"
                  }
                  // color="var(--main-color)"
                  dropdownItems={[]}
                  name="state"
                  placeholder="State"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                  typee="obj"
                />
              </div>
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>State</div>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "State"
                }
                // color="var(--main-color)"
                dropdownItems={[]}
                name="state"
                placeholder="State"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>Coverage Type</div>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "State"
                }
                // color="var(--main-color)"
                dropdownItems={[]}
                name="state"
                placeholder="State"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>SPOC</div>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "State"
                }
                // color="var(--main-color)"
                dropdownItems={[]}
                name="state"
                placeholder="State"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>

            <div className={classNames.flexContainer}>
              <div className={classNames.eachFieldEqual}>
                <div className={classNames.title}>Start Date</div>
                <EachCustomDatePicker
                  //   title="Policy valid until"
                  placeholder="Click To Select"
                  // dropdown={usersDropdown}
                  name="startDate"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                  //   mandatory={clientOnboardingForm ? true : false}
                />
              </div>
              <div className={classNames.eachFieldEqual}>
                <div className={classNames.title}>End Date</div>
                <EachCustomDatePicker
                  //   title="Policy valid until"
                  placeholder="Click To Select"
                  // dropdown={usersDropdown}
                  name="endDate"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                  //   mandatory={clientOnboardingForm ? true : false}
                />
              </div>
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>GST Applicable:</div>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "State"
                }
                // color="var(--main-color)"
                dropdownItems={[]}
                name="state"
                placeholder="State"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>Flat / Graded</div>
              <EachCustomDropdown
                buttonText={
                  clientOnboardingForm ? clientOnboardingForm : "State"
                }
                // color="var(--main-color)"
                dropdownItems={[]}
                name="state"
                placeholder="State"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
                typee="obj"
              />
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>Policy Number</div>
              <EachCustomInput
                placeholder="Policy Number"
                name="pincode"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>Sum Insured</div>
              <EachCustomInput
                placeholder="Sum Insured"
                name="pincode"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
            </div>
            <div className={classNames.eachField}>
              <div className={classNames.title}>Upload Policy</div>
              <EachCustomInput
                placeholder="Upload Policy"
                name="pincode"
                stateValue={clientOnboardingForm}
                setState={setClientOnboardingForm}
              />
            </div>
          </div>
          <div
            className={classNames.subTitles}
            style={{ fontSize: "0.9rem", margin: "1rem 0" }}
          >
            Policy Coverage Details
          </div>
          <div className={classNames.footerBoxes}>
            <div>
              <div>
                <EachCustomDropdown
                  buttonText={
                    clientOnboardingForm ? clientOnboardingForm : "State"
                  }
                  // color="var(--main-color)"
                  dropdownItems={[]}
                  name="state"
                  placeholder="Coverage Heading"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                  typee="obj"
                />
                <EachCustomInput
                  placeholder="Sub Heading"
                  name="pincode"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                />
              </div>
              <div>
                <EachCustomTextArea
                  placeholder="Coverage Description"
                  name="address"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                />
              </div>
            </div>
            <div>
              <div className={classNames.eachField}>
                <div className={classNames.title}>Brokerage %</div>
                <EachCustomInput
                  placeholder="Brokerage %"
                  name="pincode"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                />
              </div>
              <div className={classNames.eachField}>
                <div className={classNames.title}>Reward %</div>
                <EachCustomInput
                  placeholder="Reward %"
                  name="pincode"
                  stateValue={clientOnboardingForm}
                  setState={setClientOnboardingForm}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientOnboardingForm;
