import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./typeSection.module.scss";
import { typeSectionContent } from "../../../assest/data/landing";

import { ImArrowRight } from "react-icons/im";
import requestCall from "../../../assest/images/requestcall.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CountUp from "react-countup";
import { BankContext } from "../../../context/Context";

const TypeSection = () => {
  const history = useHistory();
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const { isScrolled, setIsScrolled } = useContext(BankContext);

  const homeElementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting) {
          console.log("Intersection Observer Callback:", entry);
          setIsScrolled(false);
        } else {
          console.log("Intersection Observer Callback outside:", entry);
          setIsScrolled(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    if (homeElementRef?.current) {
      observer.observe(homeElementRef?.current);
    }

    return () => {
      if (homeElementRef?.current) {
        observer.unobserve(homeElementRef?.current);
      }
    };
  }, []);

  return (
    <div className={classNames.typeSection} ref={homeElementRef}>
      <div className={classNames.topSection}>
        <div className={classNames.contentBox}>
          <div>
            <div className={classNames.title}>
            <br />
            Wellness Management Made Easy
            </div>
            <div className={classNames.desc}>
            Modernize your employees benefits program management with Raha.
            </div>
            <div className={classNames.btnsContainer}>
              <div
                className={classNames.requestBtn}
                onClick={() => {
                  history.push("/getquote");
                }}
              >
                Request A Quote
                <ImArrowRight />
              </div>
              {/* <div
                className={classNames.buyBtn}
                // onClick={() => {
                //   history.push("/getquote");
                // }}
              >
                Buy Insurance Instantly
                <ImArrowRight />
              </div> */}
            </div>
            <br />
          </div>
          <div>
            <img src={requestCall} alt="requestCall" />
          </div>
        </div>
      </div>
      <div className={classNames.bottomSection} ref={sectionRef}>
        {/* <div className={classNames.typeCards}>
          <div className={classNames.selectSections}>
            <div className={classNames.selectedSectionItem}>Coverage Type</div>
            <div>Coverage Details</div>
            <div>Policy Size</div>
            <div>Cost Estimation</div>
          </div>
          <div className={classNames.sectionContent}>
            {typeSectionContent?.map((eachContent, i) => {
              return (
                <div className={classNames.eachContent} key={"eachcontent" + i}>
                  <img src={eachContent?.image} alt="" />
                  <div className={classNames.title}>{eachContent?.name}</div>
                </div>
              );
            })}
          </div>
        </div> */}
        <div className={classNames.allCards}>
          <div className={classNames.eachNumbersContent}>
            <div className={classNames.eachNumber}>
              {isVisible ? (
                <CountUp start={0} end={600} duration={2} />
              ) : (
                <span>0</span>
              )}
              +
            </div>
            <div className={classNames.eachTitle}>Clients</div>
          </div>
          <div className={classNames.eachNumbersContent}>
            <div className={classNames.eachNumber}>
              {isVisible ? (
                <CountUp start={0} end={400000} duration={2} />
              ) : (
                <span>0</span>
              )}
              +
            </div>
            <div className={classNames.eachTitle}>Lives Insured</div>
          </div>

          <div className={classNames.eachNumbersContent}>
            <div className={classNames.eachNumber}>
              {isVisible ? (
                <CountUp start={0} end={10000} duration={2} />
              ) : (
                <span>0</span>
              )}
              +
            </div>
            <div className={classNames.eachTitle}>Handled Claims</div>
          </div>
          <div className={classNames.eachNumbersContent}>
            <div className={classNames.eachNumber}>
              CR
              {isVisible ? (
                <CountUp start={0} end={50} duration={2} />
              ) : (
                <span>0</span>
              )}
              +
            </div>
            <div className={classNames.eachTitle}>Claims (INR) Processed</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeSection;
