//temp login with lead email employees

import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./temploginemployees.module.scss";
import classNames from "./tempemployeesextras.module.scss";

import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { BankContext } from "../../../context/Context";
import { APP_CODE } from "../../../config/appConfig";
import { APP_USER_TOKEN } from "../../../config";

import fullLogo from "../../../assest/images/fullLogo.svg";

import close from "../close1.svg";
import info from "../info.svg";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import asteriskIcon from "../../../assest/images/icons/asterisk.svg";
import Axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { predefinedToast } from "../../../utils/toast";
import { useMutation } from "react-query";
import { registerOnApp } from "../api";
import { MdOutlineEdit } from "react-icons/md";
import {
  EachCustomDatePicker,
  EachCustomDropdown,
  EachCustomInput,
} from "../../../components/Custom/Input";
import CustomCheckbox from "../../../components/Custom";
import {
  calculateAge,
  calculateAgeDash,
  convertDateFormatToUnderscore,
  filterObjectsWithAllTruthy,
} from "../../../assest/functions";
import flatpickr from "flatpickr";
import {
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoIosCheckmarkCircle,
} from "react-icons/io";

const TempLoginEmployees = () => {
  const history = useHistory();
  const { leademail } = useParams();

  const {
    email,
    userLoginHandler,
    setLoggedInApp,
    setGlobalUserId,
    globalUserId,
    setGlobalHrId,
    setGlobalCompanyId,
  } = useContext(BankContext);
  const [showPass, setShowPass] = useState(false);
  const [tempPasswordStep, setTempPasswordStep] = useState(1); //1 is default
  const [currentStep, setCurrentStep] = useState(1); // 1 is default
  const [selectedToggle, setSelectedToggle] = useState("benefits");
  const [isProfileFound, setIsProfileFound] = useState("");
  const [isProfileFoundDependents, setIsProfileFoundDependents] = useState([]);
  const [dependentsUpdate, setDependentsUpdate] = useState([]);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [confirmStep, setConfirmStep] = useState([]);

  const [tempPassword, setTempPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [ages, setAges] = useState([]);

  //API RESPONSES
  const [tempCredentials, setTempCredentials] = useState("");
  const [companyProfileDetails, setCompanyProfileDetails] = useState("");
  const [formDetails, setFormDetails] = useState(
    (localStorage.getItem("formDetailsPreLogin") &&
      JSON.parse(localStorage.getItem("formDetailsPreLogin"))) ||
      ""
  );
  const [formDetailsArrayNumber, setFormDetailsArrayNumber] = useState(0); // 0 is default
  const [updatedDependentArray, setUpdatedDependentArray] = useState("");

  //validations
  const [hasCapitalLetter, setHasCapitalLetter] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasMinLength, setHasMinLength] = useState(false);

  const [formDetailsArray, setFormDetailsArray] = useState(
    Array.from({ length: formDetailsArrayNumber }, () => ({}))
  );

  const updateFormDetailsAtIndex = (index, newValue) => {
    setUpdatedDependentArray(true);
    setFormDetailsArray((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = { ...updatedDetails[index], ...newValue };
      return updatedDetails;
    });
  };
  const updateFormDetailsAtIndexIsProfile = (index, newValue) => {
    setUpdatedDependentArray(true);
    setIsProfileFoundDependents((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = { ...updatedDetails[index], ...newValue };
      return updatedDetails;
    });
  };
  const updateFormDetailsAtIndexUpdateDependents = (index, newValue) => {
    setUpdatedDependentArray(true);
    setDependentsUpdate((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = { ...updatedDetails[index], ...newValue };
      return updatedDetails;
    });
  };

  // login code

  const { mutate: registerApp } = useMutation(registerOnApp, {});

  function getConfirmEmployees() {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/employee-spreadsheet/get?email_id=${leademail}`
    )
      .then((response) => {
        console.log(response, "Confirm employees response");
        if (response?.data?.data?.length > 0) {
          let res = response?.data?.data[0];
          res.age = formDetails?.date_of_birth?.includes("-")
            ? calculateAgeDash(res?.date_of_birth)
            : calculateAge(res?.date_of_birth);
          localStorage.setItem("formDetailsPreLogin", JSON.stringify(res));
          setFormDetails(res);

          if (res?.family_definition == "Employee + Spouse") {
            setFormDetailsArrayNumber(1);
          } else if (
            res?.family_definition == "Employee + Spouse + 2 Children"
          ) {
            setFormDetailsArrayNumber(3);
          } else if (
            res?.family_definition ==
            "Employee + Spouse + 2 Children + 2 Parents"
          ) {
            setFormDetailsArrayNumber(5);
          } else if (
            res?.family_definition ==
            "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws"
          ) {
            setFormDetailsArrayNumber(7);
          }
        }
      })
      .catch((error) => {
        console.log(error?.message, "Confirm employees error");
        // setProtectionScoreLoading(false);
      });
  }

  function getCompanyProfile() {
    Axios.get(
      `https://insurance.apimachine.com/insurance/lead/get?lead_email=${leademail}`
    )
      .then((response) => {
        console.log(response, "Company profile response");
        if (response?.data?.data?.length > 0) {
          setCompanyProfileDetails(response?.data?.data[0]);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Company profile error");
        // setProtectionScoreLoading(false);
      });
  }

  function getUserId() {
    Axios.get(
      `https://insurance.apimachine.com/insurance/user/getuser?user_email=${leademail}`
    )
      .then((response) => {
        // console.log(
        //   response?.data?.data?.length > 0,
        //   "user profile response for user_id"
        // );
        if (response?.data?.status && response?.data?.data?.length > 0) {
          localStorage?.setItem(
            "globalUserIdRaha",
            response?.data?.data[0]?.user_id
          );
          setGlobalUserId(response?.data?.data[0]?.user_id);
          // setIsProfileFound(response?.data?.data[0]);
          // setDropdown(response?.data?.data[0]);
          // setProtectionScore(response?.data?.data[0]);
          getGlobalHrId(response?.data?.data[0]?.user_id);
        } else {
          // setIsProfileFound(false);
        }
        // setProtectionScoreLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");
        // setProtectionScoreLoading(false);
      });
  }

  function getGlobalHrId(userid) {
    if (userid) {
      Axios.get(
        `https://insurance.apimachine.com/insurance/hr/get?user_id=${userid}`
      )
        .then((response) => {
          console.log(response?.data?.data, "company profile response");
          if (response?.data?.status && response?.data?.data?.length > 0) {
            // console.log(response?.data?.data[0]?.hr_id, "hr id response");

            if (response?.data?.data[0]?.hr_id) {
              setGlobalHrId(response?.data?.data[0]?.hr_id);
              localStorage.setItem(
                "globalUserHrIdRaha",
                response?.data?.data[0]?.hr_id
              );
            }
          }
        })
        .catch((error) => {
          console.log(error?.message, "user profile error");
          // setAllInsuranceCompaniesLoading(false);
        });
    }
  }

  function logoutFunction() {
    setGlobalUserId("");
    setGlobalHrId("");
    setGlobalCompanyId("");
    localStorage.clear();
  }

  function makeLeadToEmployee() {
    Axios.post(
      `https://insurance.apimachine.com/insurance/user/create/userandemployee`,
      {
        hr_id: isProfileFound?.hr_id,
        lead_id: isProfileFound?.lead_id,
        user_email: leademail,
      }
    )
      .then((response) => {
        console.log(response?.data, "Make lead into employee response");
        if (response?.data?.status) {
          // history.push("/employee/login");
        } else {
          predefinedToast("Error while making lead into employee");
        }
      })
      .catch((error) => {
        console.log(error?.message, "Make lead into employee error");
        predefinedToast(error?.message);
      });
  }

  function transformObject(originalObject) {
    console.log(originalObject, "Value in transformObject");
    return {
      employee_id: originalObject.employee_id,
      dependent_user_company_email: leademail,
      dependent_name: originalObject.dependant_name
        ? originalObject.dependant_name
        : originalObject.dependent_name
        ? originalObject.dependent_name
        : "",
      dependent_email: originalObject.email_id,
      relationship: originalObject?.relation,
      gender: originalObject?.gender,
      date_of_birth: originalObject?.date_of_birth,
      primary_phone_number: originalObject?.phone_number,
      secondary_phone_numbers: [],
    };
  }

  async function addDependents() {
    console.log(formDetailsArray, "formDetailsArray in add dependent");
    let employeeID = await Axios.get(
      `https://insurance.apimachine.com/insurance/user/getuserallprofile?user_email=${leademail}`
    );
    let empID = employeeID?.data?.data?.employeeDetails;
    if (employeeID?.data?.data?.employeeDetails?.length > 0) {
      empID = employeeID?.data?.data?.employeeDetails[0]?._id;
    }

    let finalArray;

    let newArrayWithoutAge = formDetailsArray?.map((obj, index) => {
      // Create a new object without the age field if it exists
      const { date_of_birth, age, ...rest } = obj;
      rest.dependent_user_company_email = leademail;
      rest.date_of_birth = convertDateFormatToUnderscore(date_of_birth);
      rest.employee_id = empID;
      rest.primary_phone_number = formDetails?.phone_number;
      rest.dependent_name = obj?.dependent_name
        ? obj?.dependent_name
        : isProfileFoundDependents[index]?.dependent_name
        ? isProfileFoundDependents[index]?.dependent_name
        : "";
      return rest;
    });
    console.log(newArrayWithoutAge, "newArrayWithoutAge before removing empty");
    newArrayWithoutAge = filterObjectsWithAllTruthy(newArrayWithoutAge);
    console.log(newArrayWithoutAge, "newArrayWithoutAge after removing empty");

    if (
      isProfileFoundDependents?.length > 0 &&
      (isProfileFoundDependents[0]?.hr_id ||
        isProfileFoundDependents[0]?.phone_number ||
        isProfileFoundDependents[0]?.relation ||
        isProfileFoundDependents[0]?.lead_id)
    ) {
      finalArray = isProfileFoundDependents?.map(transformObject);
      console.log(finalArray, "predone before removing empty");
      // finalArray = filterObjectsWithAllTruthy(
      //   isProfileFoundDependents?.map(transformObject)
      // );
      console.log(finalArray, "predone after removing empty");
    } else {
      finalArray = newArrayWithoutAge;
      console.log(finalArray, "postdone before removing empty");
      finalArray = filterObjectsWithAllTruthy(newArrayWithoutAge);
      console.log(finalArray, "postdone after removing empty");
    }

    console.log(finalArray, "finalObject");

    Axios.post(
      // `https://insurance.apimachine.com/insurance/dependent/create/bulktemp`,
      `https://insurance.apimachine.com/insurance/dependent/create/bulk`,
      {
        dependents_details:
          // isProfileFoundDependents?.length > 0
          //   ? isProfileFoundDependents
          //   : newArrayWithoutAge,
          Array.isArray(isProfileFoundDependents) &&
          isProfileFoundDependents?.length > 0
            ? finalArray
            : formDetailsArray?.length > 0
            ? newArrayWithoutAge
            : finalArray,
      }
    )
      .then((response) => {
        console.log(response?.data, "Added dependents response");
        if (response?.data?.status) {
          setTempPasswordStep(6);
          predefinedToast("Added dependent successfully!");
        } else {
          predefinedToast("Error while adding dependents");
        }
      })
      .catch((error) => {
        console.log(error?.message, "Added dependents error");
        predefinedToast(error?.message);
      });
  }

  function updateFieldsDependents(array) {
    // Loop through each object in the array
    array.forEach((obj) => {
      // Check if the object has the fields dependent_name and relationship
      if (
        obj.hasOwnProperty("dependent_name") &&
        obj.hasOwnProperty("relationship")
      ) {
        // Update the fields to dependant_name and relation
        obj.dependant_name = obj.dependent_name;
        obj.relation = obj.relationship;
        // Delete the old fields if needed
        delete obj.dependent_name;
        delete obj.relationship;
      }
    });
    return array;
  }

  function updateDependentInfoFinal(data) {
    return data.map((dependent) => {
      return {
        dependent_name: dependent.dependant_name || "",
        dependent_email: dependent.dependent_email || "",
        relationship: dependent.relation || "",
        gender: dependent.gender || "",
        date_of_birth: dependent.date_of_birth || "",
        dependent_id: dependent.dependent_id || "",
        primary_phone_number: dependent.primary_phone_number || "",
        secondary_phone_numbers: dependent.secondary_phone_numbers || [],
      };
    });
  }

  async function updateDependents() {
    const finalData = updateDependentInfoFinal(dependentsUpdate);
    console.log(finalData, "Update dependents API called");
    try {
      let response = await Axios.put(
        `https://insurance.apimachine.com/insurance/dependent/update/bulk`,
        {
          dependents_details: finalData,
        }
      );
      if (response?.data?.status) {
        setTempPasswordStep(6);
        predefinedToast("Added dependent successfully!");
      } else {
        predefinedToast("Error while adding dependents");
      }
      console.log(response, "updateDependents response");
    } catch (error) {
      console.log(error, "updateDependents error");
      predefinedToast(error?.message);
    }
  }

  const handleLoginSuccess = (data) => {
    // console.log(data, "login data");
    makeLeadToEmployee();
    localStorage.setItem("accessToken aiprowallet", data?.accessToken);
    getUserId();
    userLoginHandler(email, data.accessToken, data.idToken);
    registerApp({ email, app_code: APP_CODE });
    registerApp({ email, app_code: "ice" });
    Cookies.set(APP_USER_TOKEN, data.idToken);
    setLoggedInApp("employee");
    localStorage.setItem("bankState", "employee");
    setTempPasswordStep(4);
    setCurrentStep(2);
  };

  async function getUpdateDependents() {
    try {
      let response = await Axios.get(
        `https://insurance.apimachine.com/insurance/dependent/get?user_email=${leademail}`
      );
      if (
        Array.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        const updatedArray = updateFieldsDependents(response?.data?.data);
        setDependentsUpdate(updatedArray);
      } else {
        setDependentsUpdate("");
      }
      console.log(response, "getUpdateDependents response");
    } catch (error) {
      console.log(error, "getUpdateDependents response");
    }
  }

  useEffect(() => {
    getCompanyProfile();
    getConfirmEmployees();

    setIsLoggingIn(true);
    getUpdateDependents();
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/employee-spreadsheet/get?email_id=${leademail}`
    )
      .then((response) => {
        console.log(response, "Get profile data for lead email response");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          setIsProfileFound(response?.data?.data[0]);
          setIsProfileFoundDependents(
            response?.data?.data[0]?.employee_dependents
          );
        } else {
          setIsProfileFound(false);
          setIsProfileFoundDependents(false);
          // history.push("/employee/login");
        }
        setIsLoggingIn(false);
      })
      .catch((error) => {
        console.log(error?.message, "Get profile data for lead email error");
        setIsLoggingIn(false);
      });
  }, []);

  function verifyTempCredentials() {
    setIsLoggingIn(true);
    Axios.post(`https://gxauth.apimachine.com/gx/user/auth/login`, {
      email: leademail,
      password: tempPassword,
    })
      .then((response) => {
        console.log(
          response?.data,
          "Verify Temporary Credentials API response"
        );
        if (
          response?.data?.authChallenge &&
          response?.data?.challengeName == "NEW_PASSWORD_REQUIRED"
        ) {
          setTempCredentials(response?.data);
          setTempPasswordStep(2);
        } else {
          // setTempCredentials(false);
          predefinedToast(response?.data?.message);
        }
        setIsLoggingIn(false);
      })
      .catch((error) => {
        console.log(error?.message, "Verify Temporary Credentials API error");
        predefinedToast(error?.message);
        setIsLoggingIn(false);
      });
    // console.log(
    //   {
    //     email: leademail,
    //     password: tempPassword,
    //   },
    //   "verifyTempCredentials"
    // );
  }

  function updateUsersPassword() {
    if (newPassword !== confirmPassword) {
      predefinedToast("Passwords doesn't match..");
    } else {
      setIsLoggingIn(true);
      Axios.post(`https://gxauth.apimachine.com/gx/user/auth/login/challenge`, {
        authChallenge_id: tempCredentials?.authChallenge_id,
        email: leademail,
        username: tempCredentials?.username,
        challengeName: tempCredentials?.challengeName,
        session: tempCredentials?.session,
        newPassword: confirmPassword,
      })
        .then((response) => {
          console.log(response?.data, "Update Users Password");
          if (response?.data) {
            handleLoginSuccess(response?.data);
          } else {
            // setTempCredentials(false);
            predefinedToast(response?.data?.message);
          }
          setIsLoggingIn(false);
        })
        .catch((error) => {
          console.log(error?.message, "Update Users Password error");
          predefinedToast(error?.message);
          setIsLoggingIn(false);
        });
    }
  }

  function handleChange(event) {
    const password = event.target.value;
    const capitalRegex = /[A-Z]/;
    const specialRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;

    setHasCapitalLetter(capitalRegex.test(password));
    setHasSpecialCharacter(specialRegex.test(password));
    setHasNumber(numberRegex.test(password));
    setHasMinLength(password.length >= 7);
  }

  useEffect(() => {
    console.log(formDetailsArrayNumber, "coming inside formDetailsArrayNumber");
    setFormDetailsArray(
      Array.from({ length: formDetailsArrayNumber }, () => ({}))
    );
  }, [formDetailsArrayNumber]);

  useEffect(() => {
    if (formDetailsArray?.length > 0) {
      const updatedAges = formDetailsArray.map((formDetail) => {
        return {
          age: formDetails?.date_of_birth?.includes("-")
            ? calculateAgeDash(formDetail?.date_of_birth)
            : calculateAge(formDetail?.date_of_birth),
        };
      });

      // Update the state with the new ages
      setAges(updatedAges);
    }
  }, [formDetailsArray]);

  //editable data
  const [editEnabled, setEditEnabled] = useState(false);

  async function updateEmployeeDetails() {
    try {
      let objj = {
        name: formDetails?.employee_name,
        date_of_birth: formDetails?.date_of_birth,
        phone_number: formDetails?.phone_number,
        gender: formDetails?.gender,
        role: formDetails?.designation,
        family_definition: isProfileFound?.family_definition
          ? isProfileFound?.family_definition
          : formDetails?.family_definition
          ? formDetails?.family_definition
          : "",
      };

      let employeeID = await Axios.get(
        `https://insurance.apimachine.com/insurance/user/getuserallprofile?user_email=${leademail}`
      );
      let empID = employeeID?.data?.data?.employeeDetails;
      if (employeeID?.data?.data?.employeeDetails?.length > 0) {
        empID = employeeID?.data?.data?.employeeDetails[0]?.employee_id;
      }
      console.log(empID, "employeeID ");

      let response = await Axios.put(
        `https://insurance.apimachine.com/insurance/employee/update?employee_id=${empID}`,
        objj
      );
      console.log(response, "response from formDetails updateEmployeeDetails");
      predefinedToast(response?.data?.message);
      setEditEnabled(false);
    } catch (error) {
      console.log(error?.message);
    }
  }

  return (
    <div className={styles.tempLoginEmployees}>
      <div
        className={styles.leftSide}
        style={{
          justifyContent: currentStep === 1 ? "center" : "flex-start",
          display: tempPasswordStep === 6 ? "none" : "flex",
          paddingTop: tempPasswordStep == 5 ? "5rem" : "",
        }}
      >
        <div className={styles.headerContainer}>
          <img src={fullLogo} alt="Full Logo" />
          <div className={styles.stepTracker}>
            <div className={currentStep === 1 ? styles.currentStep : ""}>
              1<div className={styles.stepTitle}>Reset Password</div>
            </div>
            <div className={currentStep === 2 ? styles.currentStep : ""}>
              2 <div className={styles.stepTitle}>Confirm Details</div>
            </div>
            <div className={currentStep === 3 ? styles.currentStep : ""}>
              3<div className={styles.stepTitle}>Confirm Dependants</div>
            </div>
          </div>
        </div>
        <img
          src={companyProfileDetails?.employee_company_icon}
          alt="Full Logo"
          className={styles.logo}
          style={{
            maxWidth: "85%",
            paddingBottom: "6%",
            maxHeight: "170px",
            display: currentStep === 1 ? "unset" : "none",
          }}
          onClick={() => {
            history.push("/");
          }}
        />
        {tempPasswordStep == 1 ? (
          <div className={styles.inputFields}>
            <div
              style={{
                background: "#FFFDF2",
                borderRadius: "15px",
                padding: "1.5rem 1.5rem",
                fontSize: "1rem",
                fontWeight: "405",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: "100%",
                marginBottom: "3rem",
              }}
            >
              <div>
                <img src={info} alt="" />
              </div>
              <div>
                Enter the temporary password which you got in your email
              </div>
            </div>
            <div className={styles.singleInputContainer}>
              <input
                type={showPass ? "text" : "password"}
                placeholder="Temp password..."
                className={styles.input2}
                value={tempPassword}
                onChange={(event) => {
                  setTempPassword(event?.target?.value);
                }}
              />
              {showPass ? (
                <AiOutlineEye onClick={() => setShowPass((prev) => !prev)} />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => setShowPass((prev) => !prev)}
                />
              )}
            </div>
            <div
              className={styles.loginButton}
              onClick={verifyTempCredentials}
              style={{
                opacity: isLoggingIn ? "0.25" : "1",
                pointerEvents: isLoggingIn ? "none" : "",
              }}
            >
              {isLoggingIn ? "Loading..." : "Next Step"}
            </div>
          </div>
        ) : tempPasswordStep == 2 ? (
          <div className={styles.inputFields}>
            <div
              style={{
                background: "#FFFDF2",
                borderRadius: "15px",
                padding: "1.5rem 1.5rem",
                fontSize: "1rem",
                fontWeight: "405",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: "100%",
                marginBottom: "3rem",
              }}
            >
              <div>
                <img src={info} alt="" />
              </div>
              <div>
                Your temporary credentials have been verified. Now create your
                new password
              </div>
            </div>
            <div className={styles.singleInputContainer}>
              <input
                type={showPass ? "text" : "password"}
                placeholder="New password..."
                className={styles.input2}
                value={newPassword}
                onChange={(event) => {
                  setNewPassword(event?.target?.value);
                  handleChange(event);
                }}
              />
              {showPass ? (
                <AiOutlineEye onClick={() => setShowPass((prev) => !prev)} />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => setShowPass((prev) => !prev)}
                />
              )}
            </div>
            {/* <div className={styles.requirmentsBtn}>
              See Password Requirements
            </div> */}
            <div className={styles.conditions}>
              <div className={styles.eachCondition}>
                Minimum One Capital Letter
                <div
                  className={styles.indicator}
                  style={{
                    background: hasCapitalLetter
                      ? "#B5E6EA"
                      : "rgba(220, 48, 39, 0.37)",
                  }}
                ></div>
              </div>
              <div className={styles.eachCondition}>
                Minimum One Special Character
                <div
                  className={styles.indicator}
                  style={{
                    background: hasSpecialCharacter
                      ? "#B5E6EA"
                      : "rgba(220, 48, 39, 0.37)",
                  }}
                ></div>
              </div>
              <div className={styles.eachCondition}>
                Minimum Seven Characters
                <div
                  className={styles.indicator}
                  style={{
                    background: hasMinLength
                      ? "#B5E6EA"
                      : "rgba(220, 48, 39, 0.37)",
                  }}
                ></div>
              </div>
              <div className={styles.eachCondition}>
                Minimum One Number
                <div
                  className={styles.indicator}
                  style={{
                    background: hasNumber
                      ? "#B5E6EA"
                      : "rgba(220, 48, 39, 0.37)",
                  }}
                ></div>
              </div>
            </div>
            <div
              className={styles.loginButton}
              style={{
                pointerEvents:
                  hasCapitalLetter &&
                  hasSpecialCharacter &&
                  hasMinLength &&
                  hasNumber
                    ? ""
                    : isLoggingIn
                    ? "none"
                    : "none",
                opacity:
                  hasCapitalLetter &&
                  hasSpecialCharacter &&
                  hasMinLength &&
                  hasNumber
                    ? ""
                    : isLoggingIn
                    ? "0.5"
                    : "0.5",
              }}
              // style={{
              //   opacity: isLoggingIn ? "0.25" : "1",
              //   pointerEvents: isLoggingIn ? "none" : "",
              // }}
              onClick={() => {
                logoutFunction();
                setTempPasswordStep(3);
              }}
            >
              {isLoggingIn ? "Loading..." : "Next Step"}
            </div>
          </div>
        ) : tempPasswordStep == 3 ? (
          <div className={styles.inputFields}>
            <div
              style={{
                background: "#FFFDF2",
                borderRadius: "15px",
                padding: "1.5rem 1.5rem",
                fontSize: "1rem",
                fontWeight: "405",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: "100%",
                marginBottom: "3rem",
              }}
            >
              <div>
                <img src={info} alt="" />
              </div>
              <div>
                Please confirm your password by entering it one more time
              </div>
            </div>
            <div className={styles.singleInputContainer}>
              <input
                type={showPass ? "text" : "password"}
                placeholder="Confirm Password..."
                className={styles.input2}
                value={confirmPassword}
                onChange={(event) => {
                  setConfirmPassword(event?.target?.value);
                }}
              />
              {showPass ? (
                <AiOutlineEye onClick={() => setShowPass((prev) => !prev)} />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => setShowPass((prev) => !prev)}
                />
              )}
            </div>
            <div className={styles.conditions}>
              <div className={styles.eachCondition}>
                Password Matches
                <div
                  // className={styles.indicator}
                  style={{
                    color:
                      newPassword === confirmPassword
                        ? "var(--main-color)"
                        : "var(--font-color)",
                  }}
                >
                  <IoIosCheckmarkCircle />
                </div>
              </div>
            </div>
            <div
              className={styles.loginButton}
              style={{
                opacity: isLoggingIn ? "0.25" : "1",
                pointerEvents: isLoggingIn ? "none" : "",
              }}
              onClick={updateUsersPassword}
            >
              {isLoggingIn ? "Loading..." : "Next Step"}
            </div>
            <div
              className={styles.backBtn}
              onClick={() => setTempPasswordStep(2)}
            >
              Go Back
            </div>
          </div>
        ) : tempPasswordStep == 4 ? (
          <>
            <div
              className={styles.userDetailsContainer}
              style={{
                marginTop: currentStep === 1 ? "" : "10rem",
              }}
            >
              <div className={styles.title}>
                <span>Employee Details</span>
                {/* <span>
                  <MdOutlineEdit />
                </span> */}
              </div>
              <div
                className={styles.eachDetails}
                // style={{
                //   pointerEvents: "none",
                // }}
              >
                <div className={styles.threeDivContainer}>
                  <EachCustomInputEditable
                    placeholder="-"
                    name="employee_name"
                    stateValue={formDetails}
                    setState={setFormDetails}
                    title="Name"
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                  />
                  {/* <EachCustomInputEditable
                    placeholder="-"
                    name="gender"
                    stateValue={formDetails}
                    setState={setFormDetails}
                    title="Gender"
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                  /> */}
                  <EachCustomDropdownEditable
                    title="Gender"
                    placeholder="-"
                    name="gender"
                    dropdown={["Male", "Female"]}
                    stateValue={formDetails}
                    setState={setFormDetails}
                    typee="single"
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    option={"alreadySet"}
                  />
                  <EachCustomDatePickerEditable
                    //   title="Policy valid until"
                    placeholder="Click To Select"
                    // dropdown={usersDropdown}
                    name="date_of_birth"
                    stateValue={formDetails}
                    setState={setFormDetails}
                    //   mandatory={clientOnboardingForm ? true : false}
                    title="Date of birth"
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    typee="spreadObj"
                  />
                </div>
                <div className={styles.twoDivContainer}>
                  <EachCustomInputEditable
                    placeholder=""
                    name="phone_number"
                    stateValue={formDetails}
                    setState={setFormDetails}
                    title="Phone number"
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    validation={{ length: 10, type: "length" }}
                  />
                  <EachCustomInput
                    placeholder="-"
                    name="email_id"
                    stateValue={formDetails}
                    setState={setFormDetails}
                    title="Email"
                  />
                </div>
                <div className={styles.twoDivContainer}>
                  <EachCustomInput
                    placeholder="-"
                    name="designation"
                    stateValue={formDetails}
                    setState={setFormDetails}
                    title="Designation"
                  />

                  <EachCustomInput
                    placeholder="-"
                    name="age"
                    stateValue={{
                      age:
                        formDetails?.date_of_birth?.includes("-") &&
                        formDetails?.date_of_birth
                          ? calculateAgeDash(formDetails?.date_of_birth)
                          : formDetails?.date_of_birth
                          ? calculateAge(formDetails?.date_of_birth)
                          : "-",
                    }}
                    setState={setFormDetails}
                    title="Age"
                  />
                  {/* <div className={styles.ageBox}>
                    {formDetails?.date_of_birth
                      ? calculateAgeDash(formDetails?.date_of_birth)
                      : "-"}
                  </div> */}
                </div>
              </div>
              {editEnabled ? (
                ""
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <div style={{ position: "relative", width: "40px" }}>
                    <CustomCheckbox
                      actionBtns={confirmStep}
                      setActionBtns={setConfirmStep}
                      id={"temppasswordstep4"}
                      name={"temppasswordstep4 name"}
                    />
                  </div>
                  <span>
                    I hereby acknowledge that my personal information is
                    presented correctly
                  </span>
                </div>
              )}
            </div>
            {editEnabled ? (
              <div className={styles.submitBtn} onClick={updateEmployeeDetails}>
                Save changes
              </div>
            ) : (
              <div
                className={styles.submitBtn}
                style={{
                  pointerEvents: confirmStep?.includes("temppasswordstep4")
                    ? ""
                    : "none",
                  opacity: confirmStep?.includes("temppasswordstep4")
                    ? ""
                    : "0.5",
                }}
                onClick={() => {
                  updateEmployeeDetails();
                  if (formDetails?.family_definition === "Only The Employee") {
                    setTempPasswordStep(6);
                  } else {
                    setCurrentStep(3);
                    setTempPasswordStep(5);
                  }
                  //  setTempPasswordStep(5);
                }}
              >
                Next Step
              </div>
            )}
          </>
        ) : tempPasswordStep == 5 ? (
          <>
            <div
              className={styles.userDetailsContainer}
              style={{
                marginTop: currentStep === 1 ? "" : "5rem",
              }}
            >
              <div className={styles.title}>
                <span>Dependent Details</span>
              </div>
              {dependentsUpdate?.length > 0
                ? dependentsUpdate?.map((formDetails, index) => {
                    return (
                      <div
                        className={styles.eachDetails}
                        key={`eachDependent${index}`}
                        // style={{ pointerEvents: "none" }}
                      >
                        <div className={styles.threeDivContainer}>
                          <EachCustomInput
                            placeholder="Enter dependent name"
                            name="dependant_name"
                            stateValue={formDetails}
                            setState={(newValue) =>
                              updateFormDetailsAtIndexUpdateDependents(
                                index,
                                newValue
                              )
                            }
                            // asteriskIconn="true"
                          />

                          <div>
                            <EachCustomDatePicker
                              //   title="Policy valid until"
                              placeholder="Select date of birth"
                              // dropdown={usersDropdown}
                              name="date_of_birth"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexUpdateDependents(
                                  index,
                                  newValue
                                )
                              }
                              // asteriskIconn="true"
                              //   mandatory={clientOnboardingForm ? true : false}
                              values={{
                                state: formDetails,
                                index: index,
                              }}
                              validation={{ type: "relationship" }}
                            />
                          </div>
                          <div className={styles.ageBox}>
                            {formDetails?.date_of_birth
                              ? calculateAgeDash(formDetails?.date_of_birth)
                              : "-"}
                          </div>
                        </div>
                        <div className={styles.twoDivContainer}>
                          {/* <EachCustomInput
                              placeholder="Relation"
                              name="relation"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexUpdateDependents(index, newValue)
                              }
                            /> */}
                          <EachCustomDropdown
                            placeholder="Relation"
                            dropdown={
                              isProfileFound?.family_definition ==
                              "Employee + Spouse"
                                ? ["Spouse"]
                                : isProfileFound?.family_definition ==
                                  "Employee + Spouse + 2 Children"
                                ? ["Spouse", "Son", "Daughter"]
                                : isProfileFound?.family_definition ==
                                  "Employee + Spouse + 2 Children + 2 Parents"
                                ? [
                                    "Spouse",
                                    "Son",
                                    "Daughter",
                                    "Father",
                                    "Mother",
                                  ]
                                : isProfileFound?.family_definition ==
                                  "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws"
                                ? [
                                    "Spouse",
                                    "Son",
                                    "Daughter",
                                    "Father",
                                    "Mother",
                                    "Father-in-Law",
                                    "Mother-in-Law",
                                  ]
                                : []
                            }
                            name="relation"
                            stateValue={formDetails}
                            setState={(newValue) =>
                              updateFormDetailsAtIndexUpdateDependents(
                                index,
                                newValue
                              )
                            }
                            typee="single"
                            asteriskIconn="true"
                            isAlreadyThere={formDetails?.relation}
                          />
                          <EachCustomInput
                            placeholder="Gender"
                            name="gender"
                            stateValue={formDetails}
                            setState={(newValue) =>
                              updateFormDetailsAtIndexUpdateDependents(
                                index,
                                newValue
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  })
                : isProfileFoundDependents?.length > 0
                ? isProfileFoundDependents?.map((formDetails, index) => {
                    return (
                      <div
                        className={styles.eachDetails}
                        key={`eachDependent${index}`}
                        // style={{ pointerEvents: "none" }}
                      >
                        <div className={styles.threeDivContainer}>
                          <EachCustomInput
                            placeholder="Enter dependent name"
                            name="dependant_name"
                            stateValue={formDetails}
                            setState={(newValue) =>
                              updateFormDetailsAtIndexIsProfile(index, newValue)
                            }
                            // asteriskIconn="true"
                          />

                          <div>
                            <EachCustomDatePicker
                              //   title="Policy valid until"
                              placeholder="Select date of birth"
                              // dropdown={usersDropdown}
                              name="date_of_birth"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexIsProfile(
                                  index,
                                  newValue
                                )
                              }
                              // asteriskIconn="true"
                              //   mandatory={clientOnboardingForm ? true : false}
                              values={{
                                state: formDetails,
                                index: index,
                              }}
                              validation={{ type: "relationship" }}
                            />
                          </div>
                          <div className={styles.ageBox}>
                            {formDetails?.date_of_birth
                              ? calculateAgeDash(formDetails?.date_of_birth)
                              : "-"}
                          </div>
                        </div>
                        <div className={styles.twoDivContainer}>
                          {/* <EachCustomInput
                              placeholder="Relation"
                              name="relation"
                              stateValue={formDetails}
                              setState={(newValue) =>
                                updateFormDetailsAtIndexIsProfile(index, newValue)
                              }
                            /> */}
                          <EachCustomDropdown
                            placeholder="Relation"
                            dropdown={
                              isProfileFound?.family_definition ==
                              "Employee + Spouse"
                                ? ["Spouse"]
                                : isProfileFound?.family_definition ==
                                  "Employee + Spouse + 2 Children"
                                ? ["Spouse", "Son", "Daughter"]
                                : isProfileFound?.family_definition ==
                                  "Employee + Spouse + 2 Children + 2 Parents"
                                ? [
                                    "Spouse",
                                    "Son",
                                    "Daughter",
                                    "Father",
                                    "Mother",
                                  ]
                                : isProfileFound?.family_definition ==
                                  "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws"
                                ? [
                                    "Spouse",
                                    "Son",
                                    "Daughter",
                                    "Father",
                                    "Mother",
                                    "Father-in-Law",
                                    "Mother-in-Law",
                                  ]
                                : []
                            }
                            name="relation"
                            stateValue={formDetails}
                            setState={(newValue) =>
                              updateFormDetailsAtIndexIsProfile(index, newValue)
                            }
                            typee="single"
                            asteriskIconn="true"
                            isAlreadyThere={formDetails?.relation}
                          />
                          <EachCustomInput
                            placeholder="Gender"
                            name="gender"
                            stateValue={formDetails}
                            setState={(newValue) =>
                              updateFormDetailsAtIndexIsProfile(index, newValue)
                            }
                          />
                        </div>
                      </div>
                    );
                  })
                : formDetailsArray.map((formDetails, index) => (
                    <div
                      className={styles.eachDetails}
                      key={`eachDependent${index}`}
                    >
                      <div className={styles.threeDivContainer}>
                        <EachCustomInput
                          placeholder="Enter dependent name"
                          name="dependent_name"
                          stateValue={formDetails}
                          setState={(newValue) =>
                            updateFormDetailsAtIndex(index, newValue)
                          }
                          asteriskIconn="true"
                        />
                        <div>
                          <EachCustomDatePicker
                            //   title="Policy valid until"
                            placeholder="Select date of birth"
                            // dropdown={usersDropdown}
                            name="date_of_birth"
                            stateValue={formDetails}
                            setState={(newValue) =>
                              updateFormDetailsAtIndex(index, newValue)
                            }
                            asteriskIconn="true"
                            values={{ state: formDetails, index: index }}
                            validation={{ type: "relationship" }}
                            //   mandatory={clientOnboardingForm ? true : false}
                          />
                        </div>
                        <div className={styles.ageBox}>
                          {formDetails?.date_of_birth
                            ? calculateAgeDash(formDetails?.date_of_birth)
                            : "-"}
                        </div>
                      </div>
                      <div className={styles.twoDivContainer}>
                        <EachCustomDropdown
                          placeholder="Relation"
                          dropdown={
                            isProfileFound?.family_definition ==
                            "Employee + Spouse"
                              ? ["Spouse"]
                              : isProfileFound?.family_definition ==
                                "Employee + Spouse + 2 Children"
                              ? ["Spouse", "Son", "Daughter"]
                              : isProfileFound?.family_definition ==
                                "Employee + Spouse + 2 Children + 2 Parents"
                              ? [
                                  "Spouse",
                                  "Son",
                                  "Daughter",
                                  "Father",
                                  "Mother",
                                ]
                              : isProfileFound?.family_definition ==
                                "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws"
                              ? [
                                  "Spouse",
                                  "Son",
                                  "Daughter",
                                  "Father",
                                  "Mother",
                                  "Father-in-Law",
                                  "Mother-in-Law",
                                ]
                              : []
                            // [
                            //   "Spouse",
                            //   "Son",
                            //   "Daughter",
                            //   "Father",
                            //   "Mother",
                            //   "Father-in-Law",
                            //   "Mother-in-Law",
                            // ]
                          }
                          name="relationship"
                          stateValue={formDetails}
                          setState={(newValue) =>
                            updateFormDetailsAtIndex(index, newValue)
                          }
                          typee="single"
                          asteriskIconn="true"
                        />
                        <EachCustomDropdown
                          placeholder="Gender"
                          dropdown={["Male", "Female"]}
                          name="gender"
                          stateValue={formDetails}
                          setState={(newValue) =>
                            updateFormDetailsAtIndex(index, newValue)
                          }
                          typee="single"
                          asteriskIconn="true"
                        />
                      </div>
                    </div>
                  ))}
              <div
                className={styles.submitBtn}
                style={{ marginBottom: "1rem" }}
                onClick={
                  Array.isArray(dependentsUpdate) &&
                  dependentsUpdate?.length > 0
                    ? updateDependents
                    : addDependents
                }
              >
                Save Dependents
              </div>
              <div
                className={styles.submitBtn}
                style={{ background: "white", color: "var(--font-color)" }}
                onClick={() => {
                  setTempPasswordStep(6);
                  predefinedToast("Successfully completed the registration!");
                }}
              >
                Skip, I have no dependents
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className={styles.rightSide}
        style={{
          width: tempPasswordStep === 6 ? "100%" : "50%",
          background: tempPasswordStep === 6 ? "var(--main-color)" : "",
        }}
      >
        {tempPasswordStep === 6 ? (
          <div className={styles.successContainer}>
            <img src={fullLogo} alt="Full Logo" />
            <div className={styles.title}>You have successfully</div>
            <ol>
              <li>Updated your password</li>
              <li>Verified your personal details</li>
              <li>Added your dependents</li>
            </ol>
            <div
              className={styles.submitBtn}
              onClick={() => {
                history.push("/employee/login");
              }}
            >
              Go To Login
            </div>
          </div>
        ) : (
          <div className={styles.mainContainer}>
            <div className={styles.title}>
              Great News {isProfileFound?.employee_name}!{" "}
            </div>
            <div className={styles.desc}>
              {companyProfileDetails?.employee_company_name
                ? companyProfileDetails.employee_company_name
                : ""}{" "}
              is adding you to their Raha Coverage Plan. In a couple of steps,
              you will have your own Raha account where you can;
            </div>

            <div className={styles.allInsurance}>
              <div
                className={styles.eachInsurance}
                style={{
                  display:
                    isProfileFound?.gmc_grade?.toLowerCase() === "na"
                      ? "none"
                      : "",
                }}
              >
                <div className={styles.title}>Health Insurance</div>
                <div className={styles.boxContainer}>
                  <div className={styles.eachItem}>
                    <div className={styles.title}>Family definition</div>
                    <div className={styles.valueItem}>
                      {isProfileFound?.family_definition
                        ? isProfileFound?.family_definition
                        : ""}
                    </div>
                  </div>
                  <div className={styles.eachItem}>
                    <div className={styles.title}>Sum Insured</div>
                    <div className={styles.valueItem}>
                      <span>
                        {isProfileFound?.gmc_grade
                          ? isProfileFound?.gmc_grade
                          : ""}
                      </span>
                      <span>
                        {isProfileFound?.gmc_si
                          ? `₹${isProfileFound?.gmc_si}`
                          : "₹"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={styles.eachInsurance}
                style={{
                  display:
                    isProfileFound?.gpa_grade?.toLowerCase() === "na"
                      ? "none"
                      : "",
                }}
              >
                <div className={styles.title}>Accident Insurance</div>
                <div className={styles.boxContainer}>
                  <div className={styles.eachItem}>
                    <div className={styles.title}>Sum Insured</div>
                    <div className={styles.valueItem}>
                      <span>
                        {isProfileFound?.gpa_grade
                          ? isProfileFound?.gpa_grade
                          : ""}
                      </span>
                      <span>
                        {isProfileFound?.gpa_si
                          ? `₹${isProfileFound?.gpa_si}`
                          : "₹"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={styles.eachInsurance}
                style={{
                  display:
                    isProfileFound?.gtli_grade?.toLowerCase() === "na"
                      ? "none"
                      : "",
                }}
              >
                <div className={styles.title}>Life Insurance</div>
                <div className={styles.boxContainer}>
                  <div className={styles.eachItem}>
                    <div className={styles.title}>Sum Insured</div>
                    <div className={styles.valueItem}>
                      <span>
                        {isProfileFound?.gtli_grade
                          ? isProfileFound?.gtli_grade
                          : ""}
                      </span>
                      <span>
                        {isProfileFound?.gtli_si
                          ? `₹${isProfileFound?.gtli_si}`
                          : "₹"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TempLoginEmployees;

export const EachCustomInputEditable = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  image,
  indexx,
  asteriskIconn,
  validation,
  callAPI,
  editEnabled,
  setEditEnabled,
}) => {
  return (
    <div className={classNames.eachInput}>
      {title && (
        <div className={classNames.title}>
          {title}{" "}
          <span
            onClick={() => {
              if (!editEnabled) {
                document.getElementById("inputBox" + title).focus();
              }
              setEditEnabled((prev) => {
                if (prev) {
                  return false;
                } else {
                  return `editEnabled${title}`;
                }
              });
            }}
          >
            {editEnabled == `editEnabled${title}` ? "X" : <MdOutlineEdit />}
          </span>
        </div>
      )}
      <div
        style={{
          border: image ? "1px solid #e5e5e566" : "",
          borderRadius: image ? "10px" : "",
          paddingLeft: image ? "1rem" : "",
          width: image ? "100%" : "",
          pointerEvents: editEnabled === `editEnabled${title}` ? "" : "none",
        }}
      >
        {image && <img src={image} alt={image} />}{" "}
        <input
          className={classNames.inputContainer}
          placeholder={
            editEnabled === `editEnabled${title}` ? placeholder : "-"
          }
          name={name}
          type="text"
          id={"inputBox" + title}
          value={
            indexx
              ? stateValue[name][indexx]
              : name
              ? stateValue?.hasOwnProperty(name)
                ? stateValue[name]
                : ""
              : stateValue
          }
          onChange={(event) => {
            if (name == "phone_number") {
              if (/^-?\d+$/.test(event?.target?.value)) {
                if (!validation) {
                  if (indexx) {
                    let array = stateValue[name];
                    array[Number(indexx)] = event?.target?.value;
                    console.log(array, "array valuee");
                    setState({ ...stateValue, [name]: array });
                  } else {
                    setState({ ...stateValue, [name]: event?.target?.value });
                  }
                } else if (validation) {
                  if (
                    validation?.type === "length" &&
                    event?.target?.value?.length <= validation?.length
                  ) {
                    if (indexx) {
                      let array = stateValue[name];
                      array[Number(indexx)] = event?.target?.value;
                      console.log(array, "array valuee");
                      setState({ ...stateValue, [name]: array });
                    } else {
                      setState({ ...stateValue, [name]: event?.target?.value });
                    }
                  }
                }
              }
            } else {
              if (!validation) {
                if (indexx) {
                  let array = stateValue[name];
                  array[Number(indexx)] = event?.target?.value;
                  console.log(array, "array valuee");
                  setState({ ...stateValue, [name]: array });
                } else {
                  setState({ ...stateValue, [name]: event?.target?.value });
                }
              } else if (validation) {
                if (
                  validation?.type === "length" &&
                  event?.target?.value?.length <= validation?.length
                ) {
                  if (indexx) {
                    let array = stateValue[name];
                    array[Number(indexx)] = event?.target?.value;
                    console.log(array, "array valuee");
                    setState({ ...stateValue, [name]: array });
                  } else {
                    setState({ ...stateValue, [name]: event?.target?.value });
                  }
                }
              }
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              setState({
                ...stateValue,
                [name]: stateValue[name]?.slice(0, -1),
              });
            }
          }}
          style={{
            border: image ? "none" : "",
          }}
        />
      </div>
      {asteriskIconn && (
        <img
          src={asteriskIcon}
          alt="asteriskIcon"
          className={classNames.asteriskIcon}
        />
      )}
    </div>
  );
};

export const EachCustomDropdownEditable = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  typee,
  placeholder,
  indexValue,
  objName,
  option,
  currentValue,
  indexx,
  asteriskIconn,
  typeee,
  editEnabled,
  setEditEnabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      // style={{ minWidth: isOpen ? "250px" : "" }}
    >
      {title && (
        <div className={classNames.title}>
          {title}{" "}
          <span
            onClick={() => {
              setEditEnabled((prev) => {
                if (prev) {
                  return false;
                } else {
                  return `editEnabled${title}`;
                }
              });
            }}
          >
            {editEnabled == `editEnabled${title}` ? "X" : <MdOutlineEdit />}
          </span>
        </div>
      )}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: !editEnabled
            ? "none"
            : dropdown?.length < 1
            ? "none"
            : "",
          zIndex: isOpen ? "3" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {option === "alreadySet"
              ? name && indexx
                ? stateValue[name][indexx]
                : stateValue[name] === true
                ? "Applicable"
                : stateValue[name] === false
                ? "Not Applicable"
                : stateValue[name]
              : selectedOption
              ? selectedOption
              : title
              ? title
              : placeholder
              ? placeholder
              : "Select an option"}
          </span>
          <span
            style={{ visibility: typeee === "removeArrow" ? "hidden" : "" }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li style={{ display: dropdown?.length < 3 ? "none" : "" }}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {typee === "arrayObj" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    return row
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase());
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState((prev) => {
                            return prev?.map((item, i) => {
                              if (i === indexValue) {
                                return { ...item, [name]: eachitem };
                              }
                              return item;
                            });
                          });
                        }}
                        key={eachitem + index}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : typee === "single" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          console.log(eachitem, "eachitem", name);

                          if (name) {
                            setState({
                              ...stateValue,
                              [name]: eachitem,
                            });
                            // setState((prev) => {
                            //   return { ...prev, [name]: eachitem };
                            // });
                          } else {
                            setState(eachitem);
                          }
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : typee === "objVal" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem[objName]);
                          setState({
                            ...stateValue,
                            [name]: eachitem[objName],
                          });
                        }}
                        key={eachitem[objName] + index}
                      >
                        {/* <img src={eachitem} alt={eachitem} /> */}
                        {eachitem[objName]}
                      </li>
                    );
                  })
              : typee === "obj" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                      >
                        <img src={eachitem} alt={eachitem} />
                        {eachitem}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {/* {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"allCompanies"}
              >
                All Companies
              </li>
            ) : (
              ""
            )} */}
          </ul>
        )}
      </div>
      {asteriskIconn && (
        <img
          src={asteriskIcon}
          alt="asteriskIcon"
          className={classNames.asteriskIcon}
        />
      )}
    </div>
  );
};

export const EachCustomDatePickerEditable = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  mandatory,
  typee,
  indexValue,
  asteriskIconn,
  editEnabled,
  setEditEnabled,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (datepickerRef.current) {
      const currentDate = new Date();

      const minDate = new Date(currentDate);
      const maxDate = new Date(currentDate);

      // Calculate minDate for a 80-year-old person
      minDate.setFullYear(currentDate.getFullYear() - 80);

      // Calculate maxDate for a 25-year-old person
      maxDate.setFullYear(currentDate.getFullYear() - 25);

      const options = {
        dateFormat: "d-m-Y",
        // Other options here
        minDate: minDate,
        maxDate: maxDate,
        onChange: function (selectedDates, dateStr, instance) {
          console.log("Selected start date:", dateStr, datepickerRef.current);
          if (typee == "spreadObj") {
            setState((prev) => {
              return {
                ...prev,
                [name]: dateStr,
              };
            });
          } else if (typee === "arrayObj") {
            setState((prev) => {
              return prev?.map((item, i) => {
                if (i === indexValue) {
                  return { ...item, [name]: dateStr };
                }
                return item;
              });
            });
          } else if (name) {
            setState({
              ...stateValue,
              [name]: dateStr,
            });
            // setState((prev) => {
            //   return { ...prev, [name]: dateStr };
            // });
          } else {
            setState(dateStr);
          }
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div className={classNames.eachInputDatePicker}>
      {title && (
        <div className={classNames.title}>
          {title}{" "}
          <span
            onClick={() => {
              if (!editEnabled) {
                document.getElementById("datepicker" + title).click();
              }
              setEditEnabled((prev) => {
                if (prev) {
                  return false;
                } else {
                  return `editEnabled${title}`;
                }
              });
            }}
          >
            {editEnabled === `editEnabled${title}` ? "X" : <MdOutlineEdit />}
          </span>
        </div>
      )}
      <input
        type="text"
        id={"datepicker" + title}
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        value={name ? stateValue[name] : stateValue ? stateValue : ""}
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
        style={{
          pointerEvents: editEnabled === `editEnabled${title}` ? "" : "none",
        }}
      />
      {asteriskIconn && (
        <img
          src={asteriskIcon}
          alt="asteriskIcon"
          className={classNames.asteriskIcon}
        />
      )}
    </div>
  );
};
