import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./healthflatcompanyprofile.module.scss";
import Axios from "axios";
import { BankContext } from "../../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import uploading from "../../../assest/images/icons/uploading.svg";
import dummyProfile from "../../../assest/images/icons/dummyProfile.svg";
import { AppContext } from "../../../context/AppContext";

//assets icon
import { ReactComponent as PropertyIcon } from "../../../assest/images/profile/property.svg";
import { ReactComponent as VehicleIcon } from "../../../assest/images/profile/vehicle.svg";
import { ReactComponent as WorldIcon } from "../../../assest/images/profile/world.svg";

import { uploadImageFunc } from "../../../utils/imageUpload";
import { useHistory } from "react-router-dom";
import { predefinedToast } from "../../../utils/toast";
import { Redirect } from "react-router-dom";

import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/material_green.css";
import { EachCustomProfileDropdown } from "../../../components/Custom";
import Skeleton from "react-loading-skeleton";
import NavbarV2 from "../../../components/NavbarV2";
import NavbarV3 from "../../../components/NavbarV3";
import { logoutToSameLogin } from "../../../assest/functions";

const SeePolicyRequirments = () => {
  const history = useHistory();
  const {
    email,
    token,
    dashboardHam,
    setDashboardHam,
    popularAction,
    setPopularAction,
    globalUserId,
    globalCompanyId,
    globalHrId,
    setUpdateLoading,
  } = useContext(BankContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [policyRequirements, setPolicyRequirements] = useState("");

  const [healthPolicyRequirements, setHealthPolicyRequirements] = useState("");
  const [healthPolicyRequirementsLoading, setHealthPolicyRequirementsLoading] =
    useState(false);
  const [accidentalPolicyRequirements, setAccidentalPolicyRequirements] =
    useState("");
  const [lifePolicyRequirements, setLifePolicyRequirements] = useState("");

  const [selectedProfileNav, setSelectedProfileNav] = useState(
    "Policy Requirements"
  );

  const [indianStatesDropdown, setIndianStatesDropdown] = useState("");
  const [createEmployeeSumInsured, setCreateEmployeeSumInsured] = useState("");
  const [corporateLead, setCorporateLead] = useState({
    hr_id: globalHrId
      ? globalHrId
      : localStorage.getItem("globalUserHrIdRaha")
      ? localStorage.getItem("globalUserHrIdRaha")
      : "",
    company_id: localStorage.getItem("globalCompanyIdRaha")
      ? localStorage.getItem("globalCompanyIdRaha")
      : globalCompanyId
      ? globalCompanyId
      : "",
    insurance_type: "Health",
    coverage_type: "Flat",
    covered_persons: [],
    sum_insured: [],
    currency: "INR",
  });

  useEffect(() => {
    Axios.get(`https://insurance.apimachine.com/insurance/general/getstates`)
      .then((response) => {
        console.log(response, "state dropdown response");
        if (response?.data?.status) {
          setIndianStatesDropdown(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error?.message, "state dropdown error");
      });
  }, []);

  useEffect(() => {
    setHealthPolicyRequirementsLoading(true);
    Axios.get(
      `https://insurance.apimachine.com/insurance/hr/get?hr_id=${globalHrId}`
    )
      .then((response) => {
        if (response?.data?.status && response?.data?.data?.length > 0) {
          console.log(
            response?.data?.data[0],
            "see policy requirments response"
          );
          setPolicyRequirements(response?.data?.data[0]);
          // setIndianStatesDropdown(response?.data?.data);
          let dataAPI = response?.data?.data[0]?.policyQuotationProfile;
          let health = dataAPI?.filter((eachItem) => {
            return eachItem?.insurance_type?.toLowerCase() == "health";
          });
          let life = dataAPI?.filter((eachItem) => {
            return eachItem?.insurance_type?.toLowerCase() == "life";
          });
          let accidental = dataAPI?.filter((eachItem) => {
            return eachItem?.insurance_type?.toLowerCase() == "accidental";
          });
          setHealthPolicyRequirements(health);
          setAccidentalPolicyRequirements(accidental);
          setLifePolicyRequirements(life);
          setHealthPolicyRequirementsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error?.message, "see policy requirments error");
        setHealthPolicyRequirementsLoading(false);
      });
  }, []);

  let healthOptions = [
    "Only The Employee",
    "Employee + Spouse",
    "Employee + Spouse + 2 Children",
    "Employee + Spouse + 2 Children + 2 Parents",
    "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws",
  ];

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.newCompanyProfileStep2Parent}>
      <NavbarV3
        dropdownItems={["Dashboard"]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setSearchQuery}
      />
      <div className={classNames.contentContainer}>
        {healthPolicyRequirementsLoading ? (
          <div className={classNames.loadingDiv}>
            <div className={classNames.title}>
              Health Insurance Requirements
            </div>
            <Skeleton width={120} height={25} />
            <div style={{ display: "flex", gap: "2rem" }}>
              <Skeleton
                width={150}
                height={25}
                style={{ marginTop: "1.5rem" }}
              />
              <Skeleton
                width={150}
                height={25}
                style={{ marginTop: "1.5rem" }}
              />
            </div>

            <div className={classNames.title} style={{ marginTop: "3rem" }}>
              Accident Insurance Requirements
            </div>
            <Skeleton width={120} height={25} />
            <div style={{ display: "flex", gap: "2rem" }}>
              <Skeleton
                width={150}
                height={25}
                style={{ marginTop: "1.5rem" }}
              />
              <Skeleton
                width={150}
                height={25}
                style={{ marginTop: "1.5rem" }}
              />
            </div>
            <div className={classNames.title} style={{ marginTop: "3rem" }}>
              Life Insurance Requirements
            </div>
            <Skeleton width={120} height={25} />
            <div style={{ display: "flex", gap: "2rem" }}>
              <Skeleton
                width={150}
                height={25}
                style={{ marginTop: "1.5rem" }}
              />
              <Skeleton
                width={150}
                height={25}
                style={{ marginTop: "1.5rem" }}
              />
            </div>
          </div>
        ) : (
          <div className={classNames.companyProfileStep2}>
            {healthPolicyRequirements?.length > 0 && (
              <>
                <div className={classNames.title}>
                  Health Insurance Requirements
                </div>
                <div className={classNames.inputContainers}>
                  <EachInputSelectOne
                    title="Coverage Type"
                    placeholder="Enter name..."
                    name="coverage_type"
                    stateValue={healthPolicyRequirements[0]?.coverage_type}
                    setState={setCorporateLead}
                    options={["Flat", "Graded"]}
                  />
                  <EachInputSelectMultiples
                    title="Select who will be covered in the health insurance policy"
                    name="covered_persons"
                    stateValue={healthPolicyRequirements[0]?.covered_persons}
                    setState={setCorporateLead}
                    options={healthOptions}
                  />
                  {healthPolicyRequirements[0]?.coverage_type == "Flat" ? (
                    <EachInput
                      title="How much coverage do you wish to offer to each employee?"
                      placeholder="Enter amount..."
                      name="sum_insured"
                      stateValue={healthPolicyRequirements[0]?.sum_insured}
                      setState={setCorporateLead}
                    />
                  ) : (
                    <EachProfileInputArray
                      title="How much coverage do you wish to offer to each employee?"
                      placeholder="Enter amount..."
                      dropdown=""
                      stateValue={healthPolicyRequirements[0]?.sum_insured}
                      setState={setCreateEmployeeSumInsured}
                      banner={"Health"}
                      name="sum_insured"
                      stateValueMain={corporateLead}
                    />
                  )}
                </div>
              </>
            )}
            {accidentalPolicyRequirements?.length > 0 && (
              <>
                <div className={classNames.title} style={{ marginTop: "3rem" }}>
                  Accident Insurance Requirements
                </div>
                <div className={classNames.inputContainers}>
                  <EachInputSelectOne
                    title="Coverage Type"
                    placeholder="Enter name..."
                    name="coverage_type"
                    stateValue={accidentalPolicyRequirements[0]?.coverage_type}
                    setState={setCorporateLead}
                    options={["Flat", "Graded"]}
                  />
                  {accidentalPolicyRequirements[0]?.coverage_type == "Flat" ? (
                    <EachInput
                      title="How much coverage do you wish to offer to each employee?"
                      placeholder="Enter amount..."
                      name="sum_insured"
                      stateValue={accidentalPolicyRequirements[0]?.sum_insured}
                      setState={setCorporateLead}
                    />
                  ) : (
                    <EachProfileInputArray
                      title="How much coverage do you wish to offer to each employee?"
                      placeholder="Enter amount..."
                      dropdown=""
                      stateValue={accidentalPolicyRequirements[0]?.sum_insured}
                      setState={setCreateEmployeeSumInsured}
                      banner={"Health"}
                      name="sum_insured"
                      stateValueMain={corporateLead}
                    />
                  )}
                </div>
              </>
            )}{" "}
            {lifePolicyRequirements?.length > 0 && (
              <>
                <div className={classNames.title} style={{ marginTop: "3rem" }}>
                  Life Insurance Requirements
                </div>
                <div className={classNames.inputContainers}>
                  <EachInputSelectOne
                    title="Coverage Type"
                    placeholder="Enter name..."
                    name="coverage_type"
                    stateValue={lifePolicyRequirements[0]?.coverage_type}
                    setState={setCorporateLead}
                    options={["Flat", "Graded"]}
                  />
                  {lifePolicyRequirements[0]?.coverage_type == "Flat" ? (
                    <EachInput
                      title="How much coverage do you wish to offer to each employee?"
                      placeholder="Enter amount..."
                      name="sum_insured"
                      stateValue={lifePolicyRequirements[0]?.sum_insured}
                      setState={setCorporateLead}
                    />
                  ) : (
                    <EachProfileInputArray
                      title="How much coverage do you wish to offer to each employee?"
                      placeholder="Enter amount..."
                      dropdown=""
                      stateValue={lifePolicyRequirements[0]?.sum_insured}
                      setState={setCreateEmployeeSumInsured}
                      banner={"Health"}
                      name="sum_insured"
                      stateValueMain={corporateLead}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SeePolicyRequirments;

const EachInput = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  banner,
}) => {
  return (
    <div className={classNames.eachInput} style={{ pointerEvents: "none" }}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.inputDiv}>
        <input
          className={classNames.inputContainer}
          placeholder={placeholder}
          name={name}
          value={stateValue?.length > 0 && stateValue[0]}
          onChange={(event) => {
            setState({ ...stateValue, [name]: event?.target?.value });
          }}
          style={{ pointerEvents: stateValue?.length > 0 ? "none" : "" }}
        />
        <div
          className={classNames.selectedBanner}
          style={{
            background: stateValue?.length > 0 ? "var(--main-color)" : "",
            color: "white",
          }}
        >
          Flat
        </div>
      </div>
    </div>
  );
};
const EachInputSelectOne = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useState(stateValue);
  return (
    <div
      className={classNames.eachInputPickOne}
      style={{ pointerEvents: "none" }}
    >
      <div className={classNames.title}>{title}</div>
      <div
        classNames={classNames.selectOneAllOptions}
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          fontSize: "1rem",
          marginTop: "0.5rem",
        }}
      >
        {options?.length > 0
          ? options?.map((eachOption, index) => {
              return (
                <div
                  key={eachOption + index}
                  onClick={() => {
                    setSelectedValue(eachOption);
                    setState({ ...stateValue, [name]: eachOption });
                  }}
                  style={{
                    opacity: selectedValue == eachOption ? "" : "0.5",
                    textAlign: "center",
                    width: "max-content",
                    padding: "1rem 2rem",
                    cursor: "pointer",
                    fontSize: "0.9rem",
                    border:
                      selectedValue == eachOption
                        ? "1px solid var(--main-color)"
                        : "1px solid #e5e5e5",
                    borderRadius: "35px",
                    transition: "all linear 0.3s",
                    marginTop: "1rem",
                    fontWeight: "550",
                    background:
                      selectedValue == eachOption ? "var(--main-color)" : "",
                    color: selectedValue == eachOption ? "white" : "",
                    display: selectedValue !== eachOption ? "none" : "",
                  }}
                >
                  {eachOption}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

const EachInputSelectMultiples = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useState(stateValue);
  return (
    <div
      className={classNames.eachInputPickOne}
      style={{ pointerEvents: "none" }}
    >
      <div className={classNames.title}>{title}</div>
      <div
        classNames={classNames.selectOneAllOptions}
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          fontSize: "1rem",
          marginTop: "0.5rem",
        }}
      >
        {options?.length > 0
          ? options?.map((eachOption, index) => {
              return (
                <div
                  key={eachOption + index}
                  onClick={() => {
                    if (
                      selectedValue?.includes(eachOption) ||
                      stateValue[name]?.includes(eachOption)
                    ) {
                      setSelectedValue(
                        selectedValue?.filter((item) => item !== eachOption)
                      );
                      let { covered_persons, ...stateValuee } = stateValue;
                      let cv = covered_persons?.filter(
                        (item) => item !== eachOption
                      );
                      setState({ ...stateValuee, covered_persons: cv });
                    } else {
                      setSelectedValue([...selectedValue, eachOption]);
                      setState({
                        ...stateValue,
                        [name]: [...stateValue[name], eachOption],
                      });
                    }
                  }}
                  style={{
                    opacity: selectedValue?.includes(eachOption) ? "" : "0.5",
                    textAlign: "center",
                    width: "max-content",
                    padding: "1rem 2rem",
                    cursor: "pointer",
                    fontSize: "0.9rem",
                    border: selectedValue?.includes(eachOption)
                      ? "1px solid var(--main-color)"
                      : "1px solid #e5e5e5",
                    borderRadius: "35px",
                    transition: "all linear 0.3s",
                    marginTop: "1rem",
                    background: selectedValue?.includes(eachOption)
                      ? "var(--main-color)"
                      : "",
                    color: selectedValue?.includes(eachOption) ? "white" : "",
                    fontWeight: "550",
                    display: !selectedValue?.includes(eachOption) ? "none" : "",
                  }}
                >
                  {eachOption}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

const EachProfileInputArray = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
  banner,
  name,
  stateValueMain,
}) => {
  const [currentInputValue, setCurrentInputValue] = useState("");
  const [allInputs, setAllInputs] = useState(stateValue);
  const [numberOfEmails, setNumberOfEmails] = useState(stateValue?.length);

  const handleAddEmail = () => {
    setAllInputs((prev) => [...prev, currentInputValue]);
    setState((prev) => [...prev, currentInputValue]); // Add an empty object to the array

    setCurrentInputValue("");
    setNumberOfEmails((prev) => prev + 1);
  };

  return (
    <div
      className={classNames.eachProfileInput}
      style={{ pointerEvents: "none" }}
    >
      <div className={classNames.title}>{title}</div>
      {Array.from({ length: numberOfEmails })?.map((email, index) => (
        <div className={classNames.addByEmail} key={index}>
          <div className={classNames.inputParent}>
            <div
              className={classNames.inputDiv}
              key={index}
              style={{
                pointerEvents: allInputs?.length == index ? "" : "none",
              }}
            >
              <input
                className={classNames.inputContainer}
                placeholder={placeholder}
                name={name}
                value={allInputs[index]}
                onChange={(event) =>
                  // handleEmailChange(index, "employee_email", event.target.value)
                  setCurrentInputValue(event.target.value)
                }
              />
              <div
                className={classNames.selectedBanner}
                style={{
                  background:
                    allInputs?.length == index ? "" : "var(--main-color)",
                  color: allInputs?.length == index ? "" : "white",
                }}
              >
                {"Grade " + (index + 1)}
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* {allInputs?.length == 0 && (
        <div className={classNames.addByEmail}>
          <div className={classNames.inputParent}>
            <div className={classNames.inputDiv}>
              <input
                className={classNames.inputContainer}
                placeholder={placeholder}
                name={name}
                onChange={(event) =>
                  // handleEmailChange(index, "employee_email", event.target.value)
                  setCurrentInputValue(event.target.value)
                }
              />
              <div
                className={classNames.selectedBanner}
                style={{
                  background:
                    allInputs?.length - 1 > numberOfEmails
                      ? "var(--main-color)"
                      : "",
                }}
              >
                {"Grade 1"}
              </div>
            </div>
            <div
              className={classNames.addButton}
              style={{ pointerEvents: currentInputValue ? "" : "none" }}
              onClick={handleAddEmail}
            >
              +
            </div>
          </div>
        </div>
      )} */}

      {dropdown && <IoMdArrowDropdown />}
    </div>
  );
};
