import React, { useState, useEffect, useContext } from "react";
import styles from "./policyDetails.module.scss";
import Axios from "axios";
import { getCurrentDate } from "../../../../assest/functions";
import { predefinedToast } from "../../../../utils/toast";
import { BankContext } from "../../../../context/Context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import fullLogo from "../../../../assest/images/fullLogo.svg";

const PolicyDetails = ({
  selectedLead,
  selectedEmployee,
  seeDetails,
  selectedHrId,
  type,
  setRefresh,
}) => {
  const history = useHistory();
  const { globalHrId } = useContext(BankContext);
  const [selectedUserPlocies, setSelectedUserPlocies] = useState([]);
  const [submitAPIResponse, setSubmitAPIResponse] = useState("");
  const getPolicies = () => {
    Axios.get(
      type == "seePolicy" || type == "removePolicy"
        ? `https://api-dev.rahainsure.com/api/v1/client-user/employee/?employee_email_id=${selectedEmployee?.user_company_email}`
        : `https://api-dev.rahainsure.com/api/v1/policy?hr_id=${
            seeDetails ? selectedEmployee?.hr_id : selectedLead?.hr_id
          }`
    ).then(({ data }) => {
      if (data.status) {
        console.log(data, "data getPolicies");
        if (
          (type == "seePolicy" || type == "removePolicy") &&
          data?.data?.length > 0
        ) {
          setSelectedUserPlocies(data?.data[0]?.policies);
        } else {
          setSelectedUserPlocies(data.data);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedLead && selectedEmployee) {
      getPolicies();
    }
  }, [selectedLead, selectedEmployee]);

  async function addPolicyToEmployee(
    companyEmail,
    employeeID,
    policyGrade,
    companyEmpID,
    policyID,
    policyNumber
  ) {
    let obj;
    let URL;
    if (
      selectedEmployee?.endorsement == true ||
      selectedEmployee?.endorsement == "true" ||
      selectedEmployee?.endorsement == "True"
    ) {
      URL =
        "https://api-dev.rahainsure.com/api/v1/employee-spreadsheet/add-employee/endorsement";
      obj = {
        hr_id: selectedEmployee?.hr_id,
        employee_data: [
          {
            employee_id: employeeID,
            company_employee_id: companyEmpID,
            date_of_birth: selectedEmployee?.date_of_birth,
            date_of_joining: getCurrentDate(),
            employee_email_id: companyEmail,
            policy_number: policyNumber,
            Grade: policyGrade,
          },
        ],
      };
    } else {
      URL = `https://api-dev.rahainsure.com/api/v1/policy/policy-employee-map/create/bulk`;
      obj = {
        hr_id: selectedEmployee?.hr_id,
        employee_data: [
          {
            employee_id: employeeID,
            company_employee_id: companyEmpID,
            employee_email_id: companyEmail,
            policy_number: policyNumber,
            Grade: policyGrade,
          },
        ],
      };
    }
    try {
      const response = await Axios.post(URL, obj);
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
        if (
          selectedEmployee?.endorsement == true ||
          selectedEmployee?.endorsement == "true" ||
          (selectedEmployee?.endorsement == "True" &&
            response?.data?.data?.length > 0)
        ) {
          setSubmitAPIResponse(response?.data?.data[0]);
        } else {
          setTimeout(() => {
            setRefresh();
          }, 1500);
        }
      } else {
        predefinedToast(response?.data?.message);
      }
      console.log(response, "Add policy to employee response");
    } catch (error) {
      predefinedToast("Error while adding policy to employee");
      console.log(error?.message, "Add policy to employee error");
    }
  }

  async function removePolicyForEmployee(
    companyEmail,
    employeeID,
    policyGrade,
    companyEmpID,
    policyID,
    policyNumber
  ) {
    let obj = {
      hr_id: selectedHrId,
      employee_data: [
        {
          employee_id: employeeID,
          date_of_birth: selectedEmployee?.date_of_birth,
          date_of_leaving: getCurrentDate(),
          policy_number: policyNumber,
          Grade: policyGrade,
        },
      ],
    };
    try {
      let response = await Axios.post(
        `https://api-dev.rahainsure.com/api/v1/employee-spreadsheet/remove-employee/endorsement`,
        obj
      );
      predefinedToast("Removed selected policy...");
      setTimeout(() => {
        setRefresh();
      }, 1500);
    } catch (error) {
      console.log(error?.message, "Remove policy for employee");
    }
  }

  return (
    <div>
      <div className={styles.tabWrapper}>
        {(type == "seePolicy" || type == "removePolicy") &&
        Array.isArray(selectedUserPlocies) &&
        selectedUserPlocies?.length > 0
          ? selectedUserPlocies?.map((item) => (
              <div className={styles.tabItem}>
                <div className={styles.row1}>
                  <img
                    src={
                      item?.policy_id?.insurance_company_id
                        ?.insurance_company_logo_url
                    }
                    alt=""
                    className={styles.compLogo}
                  />
                  <div className={styles.compDetails}>
                    <div>{item?.policy_type}</div>
                    <div>
                      Policy By:{" "}
                      {
                        item?.policy_id?.insurance_company_id
                          ?.insurance_company_display_name
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.row2}>
                  <div>Start Date: {item?.policy_id?.start_date}</div>
                  <div>Policy Number: {item?.policy_id?.policy_number}</div>
                </div>
                <div className={styles.row2}>
                  <div>Valid Till: {item?.policy_id?.end_date}</div>
                </div>
                <div className={styles.row3}>
                  <div>Coverage Type</div>
                  <div className={styles.innerCardWrapper}>
                    <div
                      className={styles.innerCard}
                      onClick={() => {
                        if (type == "removePolicy") {
                          removePolicyForEmployee(
                            selectedEmployee?.user_company_email,
                            selectedEmployee?._id,
                            item?.policy_grade,
                            selectedEmployee?.user_employee_id,
                            item?.policy_id?._id,
                            item?.policy_number
                          );
                        } else if (type == "addPolicy") {
                          addPolicyToEmployee(
                            selectedEmployee?.user_company_email,
                            selectedEmployee?._id,
                            item?.policy_grade,
                            selectedEmployee?.user_employee_id,
                            item?.policy_id?._id,
                            item?.policy_number
                          );
                        } else {
                          return !seeDetails ? addPolicyToEmployee(item) : null;
                        }
                      }}
                    >
                      <div>{item?.policy_grade}</div>
                      <div>{item?.policy_id?.sum_insured}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : Array.isArray(selectedUserPlocies) &&
            selectedUserPlocies?.length > 0 &&
            selectedUserPlocies?.map((item) => (
              <div className={styles.tabItem}>
                <div className={styles.row1}>
                  <img
                    src={item?.insurance_company_id?.insurance_company_logo_url}
                    alt=""
                    className={styles.compLogo}
                  />
                  <div className={styles.compDetails}>
                    <div>{item?.policy_type}</div>
                    <div>
                      Policy By:{" "}
                      {item?.insurance_company_id?.insurance_company_name}
                    </div>
                  </div>
                </div>
                <div className={styles.row2}>
                  <div>Start Date: {item?.start_date}</div>
                  <div>Policy Number: {item?.policy_number}</div>
                </div>
                <div className={styles.row2}>
                  <div>Valid Till: {item?.end_date}</div>
                </div>
                <div className={styles.row3}>
                  <div>Coverage Type</div>
                  <div className={styles.innerCardWrapper}>
                    {item?.policy_rate_card?.map((item1, index) => (
                      <div
                        className={styles.innerCard}
                        onClick={() => {
                          if (type == "removePolicy") {
                            removePolicyForEmployee(
                              selectedEmployee?.user_company_email,
                              selectedEmployee?._id,
                              item1?.policy_grade,
                              selectedEmployee?.user_employee_id,
                              item1?._id,
                              item1?.policy_number
                            );
                          } else if (type == "addPolicy") {
                            addPolicyToEmployee(
                              selectedEmployee?.user_company_email,
                              selectedEmployee?._id,
                              item1?.policy_grade,
                              selectedEmployee?.user_employee_id,
                              item1?._id,
                              item1?.policy_number
                            );
                          } else {
                            return !seeDetails
                              ? addPolicyToEmployee(item1)
                              : null;
                          }
                        }}
                      >
                        <div>{item1?.policy_grade}</div>
                        <div>{item1?.sum_insured}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        {submitAPIResponse && (
          <div className={styles.shadowDiv}>
            <div className={styles.balanceDiv}>
              <img src={fullLogo} alt="" className={styles.logo} />
              <div
                className={styles.desc}
              >{`Your CD balance has been debited ${submitAPIResponse?.cd_balance_debited?.toFixed(
                2
              )} INR for this endorsement`}</div>
              <div className={styles.btnsContainer}>
                <button
                  className={styles.coloredBtn}
                  onClick={() => {
                    history.push("/app/coverfi/overview", {
                      from: history.location,
                    });
                  }}
                >
                  Go To CD Balance
                </button>
                <button
                  className={styles.transparentBtn}
                  onClick={() => {
                    setSubmitAPIResponse("");
                    setTimeout(() => {
                      setRefresh();
                    }, 1500);
                  }}
                >
                  Stay On This Page
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PolicyDetails;

export const SinglePolicyCards = ({
  type,
  item,
  allEmployees,
  refreshPage,
  name,
  setOtherSubCards,
}) => {
  async function bulkAddPolicToEmployees(policyNumber, grade) {
    const finalData = await allEmployees?.map((item) => ({
      employee_id: item?._id,
      company_employee_id: item?.user_employee_id,
      employee_email_id: item?.user_company_email,
      policy_number: policyNumber,
      Grade: grade,
    }));

    let finalObj = {
      hr_id: allEmployees[0]?.hr_id,
      employee_data: finalData,
    };

    try {
      let response = await Axios.post(
        "https://api-dev.rahainsure.com/api/v1/policy/policy-employee-map/create/bulk",
        finalObj
      );
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
        refreshPage((prev) => !prev);
      } else {
        predefinedToast("Error while adding policies to employees");
      }
    } catch (error) {
      console.log(error?.message, "Bulk add policy to employee error");
      predefinedToast("Error while adding policies to employees");
    }

    console.log(finalObj, "bulkAddPolicToEmployees finalData", allEmployees);
  }
  return (
    <div
      className={styles.tabItem}
      style={{ height: "unset", marginBottom: "1rem" }}
      onClick={() => {
        if (name == "policyComplete") {
          console.log("Bulk Assign working", item, allEmployees);
          setOtherSubCards({
            type: "Bulk Assign Employees List",
            data: {
              policyNum: item?.policy_number,
              companyId: item?.company_id?._id,
              hrId: allEmployees?.length > 0 ? allEmployees[0]?.hr_id : "",
            },
          });
        }
      }}
    >
      <div className={styles.row1}>
        <img
          src={item?.insurance_company_id?.insurance_company_logo_url}
          alt=""
          className={styles.compLogo}
        />
        <div className={styles.compDetails}>
          <div>{item?.policy_type}</div>
          <div>
            Policy By: {item?.insurance_company_id?.insurance_company_name}
          </div>
        </div>
      </div>
      <div className={styles.row2}>
        <div>Start Date: {item?.start_date}</div>
        <div>Policy Number: {item?.policy_number}</div>
      </div>
      <div className={styles.row2}>
        <div>Valid Till: {item?.end_date}</div>
      </div>
      <div className={styles.row3}>
        <div>Coverage Type</div>
        <div
          className={styles.innerCardWrapper}
          style={{ height: "unset", overflow: "unset" }}
        >
          {item?.policy_rate_card?.map((item1, index) => (
            <div
              className={styles.innerCard}
              onClick={() => {
                if (
                  type == "addPolicyToAll" &&
                  allEmployees?.length > 0 &&
                  name == "policy"
                ) {
                  bulkAddPolicToEmployees(
                    item?.policy_number,
                    item1?.policy_grade
                  );
                }
              }}
            >
              <div>{item1?.policy_grade}</div>
              <div>{item1?.sum_insured}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
