import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./navbar.module.scss";

//assets
import fullLogo from "../../assest/images/fullLogo.svg";
import indianFlag from "../../assest/images/icons/indianFlag.svg";
import { ReactComponent as DownArrow } from "../../assest/images/icons/down.svg";
import { ReactComponent as UpArrow } from "../../assest/images/icons/up.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FALSE } from "sass";
import Axios from "axios";
import { BankContext } from "../../context/Context";

const Navbar = ({ isScrolled }) => {
  const history = useHistory();

  const { setName, setGlobalUserId, setGlobalHrId, setGlobalCompanyId } =
    useContext(BankContext);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setStateDropdownOpened(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [stateDropdown, setStateDropdown] = useState("");
  const [stateDropdownOpened, setStateDropdownOpened] = useState(false);
  const [selectedState, setSelectedState] = useState("Telangana (TS)");

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    Axios.get(`https://insurance.apimachine.com/insurance/general/getstates`)
      .then((response) => {
        console.log(response, "state dropdown response");
        if (response?.data?.status) {
          setStateDropdown(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error?.message, "state dropdown error");
      });
  }, []);

  function logoutFunction() {
    setName("");
    setGlobalUserId("");
    setGlobalHrId("");
    setGlobalCompanyId("");
    localStorage.clear();
  }

  const scrollToProductsSection = () => {
    document.getElementById("productsSection").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div
      className={classNames.navbar}
      style={{
        background: isScrolled ? "var(--main-color)" : "",
        boxShadow: isScrolled ? "0px 2px 2px 0px #00000026" : "",
      }}
    >
      <div className={classNames.topSection}>
        <div
          className={classNames.categories}
          style={{ color: isScrolled ? "white" : "" }}
        >
          <Link onClick={logoutFunction} to="/employer/login">
            For Employers
          </Link>
          <Link onClick={logoutFunction} to="/employee/login">
            For Employees
          </Link>
          <div onClick={logoutFunction}>
            For Associates
            <div className={classNames.navOptionsDropdown}>
              <Link to="/associates/login">For Associates</Link>
              <Link to="/teamlead/login">For Team leads</Link>
              <Link to="/verticalhead/login">For Vertical heads</Link>
              <Link to="/businesshead/login">For Business heads</Link>
              <Link to="/admin/login">For Super admin</Link>
              <Link to="/affiliates/login">For Affiliates</Link>
            </div>
          </div>
          {/* <Link onClick={logoutFunction} to="/admin/login">
            BrokerApp
          </Link> */}
        </div>
        <div
          className={classNames.selectCountry}
          style={{ color: isScrolled ? "white" : "" }}
          ref={dropdownRef}
        >
          <span
            onClick={() => {
              setStateDropdownOpened((prev) => !prev);
            }}
            style={{ color: isScrolled ? "white" : "" }}
          >
            {selectedState}
          </span>
          {stateDropdownOpened ? (
            <UpArrow
              className={
                isScrolled ? classNames.downArrowWhite : classNames.downArrow
              }
            />
          ) : (
            <DownArrow
              className={
                isScrolled ? classNames.downArrowWhite : classNames.downArrow
              }
            />
          )}
          <div
            className={classNames.dropdown}
            style={{ display: stateDropdownOpened ? "" : "none" }}
          >
            <input
              type="text"
              placeholder="Find Your State..."
              onChange={(event) => {
                setSearchQuery(event?.target?.value);
              }}
            />
            {stateDropdown?.length > 0 &&
              stateDropdown
                ?.filter((eachItem) =>
                  eachItem?.state?.toLowerCase()?.includes(searchQuery)
                )
                ?.map((eachItem, index) => {
                  return (
                    <div
                      key={eachItem?.state + index}
                      onClick={() => {
                        setSelectedState(eachItem?.state);
                        setStateDropdownOpened(false);
                      }}
                    >
                      {eachItem?.state}
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      <div className={classNames.bottomSection}>
        <div
          className={classNames.logoDiv}
          onClick={() => {
            history.push("/");
          }}
        >
          <img src={fullLogo} alt="fullLogo" />
        </div>
        <div className={classNames.navOptions}>
          <div className={classNames.firstNavOptions}>
            <div
              className={
                window?.location.pathname?.includes("about")
                  ? classNames.selectedOption
                  : ""
              }
            >
              About
            </div>
            <div
              onClick={() => {
                // history.push("/rahax/about#productsSection");
                scrollToProductsSection();
              }}
            >
              Products
            </div>
            {/* <div>Marketplace</div> */}
            {/* <div>Resources</div> */}
          </div>
          <div className={classNames.btnsContainer}>
            <Link to="/login" style={{ display: "none" }}>
              Login
            </Link>
            <Link to="/getquote">Request a quote</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
