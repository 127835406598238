import axios from "axios";
import * as jose from "jose";
import { predefinedToast } from "./toast";

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "nitin.posani@gmail.com"; // email of the developer.

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile?.type,
    lastModified: originalFile?.lastModified,
  });
}

const signJwt = async (fileName, emailDev, secret) => {
  try {
    const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
      .setProtectedHeader({ alg: "HS512" })
      .setIssuer("gxjwtenchs512")
      .setExpirationTime("10m")
      .sign(new TextEncoder().encode(secret));
    return jwts;
  } catch (error) {
    console.log(error, "kjbedkjwebdw");
  }
};

export const uploadImageFunc = async (e, setImage, setLoading, name) => {
  setLoading(true);
  const fileName = `${new Date().getTime()}${e.target?.files[0]?.name?.substr(
    e?.target?.files[0]?.name?.lastIndexOf(".")
  )}`;
  const formData = new FormData();
  const file = renameFile(e.target.files[0], fileName);
  formData.append("files", file);
  const path_inside_brain = "root/";

  const jwts = await signJwt(fileName, emailDev, secret);

  let { data } = await axios.post(
    `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
    formData,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  // let { data } = await axios.post(
  //   `https://insurance.apimachine.com/insurance/general/upload`,
  //   formData,
  //   {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   }
  // );

  console.log(data, "drive uploaded img");
  if (data?.status && data?.payload?.url) {
    setLoading(false);
    if (name) {
      setImage((prev) => {
        return { ...prev, [name]: data?.payload?.url };
      });
    }
    return data?.payload?.url;
  } else {
    predefinedToast("Error while uploading file");
    setLoading(false);
  }
};

export const uploadFileFunc = async (e, setFile, setLoading, eachItem) => {
  setLoading(true);
  const fileName = `${new Date().getTime()}${e?.target?.files[0]?.name?.substr(
    e?.target?.files[0]?.name?.lastIndexOf(".")
  )}`;
  const formData = new FormData();
  const file = renameFile(e.target.files[0], fileName);
  formData.append("files", file);
  const path_inside_brain = "root/";
  let uploadedfile = e?.target?.files[0]?.name;
  console.log(uploadedfile, "drive uploaded img 0");

  const jwts = await signJwt(fileName, emailDev, secret);

  let { data } = await axios.post(
    `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
    formData,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  // console.log(file?.name, "file name upload");
  // let { data } = await axios.post(
  //   `https://insurance.apimachine.com/insurance/general/upload`,
  //   formData,
  //   {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   }
  // );
  console.log(uploadedfile, "drive uploaded img");
  if (data?.status && data?.payload?.url) {
    // if (eachItem) {
    //   setFile((prev) => {
    //     return { ...prev, [eachItem]: data?.payload?.url };
    //   });
    // } else {
    //   setFile(data?.payload?.url);
    // }
    setFile({ name: uploadedfile });

    setLoading(false);
    return data?.payload?.url;
  } else {
    predefinedToast("Error while uploading file");
    setLoading(false);
  }
};
