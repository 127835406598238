import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./newagent.module.scss";
import Axios from "axios";
import { BankContext } from "../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import uploading from "../../assest/images/icons/uploading.svg";
import dummyProfile from "../../assest/images/icons/dummyProfile.svg";
import { AppContext } from "../../context/AppContext";

import { uploadImageFunc } from "../../utils/imageUpload";
import { useHistory } from "react-router-dom";
import { predefinedToast } from "../../utils/toast";
import { Redirect } from "react-router-dom";
import { logoutToSameLogin } from "../../assest/functions";

const NewAgent = () => {
  const history = useHistory();
  const {
    email,
    token,
    dashboardHam,
    setDashboardHam,
    popularAction,
    setPopularAction,
    setUpdateLoading,
  } = useContext(BankContext);

  const [brokerageDropdown, setBrokerageDropdown] = useState("");
  const [createAssociate, setCreateAssociate] = useState({
    brokerage_email: email,
    agent_email: "",
    brokerage_username: "",
    first_name: "",
    last_name: "",
    agent_image: "",
    address: "",
    phone_number: "",
    other_data: {},
  });

  useEffect(() => {
    Axios.get(
      `https://insurance.apimachine.com/insurance/brokerage/get?brokerage_email=${email}`
    )
      .then((response) => {
        // console.log(response, "brokerageDropdown response");
        if (response?.data?.status) {
          setBrokerageDropdown(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error?.message, "brokerage dropdown error");
      });
  }, [email]);

  function createAssociateAPI() {
    setUpdateLoading(true);
    Axios.post(
      "https://insurance.apimachine.com/insurance/agent/create",
      createAssociate
    )
      .then((response) => {
        // console.log(response, "Associates created");
        setUpdateLoading(false);
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
        } else {
          predefinedToast(response?.data?.message);
        }
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        console.log(error?.message, "Associate create error error");
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });
    console.log(createAssociate, "createAssociate API");
  }

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.newAgentParent}>
      <div className={classNames.newagent}>
        <div className={classNames.title}>Add An Associate</div>
        <div className={classNames.inputContainers}>
          <EachInput
            title="Associate Email"
            placeholder="Enter email.."
            name="agent_email"
            stateValue={createAssociate}
            setState={setCreateAssociate}
          />
          <EachCustomDropdown
            title="Select Brokerage"
            dropdown={brokerageDropdown}
            name="brokerage_username"
            stateValue={createAssociate}
            setState={setCreateAssociate}
          />
          <EachInput
            title="First Name"
            placeholder="Enter first name.."
            name="first_name"
            stateValue={createAssociate}
            setState={setCreateAssociate}
          />
          <EachInput
            title="Last Name"
            placeholder="Enter last Name.."
            name="last_name"
            stateValue={createAssociate}
            setState={setCreateAssociate}
          />
          <EachInput
            title="Phone Number"
            placeholder="Enter number.."
            name="phone_number"
            stateValue={createAssociate}
            setState={setCreateAssociate}
          />
          <EachInput
            title="Address"
            placeholder="Enter address.."
            name="address"
            stateValue={createAssociate}
            setState={setCreateAssociate}
          />
          <ImageUploadDivIcon
            heading="Upload Image Of Associate"
            name="agent_image"
            stateValue={createAssociate}
            setState={setCreateAssociate}
          />
        </div>
      </div>
      <div className={classNames.btnsContainer}>
        <div
          onClick={() => {
            if (popularAction) {
              setPopularAction(false);
            } else {
              setDashboardHam(!dashboardHam);
            }
            if (window?.location?.pathname?.includes("/app/rahax")) {
              history.push("/app/rahax/claims");
            } else {
              history.push("/app/agency/dashboard");
            }
          }}
        >
          Close Form
        </div>
        <div onClick={createAssociateAPI}>Add New Associate</div>
      </div>
    </div>
  );
};

export default NewAgent;

const EachInput = ({ title, placeholder, name, stateValue, setState }) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
      />
    </div>
  );
};

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionImg, setSelectedOptionimg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, img) => {
    setSelectedOption(option);
    setSelectedOptionimg(img);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div className={classNames.eachCustomDropdown}>
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: isOpen
            ? "2.4rem"
            : // : title == "Select Brokerage" || title == "Select Associate"
              // ? "0 !important"
              "",
          bottom: isOpen ? "auto" : "",
          background: isOpen ? "#e5e5e5" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOptionImg && <img src={selectedOptionImg} alt="" />}
            {selectedOption
              ? selectedOption
              : title == "Select Brokerage"
              ? "Search one of your brokerage.."
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {dropdown?.length > 0 &&
              dropdown
                ?.filter((row) => {
                  // Convert the row object values to an array and check if any value matches the search query
                  const values = Object?.values(row);
                  const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                  return values?.some((value) => {
                    if (typeof value === "string") {
                      return value?.toLowerCase()?.includes(searchQueryy);
                    }
                    return false;
                  });
                })
                ?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(
                          eachitem?.brokerage_name,
                          eachitem?.brokerage_icon
                        );
                        setState({
                          ...stateValue,
                          [name]: eachitem?.brokerage_username,
                        });
                      }}
                      key={eachitem?.brokerage_name + index}
                    >
                      <img
                        src={eachitem?.brokerage_icon}
                        alt={eachitem?.brokerage_name}
                      />
                      {eachitem?.brokerage_name}
                    </li>
                  );
                })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"Select Brokerage"}
              >
                {title == "Select Brokerage"
                  ? "Search one of your brokerage.."
                  : "Select an option"}
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const ImageUploadDivIcon = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const { setSelectedDropDown } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDiv}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor="profileUpdateImgPlanB"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanB"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanB"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await");
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};
