import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./crm.module.scss";

import moment from "moment";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Axios from "axios";
import { BankContext } from "../../../context/Context";
import CustomCheckbox, {
  CopyBtn,
  EachCustomPolicyNumberDropdown,
  EachCustomProfileDropdown,
} from "../../../components/Custom";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import NavbarV2 from "../../../components/NavbarV2";

import { ReactComponent as FilterIcon } from "../../../assest/images/allicons/filter.svg";
import { ReactComponent as SortArrows } from "../../../assest/images/allicons/sort.svg";
import NavbarV3 from "../../../components/NavbarV3";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logoutToSameLogin } from "../../../assest/functions";

const fetchUsersAllCompaniesDropdown = (setUsersAllCompany, email) => {
  Axios.get(
    `https://insurance.apimachine.com/insurance/corporate/get?owner_email=${email}`
  )
    .then((response) => {
      console.log(response, "Users allCompanies dropdown response");
      if (response?.data?.status) {
        setUsersAllCompany(response?.data?.data);
      }
    })
    .catch((error) => {
      console.log(error?.message, "UsersAllCompanies dropdown error");
    });
};

const BrokerAppCRM = () => {
  const history = useHistory();
  const { email, popularAction, setPopularAction, actionBtns, setActionBtns } =
    useContext(BankContext);

  const [selectedProfileNav, setSelectedProfileNav] = useState("Users");
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusDropdown, setStatusDropdown] = useState("");
  const [statesDropdown, setStatesDropdown] = useState("");
  const [designationDropdown, setDesignationDropdown] = useState([
    "Brokerage Owner",
    "Associate",
    "Vertical Head",
    "TL",
    "Manager",
    "Super Admin",
    "HR",
    "Founder",
    "Customer",
  ]);
  const [countryDropdown, setCountryDropdown] = useState("");
  const [associatesDropdown, setAssociatesDropdown] = useState("");
  const [brokersDropdown, setBrokersDropdown] = useState("");
  const [selectDesignation, setSelectDesignation] = useState("");
  const [selectCountryDropdown, setSelectCountryDropdown] = useState("");
  const [selectAssociatesDropdown, setSelectAssociatesDropdown] = useState("");
  const [selectBrokerageDropdown, setSelectBrokerageDropdown] = useState("");
  const [appDropdown, setAppDropdown] = useState("");
  const [usersAllCompany, setUsersAllCompany] = useState("");
  const [usersAllCompanySelected, setUsersAllCompanySelected] = useState("");

  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [allLeads, setAllLeads] = useState("");
  const [allLeadsAPI, setAllLeadsAPI] = useState("");
  const [allLeadsLoading, setAllLeadsLoading] = useState("");
  const [allBrokeragesTotalProfile, setAllBrokeragesTotalProfile] =
    useState("");

  useEffect(() => {
    setAllLeads("");
    setAllLeadsAPI("");
    setAllBrokeragesTotalProfile("");
    setAllLeadsLoading(true);

    const fetchAllBrokerages = () => {
      let URL;
      if (selectedProfileNav === "Users") {
        URL = `https://insurance.apimachine.com/insurance/user/get?brokerage_email=${email}`;
      } else if (selectedProfileNav === "Leads") {
        URL = `https://insurance.apimachine.com/insurance/lead/get?brokerage_email=${email}`;
      }

      Axios.get(URL)
        .then((response) => {
          console.log(response, "all CRM response");
          if (response?.data?.status) {
            setAllLeads(response?.data?.data);
            setAllLeadsAPI(response?.data?.data);
            setAllBrokeragesTotalProfile(response?.data?.total_profiles);
          }
          setAllLeadsLoading(false);
        })
        .catch((error) => {
          console.log(error?.message, "app dropdown error");
          setAllLeadsLoading(false);
        });
    };

    fetchAllBrokerages();
    fetchAllCountries();
    fetchAllAsssociates();
    fetchAllBrokers();
  }, [selectedProfileNav, email]);

  const fetchAllCountries = () => {
    Axios.get("https://insurance.apimachine.com/insurance/user/getcountries")
      .then((response) => {
        console.log(response, "all CRM country response");
        if (response?.data?.status) {
          setCountryDropdown(response?.data?.data);
        }
        // setAllLeadsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "app dropdown error");
        // setAllLeadsLoading(false);
      });
  };

  const fetchAllAsssociates = () => {
    Axios.get(
      "https://insurance.apimachine.com/insurance/agent/get?atleastonelead=true"
    )
      .then((response) => {
        console.log(response, "all CRM associates response");
        if (response?.data?.status) {
          setAssociatesDropdown(response?.data?.data);
        }
        // setAllLeadsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "associates dropdown error CRM");
        // setAllLeadsLoading(false);
      });
  };

  const fetchAllBrokers = () => {
    Axios.get(
      "https://insurance.apimachine.com/insurance/brokerage/get?atleastoneuser=true"
    )
      .then((response) => {
        console.log(response, "all CRM brokers response");
        if (response?.data?.status) {
          setBrokersDropdown(response?.data?.data);
        }
        // setAllLeadsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "brokers dropdown error CRM");
        // setAllLeadsLoading(false);
      });
  };

  useEffect(() => {
    fetchUsersAllCompaniesDropdown(setUsersAllCompany, email);
  }, [selectedProfileNav]);

  useEffect(() => {
    if (allLeadsAPI?.length > 0 && Array?.isArray(allLeadsAPI)) {
      setAllBrokeragesTotalProfile("");
      let res = allLeadsAPI?.filter((row) => {
        console.log("CRM filter", row);
        // Convert the row object values to an array and check if any value matches the search query
        // const values = Object?.values(row);
        const { displayName, ...newObject } = row;

        if (selectedProfileNav == "Management") {
          const separateObject = { displayName };
          // Convert the row object values to an array and check if any value matches the search query
          const values = Object?.values(separateObject);
          const searchQuery = usersAllCompanySelected?.toLowerCase();
          // const searchQueryOwner = leadSourceSelected?.toLowerCase();

          // console.log(separateObject, "values sort", searchQueryOwner);

          // Check if the selected country matches the object's country (if selected)
          const countryMatches =
            !searchQuery ||
            values.some(
              (value) =>
                typeof value === "string" &&
                searchQuery.toLowerCase().includes(value?.toLowerCase())
            );

          return countryMatches;
          //  && brokerageMatches;
        }

        const searchQuery = selectDesignation?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

        // return values?.some((value) => {
        //   if (typeof value === "string") {
        return (
          row?.designation?.toLowerCase()?.includes(searchQuery) &&
          row?.country
            ?.toLowerCase()
            ?.includes(selectCountryDropdown?.toLowerCase()) &&
          row?.agent_details?.agent_email
            ?.toLowerCase()
            ?.includes(selectAssociatesDropdown?.toLowerCase())
        );
        // }
        // return false;
        // });
      });
      setAllBrokeragesTotalProfile(res.length);
      setAllLeads(res);
    }
  }, [
    selectDesignation,
    selectCountryDropdown,
    selectAssociatesDropdown,
    usersAllCompanySelected,
  ]);

  const filterItems = [];

  const filterDropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      <NavbarV3
        dropdownItems={["Users", "Leads"]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setSearchQuery}
      />

      <div className={classNames.brokerageDetails}>
        <div className={classNames.dropdownItems}>
          <>
            <div className={classNames.downloadListBtn}>Download This List</div>
            <div className={classNames.inviteBtn}>Add Associate</div>
          </>
        </div>

        <div className={classNames.allLeads}>
          <div className={classNames.nav}>
            <div className={classNames.filterBtn} ref={filterDropdownRef}>
              <FilterIcon
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              />
              {filterOpen && (
                <div className={classNames.dropdownContent}>
                  {filterItems?.map((eachItem, index) => {
                    return (
                      <div
                        className={classNames.eachFilterItem}
                        key={eachItem + index}
                      >
                        {eachItem}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div
              className={classNames.actionBtn}
              style={{
                display: popularAction
                  ? "none"
                  : actionBtns?.length > 0
                  ? ""
                  : "none",
              }}
            >
              <div>Edit</div>
              <div
                onClick={() => {
                  setPopularAction("deletelead");
                }}
              >
                Delete
              </div>
            </div>
          </div>
          {selectedProfileNav?.includes("Leads") ? (
            <LeadsTable
              allLeads={allLeads}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allLeadsLoading={allLeadsLoading}
            />
          ) : (
            <Table
              allLeads={allLeads}
              allBrokeragesSearch={allBrokeragesSearch}
              actionBtns={actionBtns}
              setActionBtns={setActionBtns}
              allLeadsLoading={allLeadsLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BrokerAppCRM;

const Table = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table>
        <thead>
          <tr>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>
              Name <SortArrows />
            </th>
            <th>
              Email <SortArrows />
            </th>
            <th>
              Phone Number <SortArrows />
            </th>
            <th>
              Associate <SortArrows />
            </th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr key={"loading"}>
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr key={row?.brokerage_name + index}>
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.user_id}
                      name={row?.first_name + " " + row?.last_name}
                    />
                  </td>
                  <td>
                    {row?.first_name
                      ? row?.first_name
                      : "" + row?.last_name
                      ? row?.last_name
                      : ""}
                    <CopyBtn
                      copyText={
                        row?.first_name
                          ? row?.first_name
                          : "" + row?.last_name
                          ? row?.last_name
                          : ""
                      }
                    />
                  </td>
                  <td>
                    {row?.user_email ? row?.user_email : ""}
                    <CopyBtn
                      copyText={row?.user_email ? row?.user_email : ""}
                    />
                  </td>
                  <td>
                    {row?.primary_phone_number ? row?.primary_phone_number : ""}
                    <CopyBtn
                      copyText={
                        row?.primary_phone_number
                          ? row?.primary_phone_number
                          : ""
                      }
                    />
                  </td>
                  <td>
                    {row?.agent_name ? row?.agent_name : ""}
                    <CopyBtn
                      copyText={row?.agent_name ? row?.agent_name : ""}
                    />
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

const LeadsTable = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ minWidth: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "min-content repeat(5, 1fr)" }}>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>
              Organization <SortArrows />
            </th>
            <th>
              Name <SortArrows />
            </th>
            <th>
              Email <SortArrows />
            </th>
            <th>
              Phone Number <SortArrows />
            </th>
            <th>
              Associate <SortArrows />
            </th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(5, 1fr)" }}
            >
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr
                  key={row?.brokerage_name + index}
                  style={{ gridTemplateColumns: "min-content repeat(5, 1fr)" }}
                >
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.lead_id}
                      name={row?.first_name + " " + row?.last_name}
                    />
                  </td>
                  <td>
                    {row?.employee_company_icon && (
                      <img
                        src={
                          row?.employee_company_icon
                            ? row?.employee_company_icon
                            : ""
                        }
                        alt={
                          row?.employee_company_name
                            ? row?.employee_company_name
                            : ""
                        }
                      />
                    )}
                    {row?.employee_company_name
                      ? row?.employee_company_name
                      : ""}
                    {row?.employee_company_name && (
                      <CopyBtn
                        copyText={
                          row?.employee_company_name
                            ? row?.employee_company_name
                            : ""
                        }
                      />
                    )}
                  </td>
                  <td>
                    {row?.first_name
                      ? row?.first_name
                      : "" + row?.last_name
                      ? row?.last_name
                      : ""}
                    {row?.first_name && (
                      <CopyBtn
                        copyText={
                          row?.first_name
                            ? row?.first_name
                            : "" + row?.last_name
                            ? row?.last_name
                            : ""
                        }
                      />
                    )}
                  </td>
                  <td>
                    {row?.lead_email ? row?.lead_email : ""}
                    {row?.lead_email && (
                      <CopyBtn
                        copyText={row?.lead_email ? row?.lead_email : ""}
                      />
                    )}
                  </td>
                  <td>
                    {row?.phone_number ? row?.phone_number : ""}
                    {row?.phone_number && (
                      <CopyBtn
                        copyText={row?.phone_number ? row?.phone_number : ""}
                      />
                    )}
                  </td>
                  <td>
                    {row?.agent_name ? row?.agent_name : ""}
                    {row?.agent_name && (
                      <CopyBtn
                        copyText={row?.agent_name ? row?.agent_name : ""}
                      />
                    )}
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};
