import React, { useState } from "react";
import {
  Logo,
  LinkedInIcon,
  InstagramIcon,
  TwitterIcon,
  FacebookIcon,
  YoutubeIcon,
  OpenEyeIcon,
  CloseEyeIcon,
} from "../../../assest/_new/svgs/svgs";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    temporaryPassword: "",
    password: "",
    confirmPassword: "",
    checkbox: false,
  });
  const [views, setViews] = useState({
    temporaryPassword: false,
    password: false,
    confirmPassword: false,
  });

  const handleViewClick = (name) => {
    const current = views[name];
    console.log(current, views);
    setViews({ ...views, [name]: !current });
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    if (name === "checkbox") {
      setFormData({ ...formData, checkbox: e.target.checked });
    } else setFormData({ ...formData, [name]: value });
    console.log(name, value);
  };
  const handleFormSubmit = () => {
    console.log(formData);
  };
  return (
    <div className="h-full w-screen flex-flex-col relative justify-center overscroll-auto">
      <div>
        <Header />
      </div>
      <main className="flex-1 bg-[#2EC697] h-fit flex justify-center items-center flex-col gap-6 pt-[73px] pb-[27px]">
        <form
          className="bg-white rounded-[15px] shadow-form pt-3 pb-[18px] w-[463px]"
          onSubmit={handleFormSubmit}
        >
          <div className="flex justify-center items-center flex-col">
            <Logo />
            <p className="text-[#181e25] text-center font-DM-Sans font-normal text-lg leading-9">
              Create Your Password
            </p>
          </div>
          <div className="px-8 flex flex-col gap-4">
            <div className="flex flex-col gap-3">
              <label
                forhtml="email"
                className="text-[#181e25] font-DM-Sans text-sm"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData?.email}
                onChange={handleInputChange}
                required
                placeholder="yourname@company.com"
                className="w-full px-3 py-2 text-sm text-gray-700 placeholder-gray-400 border border-gray-300 rounded-md"
              />
            </div>{" "}
            <div className="flex flex-col gap-3 relative">
              <label
                forhtml="temporaryPassword"
                className="text-[#181e25] font-DM-Sans text-sm"
              >
                Temporary Password
              </label>
              <div className=" relative w-full">
                <input
                  type={views?.temporaryPassword ? "text" : "password"}
                  id="temporaryPassword"
                  name="temporaryPassword"
                  value={formData?.temporaryPassword}
                  onChange={handleInputChange}
                  placeholder="Enter Temporary password"
                  className="w-full px-3 py-2 text-sm text-gray-700 placeholder-gray-400 border border-gray-300 rounded-md"
                />
                <span
                  className="absolute right-4 top-0 translate-y-[50%] cursor-pointer"
                  onClick={() => handleViewClick("temporaryPassword")}
                >
                  {views?.temporaryPassword ? (
                    <CloseEyeIcon />
                  ) : (
                    <OpenEyeIcon />
                  )}
                </span>
              </div>
            </div>{" "}
            <div className="flex flex-col gap-3">
              <label
                forhtml="password"
                className="text-[#181e25] font-DM-Sans text-sm"
              >
                Create Password
              </label>
              <div className=" relative w-full">
                <input
                  type={views?.password ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formData?.password}
                  onChange={handleInputChange}
                  placeholder="Enter Temporary password"
                  className="w-full px-3 py-2 text-sm text-gray-700 placeholder-gray-400 border border-gray-300 rounded-md"
                />
                <span
                  className="absolute right-4 top-0 translate-y-[50%] cursor-pointer"
                  onClick={() => handleViewClick("password")}
                >
                  {views?.password ? <CloseEyeIcon /> : <OpenEyeIcon />}
                </span>
              </div>
            </div>{" "}
            <div className="flex flex-col gap-3">
              <label
                forhtml="confirmPassword"
                className="text-[#181e25] font-DM-Sans text-sm"
              >
                Confirm Password
              </label>
              <div className=" relative w-full">
                <input
                  type={views?.confirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData?.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Enter Temporary password"
                  className="w-full px-3 py-2 text-sm text-gray-700 placeholder-gray-400 border border-gray-300 rounded-md"
                />
                <span
                  className="absolute right-4 top-0 translate-y-[50%] cursor-pointer"
                  onClick={() => handleViewClick("confirmPassword")}
                >
                  {views?.confirmPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                </span>
              </div>
            </div>
            <div className="flex justify-start items-center">
              <input
                type="checkbox"
                id="checkbox"
                name="checkbox"
                checked={formData?.checkbox}
                onChange={handleInputChange}
                className="form-checkbox h-4 w-4 p-2"
              />

              <label
                forhtml="checkbox"
                className="ml-2 text-black font-DM-Sans text-[10px]"
              >
                By Check- in to Raha you agree with Raha Fintech Pvt. Ltd.'s
                <span className="text-[#D0004B] underline cursor-pointer">
                  Privacy Policy
                </span>{" "}
                and{" "}
                <span className="text-[#D0004B] underline cursor-pointer">
                  Terms of Use
                </span>
                .
              </label>
            </div>
            <button
              disabled={
                !formData?.email ||
                !formData?.temporaryPassword ||
                !formData?.password ||
                !formData?.confirmPassword ||
                !formData?.checkbox
              }
              className="bg-[#624DE3] disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:scale-100 hover:scale-105 active:scale-95 transition-transform duration-200 flex h-10 min-h-6 p-2 justify-center items-center shrink-0 text-white w-full text-center font-Inter text-sm font-normal rounded-[6px] border border-[#4938C3] shadow-sm"
            >
              Submit
            </button>
          </div>
        </form>
        <div className="w-fit">
          <div className="flex gap-[15px] justify-center items-center">
            <LinkedInIcon
              className={
                "cursor-pointer hover:scale-125 duration-200 transition-transform"
              }
            />
            <InstagramIcon
              className={
                "cursor-pointer hover:scale-125 duration-200 transition-transform"
              }
            />
            <TwitterIcon
              className={
                "cursor-pointer hover:scale-125 duration-200 transition-transform"
              }
            />
            <FacebookIcon
              className={
                "cursor-pointer hover:scale-125 duration-200 transition-transform"
              }
            />
            <YoutubeIcon
              className={
                "cursor-pointer hover:scale-125 duration-200 transition-transform"
              }
            />
          </div>

          <p
            className="text-white font-monserrat text-sm font-normal cursor-pointer"
            onClick={() => {
              window.location.href = "https://www.rahainsure.com";
            }}
          >
            www.rahainsure.com
          </p>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <header className="pl-[108px] py-[22px] bg-white">
      <div className="flex flex-col w-fit gap-0">
        <Logo
          className={
            "cursor-pointer transition-transform hover:scale-110 duration-300"
          }
        />
        <p className="text-black font-DM-Sans text-[10px] font-bold ">
          Insuring <span className="text-[#2ec697]">Health</span>, Ensuring{" "}
          <span className="text-[#2ec697]">Wellness</span>.
        </p>
      </div>
    </header>
  );
};

const Footer = () => {
  return (
    <footer className="bg-[#374B43] pt-3 pb-2 flex flex-col text-center ">
      <p className=" text-white text-center font-DM-Sans text-[10px] font-normal leading-5">
        © Raha Fintech Pvt. Ltd. All Rights Reserved 2024.
      </p>
      <p className=" text-white text-center font-DM-Sans text-[10px] font-normal leading-5">
        Insurance products are offered and serviced by Visista Insurance Broking
        Services Pvt Ltd | IRDAI Certificate No. 321, License category - Direct
        Broker (Life & General), Valid till 05-01-2027 | CIN
        U74140TG2003PTC040308
      </p>
    </footer>
  );
};

export default Login;
