import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./newhrclient.module.scss";
import Axios from "axios";
import { BankContext } from "../../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import dummyProfile from "../../../assest/images/icons/dummyProfile.svg";
import { AppContext } from "../../../context/AppContext";
import uploading from "../../../assest/images/icons/uploading.svg";
import cloudWhite from "../../../assest/images/icons/cloudWhite.svg";

import { uploadFileFunc, uploadImageFunc } from "../../../utils/imageUpload";
import { useHistory } from "react-router-dom";
import { predefinedToast } from "../../../utils/toast";
import { Redirect } from "react-router-dom";
import flatpickr from "flatpickr";

import downArrowIcon from "../../../assest/images/icons/downArrowGreen.svg";
import { ReactComponent as CompleteIcon } from "../../../assest/images/icons/complete.svg";
import { ReactComponent as TrashcanIcon } from "../../../assest/images/icons/trashcan.svg";
//assets icon

import { ReactComponent as AddIcon } from "../../../assest/images/allicons/tableaction/addComponent.svg";

import asteriskIcon from "../../../assest/images/icons/asterisk.svg";
import { logoutToSameLogin } from "../../../assest/functions";

const NewHRClient = () => {
  const history = useHistory();
  const {
    email,
    token,
    dashboardHam,
    setDashboardHam,
    popularAction,
    setPopularAction,
    setUpdateLoading,
  } = useContext(BankContext);

  const [leadSourceDropdown, setLeadSourceDropdown] = useState([]);

  const [statesDropdown, setStatesDropdown] = useState([]);
  const [organizationalDropdown, setOrganizationalDropdown] = useState([]);
  const [insuranceCompaniesDropdown, setInsuranceCompaniesDropdown] =
    useState("");
  const [dobDate, setDOBDate] = useState("");
  const [healthDate, setHealthDate] = useState("");
  const [accidentDate, setAccidentDate] = useState("");
  const [lifeDate, setLifeDate] = useState("");
  const [healthTerms, setHealthTerms] = useState([
    {
      termNumber: 1,
      termDescription: "",
      sub_terms: [],
    },
  ]);
  const [accidentTerms, setAccidentTerms] = useState([
    {
      termNumber: 1,
      termDescription: "",
      sub_terms: [],
    },
  ]);
  const [lifeTerms, setLifeTerms] = useState([
    {
      termNumber: 1,
      termDescription: "",
      sub_terms: [],
    },
  ]);

  const healthDetailsObj = {
    policy_number_T: "",
    insuranceCompany_id: "",
    description: "",
    policy_name: "",
    sum_insured: [],
    currency: "INR",
    covered_persons: "",
    coverage_type: "",
    premium_amount: "",
    valid_till: healthDate,
    insurance_company_logo: "",
    insurance_company_name: "",
    policy_document: "",
    terms_to_employees: [
      {
        termNumber: 1,
        termDescription: "",
        sub_terms: [],
      },
    ],
  };

  const accidentDetailsObj = {
    policy_number_T: "",
    insuranceCompany_id: "",
    description: "",
    policy_name: "",
    sum_insured: [],
    currency: "INR",
    coverage_type: "",
    premium_amount: "",
    valid_till: accidentDate,
    insurance_company_logo: "",
    insurance_company_name: "",
    policy_document: "",
    terms_to_employees: [
      {
        termNumber: 1,
        termDescription: "",
        sub_terms: [
          // {
          //   termNumber: 1,
          //   termDescription:
          //     "During the currency of the Policy, inclusions will be permitted for new joinees only.",
          // }
        ],
      },
    ],
  };

  const lifeDetailsObj = {
    policy_number_T: "",
    insuranceCompany_id: "",
    description: "",
    policy_name: "",
    sum_insured: [],
    currency: "INR",
    coverage_type: "",
    premium_amount: "0",
    valid_till: lifeDate,
    insurance_company_logo: "",
    insurance_company_name: "",
    policy_document: "",
    terms_to_employees: [
      {
        termNumber: 1,
        termDescription: "",
        sub_terms: [],
      },
    ],
  };

  const [healthDetailsArray, setHealthDetailsArray] = useState([]);
  const [accidentDetailsArray, setAccidentDetailsArray] = useState([]);
  const [lifeDetailsArray, setLifeDetailsArray] = useState([]);
  const [healthDetails, setHealthDetails] = useState({
    policy_number_T: "",
    insuranceCompany_id: "",
    description: "",
    policy_name: "",
    sum_insured: [],
    currency: "INR",
    covered_persons: "",
    coverage_type: "",
    premium_amount: "",
    valid_till: healthDate,
    insurance_company_logo: "",
    insurance_company_name: "",
    policy_document: "",
    terms_to_employees: [
      {
        termNumber: 1,
        termDescription: "",
        sub_terms: [],
      },
    ],
  });
  const [accidentDetails, setAccidentDetails] = useState({
    policy_number_T: "",
    insuranceCompany_id: "",
    description: "",
    policy_name: "",
    sum_insured: [],
    currency: "INR",
    coverage_type: "",
    premium_amount: "",
    valid_till: accidentDate,
    insurance_company_logo: "",
    insurance_company_name: "",
    policy_document: "",
    terms_to_employees: [
      {
        termNumber: 1,
        termDescription: "",
        sub_terms: [
          // {
          //   termNumber: 1,
          //   termDescription:
          //     "During the currency of the Policy, inclusions will be permitted for new joinees only.",
          // }
        ],
      },
    ],
  });
  const [lifeDetails, setLifeDetails] = useState({
    policy_number_T: "",
    insuranceCompany_id: "",
    description: "",
    policy_name: "",
    sum_insured: [],
    currency: "INR",
    coverage_type: "",
    premium_amount: "0",
    valid_till: lifeDate,
    insurance_company_logo: "",
    insurance_company_name: "",
    policy_document: "",
    terms_to_employees: [
      {
        termNumber: 1,
        termDescription: "",
        sub_terms: [],
      },
    ],
  });

  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
    address: "",
    city: "",
    pin_code: "",
    state: "",
    country: "",
    website: "",
    logo: "",
    icon: "",
    legal_name: "",
    industry: "",
    description: "",
    date_of_joining: "",
  });
  const [corporateLead, setCorporateLead] = useState({
    reference_email: email,
    lead_source: "",
    lead_type: "company",
    lead_email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    secondary_phone_numbers: [],
    address: "",
    date_of_birth: dobDate,
    city: "",
    state: "",
    country: "India",
    pin_code: "",
    martial_status: "",
    profile_picture: "",
    company_name: "",
    company_size: "",
    gender: "",
    no_of_parents: 0,
    no_of_children: 0,
    no_of_parents_in_law: 0,
    accidental_policy_number: null,
    health_policy_number: null,
    life_policy_number: null,
    company_details: companyDetails,
    policy_details_T: {},
    vertical_head: "",
    business_head: "",
    team_lead: "",
  });

  function extractPolicyNumbers(data) {
    // Use map to extract "policy_number_T" values
    if (Array.isArray(data) && data?.length > 0) {
      const policyNumbers = data
        .map((item) => item.policy_number_T)
        .filter(Boolean);

      return policyNumbers;
    } else {
      return [];
    }
  }

  async function createCorporateLead() {
    corporateLead.company_name = companyDetails?.company_name;
    if (
      !corporateLead?.lead_source ||
      !corporateLead?.phone_number ||
      !corporateLead?.lead_email ||
      !corporateLead?.company_name
    ) {
      predefinedToast("Enter all the mandatory fields");
    } else {
      corporateLead.date_of_birth = dobDate;
      corporateLead.company_details = companyDetails;

      // health policy final

      let finalhealthDetailsArray;
      if (
        healthDetailsArray?.length > 0 &&
        !healthDetailsArray[0]?.policy_number_T
      ) {
        finalhealthDetailsArray = healthDetailsArray.slice(1);
      }

      if (healthDetails?.policy_number_T) {
        finalhealthDetailsArray = [...finalhealthDetailsArray, healthDetails];
      }

      const filteredHealthData = await finalhealthDetailsArray?.filter(
        (item) => item.policy_number_T
      );

      // accident policy final

      let finalaccidentDetailsArray;
      if (
        accidentDetailsArray?.length > 0 &&
        !accidentDetailsArray[0]?.policy_number_T
      ) {
        finalaccidentDetailsArray = accidentDetailsArray.slice(1);
      }

      if (accidentDetails?.policy_number_T) {
        finalaccidentDetailsArray = [
          ...finalaccidentDetailsArray,
          accidentDetails,
        ];
      }

      const filteredAccidentData = await finalaccidentDetailsArray?.filter(
        (item) => item.policy_number_T
      );

      // life policy final

      let finallifeDetailsArray;
      if (
        lifeDetailsArray?.length > 0 &&
        !lifeDetailsArray[0]?.policy_number_T
      ) {
        finallifeDetailsArray = lifeDetailsArray.slice(1);
      }

      if (lifeDetails?.policy_number_T) {
        finallifeDetailsArray = [...finallifeDetailsArray, lifeDetails];
      }

      const filteredLifeData = await finallifeDetailsArray?.filter(
        (item) => item.policy_number_T
      );

      //adding filtered values to final object
      corporateLead.policy_details_T.Health =
        filteredHealthData?.length > 0 ? filteredHealthData : [];
      corporateLead.policy_details_T.Accident =
        filteredAccidentData?.length > 0 ? filteredAccidentData : [];
      corporateLead.policy_details_T.Life =
        filteredLifeData?.length > 0 ? filteredLifeData : [];

      // adding policy numbers to top object
      corporateLead.health_policy_number =
        extractPolicyNumbers(filteredHealthData);
      corporateLead.accidental_policy_number =
        extractPolicyNumbers(filteredAccidentData);
      corporateLead.life_policy_number = extractPolicyNumbers(filteredLifeData);

      setUpdateLoading(true);
      Axios.post(
        "https://insurance.apimachine.com/insurance/agent/create/leadandhr",
        corporateLead
      )
        .then((response) => {
          // console.log(response, "corporate lead created agency");
          setUpdateLoading(false);
          if (response?.data?.status) {
            predefinedToast(response?.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            predefinedToast(response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error?.message, "corporate lead create error agency");
          setUpdateLoading(false);
          predefinedToast(error?.message);
        });
    }
    // console.log(corporateLead, "create new HR Client API");
  }

  const [minimisedSections, setMinimisedSections] = useState([]);

  async function getDataAPI(url) {
    let response = await Axios.get(url);
    return response?.data?.data;
  }

  useEffect(() => {
    async function getAllValues() {
      let leadSources = await getDataAPI(
        "https://insurance.apimachine.com/insurance/lead/getleadsources"
      );
      let getStates = await getDataAPI(
        "https://insurance.apimachine.com/insurance/general/getstates"
      );
      let insuranceCompanies = await getDataAPI(
        "https://insurance.apimachine.com/insurance/insuranceCompany/get"
      );
      let organizationalMapping = await getDataAPI(
        "https://insurance.apimachine.com/insurance/brokerage/get?brokerage_email=vamshi@visistarisk.com"
      );
      setLeadSourceDropdown(leadSources);
      setStatesDropdown(getStates);
      setInsuranceCompaniesDropdown(insuranceCompanies);
      if (
        organizationalMapping?.length > 0 &&
        Array.isArray(organizationalMapping)
      ) {
        setOrganizationalDropdown(organizationalMapping[0]);
      }
      console.log(organizationalMapping, "organizationalMapping response");
      // console.log(getStates, "getStates response");
      // console.log(insuranceCompanies, "insuranceCompanies response");
    }
    getAllValues();
  }, []);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.newBrokerageParent}>
      <div className={classNames.newBrokerage}>
        <div className={classNames.title}>Add Client</div>
        <div
          className={classNames.sectionTitle}
          onClick={() => {
            if (minimisedSections?.includes("HR Contact Details")) {
              setMinimisedSections((prev) =>
                prev?.filter((item) => item !== "HR Contact Details")
              );
            } else {
              setMinimisedSections((prev) => [...prev, "HR Contact Details"]);
            }
          }}
        >
          <span>Contact Details</span>
          <img
            src={downArrowIcon}
            alt="downArrowIcon"
            style={{
              rotate: minimisedSections?.includes("HR Contact Details")
                ? "180deg"
                : "",
            }}
          />
        </div>
        <div
          className={classNames.inputContainers}
          style={{
            display: minimisedSections?.includes("HR Contact Details")
              ? ""
              : "none",
          }}
        >
          <EachInput
            title="First Name"
            placeholder="Enter name.."
            name="first_name"
            stateValue={corporateLead}
            setState={setCorporateLead}
          />
          <EachInput
            title="Last Name"
            placeholder="Enter name.."
            name="last_name"
            stateValue={corporateLead}
            setState={setCorporateLead}
          />
          <EachInput
            title="Email"
            placeholder="Enter email.."
            name="lead_email"
            stateValue={corporateLead}
            setState={setCorporateLead}
            mandatory="yes"
          />
          <EachInput
            title="Phone Number"
            placeholder="Enter number.."
            name="phone_number"
            stateValue={corporateLead}
            setState={setCorporateLead}
            mandatory="yes"
          />
          <EachCustomDropdown
            title="Lead Source"
            dropdown={leadSourceDropdown}
            name="lead_source"
            stateValue={corporateLead}
            setState={setCorporateLead}
            mandatory="yes"
          />
        </div>

        <div
          className={classNames.sectionTitle}
          onClick={() => {
            if (minimisedSections?.includes("Company Details")) {
              setMinimisedSections((prev) =>
                prev?.filter((item) => item !== "Company Details")
              );
            } else {
              setMinimisedSections((prev) => [...prev, "Company Details"]);
            }
          }}
          style={{ marginTop: "2rem" }}
        >
          <span>Company Details</span>
          <img
            src={downArrowIcon}
            alt="downArrowIcon"
            style={{
              rotate: minimisedSections?.includes("Company Details")
                ? "180deg"
                : "",
            }}
          />
        </div>
        <div
          className={classNames.inputContainers}
          style={{
            display: minimisedSections?.includes("Company Details")
              ? ""
              : "none",
          }}
        >
          <EachInput
            title="Company name"
            placeholder="Enter name.."
            name="company_name"
            stateValue={companyDetails}
            setState={setCompanyDetails}
            mandatory="yes"
          />
          <EachInput
            title="Industry"
            placeholder="Enter industry.."
            name="industry"
            stateValue={companyDetails}
            setState={setCompanyDetails}
          />

          <EachInput
            title="Company legal name"
            placeholder="Enter name.."
            name="company_size"
            stateValue={companyDetails}
            setState={setCompanyDetails}
          />
          <EachInput
            title="Website"
            placeholder="Enter website.."
            name="company_size"
            stateValue={companyDetails}
            setState={setCompanyDetails}
          />
          <EachProfileTextArea
            title="Description"
            placeholder="Enter description.."
            name="description"
            dropdown=""
            stateValue={companyDetails}
            setState={setCompanyDetails}
          />
        </div>
        <div
          className={classNames.inputContainers}
          style={{
            display: minimisedSections?.includes("Company Details")
              ? ""
              : "none",
            marginTop: "1rem",
          }}
        >
          <ImageUploadDivIcon
            heading="Upload company icon"
            name="icon"
            stateValue={companyDetails}
            setState={setCompanyDetails}
            idd="companyProfile"
          />
          <ImageUploadDivProfilePic
            heading="Upload company logo"
            name="logo"
            stateValue={companyDetails}
            setState={setCompanyDetails}
            idd="companyLogo"
          />
        </div>
        <div
          className={classNames.sectionTitle}
          onClick={() => {
            if (minimisedSections?.includes("Company Address")) {
              setMinimisedSections((prev) =>
                prev?.filter((item) => item !== "Company Address")
              );
            } else {
              setMinimisedSections((prev) => [...prev, "Company Address"]);
            }
          }}
          style={{ marginTop: "2rem" }}
        >
          <span>Company Address</span>
          <img
            src={downArrowIcon}
            alt="downArrowIcon"
            style={{
              rotate: minimisedSections?.includes("Company Address")
                ? "180deg"
                : "",
            }}
          />
        </div>
        <div
          className={classNames.inputContainers}
          style={{
            display: minimisedSections?.includes("Company Address")
              ? ""
              : "none",
          }}
        >
          <EachInput
            title="Address"
            placeholder="Enter address.."
            name="address"
            stateValue={companyDetails}
            setState={setCompanyDetails}
          />
          <EachInput
            title="City"
            placeholder="Enter city.."
            name="city"
            stateValue={companyDetails}
            setState={setCompanyDetails}
          />
          <EachCustomDropdown
            title="Select State"
            dropdown={statesDropdown}
            name="state"
            stateValue={companyDetails}
            setState={setCompanyDetails}
          />
          <EachInput
            title="Pin Code"
            placeholder="Enter pin code.."
            name="pin_code"
            stateValue={companyDetails}
            setState={setCompanyDetails}
          />
          <EachInput
            title="Select Country"
            placeholder=""
            name="country"
            stateValue={"India"}
            setState={setCompanyDetails}
          />
        </div>
        {/* policy buttons */}
        <div
          className={classNames.sectionTitle}
          onClick={() => {
            if (healthDetailsArray?.length > 0) {
              setHealthDetailsArray((prev) => [...prev, healthDetails]);
              setHealthDetails(healthDetailsObj);
              setHealthDate("");
              setHealthTerms([
                {
                  termNumber: 1,
                  termDescription: "",
                  sub_terms: [],
                },
              ]);
            } else {
              setHealthDetailsArray((prev) => [...prev, healthDetailsObj]);
            }
          }}
          style={{ marginTop: "2rem" }}
        >
          <span>
            <AddIcon />
            Health Policy
          </span>
        </div>

        <div
          className={classNames.sectionTitle}
          onClick={() => {
            if (accidentDetailsArray?.length > 0) {
              setAccidentDetailsArray((prev) => [...prev, accidentDetails]);
              setAccidentDetails(accidentDetailsObj);
              setAccidentDate("");
              setAccidentTerms([
                {
                  termNumber: 1,
                  termDescription: "",
                  sub_terms: [],
                },
              ]);
            } else {
              setAccidentDetailsArray((prev) => [...prev, accidentDetailsObj]);
            }
          }}
          style={{ marginTop: "2rem" }}
        >
          <span>
            <AddIcon />
            Accident Policy
          </span>
        </div>

        <div
          className={classNames.sectionTitle}
          onClick={() => {
            if (lifeDetailsArray?.length > 0) {
              setLifeDetailsArray((prev) => [...prev, lifeDetails]);
              setLifeDetails(lifeDetailsObj);
              setLifeDate("");
              setLifeTerms([
                {
                  termNumber: 1,
                  termDescription: "",
                  sub_terms: [],
                },
              ]);
            } else {
              setLifeDetailsArray((prev) => [...prev, lifeDetailsObj]);
            }
          }}
          style={{ marginTop: "2rem" }}
        >
          <span>
            <AddIcon />
            Life Policy
          </span>
        </div>

        <div
          className={classNames.sectionTitle}
          onClick={() => {
            if (minimisedSections?.includes("Organizational Mapping")) {
              setMinimisedSections((prev) =>
                prev?.filter((item) => item !== "Organizational Mapping")
              );
            } else {
              setMinimisedSections((prev) => [
                ...prev,
                "Organizational Mapping",
              ]);
            }
          }}
          style={{ marginTop: "2rem" }}
        >
          <span>Organizational Mapping</span>
          <img
            src={downArrowIcon}
            alt="downArrowIcon"
            style={{
              rotate: minimisedSections?.includes("Organizational Mapping")
                ? "180deg"
                : "",
            }}
          />
        </div>
        <div
          className={classNames.inputContainers}
          style={{
            display: minimisedSections?.includes("Organizational Mapping")
              ? ""
              : "none",
          }}
        >
          <EachCustomDropdown
            title="Select Team Leader"
            dropdown={
              organizationalDropdown?.team_lead
                ? organizationalDropdown?.team_lead
                : []
            }
            name="team_lead"
            stateValue={corporateLead}
            setState={setCorporateLead}
          />
          <EachCustomDropdown
            title="Select Vertical Leader"
            dropdown={
              organizationalDropdown?.vertical_head
                ? organizationalDropdown?.vertical_head
                : []
            }
            name="vertical_head"
            stateValue={corporateLead}
            setState={setCorporateLead}
          />
          <EachCustomDropdown
            title="Select Business Head"
            dropdown={
              organizationalDropdown?.business_head
                ? organizationalDropdown?.business_head
                : []
            }
            name="business_head"
            stateValue={corporateLead}
            setState={setCorporateLead}
          />
        </div>

        {Array.from({
          length: healthDetailsArray?.length,
        })?.map((eachPolicy, index) => {
          return (
            <HealthDetailsInputs
              insuranceCompaniesDropdown={insuranceCompaniesDropdown}
              healthDetails={healthDetails}
              setHealthDetails={setHealthDetails}
              minimisedSections={minimisedSections}
              setMinimisedSections={setMinimisedSections}
              healthDate={healthDate}
              setHealthDate={setHealthDate}
              healthTerms={healthTerms}
              setHealthTerms={setHealthTerms}
              currentIndex={index}
              healthDetailsArray={healthDetailsArray}
            />
          );
        })}

        {Array.from({
          length: accidentDetailsArray?.length,
        })?.map((eachPolicy, index) => {
          return (
            <AccidentDetailsInputs
              insuranceCompaniesDropdown={insuranceCompaniesDropdown}
              accidentDetails={accidentDetails}
              setAccidentDetails={setAccidentDetails}
              minimisedSections={minimisedSections}
              setMinimisedSections={setMinimisedSections}
              accidentDate={accidentDate}
              setAccidentDate={setAccidentDate}
              accidentTerms={accidentTerms}
              setAccidentTerms={setAccidentTerms}
              currentIndex={index}
              accidentDetailsArray={accidentDetailsArray}
            />
          );
        })}

        {Array.from({
          length: lifeDetailsArray?.length,
        })?.map((eachPolicy, index) => {
          return (
            <LifeDetailsInputs
              insuranceCompaniesDropdown={insuranceCompaniesDropdown}
              lifeDetails={lifeDetails}
              setLifeDetails={setLifeDetails}
              minimisedSections={minimisedSections}
              setMinimisedSections={setMinimisedSections}
              lifeDate={lifeDate}
              setLifeDate={setLifeDate}
              lifeTerms={lifeTerms}
              setLifeTerms={setLifeTerms}
              currentIndex={index}
              lifeDetailsArray={lifeDetailsArray}
            />
          );
        })}
      </div>
      <div className={classNames.btnsContainer}>
        <div
          onClick={() => {
            if (popularAction) {
              setPopularAction(false);
            } else {
              setDashboardHam(false);
            }
            if (window?.location?.pathname?.includes("/app/rahax")) {
              history.push("/app/rahax/claims");
            } else {
              history.push("/app/agency/dashboard");
            }
          }}
        >
          Close Form
        </div>
        <div onClick={createCorporateLead}>Submit</div>
      </div>
    </div>
  );
};

export default NewHRClient;

const EachInput = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  mandatory,
}) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>
        {title}
        {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      {title == "Select currency" || title == "Select Country" ? (
        <input
          className={classNames.inputContainer}
          placeholder={placeholder}
          name={name}
          value={
            title == "Select currency" || title == "Select Country"
              ? stateValue
              : ""
          }
          style={{
            pointerEvents:
              title == "Select currency" || title == "Select Country"
                ? "none"
                : "",
          }}
        />
      ) : title == "Enter sum insured amounts" ? (
        <div className={classNames.inputDiv}>
          <input
            className={classNames.inputContainer}
            placeholder={placeholder}
            name={name}
            onChange={(event) => {
              setState({ ...stateValue, [name]: [event?.target?.value] });
            }}
            style={{ pointerEvents: stateValue?.length > 0 ? "none" : "" }}
          />
          <div
            className={classNames.selectedBanner}
            // style={{
            //   background: stateValue?.length > 0 ? "var(--main-color)" : "",
            //   color: "white",
            // }}
          >
            Flat
          </div>
        </div>
      ) : name == "sum_insured" ? (
        <input
          className={classNames.inputContainer}
          placeholder={placeholder}
          name={name}
          onChange={(event) => {
            setState({
              ...stateValue,
              [name]: [...stateValue[name], event?.target?.value],
            });
          }}
        />
      ) : (
        <input
          className={classNames.inputContainer}
          placeholder={placeholder}
          name={name}
          onChange={(event) => {
            setState({ ...stateValue, [name]: event?.target?.value });
          }}
        />
      )}
    </div>
  );
};

const EachProfileTextArea = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
  name,
  mandatory,
}) => {
  return (
    <div className={classNames.eachProfileInputTextarea}>
      <div className={classNames.title}>
        {title}
        {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      <div className={classNames.inputParent}>
        <textarea
          type="text"
          name=""
          placeholder={placeholder}
          value={stateValue[name]}
          className={classNames.inputContainer}
          style={{ pointerEvents: setState ? "" : "none" }}
          onChange={(event) => {
            setState({ ...stateValue, [name]: event?.target?.value });
          }}
        >
          {stateValue}
        </textarea>
        {status == "secondary" ? (
          <div
            className={classNames.trashCan}
            onClick={() => {
              removeState((prev) => prev - 1);
            }}
          >
            <TrashcanIcon />
          </div>
        ) : (
          ""
        )}
        {status == "primary" ? (
          <div className={classNames.status}>
            <CompleteIcon />
            <span>Primary</span>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* {dropdown && <IoMdArrowDropdown />} */}
    </div>
  );
};

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  mandatory,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionImg, setSelectedOptionimg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, img) => {
    setSelectedOption(option);
    setSelectedOptionimg(img);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div className={classNames.eachCustomDropdown}>
      <div className={classNames.title}>
        {title}
        {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: isOpen
            ? "2.4rem"
            : // : title == "Select Brokerage" || title == "Select Associate"
              // ? "0 !important"
              "",
          bottom: isOpen ? "auto" : "",
          background: isOpen ? "#e5e5e5" : "",
          zIndex: isOpen ? 4 : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOptionImg && <img src={selectedOptionImg} alt="" />}
            {selectedOption
              ? selectedOption
              : title == "Select Brokerage"
              ? "Search one of your brokerage.."
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title == "Lead Source"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : title == "Select State"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.state);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.state,
                          });
                        }}
                        key={eachitem?.state + index}
                        style={{
                          display: eachitem?.state === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem?.state}
                      </li>
                    );
                  })
              : title == "Insurance company"
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.displayName);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.insuranceCompany_id,
                          });
                        }}
                        key={eachitem?.displayName + index}
                      >
                        {eachitem?.displayName}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                      >
                        {eachitem}
                      </li>
                    );
                  })}
          </ul>
        )}
      </div>
    </div>
  );
};

export const ImageUploadDivIcon = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
  idd,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDiv}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor={"profileUpdateImgPlanB" + idd}
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor={"profileUpdateImgPlanB" + idd}
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor={"profileUpdateImgPlanB" + idd}
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await");
          }}
          accept="image/*"
          id={"profileUpdateImgPlanB" + idd}
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

export const ImageUploadDivProfilePic = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
  idd,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDivProfilePic}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor={"profileUpdateImgPlanBProfilePic" + idd}
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor={"profileUpdateImgPlanBProfilePic" + idd}
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor={"profileUpdateImgPlanBProfilePic" + idd}
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await", name);
          }}
          accept="image/*"
          id={"profileUpdateImgPlanBProfilePic" + idd}
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

const EachInputDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  mandatory,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (datepickerRef.current) {
      const options = {
        dateFormat: "d-m-Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("Selected start date:", dateStr, datepickerRef.current);
          setState(dateStr);
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div className={classNames.eachInputDatePicker}>
      <div className={classNames.title}>
        {title}
        {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        value={stateValue}
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
      />
    </div>
  );
};

export const EachDocumentUpload = ({
  title,
  setState,
  stateValue,
  uploadIcon,
  name,
  idd,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const [fileName, setFileName] = useState("");
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={`${classNames.imageUploadDiv} ${classNames.eachDocumentUpload}`}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{title}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor={"profileUpdateImgPlanB" + idd}
      >
        <img src={cloudWhite} alt="cloudWhite" />
        <span>{fileName?.name ? fileName?.name : "Upload Document"}</span>
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor={"profileUpdateImgPlanB" + idd}
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadFileFunc(
              e,
              setFileName,
              setplanBAccountPicUploading,
              "policy_document"
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await");
          }}
          accept="*/*"
          id={"profileUpdateImgPlanB" + idd}
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

const EachInputSelectOne = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  options,
  mandatory,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  return (
    <div className={classNames.eachInputPickOne}>
      <div className={classNames.title}>
        {title} {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      <div
        classNames={classNames.selectOneAllOptions}
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          fontSize: "1rem",
          marginTop: "0.5rem",
        }}
      >
        {options?.length > 0
          ? options?.map((eachOption, index) => {
              return (
                <div
                  key={eachOption + index}
                  onClick={() => {
                    setSelectedValue(eachOption);
                    setState({ ...stateValue, [name]: eachOption });
                  }}
                  style={{
                    textAlign: "center",
                    width: "max-content",
                    padding: "1rem 2rem",
                    cursor: "pointer",
                    fontSize: "0.9rem",
                    border:
                      selectedValue == eachOption
                        ? "1px solid var(--main-color)"
                        : "1px solid #e5e5e5",
                    borderRadius: "35px",
                    transition: "all linear 0.3s",
                    marginTop: "1rem",
                  }}
                >
                  {eachOption}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

const EachProfileInputArray = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
  banner,
  name,
  stateValueMain,
  mandatory,
}) => {
  const [currentInputValue, setCurrentInputValue] = useState("");
  const [allInputs, setAllInputs] = useState(stateValue);
  const [numberOfEmails, setNumberOfEmails] = useState(1);

  const handleAddEmail = () => {
    // setAllInputs((prev) => {
    //   if (prev?.length > 0) {
    //     return [...prev, currentInputValue];
    //   }
    // });
    // setState((prev) => {
    //   if (prev?.length > 0) {
    //     return [...prev, currentInputValue];
    //   }
    // });
    // Add an empty object to the array

    // console.log(currentInputValue, "currentInputValue");
    // console.log(stateValue, "stateValue");
    setState((prev) => {
      return { ...prev, [name]: [...stateValue[name], currentInputValue] };
    });

    setCurrentInputValue("");
    setNumberOfEmails((prev) => prev + 1);
  };

  return (
    <div className={classNames.eachProfileInput}>
      <div className={classNames.title}>
        {title} {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
      </div>
      {/* {Array.from({ length: numberOfEmails })?.map((email, index) => (
        <div className={classNames.addByEmail} key={index}>
          <div className={classNames.inputParent}>
            <div
              className={classNames.inputDiv}
              key={index}
              style={{
                pointerEvents: allInputs?.length == index ? "" : "none",
              }}
            >
              <input
                className={classNames.inputContainer}
                placeholder={placeholder}
                name={name}
                value={allInputs[index]}
                onChange={(event) =>
                  // handleEmailChange(index, "employee_email", event.target.value)
                  setCurrentInputValue(event.target.value)
                }
              />
              <div
                className={classNames.selectedBanner}
                style={{
                  background:
                    allInputs?.length == index ? "" : "var(--main-color)",
                  color: allInputs?.length == index ? "" : "white",
                }}
              >
                {"Grade " + (index + 1)}
              </div>
            </div>
          </div>
        </div>
      ))} */}

      {Array.from({ length: numberOfEmails })?.map((email, index) => (
        <div className={classNames.addByEmail}>
          <div
            className={classNames.inputParent}
            style={{ pointerEvents: numberOfEmails - 1 == index ? "" : "none" }}
          >
            <div className={classNames.inputDiv}>
              <input
                className={classNames.inputContainer}
                placeholder={placeholder}
                name={name}
                onChange={(event) =>
                  // handleEmailChange(index, "employee_email", event.target.value)
                  setCurrentInputValue(event.target.value)
                }
              />
              <div
                className={classNames.selectedBanner}
                style={{
                  background:
                    allInputs?.length - 1 > numberOfEmails
                      ? "var(--main-color)"
                      : "",
                }}
              >
                {"Grade " + (index + 1)}
              </div>
            </div>
            <div
              className={classNames.addButton}
              style={{ pointerEvents: currentInputValue ? "" : "none" }}
              onClick={handleAddEmail}
            >
              +
            </div>
          </div>
        </div>
      ))}

      {dropdown && <IoMdArrowDropdown />}
    </div>
  );
};

function TermEditor({ stateValue: terms, setState: setTerms }) {
  // const [terms, setTerms] = useState([
  //   {
  //     termNumber: 1,
  //     termDescription: "",
  //     sub_terms: [],
  //   },
  // ]);

  const handleAddSubTerm = (termIndex) => {
    const newTerms = [...terms];
    newTerms[termIndex].sub_terms.push({
      termNumber: newTerms[termIndex].sub_terms.length + 1,
      termDescription: "",
    });
    setTerms(newTerms);
  };

  const handleSaveTerm = () => {
    const newTerm = {
      termNumber: terms.length + 1,
      termDescription: "",
      sub_terms: [],
    };
    setTerms([...terms, newTerm]);
  };

  const handleTermDescriptionChange = (termIndex, subTermIndex, value) => {
    const newTerms = [...terms];
    if (subTermIndex === undefined) {
      newTerms[termIndex].termDescription = value;
    } else {
      newTerms[termIndex].sub_terms[subTermIndex].termDescription = value;
    }
    setTerms(newTerms);
  };

  return (
    <div className={classNames.termEditor}>
      {terms.map((term, termIndex) => (
        <div
          key={termIndex}
          className={classNames.eachTerm}
          style={{
            borderBottom:
              terms?.length - 1 == termIndex ? "" : "1px solid #e5e5e5",
            pointerEvents: terms?.length - 1 == termIndex ? "" : "none",
          }}
        >
          <div className={classNames.title}>
            {"Add employee Coverage " + (termIndex + 1)}
          </div>
          <input
            type="text"
            placeholder={`Term ${term.termNumber}`}
            value={term.termDescription}
            onChange={(e) =>
              handleTermDescriptionChange(termIndex, undefined, e.target.value)
            }
          />
          <div className={classNames.subTerms}>
            {term.sub_terms.map((subTerm, subTermIndex) => (
              <div key={subTermIndex}>
                <input
                  type="text"
                  placeholder={`Sub-Term ${subTerm.termNumber}`}
                  value={subTerm.termDescription}
                  onChange={(e) =>
                    handleTermDescriptionChange(
                      termIndex,
                      subTermIndex,
                      e.target.value
                    )
                  }
                />
              </div>
            ))}
          </div>
          <div
            className={classNames.btnsParent}
            style={{ display: terms?.length - 1 == termIndex ? "" : "none" }}
          >
            <div onClick={() => handleAddSubTerm(termIndex)}>
              <div>+</div>
              Add Sub-Coverage
            </div>
            <button onClick={handleSaveTerm}>Save Term</button>
          </div>
        </div>
      ))}

      {/* <pre>{JSON.stringify({ terms_to_employees: terms }, null, 2)}</pre> */}
    </div>
  );
}

function HealthDetailsInputs({
  insuranceCompaniesDropdown,
  healthDetails,
  setHealthDetails,
  minimisedSections,
  setMinimisedSections,
  healthDate,
  setHealthDate,
  healthTerms,
  setHealthTerms,
  currentIndex,
  healthDetailsArray,
}) {
  let healthOptions = [
    "Only The Employee",
    "Employee + Spouse",
    "Employee + Spouse + 2 Children",
    "Employee + Spouse + 2 Children + 2 Parents",
    "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws",
  ];

  useEffect(() => {
    if (healthDate) {
      setHealthDetails((prev) => {
        return { ...prev, valid_till: healthDate };
      });
    }

    if (healthTerms) {
      //filtering before adding
      let termsFiltered = healthTerms?.filter(
        (eachItem) => eachItem?.termDescription !== ""
      );

      healthDetails.terms_to_employees = termsFiltered;
    }

    //add terms data
  }, [healthDate, healthTerms]);

  return (
    <>
      <div
        className={classNames.sectionTitle}
        onClick={() => {
          if (minimisedSections?.includes("Health Policy" + currentIndex)) {
            setMinimisedSections((prev) =>
              prev?.filter((item) => item !== "Health Policy" + currentIndex)
            );
          } else {
            setMinimisedSections((prev) => [
              ...prev,
              "Health Policy" + currentIndex,
            ]);
          }
        }}
        style={{ marginTop: "2rem" }}
      >
        <span>Health Policy {currentIndex + 1}</span>
        <img
          src={downArrowIcon}
          alt="downArrowIcon"
          style={{
            rotate: minimisedSections?.includes("Health Policy" + currentIndex)
              ? "180deg"
              : "",
          }}
        />
      </div>
      <div
        className={classNames.inputContainers}
        style={{
          display: minimisedSections?.includes("Health Policy" + currentIndex)
            ? ""
            : "none",
          pointerEvents:
            healthDetailsArray?.length - 1 == currentIndex ? "" : "none",
        }}
      >
        <EachInput
          title="Policy number"
          placeholder="Enter number.."
          name="policy_number_T"
          stateValue={healthDetails}
          setState={setHealthDetails}
        />
        <EachCustomDropdown
          title="Insurance company"
          dropdown={insuranceCompaniesDropdown}
          name="insuranceCompany_id"
          stateValue={healthDetails}
          setState={setHealthDetails}
          mandatory={healthDetails?.policy_number_T ? true : false}
        />
        <EachInput
          title="Policy name"
          placeholder="Enter name.."
          name="policy_name"
          stateValue={healthDetails}
          setState={setHealthDetails}
        />
        <EachInput
          title="Describe policy"
          placeholder="Enter policy description..."
          // dropdown={martialDropdown}
          name="description"
          stateValue={healthDetails}
          setState={setHealthDetails}
        />
        <EachInput
          title="Select currency"
          placeholder=""
          name="company_size"
          stateValue={"INR"}
          setState={setHealthDetails}
          mandatory={healthDetails?.policy_number_T ? true : false}
        />
        <EachInput
          title="Premium amount"
          placeholder="0.00"
          name="premium_amount"
          stateValue={healthDetails}
          setState={setHealthDetails}
          mandatory={healthDetails?.policy_number_T ? true : false}
        />
        <EachInputDatePicker
          title="Policy valid until"
          placeholder="Click To Select"
          // dropdown={usersDropdown}
          stateValue={healthDate}
          setState={setHealthDate}
          mandatory={healthDetails?.policy_number_T ? true : false}
        />
        <EachDocumentUpload
          title="Upload policy document"
          name="policy_document"
          stateValue={healthDetails}
          setState={setHealthDetails}
          idd="health"
        />
        <ImageUploadDivIcon
          heading="Upload insurance icon"
          name="insurance_company_logo"
          stateValue={healthDetails}
          setState={setHealthDetails}
          idd="healthInsuranceIcon"
        />
        <EachInput
          title="Insurance company name"
          placeholder="Enter name.."
          name="insurance_company_name"
          stateValue={healthDetails}
          setState={setHealthDetails}
        />
      </div>
      <div
        className={classNames.inputContainers}
        style={{
          display: minimisedSections?.includes("Health Policy" + currentIndex)
            ? ""
            : "none",
          gridTemplateColumns: "repeat(1,1fr)",
          marginTop: "1rem",
          pointerEvents:
            healthDetailsArray?.length - 1 == currentIndex ? "" : "none",
        }}
      >
        <EachInputSelectOne
          title="Select who will be covered in the health insurance policy"
          name="covered_persons"
          stateValue={healthDetails}
          setState={setHealthDetails}
          options={healthOptions}
        />
        <EachInputSelectOne
          title="Coverage Type"
          placeholder="Enter name..."
          name="coverage_type"
          stateValue={healthDetails}
          setState={setHealthDetails}
          options={["Flat", "Graded"]}
          mandatory={healthDetails?.policy_number_T ? true : false}
        />
        {healthDetails?.coverage_type == "Flat" ? (
          <EachInput
            title="Enter sum insured amounts"
            placeholder="Enter amount..."
            name="sum_insured"
            stateValue={healthDetails}
            setState={setHealthDetails}
            mandatory={healthDetails?.policy_number_T ? true : false}
          />
        ) : healthDetails?.coverage_type == "Graded" ? (
          <EachProfileInputArray
            title="How much coverage do you wish to offer to each employee?"
            placeholder="Enter amount..."
            dropdown=""
            stateValue={healthDetails}
            setState={setHealthDetails}
            banner={"Health"}
            name="sum_insured"
            stateValueMain={healthDetails}
            mandatory={healthDetails?.policy_number_T ? true : false}
          />
        ) : (
          ""
        )}
        <TermEditor stateValue={healthTerms} setState={setHealthTerms} />
      </div>
    </>
  );
}
function AccidentDetailsInputs({
  insuranceCompaniesDropdown,
  accidentDetails,
  setAccidentDetails,
  minimisedSections,
  setMinimisedSections,
  accidentDate,
  setAccidentDate,
  accidentTerms,
  setAccidentTerms,
  currentIndex,
  accidentDetailsArray,
}) {
  useEffect(() => {
    if (accidentDate) {
      setAccidentDetails((prev) => {
        return { ...prev, valid_till: accidentDate };
      });
    }

    if (accidentTerms) {
      //filtering before adding
      let termsFiltered = accidentTerms?.filter(
        (eachItem) => eachItem?.termDescription !== ""
      );

      accidentDetails.terms_to_employees = termsFiltered;
    }

    //add terms data
  }, [accidentDate, accidentTerms]);

  return (
    <>
      <div
        className={classNames.sectionTitle}
        onClick={() => {
          if (minimisedSections?.includes("Accident Policy" + currentIndex)) {
            setMinimisedSections((prev) =>
              prev?.filter((item) => item !== "Accident Policy" + currentIndex)
            );
          } else {
            setMinimisedSections((prev) => [
              ...prev,
              "Accident Policy" + currentIndex,
            ]);
          }
        }}
        style={{ marginTop: "2rem" }}
      >
        <span>Accident Policy {currentIndex + 1}</span>
        <img
          src={downArrowIcon}
          alt="downArrowIcon"
          style={{
            rotate: minimisedSections?.includes(
              "Accident Policy" + currentIndex
            )
              ? "180deg"
              : "",
          }}
        />
      </div>
      <div
        className={classNames.inputContainers}
        style={{
          display: minimisedSections?.includes("Accident Policy" + currentIndex)
            ? ""
            : "none",
          pointerEvents:
            accidentDetailsArray?.length - 1 == currentIndex ? "" : "none",
        }}
      >
        <EachInput
          title="Policy number"
          placeholder="Enter number.."
          name="policy_number_T"
          stateValue={accidentDetails}
          setState={setAccidentDetails}
        />
        <EachCustomDropdown
          title="Insurance company"
          dropdown={insuranceCompaniesDropdown}
          name="insuranceCompany_id"
          stateValue={accidentDetails}
          setState={setAccidentDetails}
          mandatory={accidentDetails?.policy_number_T ? true : false}
        />
        <EachInput
          title="Policy name"
          placeholder="Enter name.."
          name="policy_name"
          stateValue={accidentDetails}
          setState={setAccidentDetails}
        />
        <EachInput
          title="Describe policy"
          placeholder="Enter policy description..."
          // dropdown={martialDropdown}
          name="description"
          stateValue={accidentDetails}
          setState={setAccidentDetails}
        />
        <EachInput
          title="Select currency"
          placeholder=""
          name="company_size"
          stateValue={"INR"}
          setState={setAccidentDetails}
          mandatory={accidentDetails?.policy_number_T ? true : false}
        />
        <EachInput
          title="Premium amount"
          placeholder="0.00"
          name="premium_amount"
          stateValue={accidentDetails}
          setState={setAccidentDetails}
          mandatory={accidentDetails?.policy_number_T ? true : false}
        />
        <EachInputDatePicker
          title="Policy valid until"
          placeholder="Click To Select"
          // dropdown={usersDropdown}
          stateValue={accidentDate}
          setState={setAccidentDate}
          mandatory={accidentDetails?.policy_number_T ? true : false}
        />
        <EachDocumentUpload
          title="Upload policy document"
          name="policy_document"
          stateValue={accidentDetails}
          setState={setAccidentDetails}
          idd="accident"
        />
        <ImageUploadDivIcon
          heading="Upload insurance icon"
          name="insurance_company_logo"
          stateValue={accidentDetails}
          setState={setAccidentDetails}
          idd="accidentInsuranceIcon"
        />
        <EachInput
          title="Insurance company name"
          placeholder="Enter name.."
          name="insurance_company_name"
          stateValue={accidentDetails}
          setState={setAccidentDetails}
        />
      </div>
      <div
        className={classNames.inputContainers}
        style={{
          display: minimisedSections?.includes("Accident Policy" + currentIndex)
            ? ""
            : "none",
          gridTemplateColumns: "repeat(1,1fr)",
          marginTop: "1rem",
          pointerEvents:
            accidentDetailsArray?.length - 1 == currentIndex ? "" : "none",
        }}
      >
        <EachInputSelectOne
          title="Coverage Type"
          placeholder="Enter name..."
          name="coverage_type"
          stateValue={accidentDetails}
          setState={setAccidentDetails}
          options={["Flat", "Graded"]}
          mandatory={accidentDetails?.policy_number_T ? true : false}
        />
        {accidentDetails?.coverage_type == "Flat" ? (
          <EachInput
            title="Enter sum insured amounts"
            placeholder="Enter amount..."
            name="sum_insured"
            stateValue={accidentDetails}
            setState={setAccidentDetails}
            mandatory={accidentDetails?.policy_number_T ? true : false}
          />
        ) : accidentDetails?.coverage_type == "Graded" ? (
          <EachProfileInputArray
            title="How much coverage do you wish to offer to each employee?"
            placeholder="Enter amount..."
            dropdown=""
            stateValue={accidentDetails}
            setState={setAccidentDetails}
            banner={"Health"}
            name="sum_insured"
            stateValueMain={accidentDetails}
            mandatory={accidentDetails?.policy_number_T ? true : false}
          />
        ) : (
          ""
        )}
        <TermEditor stateValue={accidentTerms} setState={setAccidentTerms} />
      </div>
    </>
  );
}

function LifeDetailsInputs({
  insuranceCompaniesDropdown,
  lifeDetails,
  setLifeDetails,
  minimisedSections,
  setMinimisedSections,
  lifeDate,
  setLifeDate,
  lifeTerms,
  setLifeTerms,
  currentIndex,
  lifeDetailsArray,
}) {
  useEffect(() => {
    if (lifeDate) {
      setLifeDetails((prev) => {
        return { ...prev, valid_till: lifeDate };
      });
    }

    if (lifeTerms) {
      //filtering before adding
      let termsFiltered = lifeTerms?.filter(
        (eachItem) => eachItem?.termDescription !== ""
      );

      lifeDetails.terms_to_employees = termsFiltered;
    }

    //add terms data
  }, [lifeDate, lifeTerms]);

  return (
    <>
      <div
        className={classNames.sectionTitle}
        onClick={() => {
          if (minimisedSections?.includes("Life Policy" + currentIndex)) {
            setMinimisedSections((prev) =>
              prev?.filter((item) => item !== "Life Policy" + currentIndex)
            );
          } else {
            setMinimisedSections((prev) => [
              ...prev,
              "Life Policy" + currentIndex,
            ]);
          }
        }}
        style={{ marginTop: "2rem" }}
      >
        <span>Life Policy {currentIndex + 1}</span>
        <img
          src={downArrowIcon}
          alt="downArrowIcon"
          style={{
            rotate: minimisedSections?.includes("Life Policy" + currentIndex)
              ? "180deg"
              : "",
          }}
        />
      </div>
      <div
        className={classNames.inputContainers}
        style={{
          display: minimisedSections?.includes("Life Policy" + currentIndex)
            ? ""
            : "none",
          pointerEvents:
            lifeDetailsArray?.length - 1 == currentIndex ? "" : "none",
        }}
      >
        <EachInput
          title="Policy number"
          placeholder="Enter number.."
          name="policy_number_T"
          stateValue={lifeDetails}
          setState={setLifeDetails}
        />
        <EachCustomDropdown
          title="Insurance company"
          dropdown={insuranceCompaniesDropdown}
          name="insuranceCompany_id"
          stateValue={lifeDetails}
          setState={setLifeDetails}
          mandatory={lifeDetails?.policy_number_T ? true : false}
        />
        <EachInput
          title="Policy name"
          placeholder="Enter name.."
          name="policy_name"
          stateValue={lifeDetails}
          setState={setLifeDetails}
        />
        <EachInput
          title="Describe policy"
          placeholder="Enter policy description..."
          // dropdown={martialDropdown}
          name="description"
          stateValue={lifeDetails}
          setState={setLifeDetails}
        />
        <EachInput
          title="Select currency"
          placeholder=""
          name="company_size"
          stateValue={"INR"}
          setState={setLifeDetails}
          mandatory={lifeDetails?.policy_number_T ? true : false}
        />
        <EachInput
          title="Premium amount"
          placeholder="0.00"
          name="premium_amount"
          stateValue={lifeDetails}
          setState={setLifeDetails}
          mandatory={lifeDetails?.policy_number_T ? true : false}
        />
        <EachInputDatePicker
          title="Policy valid until"
          placeholder="Click To Select"
          // dropdown={usersDropdown}
          stateValue={lifeDate}
          setState={setLifeDate}
          mandatory={lifeDetails?.policy_number_T ? true : false}
        />
        <EachDocumentUpload
          title="Upload policy document"
          name="policy_document"
          stateValue={lifeDetails}
          setState={setLifeDetails}
          idd="life"
        />
        <ImageUploadDivIcon
          heading="Upload insurance icon"
          name="insurance_company_logo"
          stateValue={lifeDetails}
          setState={setLifeDetails}
          idd="lifeInsuranceIcon"
        />
        <EachInput
          title="Insurance company name"
          placeholder="Enter name.."
          name="insurance_company_name"
          stateValue={lifeDetails}
          setState={setLifeDetails}
        />
      </div>
      <div
        className={classNames.inputContainers}
        style={{
          display: minimisedSections?.includes("Life Policy" + currentIndex)
            ? ""
            : "none",
          gridTemplateColumns: "repeat(1,1fr)",
          marginTop: "1rem",
          pointerEvents:
            lifeDetailsArray?.length - 1 == currentIndex ? "" : "none",
        }}
      >
        <EachInputSelectOne
          title="Coverage Type"
          placeholder="Enter name..."
          name="coverage_type"
          stateValue={lifeDetails}
          setState={setLifeDetails}
          options={["Flat", "Graded"]}
          mandatory={lifeDetails?.policy_number_T ? true : false}
        />
        {lifeDetails?.coverage_type == "Flat" ? (
          <EachInput
            title="Enter sum insured amounts"
            placeholder="Enter amount..."
            name="sum_insured"
            stateValue={lifeDetails}
            setState={setLifeDetails}
            mandatory={lifeDetails?.policy_number_T ? true : false}
          />
        ) : lifeDetails?.coverage_type == "Graded" ? (
          <EachProfileInputArray
            title="How much coverage do you wish to offer to each employee?"
            placeholder="Enter amount..."
            dropdown=""
            stateValue={lifeDetails}
            setState={setLifeDetails}
            banner={"Health"}
            name="sum_insured"
            stateValueMain={lifeDetails}
            mandatory={lifeDetails?.policy_number_T ? true : false}
          />
        ) : (
          ""
        )}
        <TermEditor stateValue={lifeTerms} setState={setLifeTerms} />
      </div>
    </>
  );
}
