import React, { useContext, useEffect, useState } from "react";
import { BankContext } from "../../context/Context";
import classNames from "./popupprofiledetails.module.scss";
import Axios from "axios";
import asteriskIcon from "../../assest/images/icons/asterisk.svg";
import ToggleButton from "../Custom/Toggle";
import { ReactComponent as BoxEditIcon } from "../../assest/images/icons/boxedit.svg";
import { predefinedToast } from "../../utils/toast";
import dummyProfile from "../../assest/images/icons/dummyProfile.svg";
import { uploadImageFunc } from "../../utils/imageUpload";
import { AppContext } from "../../context/AppContext";
import uploading from "../../assest/images/icons/uploading.svg";

const PopupProfileDetails = () => {
  const { email, popupProfileDetails, setPopupProfileDetails } =
    useContext(BankContext);
  const [formDetails, setFormDetails] = useState({});
  const [formDetailsHRProfile, setFormDetailsHRProfile] = useState({});
  const [formDetailsCompany, setFormDetailsCompany] = useState({});
  const [formDetailsEmployee, setFormDetailsEmployee] = useState({});
  const [formDetailsAssociates, setFormDetailsAssociates] = useState({});
  const [formDetailsLeads, setFormDetailsLeads] = useState({});
  const [selectedSwitch, setSelectedSwitch] = useState("Personal");

  async function getProfileDetails() {
    try {
      let response = await Axios.get(
        `https://insurance.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
      );

      if (response?.data?.status) {
        console.log(response, "getProfileDetails response");
        setFormDetails(response?.data?.data?.userDetails);
        setFormDetailsLeads(response?.data?.data?.leadDetails);
        setFormDetailsHRProfile(response?.data?.data?.hrDetails);
        setFormDetailsCompany(
          response?.data?.data?.hrDetails?.hrCompanyDetails
        );
        if (response?.data?.data?.employeeDetails?.length > 0) {
          setFormDetailsEmployee(response?.data?.data?.employeeDetails[0]);
        }
      } else {
        setFormDetails("");
        setFormDetailsCompany("");
        setFormDetailsEmployee();
      }
      console.log(response, "Profile data response");
    } catch (error) {
      setFormDetails("");
      setFormDetailsCompany("");
      console.log(error?.message, "get profile details error");
    }
  }

  async function getAssociatesDetails() {
    try {
      let response = await Axios.get(
        `https://api-dev.rahainsure.com/api/v1/raha-user/?user_email_id=${email}`
      );

      if (response?.data?.status && response?.data?.data?.length > 0) {
        console.log(response, "getProfileDetails associates response");
        setFormDetailsAssociates(response?.data?.data[0]);
      } else {
        setFormDetailsAssociates("");
      }
      console.log(response, "Profile data response associates");
    } catch (error) {
      setFormDetails("");
      setFormDetailsCompany("");
      console.log(error?.message, "get profile details associates error");
    }
  }

  useEffect(() => {
    getProfileDetails();
    getAssociatesDetails();
  }, [selectedSwitch]);

  useEffect(() => {
    if (
      window?.location?.pathname?.includes("/agency") ||
      window?.location?.pathname?.includes("/teamlead") ||
      window?.location?.pathname?.includes("/verticalhead") ||
      window?.location?.pathname?.includes("/businesshead") ||
      window?.location?.pathname?.includes("/affiliates")
    ) {
      setSelectedSwitch("AssociatesClone");
    } else if (window?.location?.pathname?.includes("/coverfi")) {
      setSelectedSwitch("HR Profile");
    } else if (window?.location?.pathname?.includes("/rahax")) {
      setSelectedSwitch("Employee");
    } else {
      setSelectedSwitch("Personal");
    }
  }, []);

  return (
    <div
      style={{
        display: popupProfileDetails ? "flex" : "none",
      }}
      className={classNames.popupProfileForm}
    >
      <div className={classNames.formContainer}>
        {selectedSwitch === "AssociatesClone" ? (
          <div className={classNames.formBody}>
            <div className={classNames.title}>Raha Employee Information</div>

            <ImageUploadDivIcon
              name="user_picture"
              stateValue={formDetailsAssociates}
              setState={setFormDetailsAssociates}
              idd="healthInsuranceIcon"
              callAPI="editAssociateDetails"
            />
            <div className={classNames.inputFields}>
              <EachCustomInput
                title="Name"
                placeholder="-"
                name="user_first_name"
                stateValue={formDetailsAssociates}
                setState={setFormDetailsAssociates}
                callAPI="editAssociateDetails"
              />

              <div style={{ pointerEvents: "none", cursor: "not-allowed" }}>
                <EachCustomInput
                  title="Email"
                  placeholder="-"
                  name="user_email_id"
                  stateValue={formDetailsAssociates}
                  setState={setFormDetailsAssociates}
                />
              </div>
              <EachCustomInput
                title="Phone number"
                placeholder="-"
                name="user_phone_number"
                stateValue={formDetailsAssociates}
                setState={setFormDetailsAssociates}
                callAPI="editAssociateDetails"
              />
              <EachCustomInput
                title="Address"
                placeholder="-"
                name="address"
                stateValue={formDetailsAssociates}
                setState={setFormDetailsAssociates}
                callAPI="editAssociateDetails"
              />
            </div>
          </div>
        ) : selectedSwitch === "HR Profile" ? (
          <div className={classNames.formBody}>
            <div className={classNames.title}>
              User Information{" "}
              <div>
                <ToggleButton
                  stateValue={selectedSwitch}
                  stateFunc={setSelectedSwitch}
                />
                <div>HR Profile</div>
              </div>
            </div>

            <ImageUploadDivIcon
              name="profile_image"
              stateValue={formDetailsHRProfile}
              setState={setFormDetailsHRProfile}
              idd="healthInsuranceIcon"
              callAPI="editHRDetails"
            />

            <div className={classNames.inputFields}>
              <EachCustomInput
                title="First name"
                placeholder="Enter first name"
                name="first_name"
                stateValue={formDetailsHRProfile}
                setState={setFormDetailsHRProfile}
                callAPI="editHRDetails"
              />
              <EachCustomInput
                title="Last name"
                placeholder="Enter last name"
                name="last_name"
                stateValue={formDetailsLeads}
                setState={setFormDetailsLeads}
                callAPI="editHRDetails"
              />
              <div style={{ pointerEvents: "none", cursor: "not-allowed" }}>
                <EachCustomInput
                  title="Email"
                  placeholder="Enter email"
                  name="user_email"
                  stateValue={formDetailsHRProfile}
                  setState={setFormDetailsHRProfile}
                />
              </div>
              <EachCustomInput
                title="Phone number"
                placeholder="Enter phone"
                name="phone_number"
                stateValue={formDetailsHRProfile}
                setState={setFormDetailsHRProfile}
                callAPI="editHRDetails"
              />
            </div>
          </div>
        ) : selectedSwitch === "Personal" ? (
          <div className={classNames.formBody}>
            <div className={classNames.title}>
              User Information{" "}
              <div>
                <ToggleButton
                  stateValue={selectedSwitch}
                  stateFunc={setSelectedSwitch}
                />
                <div>Personal</div>
              </div>
            </div>
            <img
              src={
                formDetails?.profile_picture ? formDetails?.profile_picture : ""
              }
              alt=""
              className={classNames.profileImage}
            />
            <div className={classNames.inputFields}>
              <EachCustomInput
                title="First name"
                placeholder="Enter first name"
                name="first_name"
                stateValue={formDetails}
                setState={setFormDetails}
              />
              <EachCustomInput
                title="Last name"
                placeholder="Enter last name"
                name="last_name"
                stateValue={formDetails}
                setState={setFormDetails}
              />
              <EachCustomInput
                title="Gender"
                placeholder="Enter gender"
                name="gender"
                stateValue={formDetails}
                setState={setFormDetails}
              />
              <EachCustomInput
                title="Email"
                placeholder="Enter email"
                name="user_email"
                stateValue={formDetails}
                setState={setFormDetails}
              />
              <EachCustomInput
                title="Phone number"
                placeholder="Enter phone"
                name="primary_phone_number"
                stateValue={formDetails}
                setState={setFormDetails}
              />
              <EachCustomInput
                title="Date of birth"
                placeholder="Enter pincode"
                name="date_of_birth"
                stateValue={formDetails}
                setState={setFormDetails}
              />
              <EachCustomInput
                title="Address"
                placeholder="Enter pincode"
                name="address"
                stateValue={formDetails}
                setState={setFormDetails}
              />
              <EachCustomInput
                title="City"
                placeholder="Enter pincode"
                name="city"
                stateValue={formDetails}
                setState={setFormDetails}
              />
              <EachCustomInput
                title="State"
                placeholder="Enter pincode"
                name="state"
                stateValue={formDetails}
                setState={setFormDetails}
              />
              <EachCustomInput
                title="Pincode"
                placeholder="Enter pincode"
                name="pin_code"
                stateValue={formDetails}
                setState={setFormDetails}
              />
            </div>
          </div>
        ) : selectedSwitch === "Company" ? (
          <div
            className={classNames.formBody}
            style={{
              display: window?.location?.pathname?.includes("/coverfi")
                ? ""
                : "none",
            }}
          >
            <div className={classNames.title}>
              Company Information{" "}
              <div>
                <ToggleButton
                  stateValue={selectedSwitch}
                  stateFunc={setSelectedSwitch}
                />
                <div>Company</div>
              </div>
            </div>
            <img
              src={formDetailsCompany?.icon ? formDetailsCompany?.icon : ""}
              alt=""
              className={classNames.profileImage}
            />
            <div className={classNames.inputFields}>
              <EachCustomInput
                title="Company name"
                placeholder="Enter first name"
                name="company_name"
                stateValue={formDetailsCompany}
                setState={setFormDetailsCompany}
                callAPI="editCompanyDetails"
              />
              <EachCustomInput
                title="GST Number"
                placeholder="Enter last name"
                name="last_name"
                stateValue={formDetailsCompany}
                setState={setFormDetailsCompany}
                callAPI="editCompanyDetails"
              />
              <EachCustomInput
                title="Industry"
                placeholder="Enter gender"
                name="industry"
                stateValue={formDetailsCompany}
                setState={setFormDetailsCompany}
                callAPI="editCompanyDetails"
              />
              <EachCustomInput
                title="Website"
                placeholder="Enter email"
                name="website"
                stateValue={formDetailsCompany}
                setState={setFormDetailsCompany}
                callAPI="editCompanyDetails"
              />
              <EachCustomInput
                title="Description"
                placeholder="Enter phone"
                name="description"
                stateValue={formDetailsCompany}
                setState={setFormDetailsCompany}
                callAPI="editCompanyDetails"
              />
              <EachCustomInput
                title="Legal name"
                placeholder="Enter pincode"
                name="legal_name"
                stateValue={formDetailsCompany}
                setState={setFormDetailsCompany}
                callAPI="editCompanyDetails"
              />
              <EachCustomInput
                title="Address"
                placeholder="Enter pincode"
                name="address"
                stateValue={formDetailsCompany}
                setState={setFormDetailsCompany}
                callAPI="editCompanyDetails"
              />
              <EachCustomInput
                title="City"
                placeholder="Enter pincode"
                name="city"
                stateValue={formDetailsCompany}
                setState={setFormDetailsCompany}
                callAPI="editCompanyDetails"
              />
              <EachCustomInput
                title="State"
                placeholder="Enter pincode"
                name="state"
                stateValue={formDetailsCompany}
                setState={setFormDetailsCompany}
                callAPI="editCompanyDetails"
              />
              <EachCustomInput
                title="Pincode"
                placeholder="Enter pincode"
                name="pin_code"
                stateValue={formDetailsCompany}
                setState={setFormDetailsCompany}
                callAPI="editCompanyDetails"
              />
            </div>
          </div>
        ) : selectedSwitch === "Employee" ? (
          <div
            className={classNames.formBody}
            style={{
              display: window?.location?.pathname?.includes("/rahax")
                ? ""
                : "none",
            }}
          >
            <div className={classNames.title}>
              Employee Information{" "}
              <div style={{ pointerEvents: "none", opacity: "0.5" }}>
                <ToggleButton
                  stateValue={selectedSwitch}
                  stateFunc={setSelectedSwitch}
                />
                <div>Employee</div>
              </div>
            </div>
            <img
              src={formDetailsEmployee?.icon ? formDetailsEmployee?.icon : ""}
              alt=""
              className={classNames.profileImage}
            />
            <div className={classNames.inputFields}>
              <EachCustomInput
                title="Name"
                placeholder="Enter name"
                name="name"
                stateValue={formDetailsEmployee}
                setState={setFormDetailsEmployee}
              />
              <EachCustomInput
                title="Employee ID"
                placeholder="Enter employee ID"
                name="user_employee_id"
                stateValue={formDetailsEmployee}
                setState={setFormDetailsEmployee}
              />
              <EachCustomInput
                title="Email"
                placeholder="Enter email"
                name="user_company_email"
                stateValue={formDetailsEmployee}
                setState={setFormDetailsEmployee}
              />
              <EachCustomInput
                title="Phone number"
                placeholder="Enter phone number"
                name="phone_number"
                stateValue={formDetailsEmployee}
                setState={setFormDetailsEmployee}
              />
              <EachCustomInput
                title="Date of birth"
                placeholder="Enter DOB"
                name="date_of_birth"
                stateValue={formDetailsEmployee}
                setState={setFormDetailsEmployee}
              />
              <EachCustomInput
                title="Designation"
                placeholder=""
                name="role"
                stateValue={formDetailsEmployee}
                setState={setFormDetailsEmployee}
              />
              <EachCustomInput
                title="Gender"
                placeholder=""
                name="gender"
                stateValue={formDetailsEmployee}
                setState={setFormDetailsEmployee}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={classNames.blackTiltContainer}
        onClick={() => setPopupProfileDetails(false)}
      ></div>
    </div>
  );
};

export default PopupProfileDetails;

const EachCustomInput = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  image,
  indexx,
  asteriskIconn,
  validation,
  callAPI,
}) => {
  const [editEnabled, setEditEnabled] = useState(false);

  async function updateProfileDataCompany() {
    if (stateValue?.company_id) {
      try {
        let response = await Axios.put(
          `https://insurance.apimachine.com/insurance/company/update?company_id=${stateValue?.company_id}`,
          { [name]: stateValue[name] }
        );
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setEditEnabled(false);
          // window.location.reload();
        } else {
          predefinedToast(response?.data?.message);
        }
      } catch (error) {
        console.log(error?.message, "Update company profile error");
      }
    }
  }

  async function updateProfileDataHRDetails() {
    if (stateValue?.hr_id) {
      try {
        let response = await Axios.put(
          `https://insurance.apimachine.com/insurance/hr/update?hr_id=${stateValue?.hr_id}`,
          { [name]: stateValue[name] }
        );
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setEditEnabled(false);
          // window.location.reload();
        } else {
          predefinedToast(response?.data?.message);
        }
      } catch (error) {
        console.log(error?.message, "Update company profile error");
      }
    }
  }

  async function updateAssociatesProfile() {
    if (stateValue?._id) {
      try {
        let response = await Axios.put(
          `https://api-dev.rahainsure.com/api/v1/raha-user/update/${stateValue?._id}`,
          { [name]: stateValue[name] }
        );
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setEditEnabled(false);
          // window.location.reload();
        } else {
          predefinedToast(response?.data?.message);
        }
      } catch (error) {
        console.log(error?.message, "Update associates profile error");
      }
    }
  }

  return (
    <div className={classNames.eachInput}>
      {title && (
        <div className={classNames.title}>
          {title}{" "}
          <span
            onClick={() => {
              if (!editEnabled) {
                document.getElementById("inputBox" + title).focus();
              }
              setEditEnabled((prev) => !prev);
            }}
            style={{ display: callAPI ? "" : "none" }}
          >
            {editEnabled ? "X" : <BoxEditIcon />}
          </span>
        </div>
      )}
      <div
        style={{
          border: image ? "1px solid #e5e5e566" : "",
          borderRadius: image ? "10px" : "",
          paddingLeft: image ? "1rem" : "",
          width: image ? "100%" : "",
          pointerEvents: editEnabled ? "" : "none",
        }}
      >
        {image && <img src={image} alt={image} />}{" "}
        <input
          className={classNames.inputContainer}
          placeholder={editEnabled ? placeholder : "-"}
          name={name}
          id={"inputBox" + title}
          value={
            indexx
              ? stateValue[name][indexx]
              : name
              ? stateValue?.hasOwnProperty(name)
                ? stateValue[name]
                : ""
              : stateValue
          }
          onChange={(event) => {
            if (!validation) {
              if (indexx) {
                let array = stateValue[name];
                array[Number(indexx)] = event?.target?.value;
                console.log(array, "array valuee");
                setState({ ...stateValue, [name]: array });
              } else {
                setState({ ...stateValue, [name]: event?.target?.value });
              }
            } else if (validation) {
              if (
                validation?.type === "length" &&
                event?.target?.value?.length <= validation?.length
              ) {
                if (indexx) {
                  let array = stateValue[name];
                  array[Number(indexx)] = event?.target?.value;
                  console.log(array, "array valuee");
                  setState({ ...stateValue, [name]: array });
                } else {
                  setState({ ...stateValue, [name]: event?.target?.value });
                }
              }
            }
          }}
          style={{
            border: image ? "none" : "",
          }}
        />
        {editEnabled && (
          <div
            className={classNames.submitBtn}
            onClick={() => {
              if (callAPI == "editHRDetails") {
                updateProfileDataHRDetails();
              } else if (callAPI == "editCompanyDetails") {
                updateProfileDataCompany();
              } else if (callAPI == "editAssociateDetails") {
                updateAssociatesProfile();
              }
            }}
          >
            Save
          </div>
        )}
      </div>
      {asteriskIconn && (
        <img
          src={asteriskIcon}
          alt="asteriskIcon"
          className={classNames.asteriskIcon}
        />
      )}
    </div>
  );
};

const ImageUploadDivIcon = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
  idd,
  callAPI,
}) => {
  const [editEnabled, setEditEnabled] = useState(false);
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);

  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);

  async function updateProfileDataHRDetails() {
    if (stateValue?.hr_id) {
      try {
        let response = await Axios.put(
          `https://insurance.apimachine.com/insurance/hr/update?hr_id=${stateValue?.hr_id}`,
          { [name]: stateValue[name] }
        );
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setEditEnabled(false);
          // window.location.reload();
        } else {
          predefinedToast(response?.data?.message);
        }
      } catch (error) {
        console.log(error?.message, "Update company profile error");
      }
    }
  }

  async function updateAssociatesProfile() {
    if (stateValue?._id) {
      try {
        let response = await Axios.put(
          `https://api-dev.rahainsure.com/api/v1/raha-user/update/${stateValue?._id}`,
          { [name]: stateValue[name] }
        );
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setEditEnabled(false);
          // window.location.reload();
        } else {
          predefinedToast(response?.data?.message);
        }
      } catch (error) {
        console.log(error?.message, "Update associates profile error");
      }
    }
  }

  return (
    <div
      className={classNames.imageUploadDiv}
      onClick={() => setSelectedDropDown("")}
    >
      {
        <div className={classNames.title}>
          <span
            onClick={() => {
              const targetLabel = document.getElementById("targetLabel");
              targetLabel.click();
              setEditEnabled((prev) => !prev);
            }}
          >
            <BoxEditIcon />
          </span>
        </div>
      }
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
          pointerEvents: "none",
        }}
        id="targetLabel"
        htmlFor={"profileUpdateImgPlanB" + idd}
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor={"profileUpdateImgPlanB" + idd}
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor={"profileUpdateImgPlanB" + idd}
        className={classNames.uploadFileDiv}
        style={{ pointerEvents: "none" }}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            let imagee = await resImage;
            if (imagee) {
              setState({
                ...stateValue,
                [name]: await resImage,
              });
            }
            // setEditEnabled(false);
            console.log(await resImage, "resImage await");
          }}
          accept="image/*"
          id={"profileUpdateImgPlanB" + idd}
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
      {editEnabled && (
        <div
          className={classNames.submitBtn}
          onClick={() => {
            if (callAPI == "editHRDetails") {
              updateProfileDataHRDetails();
            } else if (callAPI == "editAssociateDetails") {
              updateAssociatesProfile();
            }
          }}
        >
          Update Profile Pic
        </div>
      )}
    </div>
  );
};
