import React, { useContext, useRef, useState } from "react";
import classNames from "./overview.module.scss";
import { BankContext } from "../../../context/Context";

import { ReactComponent as PhoneIcon } from "../../../assest/images/icons/phone.svg";
import { ReactComponent as LockIcon } from "../../../assest/images/icons/lock.svg";
import { AiOutlineClose } from "react-icons/ai";

import kpPic from "../../../assest/images/draft/kp.svg";

//sidebar

import cultfitLogo from "../../../assest/images/coverfi/proactive/cultfit.svg";
import dialLogo from "../../../assest/images/coverfi/proactive/dial.svg";
import callhealthLogo from "../../../assest/images/coverfi/proactive/callhealth.svg";

import { ReactComponent as AmbulanceIcon } from "../../../assest/images/coverfi/proactive/icons/ambulance.svg";

import { LoadingAnimationRaha } from "../../../components/Custom";

import { useEffect } from "react";
import Axios from "axios";
import { Redirect } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Wallet from "../../Wallet";
import NewQuote, { AddTransaction, NewQuoteMini } from "./tabs";
import NavbarV3 from "../../../components/NavbarV3";
import { useHistory } from "react-router-dom";
import { predefinedToast } from "../../../utils/toast";
import fullLogo from "../../../assest/images/fullLogo.svg";
import GetQuoteEmployer from "../../GetQuote/getquotestartemployer";
import ToggleButton from "../../../components/Custom/Toggle";
import {
  base64ToBlob,
  downloadFile,
  logoutToSameLogin,
} from "../../../assest/functions";

ChartJS.register(ArcElement, Tooltip, Legend);

const Overview = () => {
  const history = useHistory();
  const {
    email,
    globalHrId,
    setGlobalHrId,
    setPopularAction,
    supportDataGlobal,
  } = useContext(BankContext);
  const [selectedProfileNav, setSelectedProfileNav] = useState("Dashboard");
  const [searchQuery, setSearchQuery] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedPolicyEmployee, setSelectedPolicyEmployee] = useState("");
  const [associateDetails, setAssociateDetails] = useState("");
  const [associateDetailsLoading, setAssociateDetailsLoading] = useState(true);
  const [enteredPieCategory, setEnteredPieCategory] = useState("");
  const [totalCdBalance, setTotalCdBalance] = useState("");
  const [employeeStats, setEmployeeStats] = useState("");
  const [activateWalletLoading, setActivateWalletLoading] = useState(false);
  const [claimsApi, setClaimsApi] = useState("");
  const [policiesApi, setPoliciesApi] = useState([]);
  const [isOverviewLocked, setIsOverviewLocked] = useState(true);
  const [selectedPolicy, setSelectedPolicy] = useState(0);
  const [selectedSwitch, setSelectedSwitch] = useState("Quote Manager");
  const [openedQuoteMini, setOpenedQuoteMini] = useState("");
  const [refreshLocal, setRefreshLocal] = useState(false);
  const [overviewLocal, setOverviewLocal] = useState(false);

  //get support data
  const [salesAssociatesData, setSalesAssociatesData] = useState("");
  const [salesTeamleaderData, setSalesTeamleaderData] = useState("");

  async function getSupportData() {
    await supportDataGlobal.forEach((eachItem, index) => {
      if (
        eachItem?.role_id?.role_type === "Sales" &&
        eachItem?.role_id?.role_designation === "Associates" &&
        eachItem?.user_id
      ) {
        setSalesAssociatesData(eachItem?.user_id);
      } else if (
        eachItem?.role_id?.role_type === "Sales" &&
        eachItem?.role_id?.role_designation === "Team Leader" &&
        eachItem?.user_id
      ) {
        setSalesTeamleaderData(eachItem?.user_id);
      }
    });
  }

  useEffect(() => {
    if (Array.isArray(supportDataGlobal) && supportDataGlobal?.length > 0) {
      getSupportData();
    } else {
      setSalesAssociatesData("");
      setSalesTeamleaderData("");
    }
  }, [supportDataGlobal]);

  function getUserId() {
    Axios.get(
      `https://insurance.apimachine.com/insurance/user/getuser?user_email=${email}`
    )
      .then(async (response) => {
        if (response?.data?.data?.length > 0) {
          localStorage?.setItem(
            "globalUserIdRaha",
            response?.data?.data[0]?.user_id
          );
          let globalHRID = await getGlobalHrId(
            response?.data?.data[0]?.user_id
          );
        }
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");
      });
  }

  function getGlobalHrId(userid) {
    if (userid) {
      Axios.get(
        `https://insurance.apimachine.com/insurance/hr/get?user_id=${userid}`
      )
        .then((response) => {
          console.log(response?.data, "Global HR ID response", userid);
          if (response?.data?.status && response?.data?.data?.length > 0) {
            // console.log(response?.data?.data[0]?.hr_id, "hr id response");
            if (response?.data?.data[0]?.hr_id) {
              setGlobalHrId(response?.data?.data[0]?.hr_id);
              localStorage.setItem(
                "globalUserHrIdRaha",
                response?.data?.data[0]?.hr_id
              );
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error?.message, "user profile error");
          // setAllInsuranceCompaniesLoading(false);
        });
    }
  }

  function formatCurrency(number) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    });
    return formatter.format(number).replace("₹", "");
  }

  async function getTotalCDBalance() {
    let response = await Axios.get(
      `https://insurance.apimachine.com/insurance/cdBalance/hr/get?hr_id=${globalHrId}`
    );

    let res = response?.data?.data;

    if (response?.data?.status) {
      setTotalCdBalance(res[0]);
    } else {
      setTotalCdBalance("activatewallet");
    }

    console.log(response, "get total CD balance response");
  }

  async function activateCDBalance() {
    setActivateWalletLoading(true);
    let response = await Axios.post(
      `https://insurance.apimachine.com/insurance/cdBalance/hr/create`,
      {
        hr_id: globalHrId,
      }
    );

    if (response?.data?.status) {
      setActivateWalletLoading(false);
      predefinedToast("Wallet activated successfully");
      setTimeout(() => {
        window?.location?.reload();
      }, 1500);
    } else {
      setActivateWalletLoading(false);
      predefinedToast("Error while trying to add wallet");
    }

    console.log(response, "Activate CD balance response");
  }

  async function getEmployeeStats() {
    let response;
    if (globalHrId) {
      response = await Axios.get(
        `https://insurance.apimachine.com/insurance/hr/get/count?hr_id=${globalHrId}`
      );
    } else {
      await getUserId();
      response = await Axios.get(
        `https://insurance.apimachine.com/insurance/hr/get/count?hr_id=${globalHrId}`
      );
    }

    let res = response?.data?.data;

    setEmployeeStats(res);
    console.log(res, "getEmployeeStats response");
  }

  function getAssociatesDetails() {
    setAssociateDetailsLoading(true);
    Axios.get(
      `https://insurance.apimachine.com/insurance/user/get?user_email=${email}`
    )
      .then((response) => {
        console.log(response?.data?.data, "associateDetails API");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          setAssociateDetails(response?.data?.data[0]);
        }
        setAssociateDetailsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "associateDetails error");
        setAssociateDetailsLoading(false);
      });
  }

  function getClaimsApi() {
    Axios.get(
      `https://api-dev.rahainsure.com/api/v1/policy?hr_id=${globalHrId}`
    )
      .then((response) => {
        if (
          // response?.data?.message === "hr_id is not registered in hr profile"
          !response?.data?.status
        ) {
          setIsOverviewLocked("emptyPolicyBox");
          setPoliciesApi([]);
        } else if (response?.data?.data?.length > 0) {
          setPoliciesApi(response?.data?.data);
        } else {
          setPoliciesApi([]);
        }
      })
      .catch((error) => {
        console.log(error?.message, "claims API employer proactive error");
        setIsOverviewLocked("emptyPolicyBox");
        // setAllInsuranceCompaniesLoading(false);
      });
  }

  useEffect(() => {
    if (globalHrId) {
      getTotalCDBalance();
      getEmployeeStats();
      getClaimsApi();
    } else {
      setPoliciesApi([]);
    }
    getAssociatesDetails();
  }, [globalHrId]);

  useEffect(() => {
    if (globalHrId) {
      getClaimsApi();
    } else {
      setPoliciesApi([]);
      setIsOverviewLocked("emptyPolicyBox");
    }
  }, [selectedSwitch, globalHrId, refreshLocal]);

  useEffect(() => {
    function getClaimsApiFirst() {
      Axios.get(
        `https://api-dev.rahainsure.com/api/v1/policy?hr_id=${globalHrId}`
      )
        .then((response) => {
          if (
            // response?.data?.message === "hr_id is not registered in hr profile"
            !response?.data?.status
          ) {
            setIsOverviewLocked("emptyPolicyBox");
            setPoliciesApi([]);
          } else if (response?.data?.data?.length > 0) {
            setPoliciesApi(response?.data?.data);
            setSelectedSwitch("Policy Manager");
          } else {
            setPoliciesApi([]);
          }
        })
        .catch((error) => {
          console.log(error?.message, "claims API employer proactive error");
          setIsOverviewLocked("emptyPolicyBox");
          // setAllInsuranceCompaniesLoading(false);
        });
    }
    // Your existing code for getClaimsApi function

    // Run this only on the initial mount
    if (globalHrId) {
      getClaimsApiFirst();
    } else {
      setPoliciesApi([]);
    }

    // If you have any cleanup logic, you can return a function from useEffect
    return () => {
      // Cleanup logic, if needed
    };
  }, [globalHrId]); // Empty dependency array means it will run only on mount

  function onCloseSidebar() {
    if (sidebarOpen) {
      setSidebarOpen((prev) => !prev);
    }
  }

  function isAnyObjectEmpty(arrayOfObjects) {
    for (const obj of arrayOfObjects) {
      if (Object.keys(obj).length === 0) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (globalHrId) {
      getClaimsApi();
    } else {
      setPoliciesApi([]);
    }
  }, [email, globalHrId]);

  const handleScrollButtonClick = () => {
    const scrollableDiv = document.getElementById("scrollableDiv");

    // Scroll the div back to the top
    scrollableDiv.scrollTop = 0;
  };

  //tempstates

  const [speaktolead, setSpeakToLead] = useState(false);
  const [speaktoAssociate, setSpeakToAssociate] = useState(false);

  function donutData(dependent, pending, employees) {
    return {
      labels: ["Dependents", "Pending Employee", "Active Employee"],
      datasets: [
        {
          data: [
            dependent ? dependent : 0,
            pending ? pending : 0,
            employees ? employees : 0,
          ], // The values for your data points
          backgroundColor: ["#5F6163", "#2EC697", "#17A1FA"], // Colors for each data point
          hoverBackgroundColor: ["#5F6163", "#2EC697", "#17A1FA"],
        },
      ],
    };
  }

  function calculateDaysRemaining(validTillDate, daysRemain) {
    // Parse the "validTillDate" string in dd-mm-yyyy format
    const [day, month, year] = validTillDate.split("-");
    const validTill = new Date(`${year}-${month}-${day}`);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in days
    const timeDifference = validTill - currentDate;
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    if (daysRemain) {
      return daysRemaining;
    }

    return {
      datasets: [
        {
          data: [365 - daysRemaining, daysRemaining], // The values for your data points
          backgroundColor: ["#2EC697", "#17A1FA21"], // Colors for each data point
          hoverBackgroundColor: ["#2EC697", "#17A1FA21"],
        },
      ],
    };
  }

  const donutOptions = {
    cutout: "85%", // Set the cutout as a percentage to make the donut circle smaller
    plugins: {
      legend: {
        display: false,
        // position: "right",
      },
    },
  };

  const donutOptionsPolicies = {
    cutout: "80%", // Set the cutout as a percentage to make the donut circle smaller
    plugins: {
      legend: {
        display: true,
        // position: "right",
      },
    },
    elements: {
      arc: {
        spacing: 0, // Set spacing to 0 to remove the gap between donut segments
      },
    },
  };

  //renderings

  useEffect(() => {
    // Get the previous location from the history object
    const previousLocation =
      history.location.state && history.location.state.from;

    // Get the path of the previous page if redirected
    const previousPath = previousLocation ? previousLocation.pathname : null;

    if (previousPath === "/app/coverfi/members") {
      setSelectedProfileNav("CD Balance");
    }
  }, []);

  if (!email) {
    // logoutToSameLogin(history);
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      <NavbarV3
        dropdownItems={["Dashboard", "CD Balance", "Quotes"]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setSearchQuery}
      />
      {activateWalletLoading ? (
        <div className={classNames.walletLoading}>
          <div className={classNames.walletLoadingBox}>
            <img src={fullLogo} alt="fullLogo" />
            <div className={classNames.title}>
              Your CD Balance Has Been Activated
            </div>
          </div>
        </div>
      ) : associateDetailsLoading ? (
        <LoadingAnimationRaha />
      ) : selectedProfileNav === "Quotes" ? (
        <NewQuote />
      ) : selectedProfileNav === "CD Balance" ? (
        <Wallet />
      ) : (
        <div
          className={classNames.proactiveDetails}
          style={{ overflowY: sidebarOpen ? "hidden" : "" }}
          id="scrollableDiv"
        >
          <SidebarMenu
            position={sidebarOpen ? "0" : ""}
            onClose={onCloseSidebar}
            openedItem={sidebarOpen}
            selectedItemMain={selectedPolicyEmployee}
          />
          <div className={classNames.majorDetails}>
            <div className={classNames.enrollmentDiv}>
              <div>
                <div className={classNames.title}>Enrollment Status</div>
                <div className={classNames.countDiv}>
                  <div className={classNames.title}>Active Employee</div>
                  <div className={classNames.count}>
                    {employeeStats ? employeeStats?.employeeCount : "-"}
                  </div>
                </div>
                <div className={classNames.countDiv}>
                  <div className={classNames.title}>Pending Employee</div>
                  <div className={classNames.count}>
                    {employeeStats ? employeeStats?.employeePendingCount : "-"}
                  </div>
                </div>
                <div className={classNames.countDiv}>
                  <div className={classNames.title}>Dependents</div>
                  <div className={classNames.count}>
                    {employeeStats ? employeeStats?.dependentCount : "-"}
                  </div>
                </div>
              </div>
              <div>
                <Doughnut
                  data={donutData(
                    employeeStats?.dependentCount,
                    employeeStats?.employeePendingCount,
                    employeeStats?.employeeCount
                  )}
                  options={donutOptions}
                />
                <div className={classNames.totalPerc}>
                  <div>{employeeStats ? employeeStats?.total_lives : "-"}</div>
                  <div>Lives</div>
                </div>
              </div>
              {isOverviewLocked === "emptyPolicyBox" &&
              !policiesApi?.length > 0 &&
              Array.isArray(policiesApi) ? (
                <div className={classNames.lockedDiv}>
                  <div className={classNames.opacityDiv}></div>
                  <LockIcon />
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <div className={classNames.miniCards}>
                <div className={classNames.title}>
                  <span>CD Balance</span>
                  <div
                    className={classNames.viewBtn}
                    onClick={() => setSelectedProfileNav("CD Balance")}
                  >
                    View
                  </div>
                </div>
                <div className={classNames.balanceDiv}>
                  INR{" "}
                  {totalCdBalance?.cd_balance
                    ? formatCurrency(totalCdBalance?.cd_balance)
                    : "0"}
                </div>
                <div className={classNames.countDivs}>
                  <div>
                    <div className={classNames.title}>Employees Joined</div>
                    <div className={classNames.count}>
                      {employeeStats
                        ? employeeStats?.employee_endorsement_added
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <div className={classNames.title}>Employees Left</div>
                    <div className={classNames.count}>
                      {employeeStats
                        ? employeeStats?.dependent_endorsement_removed
                        : "-"}
                    </div>
                  </div>
                </div>
                {totalCdBalance === "activatewallet" ? (
                  <div
                    className={classNames.btnContainer}
                    onClick={activateCDBalance}
                  >
                    Activate
                  </div>
                ) : (
                  <div
                    className={classNames.btnContainer}
                    onClick={() => {
                      // setPopularAction("addendorsementscoverfi");
                      // history.push("/app/coverfi/members");
                      setOverviewLocal("AddTransaction");
                    }}
                  >
                    Add
                  </div>
                )}
                {isOverviewLocked === "emptyPolicyBox" &&
                !policiesApi?.length > 0 &&
                Array.isArray(policiesApi) ? (
                  <div className={classNames.lockedDiv}>
                    <div className={classNames.opacityDiv}></div>
                    <LockIcon />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={classNames.miniCards}>
                <div className={classNames.title}>Support</div>
                {salesAssociatesData && (
                  <div className={classNames.box}>
                    <div className={classNames.associateProfile}>
                      {salesAssociatesData?.user_picture ? (
                        <img
                          src={
                            salesAssociatesData?.user_picture
                              ? salesAssociatesData?.user_picture
                              : ""
                          }
                          alt="profilePic"
                        />
                      ) : (
                        <div className={classNames.profileLetter}>
                          {salesAssociatesData?.user_first_name
                            ? salesAssociatesData?.user_first_name?.charAt(0)
                            : "R"}
                        </div>
                      )}
                    </div>
                    <div className={classNames.agentDetails}>
                      <div className={classNames.name}>
                        {salesAssociatesData?.user_first_name
                          ? salesAssociatesData?.user_first_name
                          : ""}
                      </div>
                      <div
                        className={classNames.agentBtn}
                        style={{ marginTop: "0.15rem" }}
                        onClick={() => setSpeakToAssociate((prev) => !prev)}
                      >
                        <PhoneIcon />
                        {speaktoAssociate &&
                        salesAssociatesData?.user_phone_number?.length > 0
                          ? salesAssociatesData?.user_phone_number[0]
                          : "Speak To Associate"}
                      </div>
                    </div>
                  </div>
                )}
                {salesTeamleaderData && (
                  <div className={classNames.box}>
                    <div className={classNames.associateProfile}>
                      {salesTeamleaderData?.user_picture ? (
                        <img
                          src={
                            salesTeamleaderData?.user_picture
                              ? salesTeamleaderData?.user_picture
                              : ""
                          }
                          alt="kpPic"
                          style={{ border: "none" }}
                        />
                      ) : (
                        <div className={classNames.profileLetter}>
                          {salesTeamleaderData?.user_first_name
                            ? salesTeamleaderData?.user_first_name?.charAt(0)
                            : "R"}
                        </div>
                      )}
                    </div>
                    <div className={classNames.agentDetails}>
                      <div className={classNames.name}>
                        {salesTeamleaderData?.user_first_name
                          ? salesTeamleaderData?.user_first_name
                          : ""}
                      </div>

                      <div
                        className={classNames.agentBtn}
                        style={{ marginTop: "0.15rem" }}
                        onClick={() => setSpeakToLead((prev) => !prev)}
                      >
                        <PhoneIcon />
                        {speaktolead &&
                        salesTeamleaderData?.user_phone_number?.length > 0
                          ? salesTeamleaderData?.user_phone_number[0]
                          : "Speak To Team Lead"}
                      </div>
                    </div>
                  </div>
                )}
                {/* {isOverviewLocked === "emptyPolicyBox" &&
                !policiesApi?.length > 0 &&
                Array.isArray(policiesApi) ? (
                  <div className={classNames.lockedDiv}>
                    <div className={classNames.opacityDiv}></div>
                    <LockIcon />
                  </div>
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
          <div className={classNames.smallBoxes}>
            <div
              style={{
                minHeight: isOverviewLocked === "emptyPolicyBox" ? "80px" : "",
              }}
            >
              {isOverviewLocked === "emptyPolicyBox" &&
              !policiesApi?.length > 0 &&
              Array.isArray(policiesApi) ? (
                <div className={classNames.lockedDiv}>
                  <div className={classNames.opacityDiv}></div>
                  <div className={classNames.title}>Total Employees</div>
                  <LockIcon style={{ position: "absolute", bottom: "15px" }} />
                </div>
              ) : (
                <>
                  <div className={classNames.title}>Total Employees</div>
                  <div className={classNames.count}>
                    {employeeStats ? employeeStats?.total_employees : "-"}
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                minHeight: isOverviewLocked === "emptyPolicyBox" ? "80px" : "",
              }}
            >
              {isOverviewLocked === "emptyPolicyBox" &&
              !policiesApi?.length > 0 &&
              Array.isArray(policiesApi) ? (
                <div className={classNames.lockedDiv}>
                  <div className={classNames.opacityDiv}></div>
                  <div className={classNames.title}>Total Lives</div>
                  <LockIcon style={{ position: "absolute", bottom: "15px" }} />
                </div>
              ) : (
                <>
                  <div className={classNames.title}>Total Lives</div>
                  <div className={classNames.count}>
                    {employeeStats ? employeeStats?.total_lives : "-"}
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                minHeight: isOverviewLocked === "emptyPolicyBox" ? "80px" : "",
              }}
            >
              {isOverviewLocked === "emptyPolicyBox" &&
              !policiesApi?.length > 0 &&
              Array.isArray(policiesApi) ? (
                <div className={classNames.lockedDiv}>
                  <div className={classNames.opacityDiv}></div>
                  <div className={classNames.title}>Patners</div>
                  <LockIcon style={{ position: "absolute", bottom: "15px" }} />
                </div>
              ) : (
                <>
                  <div className={classNames.title}>Patners</div>
                  <div className={classNames.count}>
                    {employeeStats ? employeeStats?.spouse_count : "-"}
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                minHeight: isOverviewLocked === "emptyPolicyBox" ? "80px" : "",
              }}
            >
              {isOverviewLocked === "emptyPolicyBox" &&
              !policiesApi?.length > 0 &&
              Array.isArray(policiesApi) ? (
                <div className={classNames.lockedDiv}>
                  <div className={classNames.opacityDiv}></div>
                  <div className={classNames.title}>Children</div>
                  <LockIcon style={{ position: "absolute", bottom: "15px" }} />
                </div>
              ) : (
                <>
                  <div className={classNames.title}>Children</div>
                  <div className={classNames.count}>
                    {employeeStats ? employeeStats?.children_count : "-"}
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                minHeight: isOverviewLocked === "emptyPolicyBox" ? "80px" : "",
              }}
            >
              {isOverviewLocked === "emptyPolicyBox" &&
              !policiesApi?.length > 0 &&
              Array.isArray(policiesApi) ? (
                <div className={classNames.lockedDiv}>
                  <div className={classNames.opacityDiv}></div>
                  <div className={classNames.title}>Parents</div>
                  <LockIcon style={{ position: "absolute", bottom: "15px" }} />
                </div>
              ) : (
                <>
                  <div className={classNames.title}>Parents</div>
                  <div className={classNames.count}>
                    {employeeStats ? employeeStats?.parents_count : "-"}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classNames.policiesDiv}>
            <div
              style={{
                borderRadius: isOverviewLocked === "startQuote" ? "35px" : "",
                overflow: isOverviewLocked === "startQuote" ? "hidden" : "",
              }}
            >
              <div className={classNames.newQuotesMini}>
                <div className={classNames.header}>
                  <div className={classNames.title}>
                    {openedQuoteMini ? openedQuoteMini : selectedSwitch}
                  </div>
                  <div className={classNames.btnsContainer}>
                    <div style={{ display: openedQuoteMini ? "none" : "" }}>
                      <ToggleButton
                        stateValue={selectedSwitch}
                        stateFunc={setSelectedSwitch}
                      />
                    </div>
                    {!openedQuoteMini ? (
                      <div
                        onClick={() => {
                          setSelectedSwitch("Policy Manager");
                          setClaimsApi("startQuote");
                        }}
                      >
                        +
                      </div>
                    ) : (
                      <AiOutlineClose
                        onClick={() => {
                          setSelectedSwitch("Quote Manager");
                          setOpenedQuoteMini("");
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className={classNames.quotesContainer}>
                  {(selectedSwitch === "Policy Manager" ||
                    selectedSwitch === "Quote Manager") &&
                  claimsApi === "startQuote" ? (
                    <GetQuoteEmployer
                      setClaimsApi={setClaimsApi}
                      setSelectedProfileNav={setSelectedProfileNav}
                      setRefreshLocal={setRefreshLocal}
                      setSelectedSwitch={setSelectedSwitch}
                    />
                  ) : selectedSwitch === "Policy Manager" ? (
                    Array.isArray(policiesApi) && policiesApi?.length > 0 ? (
                      policiesApi?.map((eachPolicy) => {
                        return (
                          <div
                            className={`${classNames.eachPolicy} ${
                              selectedPolicy == "0"
                                ? classNames.eachPolicySelected
                                : ""
                            }`}
                            onClick={() => {
                              setSelectedPolicy(0);
                            }}
                          >
                            <div className={classNames.headDiv}>
                              <div className={classNames.imageDiv}>
                                <img
                                  src={
                                    eachPolicy?.insurance_company_id
                                      ?.insurance_company_logo_url
                                      ? eachPolicy?.insurance_company_id
                                          ?.insurance_company_logo_url
                                      : ""
                                  }
                                  alt="iciciIcon"
                                />
                              </div>
                              <div className={classNames.companyDetails}>
                                <div className={classNames.title}>
                                  {eachPolicy?.policy_type}
                                </div>
                                <div className={classNames.company}>
                                  Policy By :{" "}
                                  <span style={{ fontWeight: "600" }}>
                                    {eachPolicy?.insurance_company_id
                                      ?.insurance_company_name
                                      ? eachPolicy?.insurance_company_id
                                          ?.insurance_company_display_name
                                      : ""}
                                  </span>
                                </div>
                              </div>
                              <div className={classNames.donutBox}>
                                <Doughnut
                                  data={calculateDaysRemaining(
                                    eachPolicy?.end_date
                                  )}
                                  options={donutOptionsPolicies}
                                />
                                <div className={classNames.totalPerc}>
                                  <div>
                                    {calculateDaysRemaining(
                                      eachPolicy?.end_date,
                                      "daysRemain"
                                    )}{" "}
                                    Days
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={classNames.desc}
                              style={{ paddingBottom: "0.5rem" }}
                            >
                              {eachPolicy?.description}
                            </div>
                            <div
                              className={classNames.desc}
                              style={{
                                paddingTop: "0",
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                }}
                              >
                                <div>Start Date: {eachPolicy?.start_date}</div>
                                <div>Valid Till: {eachPolicy?.end_date}</div>
                              </div>
                              <div>
                                Policy Number: {eachPolicy?.policy_number}
                              </div>
                            </div>
                            <div className={classNames.moreDetails}>
                              {/* <div className={classNames.amount}>
                                <div>Coverage (INR)</div>
                                <div>
                                  {eachPolicy?.sum_insured?.length > 0 &&
                                    eachPolicy?.sum_insured[0]}
                                </div>
                              </div> */}
                              <div className={classNames.allPolicyVarients}>
                                {Array.isArray(eachPolicy?.policy_rate_card) &&
                                eachPolicy?.policy_rate_card?.length > 0
                                  ? eachPolicy?.policy_rate_card?.map(
                                      (eachItem, index) => {
                                        return (
                                          <div
                                            className={classNames.eachVarient}
                                            key={eachItem?.policy_grade + index}
                                          >
                                            <div>{eachItem?.policy_grade}</div>
                                            <div>
                                              {eachItem?.sum_insured?.length > 0
                                                ? eachItem?.sum_insured[0]
                                                : eachItem?.sum_insured}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                  }}
                                >
                                  <div
                                    className={classNames.btn}
                                    onClick={() => {
                                      console.log(
                                        eachPolicy,
                                        "selected policy Employee Terms"
                                      );
                                      setSelectedPolicyEmployee(eachPolicy);
                                      setSidebarOpen("detailedPolicy");
                                      handleScrollButtonClick();
                                    }}
                                  >
                                    Employee Terms
                                  </div>
                                  <div
                                    className={classNames.btn}
                                    onClick={() => {
                                      setSelectedPolicyEmployee(eachPolicy);
                                      setSidebarOpen("detailedConfiguration");
                                      handleScrollButtonClick();
                                    }}
                                  >
                                    Configurations
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                  }}
                                >
                                  <div
                                    className={classNames.btn}
                                    onClick={() => {
                                      window?.open(
                                        eachPolicy?.policy_pdf_link,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    Download Policy Copy
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className={classNames.emptyPoliciesDiv}>
                        <div>
                          Looks like you don’t have any policies. Let's change
                          that
                        </div>
                        <div className={classNames.btnsContainer}>
                          <button
                            onClick={() => {
                              setClaimsApi("startQuote");
                            }}
                          >
                            New Quote
                          </button>
                        </div>
                      </div>
                    )
                  ) : selectedSwitch === "Quote Manager" ? (
                    <NewQuoteMini
                      openedQuoteMini={openedQuoteMini}
                      setOpenedQuoteMini={setOpenedQuoteMini}
                      claimsApi={claimsApi}
                      setClaimsApi={setClaimsApi}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className={classNames.hiddenGraph}>
              <div className={classNames.title}>Claims</div>
              <div className={classNames.lockedDiv}>
                <div className={classNames.opacityDiv}></div>
                <LockIcon />
              </div>
              {/* {isOverviewLocked === "emptyPolicyBox" &&
              !policiesApi?.length > 0 &&
              Array.isArray(policiesApi) ? (
                <div className={classNames.lockedDiv}>
                  <div className={classNames.opacityDiv}></div>
                  <LockIcon />
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
          {overviewLocal && <AddTransaction setShowAddTxn={setOverviewLocal} />}
        </div>
      )}
    </div>
  );
};

export default Overview;

const SidebarMenu = ({ position, onClose, openedItem, selectedItemMain }) => {
  const sidebarRef = useRef(null);

  const [selectedItem, setSelectedItem] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [scrollLocked, setScrollLocked] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose(); // Call the onClose function to change the state
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const allItem = [
    { name: "Cult", image: cultfitLogo },
    { name: "Dial 4242", image: dialLogo },
    { name: "CallHealth", image: callhealthLogo },
  ];

  return (
    <div
      className={classNames.sidebarMenu}
      style={{
        right: position ? "0" : "",
      }}
      ref={sidebarRef}
    >
      {openedItem == "detailedPolicy" ? (
        <div className={classNames.eachItemDetailed}>
          <div
            className={classNames.emptyCard}
            style={{ background: "var(--main-color)" }}
          >
            {/* <div onClick={() => setSelectedItem("")}>Back</div> */}
          </div>
          <div className={classNames.allDetails}>
            <div className={classNames.mainDetail}>
              <div className={classNames.eachPolicy}>
                <div className={classNames.headDiv}>
                  <div className={classNames.imageDiv}>
                    <img
                      src={
                        selectedItemMain?.insurance_company_id
                          ?.insurance_company_logo_url
                          ? selectedItemMain?.insurance_company_id
                              ?.insurance_company_logo_url
                          : ""
                      }
                      alt="iciciIcon"
                    />
                  </div>
                  <div className={classNames.companyDetails}>
                    <div className={classNames.title}>
                      {selectedItemMain?.policy_type
                        ? selectedItemMain?.policy_type
                        : ""}
                    </div>
                    <div className={classNames.company}>
                      Policy By :{" "}
                      <span style={{ fontWeight: "600" }}>
                        {selectedItemMain?.insurance_company_id
                          ?.insurance_company_name
                          ? selectedItemMain?.insurance_company_id
                              ?.insurance_company_name
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames.desc}
                  style={{ paddingBottom: "0.5rem" }}
                >
                  {selectedItemMain?.description}
                </div>
                <div className={classNames.desc} style={{ paddingTop: "0" }}>
                  Policy Valid Until: {selectedItemMain?.end_date}
                </div>
                <div className={classNames.moreDetails}>
                  <div className={classNames.amount}>
                    <div>Coverage (INR)</div>
                    <div>
                      {selectedItemMain?.sum_insured?.length > 0 &&
                        selectedItemMain?.sum_insured[0]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.desc} style={{ margin: "0rem" }}>
              <div className={classNames.searchDiv}>
                <input
                  type="text"
                  placeholder="Search The Terms In Your Policy..."
                  onChange={(event) => setSearchQuery(event?.target?.value)}
                />
              </div>
              <div className={classNames.allDesc}>
                {selectedItemMain?.terms_and_conditions?.length > 0
                  ? selectedItemMain?.terms_and_conditions
                      ?.filter((eachItem) =>
                        eachItem?.termDescription?.includes(searchQuery)
                      )
                      ?.map((eachItem, index) => {
                        return (
                          <div
                            key={
                              "termDescription" + eachItem?.termNumber + index
                            }
                          >
                            <div>{eachItem?.termNumber}</div>
                            <div>{eachItem?.termDescription}</div>
                          </div>
                        );
                      })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      ) : openedItem == "detailedConfiguration" ? (
        <div className={classNames.eachItemDetailed}>
          <div
            className={classNames.emptyCard}
            style={{ background: "var(--main-color)" }}
          >
            {/* <div onClick={() => setSelectedItem("")}>Back</div> */}
          </div>
          <div className={classNames.allDetails}>
            <div className={classNames.mainDetail}>
              <div className={classNames.eachPolicy}>
                <div className={classNames.headDiv}>
                  <div className={classNames.imageDiv}>
                    <img
                      src={
                        selectedItemMain?.insurance_company_id
                          ?.insurance_company_logo_url
                          ? selectedItemMain?.insurance_company_id
                              ?.insurance_company_logo_url
                          : ""
                      }
                      alt="iciciIcon"
                    />
                  </div>
                  <div className={classNames.companyDetails}>
                    <div className={classNames.title}>
                      {selectedItemMain?.policy_type
                        ? selectedItemMain?.policy_type
                        : ""}
                    </div>
                    <div className={classNames.company}>
                      Policy By :{" "}
                      <span style={{ fontWeight: "600" }}>
                        {selectedItemMain?.insurance_company_id
                          ?.insurance_company_name
                          ? selectedItemMain?.insurance_company_id
                              ?.insurance_company_name
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames.desc}
                  style={{ paddingBottom: "0.5rem" }}
                >
                  {selectedItemMain?.description}
                </div>
                <div className={classNames.desc} style={{ paddingTop: "0" }}>
                  Policy Valid Until:{" "}
                  {selectedItemMain?.end_date ? selectedItemMain?.end_date : ""}
                </div>
                <div className={classNames.moreDetails}>
                  <div className={classNames.amount}>
                    <div>Coverage (INR)</div>
                    <div>
                      {selectedItemMain?.sum_insured?.length > 0 &&
                        selectedItemMain?.sum_insured[0]}
                    </div>
                  </div>
                  <div
                    className={classNames.btn}
                    onClick={() => {
                      window.open(selectedItemMain?.policy_pdf_link, "_blank");
                    }}
                  >
                    PDF
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.desc} style={{ margin: "0rem" }}>
              <div className={classNames.detail}>
                <div>Type:</div>
                <div>
                  {selectedItemMain?.policy_variant
                    ? selectedItemMain?.policy_variant
                    : "-"}
                </div>
              </div>
              <div className={classNames.detail}>
                <div>Covered Persons:</div>
                <div>
                  {selectedItemMain?.covered_members
                    ? selectedItemMain?.covered_members
                    : "-"}
                </div>
              </div>

              <div className={classNames.sumTable}>
                {selectedItemMain?.policy_variant == "Graded" ? (
                  selectedItemMain?.sum_insured?.map((eachItem, index) => {
                    return (
                      <div
                        className={classNames.eachCell}
                        key={eachItem + index + "Grade"}
                      >
                        <div>{"Grade " + (index + 1)}</div>
                        <div>{eachItem}</div>
                      </div>
                    );
                  })
                ) : (
                  <div className={classNames.eachCell}>
                    <div>Flat</div>
                    <div>
                      {selectedItemMain?.sum_insured?.length > 0
                        ? selectedItemMain?.sum_insured[0]
                        : "-"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : selectedItem == "Dial 4242" ? (
        <div className={classNames.eachItemDetailed}>
          <div
            className={classNames.emptyCard}
            style={{ background: "#04BFDA" }}
          >
            <div onClick={() => setSelectedItem("")}>Back</div>
          </div>
          <div className={classNames.allDetails}>
            <div className={classNames.mainDetail}>
              <img src={dialLogo} alt="dialLogo" />
              <div>
                <div>Dial 4242</div>
                <div>Ambulance Service Provider</div>
              </div>
            </div>
            <div className={classNames.desc}>
              <div className={classNames.title}>About</div>
              <div className={classNames.para}>
                DIAL4242, India's biggest app based ambulance network is built
                to deliver the best in emergency care to people across the
                nation through its ambulance services that is not only limited
                to emergencies, but can be used by a patient to schedule
                check-ups & appointments or simply to get home after being
                discharged from hospital. The mobile app connects users to
                emergency facilities by providing timely ambulance service when
                required. With just one click or a call, a patient can have our
                associated ambulances at the location of their choice.
              </div>
            </div>
            <div className={classNames.offerings}>
              <div className={classNames.title}>Offerings</div>
              <div className={classNames.allOfferings}>
                <div className={classNames.eachOffering}>
                  <div style={{ background: "#04BFDA40" }}>
                    <AmbulanceIcon />
                  </div>
                  <div>
                    <div>
                      <div className={classNames.title}>
                        Employee Ambulance Assurance Plan
                      </div>
                      <div className={classNames.learnBtn}>Learn More</div>
                    </div>
                    <div className={classNames.priceDiv}>
                      <div>
                        ₹149
                        <span>₹205</span>
                      </div>
                      <div className={classNames.btn}>ADD</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classNames.allWellnessItems}>
          <div className={classNames.title}>Create Your Wellness Cart</div>
          <div className={classNames.allItems}>
            {allItem?.map(({ name, image }, index) => {
              return (
                <div
                  className={classNames.eachWellnessItem}
                  key={name}
                  onClick={() => {
                    setSelectedItem(name);
                  }}
                >
                  <img src={image} alt={name} />
                  <div>{name}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
