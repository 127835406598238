import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./newcompanyprofile.module.scss";
import Axios from "axios";
import { BankContext } from "../../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import uploading from "../../../assest/images/icons/uploading.svg";
import dummyProfile from "../../../assest/images/icons/dummyProfile.svg";
import { AppContext } from "../../../context/AppContext";

import { uploadImageFunc } from "../../../utils/imageUpload";
import { useHistory } from "react-router-dom";
import { predefinedToast } from "../../../utils/toast";
import { Redirect } from "react-router-dom";

import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/material_green.css";
import NewCompanyProfileStep2 from "../CreateNewCompanyProfileStep2";
import { allIndustry } from "../../../assest/data/industry";
import { logoutToSameLogin } from "../../../assest/functions";

const NewCompanyProfile = () => {
  const history = useHistory();
  const {
    email,
    token,
    dashboardHam,
    setDashboardHam,
    popularAction,
    setPopularAction,
    globalUserId,
    setGlobalHrId,
    setUpdateLoading,
  } = useContext(BankContext);

  const [userCreated, setUserCreated] = useState("");

  const [indianStatesDropdown, setIndianStatesDropdown] = useState("");
  const [allIndustryDropdown, setAllIndustryDropdown] = useState(allIndustry);

  const [corporateLead, setCorporateLead] = useState({
    user_id: globalUserId
      ? globalUserId
      : localStorage.getItem("globalUserIdRaha")
      ? localStorage.getItem("globalUserIdRaha")
      : "",
    company_name: "",
    address: "",
    city: "",
    pin_code: "",
    state: "",
    country: "India",
    website: "",
    logo: "",
    icon: "",
    legal_name: "",
    industry: "",
    description: "",
  });

  useEffect(() => {
    Axios.get(`https://insurance.apimachine.com/insurance/general/getstates`)
      .then((response) => {
        console.log(response, "state dropdown response");
        if (response?.data?.status) {
          setIndianStatesDropdown(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error?.message, "state dropdown error");
      });
  }, []);

  function getHRIdGlobalAPI() {
    Axios.get(
      `https://insurance.apimachine.com/insurance/hr/get?user_id=${globalUserId}`
    )
      .then((response) => {
        console.log(response?.data?.data, "company profile response");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          console.log(response?.data?.data[0]?.hr_id, "hr id response");
          if (response?.data?.data[0]?.hr_id) {
            setGlobalHrId(response?.data?.data[0]?.hr_id);
            localStorage.setItem(
              "globalUserHrIdRaha",
              response?.data?.data[0]?.hr_id
            );
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");
        // setAllInsuranceCompaniesLoading(false);
      });
  }

  function createCorporateLead() {
    setUpdateLoading(true);
    let finalObj = {
      ...corporateLead,
    };
    Axios.post(
      "https://insurance.apimachine.com/insurance/user/create/companyandhr",
      finalObj
    )
      .then((response) => {
        console.log(response, "create company profile API called");
        setUpdateLoading(false);
        if (response?.data?.status) {
          if (globalUserId) {
            getHRIdGlobalAPI();
          }
          // predefinedToast(response?.data?.message);
          setTimeout(() => {
            setUserCreated(false);
            history.push("/app/coverfi/companyprofile");
          }, 3000);
          setUserCreated(true);
        } else {
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "company create error");
        setUpdateLoading(false);
        predefinedToast(error?.message);
      });

    console.log("create company profile API", finalObj);
  }

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.newBrokerageParent}>
      <div className={classNames.newBrokerage}>
        {userCreated ? (
          <div className={classNames.successfulMessage}>
            {userCreated == "nolead" ? (
              <div>Lead ID not found, can't create user profile on own</div>
            ) : (
              <>
                <div>You have successfully created company profile.</div>
                <div>You will be redirected to your company profile page</div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className={classNames.title}>Create Company Profile</div>
            <div className={classNames.sectionTitle}>Company Details</div>
            <div className={classNames.inputContainers}>
              <EachInput
                title="Company Display Name"
                placeholder="Enter name..."
                name="company_name"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachInput
                title="Company Legal Name"
                placeholder="Enter name..."
                name="legal_name"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachInput
                title="Company Website"
                placeholder="Enter Link..."
                name="website"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachCustomDropdown
                title="What industry does the company work in?"
                dropdown={allIndustryDropdown}
                name="industry"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
            </div>
            <div
              className={classNames.sectionTitle}
              style={{ marginTop: "2rem" }}
            >
              Company Address
            </div>
            <div className={classNames.inputContainers}>
              <EachInput
                title="Street Address"
                placeholder="Enter your address.."
                name="address"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachInput
                title="City"
                placeholder="Enter city.."
                name="city"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachCustomDropdown
                title="State"
                dropdown={indianStatesDropdown}
                name="state"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachInput
                title="Postal Code"
                placeholder="Enter Postal Code..."
                name="pin_code"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
            </div>
            <div
              className={classNames.sectionTitle}
              style={{ marginTop: "2rem" }}
            >
              Display Details
            </div>
            <div className={classNames.inputContainers}>
              <ImageUploadDivIcon
                heading="Upload company icon"
                name="icon"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <ImageUploadDivProfilePic
                heading="Upload company logo"
                name="logo"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
              <EachInputPara
                title="Describe the business?"
                placeholder="Write a description about the business..."
                name="description"
                stateValue={corporateLead}
                setState={setCorporateLead}
              />
            </div>
          </>
        )}
      </div>
      {userCreated ? (
        ""
      ) : (
        <div className={classNames.btnsContainer}>
          <div
            onClick={() => {
              if (popularAction) {
                setPopularAction(false);
              } else {
                setDashboardHam(!dashboardHam);
              }
              history.push("/app/coverfi/companyprofile");
            }}
          >
            Close Form
          </div>
          <div onClick={createCorporateLead}>Submit Profile</div>
        </div>
      )}
    </div>
  );
};

export default NewCompanyProfile;

const EachInput = ({ title, placeholder, name, stateValue, setState }) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
      />
    </div>
  );
};

const EachInputPara = ({ title, placeholder, name, stateValue, setState }) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <textarea
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
      ></textarea>
    </div>
  );
};

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionImg, setSelectedOptionimg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, img) => {
    setSelectedOption(option);
    setSelectedOptionimg(img);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      // style={{ pointerEvents: "none" }}
    >
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: isOpen
            ? "2.4rem"
            : // : title == "Select Brokerage" || title == "Select Associate"
              // ? "0 !important"
              "",
          bottom: isOpen ? "auto" : "",
          zIndex: isOpen ? "5" : "",
          background: isOpen ? "#e5e5e5" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOptionImg && <img src={selectedOptionImg} alt="" />}
            {selectedOption
              ? selectedOption
              : name == "industry"
              ? "Select Industry"
              : title == "State"
              ? "Select state.."
              : title == "Select Brokerage"
              ? "Search one of your brokerage.."
              : title == "Job Type" || title == "Select User"
              ? "Click To Select"
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title == "State"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.state);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.state,
                          });
                        }}
                        key={eachitem?.state + index}
                        style={{
                          display: eachitem?.state === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem?.state}
                      </li>
                    );
                  })
              : title == "Number Of Living In-Laws" ||
                title == "Number Of Living Parents" ||
                title == "Number Of Children" ||
                title == "Martial Status" ||
                name == "industry"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : title == "Select Brokerage"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.brokerage_name,
                            eachitem?.brokerage_icon
                          );
                          setState(eachitem?.brokerage_username);
                        }}
                        key={eachitem?.brokerage_name + index}
                        style={{
                          display:
                            eachitem?.brokerage_username === stateValue
                              ? "none"
                              : "",
                        }}
                      >
                        <img
                          src={eachitem?.brokerage_icon}
                          alt={eachitem?.brokerage_name}
                        />
                        {eachitem?.brokerage_name}
                      </li>
                    );
                  })
              : title == "Select User"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.first_name + eachitem?.last_name,
                            eachitem?.profile_picture
                          );
                          setState({
                            ...stateValue,
                            [name]: eachitem?.user_email,
                          });
                        }}
                        key={eachitem?.first_name + index}
                      >
                        <img
                          src={eachitem?.profile_picture}
                          alt={eachitem?.first_name}
                        />
                        <div>
                          <div>
                            {eachitem?.first_name} &nbsp; {eachitem?.last_name}
                          </div>
                          <div>{eachitem?.user_email}</div>
                        </div>
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name, eachitem?.app_icon);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"Select Brokerage"}
              >
                {title == "Select Brokerage"
                  ? "Search one of your brokerage.."
                  : "Select an option"}
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const ImageUploadDivIcon = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDiv}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor="profileUpdateImgPlanB"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanB"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            padding: stateValue[name] ? "0" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanB"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await");
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

export const ImageUploadDivProfilePic = ({
  heading,
  setState,
  stateValue,
  uploadIcon,
  name,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={classNames.imageUploadDivProfilePic}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{heading}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
          padding: stateValue[name] ? "0" : "",
        }}
        htmlFor="profileUpdateImgPlanBProfilePic"
      >
        <img
          src={
            stateValue[name]
              ? stateValue[name]
              : uploadIcon
              ? uploadIcon
              : dummyProfile
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanBProfilePic"
          style={{
            maxWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            maxHeight: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minWidth: stateValue[name] ? "" : uploadIcon ? "40%" : "",
            minHeight: stateValue[name]
              ? "calc(5vw + 5rem)"
              : uploadIcon
              ? "40%"
              : "",
            padding: stateValue[name] ? "0" : "",
            borderRadius: stateValue[name] ? "10px" : "",
            width: stateValue[name] ? "100%" : "",
            minWidth: stateValue[name] ? "100%" : "",
          }}
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor="profileUpdateImgPlanBProfilePic"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadImageFunc(
              e,
              setState,
              setplanBAccountPicUploading
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await", name);
          }}
          accept="image/*"
          id="profileUpdateImgPlanBProfilePic"
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};
