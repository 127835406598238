import React, { useContext, useEffect, useState, useRef } from "react";
import classNames from "../dashboard.module.scss";
import styles from "./ap.module.scss";

import flatpickr from "flatpickr";

import { IoMdClose, IoMdCloseCircleOutline, IoMdSearch } from "react-icons/io";

import arrowDown from "./asset/arrowDown.svg";
import calendarIcon from "./asset/calendar.svg";
import calenderIcon from "../../../../assest/images/icons/calender.svg";
import uploadIcon from "./asset/upload.svg";
import Axios from "axios";
import { uploadFileFunc, uploadImageFunc } from "../../../../utils/imageUpload";
import { BankContext } from "../../../../context/Context";

import { ReactComponent as AddIcon } from "../../../../assest/images/allicons/tableaction/add.svg";
import { predefinedToast } from "../../../../utils/toast";
import {
  checkAllFieldsHaveValuesArrayOfObjects,
  checkAllFieldsHaveValuesObjects,
  checkPolicyNumberExist,
} from "../../../../assest/functions";
//assets
import { MdDelete } from "react-icons/md";
import { LoadingAnimationRahaLogoFixed } from "../../../../components/Custom";

const AddPolicy = ({ setPopup, selectedRow, setSelectedRow }) => {
  const { setUpdateLoading, globalHrId } = useContext(BankContext);

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [addEmail, setAddEmail] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [affiliate, setAffiliate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [noOfEmployee, setNoOfEmployee] = useState("");
  const [associate, setAssociate] = useState("");
  const [teamLead, setTeamLead] = useState("");
  const [verticalHead, setVerticalHead] = useState("");
  const [businessHead, setBusinessHead] = useState("");

  const source = ["Online", "Offline", "Affiliate"];
  const poilicyType = ["Health", "Accident", "Life"];
  const sumInsured = ["Flat", "Graded"];
  const raterCardType = ["Per Individual", "Per Family"];

  const [cdBalId, setCdBalId] = useState("");

  const [allQuotes, setAllQuotes] = useState([]);
  const [showAllQuotes, setShowAllQuotes] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);

  const [policyType, setPolicyType] = useState("");
  const [policyVariant, setPolicyVariant] = useState("");
  const famDef = [
    {
      displayName: "Only Employee",
      apiName: "Only The Employee",
    },
    {
      displayName: "Employee + Spouse",
      apiName: "Employee + Spouse",
    },
    {
      displayName: "Employee + Spouse + 2 Children",
      apiName: "Employee + Spouse + 2 Children",
    },
    {
      displayName: "Employee + Spouse + 2 Children + 2 Parent/Parent In-Laws",
      apiName: "Employee + Spouse + 2 Children + 2 Parents",
    },
    {
      displayName: "Employee + Spouse + 2 Children + 4 Parent/Parent In-Laws",
      apiName: "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws",
    },
  ];
  const [showFamDef, setShowFamDef] = useState(false);
  const [selectedFamDef, setSelectedFamDef] = useState(null);

  const [flatSumInsured, setFlatSumInsured] = useState(0);
  const [gradedSumInsured, setGradedSumInsured] = useState([0]);
  const [policyNumber, setPolicyNumber] = useState("");
  const [policyNumberValidation, setPolicyNumberValidation] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [premiumAmount, setPremiumAmount] = useState("");

  const [allTPAs, setAllTPAs] = useState([]);
  const [showAllTPAs, setShowAllTPAs] = useState(false);
  const [selectedTPA, setSelectedTPA] = useState(null);

  const [allInsComp, setAllInsComp] = useState([]);
  const [showAllInsComp, setShowAllInsComp] = useState(false);
  const [selectedInsComp, setSelectedInsComp] = useState(null);

  const [selectedRaterCardType, setSelectedRaterCardType] = useState("");
  const [raterCardAmt, setRaterCardAmt] = useState(0);

  const [empNo, setEmpNo] = useState("");
  const [dependentNo, setDependentNo] = useState("");
  const [brokerCom, setBrokerCom] = useState("");
  const [affCom, setAffCom] = useState("");

  const [uploadFileQCR, setUploadFileQCR] = useState("");
  const [uploadFileQCRName, setUploadFileQCRName] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [addPolicySearch, setAddPolicySearch] = useState("");

  const [createPolicyLoading, setCreatePolicyLoading] = useState(false);

  // const [terms, setTerms] = useState([
  //   {
  //     termNumber: 1,
  //     termDescription: "",
  //     sub_terms: [],
  //   },
  // ]);

  const [terms, setTerms] = useState([
    {
      termNumber: 1,
      termDescription: "",
      sub_terms: [
        {
          termNumber: 1,
          termDescription: "",
        },
      ],
    },
  ]);

  useEffect(() => {
    console.log(selectedRow, "lksdhfkwaehflkwehf");
  }, [selectedRow]);

  const getCdBal = () => {
    Axios.get(
      `https://insurance.apimachine.com/insurance/cdBalance/hr/get?hr_id=${selectedRow?.hr_id}`
    ).then(({ data }) => {
      if (data.status) {
        setCdBalId(data.data[0]);
      }
    });
  };

  const getQuotes = () => {
    Axios.get(
      `https://insurance.apimachine.com/insurance/policy/get/quote?email_id=${selectedRow?.lead_email}&next_Step=Completed`
    ).then(({ data }) => {
      if (data.status) {
        setAllQuotes(data.data);
      }
    });
  };

  const getTPAs = () => {
    Axios.get(`https://api-dev.rahainsure.com/api/v1/tpa`).then(({ data }) => {
      if (data.status) {
        setAllTPAs(data.data);
      }
    });
  };

  const getInsComps = () => {
    Axios.get(`https://api-dev.rahainsure.com/api/v1/insurance-company`).then(
      ({ data }) => {
        if (data.status) {
          setAllInsComp(data.data);
        }
      }
    );
  };

  useEffect(() => {
    console.log(selectedRow, "kjwekjfwbekfbwkejfbkwebf");
    getCdBal();
    getQuotes();
    getTPAs();
    getInsComps();
  }, []);
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    if (selectedRow) {
      Axios.get(
        `https://insurance.apimachine.com/insurance/user/getuserallprofile?user_email=${selectedRow?.lead_email}`
      ).then(({ data }) => {
        if (data.status) {
          setUserInfo(data.data);
        }
      });
    }
  }, [selectedRow]);

  const handleAddPolicy = () => {
    document.body.style.pointerEvents = "none";
    setCreatePolicyLoading(true);

    let obj = {
      company_id: userInfo?.hrDetails?.hrCompanyDetails?._id,
      hr_id: cdBalId?.hr_id,
      cdBalance_id: cdBalId?.cdBalance_id,
      policy_number: policyNumber,
      policy_type: policyType,
      policy_pdf_link: uploadFileQCR,
      premium_amount: premiumAmount,
      policy_variant: policyVariant,
      // sum_insured: flatSumInsured ? [flatSumInsured] : gradedSumInsured,
      covered_members: selectedFamDef?.apiName,
      terms_and_conditions: terms,
      start_date: startDate,
      end_date: endDate,
      rate_card:
        selectedRaterCardType === "Per Individual"
          ? "Individual"
          : selectedRaterCardType === "Per Family"
          ? "Family"
          : "",
      // rate_card_amount: raterCardAmt,
      total_employees: empNo,
      total_dependants: dependentNo,
      has_tpa: true,
      tpa_id: selectedTPA?._id,
      insurance_company_id: selectedInsComp?._id,
      broker_commission: brokerCom,
      affiliate_commission: affCom,
    };

    Axios.post(
      `https://api-dev.rahainsure.com/api/v1/policy/create`,
      selectedQuote?.quote_id == "No Quote"
        ? obj
        : { ...obj, quote_id: selectedQuote?.quote_id }
    )
      .then(({ data }) => {
        if (data.status) {
          setPopup(false);
          setSelectedRow(null);
          predefinedToast("Add policy successful!");
        } else {
          predefinedToast(
            data?.message ? data?.message : "Error while adding policy!"
          );
        }
        document.body.style.pointerEvents = "unset";
        setCreatePolicyLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Create policy error");
        document.body.style.pointerEvents = "unset";
        setCreatePolicyLoading(false);
        predefinedToast("Error while adding policy!");
      });
  };

  const handleAddInput = () => {
    setGradedSumInsured([...gradedSumInsured, 0]); // Add a new empty string to the array
  };

  const handleInputChange = (index, value) => {
    const updatedValues = [...gradedSumInsured];
    updatedValues[index] = Number(value);
    setGradedSumInsured(updatedValues);
  };

  const handleDeleteInput = (index) => {
    if (index !== 0) {
      const updatedValues = [...gradedSumInsured];
      updatedValues.splice(index, 1);
      setGradedSumInsured(updatedValues);
    }
  };

  // term

  // const handleAddSubTerm = (index) => {
  //   const updatedTerms = [...terms];
  //   updatedTerms[index].sub_terms.push("");
  //   setTerms(updatedTerms);
  // };

  // const handleAddTerm = () => {
  //   setTerms((prevTerms) => [
  //     ...prevTerms,
  //     {
  //       termNumber: prevTerms.length + 1,
  //       termDescription: "",
  //       sub_terms: [""],
  //     },
  //   ]);
  // };

  // const handleTermChange = (index, field, subIndex, value) => {
  //   const updatedTerms = [...terms];
  //   if (field === "termDescription") {
  //     updatedTerms[index].termDescription = value;
  //   } else if (field === "subTerm") {
  //     updatedTerms[index].sub_terms[subIndex] = value;
  //   }
  //   setTerms(updatedTerms);
  // };

  // const handleDeleteSubTerm = (index, subIndex) => {
  //   const updatedTerms = [...terms];
  //   updatedTerms[index].sub_terms.splice(subIndex, 1);
  //   setTerms(updatedTerms);
  // };

  // const handleDeleteTerm = (index) => {
  //   const updatedTerms = [...terms];
  //   updatedTerms.splice(index, 1);
  //   setTerms(updatedTerms);
  // };

  const handleAddSubTerm = (index) => {
    const updatedTerms = [...terms];
    updatedTerms[index].sub_terms.push({
      termNumber: updatedTerms[index].sub_terms.length + 1,
      termDescription: "",
    });
    setTerms(updatedTerms);
  };

  const handleAddTerm = () => {
    setTerms((prevTerms) => [
      ...prevTerms,
      {
        termNumber: prevTerms.length + 1,
        termDescription: "",
        sub_terms: [
          {
            termNumber: 1,
            termDescription: "",
          },
        ],
      },
    ]);
  };

  const handleTermChange = (index, field, subIndex, value) => {
    const updatedTerms = [...terms];
    if (field === "termDescription") {
      updatedTerms[index].termDescription = value;
    } else if (field === "subTermDescription") {
      updatedTerms[index].sub_terms[subIndex].termDescription = value;
    }
    setTerms(updatedTerms);
  };

  const handleDeleteSubTerm = (index, subIndex) => {
    const updatedTerms = [...terms];
    updatedTerms[index].sub_terms.splice(subIndex, 1);
    setTerms(updatedTerms);
  };

  const handleDeleteTerm = (index) => {
    const updatedTerms = [...terms];
    updatedTerms.splice(index, 1);
    setTerms(updatedTerms);
  };

  return (
    <>
      <div
        className={classNames.overlay}
        style={{
          pointerEvents: createPolicyLoading ? "none" : "",
        }}
        onClick={(e) => {
          setPopup(false);
        }}
      ></div>
      {createPolicyLoading && <LoadingAnimationRahaLogoFixed />}
      <div
        className={styles.quoteDetailsWrapper}
        style={{
          width: "50vw",
          pointerEvents: createPolicyLoading ? "none" : "",
        }}
      >
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => {
            setPopup(false);
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>Add new policy</div>
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>CD Balance ID</div>
              <input
                disabled
                type="text"
                placeholder="7fc9dfc2-333c-4b67-8f67-8b558eb08809.."
                value={cdBalId?.cdBalance_id}
                // onChange={(e) => setAffiliate(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.rowWrapper1}>
            {!showAllQuotes ? (
              <div
                className={styles.dropdown}
                onClick={(e) => setShowAllQuotes(!showAllQuotes)}
              >
                <div>
                  {selectedQuote ? selectedQuote?.quote_id : "Select Quote"}
                </div>
                <img
                  src={arrowDown}
                  alt=""
                  onClick={(e) => setShowAllQuotes(false)}
                />
              </div>
            ) : (
              <div className={styles.dropdown1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                  onClick={(e) => setShowAllQuotes(!showAllQuotes)}
                >
                  <div>Select Quote</div>
                  <img src={arrowDown} alt="" />
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    height: "calc(100% - 40px)",
                    overflowY: "scroll",
                  }}
                >
                  {allQuotes.map((item, index) => (
                    <div
                      className={styles.individualQuote}
                      onClick={(e) => {
                        setSelectedQuote(item);
                        setShowAllQuotes(false);
                      }}
                    >
                      <div>Quote {index + 1}</div>
                      <div>{item?.quote_id}</div>
                    </div>
                  ))}
                  <div
                    className={styles.noQuote}
                    onClick={() => {
                      setSelectedQuote({ quote_id: "No Quote" });
                      setShowAllQuotes(false);
                    }}
                  >
                    No Quote
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Policy Type */}
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup1}>
              <div>What type of policy is it?</div>
              <div className={styles.leadSourceWrapper}>
                {poilicyType.map((item) => (
                  <div
                    className={
                      policyType === item
                        ? styles.leadSourceSelected
                        : styles.leadSource
                    }
                    onClick={(e) => {
                      setPolicyType(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Policy Variant */}
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup1}>
              <div>What type of sum insured is it?</div>
              <div className={styles.leadSourceWrapper}>
                {sumInsured.map((item) => (
                  <div
                    className={
                      policyVariant === item
                        ? styles.leadSourceSelected
                        : styles.leadSource
                    }
                    onClick={(e) => {
                      setPolicyVariant(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* family definition */}
          <div className={styles.rowWrapper1}>
            {!showFamDef ? (
              <div
                className={styles.dropdown}
                onClick={(e) => setShowFamDef(!showFamDef)}
              >
                <div>
                  {selectedFamDef ? (
                    selectedFamDef?.displayName
                  ) : (
                    <div className={styles.dropDwnTitle}>
                      Select the family definition
                    </div>
                  )}
                </div>
                <img
                  src={arrowDown}
                  alt=""
                  onClick={(e) => setShowFamDef(false)}
                />
              </div>
            ) : (
              <div className={styles.dropdown1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                  onClick={(e) => setShowFamDef(!showFamDef)}
                >
                  <div className={styles.dropDwnTitle}>
                    Select the family definition
                  </div>
                  <img src={arrowDown} alt="" />
                </div>
                <div style={{ paddingTop: "10px" }}>
                  {famDef.map((item, index) => (
                    <div
                      className={styles.individualQuote1}
                      onClick={(e) => {
                        setSelectedFamDef(item);
                        setShowFamDef(false);
                      }}
                    >
                      <div>{item?.displayName}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Flat Sum Insured */}
          {/* {policyVariant === "Flat" && (
            <div className={styles.rowWrapper1}>
              <div className={styles.inputGroup}>
                <div>Flat sum insured</div>
                <input
                  type="text"
                  placeholder="0.00"
                  value={flatSumInsured}
                  onChange={(e) => setFlatSumInsured(e.target.value)}
                />
              </div>
            </div>
          )} */}

          {/* Graded Sum Insured */}
          {/* {policyVariant === "Graded" && (
            <>
              <div className={styles.rowWrapper1}>
                <div className={styles.inputGroup}>
                  <div>Graded sum insured</div>
                  {gradedSumInsured.map((value, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {index !== 0 && (
                        <IoMdCloseCircleOutline
                          onClick={() => handleDeleteInput(index)}
                          style={{
                            fontSize: "20px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                      <input
                        type="text"
                        value={value}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />
                    </div>
                  ))}
                  <div className={styles.addRowBtn} onClick={handleAddInput}>
                    ADD
                  </div>
                </div>
              </div>
            </>
          )} */}
          {/* Policy number */}
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Policy number</div>
              <div className={styles.validationInputParent}>
                <input
                  type="text"
                  placeholder="Enter.."
                  value={policyNumber}
                  onChange={(e) => {
                    if (e.target.value?.length == 0) {
                      setPolicyNumberValidation(true);
                    } else {
                      checkPolicyNumberExist(
                        e.target.value,
                        setPolicyNumberValidation
                      );
                    }
                    setPolicyNumber(e.target.value);
                  }}
                />
                <div
                  className={styles.dotValidation}
                  style={{
                    background: policyNumberValidation
                      ? "red"
                      : "var(--main-color)",
                  }}
                ></div>
              </div>
            </div>
          </div>
          {/* Start & End Date */}
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup}>
              <div>Start date</div>
              <EachInputDatePicker
                placeholder="Start Date"
                stateValue={startDate}
                setState={setStartDate}
              />
            </div>
            <div className={styles.inputGroup}>
              <div>End date</div>
              <EachInputDatePicker
                placeholder="End Date"
                stateValue={endDate}
                setState={setEndDate}
              />
            </div>
          </div>
          {/* Premium Amount */}
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Premium amount</div>
              <input
                type="text"
                placeholder="0.00"
                value={premiumAmount}
                onChange={(e) => setPremiumAmount(e.target.value)}
              />
            </div>
          </div>
          {/* select tpa */}
          <div className={styles.rowWrapper1}>
            {!showAllTPAs ? (
              <div
                className={styles.dropdown}
                onClick={(e) => setShowAllTPAs(!showAllTPAs)}
              >
                <div>
                  {selectedTPA ? (
                    selectedTPA?.tpa_name
                  ) : (
                    <div className={styles.dropDwnTitle}>Select TPA</div>
                  )}
                </div>
                <img
                  src={arrowDown}
                  alt=""
                  onClick={(e) => setShowAllTPAs(false)}
                />
              </div>
            ) : (
              <div className={styles.dropdown1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                  onClick={(e) => setShowAllTPAs(!showAllTPAs)}
                >
                  <div className={styles.dropDwnTitle}>Select TPA</div>
                  <img src={arrowDown} alt="" />
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    height: "150px",
                    overflowY: "scroll",
                  }}
                >
                  {allTPAs.map((item, index) => (
                    <div
                      className={styles.individualQuote1}
                      onClick={(e) => {
                        setSelectedTPA(item);
                        setShowAllTPAs(false);
                      }}
                    >
                      <div>{item?.tpa_name}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className={styles.rowWrapper1}>
            {!showAllInsComp ? (
              <div
                className={styles.dropdown}
                onClick={(e) => setShowAllInsComp(!showAllInsComp)}
              >
                <div>
                  {selectedInsComp ? (
                    selectedInsComp?.insurance_company_name
                  ) : (
                    <div className={styles.dropDwnTitle}>
                      Select Insurance Company
                    </div>
                  )}
                </div>
                <img
                  src={arrowDown}
                  alt=""
                  onClick={(e) => setShowAllInsComp(false)}
                />
              </div>
            ) : (
              <div className={styles.dropdown1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                  onClick={(e) => setShowAllInsComp(!showAllInsComp)}
                >
                  <div className={styles.dropDwnTitle}>
                    Select Insurance Company
                  </div>
                  <img src={arrowDown} alt="" />
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    height: "150px",
                    overflowY: "scroll",
                  }}
                >
                  <input
                    className={styles.dropdown1Search}
                    type="text"
                    placeholder="Search.."
                    onChange={(event) => {
                      setAddPolicySearch(event?.target?.value);
                    }}
                  />
                  {allInsComp
                    ?.filter((eachItem) => {
                      return eachItem?.insurance_company_name
                        ?.toLowerCase()
                        ?.includes(addPolicySearch?.toLowerCase());
                    })
                    ?.map((item, index) => (
                      <div
                        className={styles.individualQuote1}
                        onClick={(e) => {
                          setSelectedInsComp(item);
                          setShowAllInsComp(false);
                        }}
                      >
                        <div>{item?.insurance_company_name}</div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>

          {/* Add Upload box here */}

          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Upload policy document</div>
              <div className={styles.uploadContainer}>
                <label
                  className={classNames.uploadDocument}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  htmlFor="fileUploadRFQ"
                >
                  <img src={uploadIcon} alt="" className={styles.upldImg} />
                  <div className={styles.upldTxt}>
                    {!loadingUpload
                      ? uploadFileQCRName?.name
                        ? uploadFileQCRName?.name
                        : "Upload File"
                      : "Uploading..."}
                  </div>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={async (e) => {
                      let resFile = uploadFileFunc(
                        e,
                        setUploadFileQCRName,
                        setLoadingUpload,
                        "name"
                      );
                      console.log(await resFile, "res file link await");
                      setUploadFileQCR(await resFile);
                    }}
                    id="fileUploadRFQ"
                  />
                </label>
              </div>
            </div>
          </div>

          {/* Select rater card type */}
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup1}>
              <div>Select rater card type</div>
              <div className={styles.leadSourceWrapper}>
                {raterCardType.map((item) => (
                  <div
                    className={
                      selectedRaterCardType === item
                        ? styles.leadSourceSelected
                        : styles.leadSource
                    }
                    onClick={(e) => {
                      console.log(">" + item + "<", "kjwgedkwjgefkjwgekjf");
                      setSelectedRaterCardType(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Rater card amount */}
          {/* <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Rater card amount</div>
              <input
                type="text"
                placeholder="0.00"
                value={raterCardAmt}
                onChange={(e) => setRaterCardAmt(e.target.value)}
              />
            </div>
          </div> */}

          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup}>
              <div>No of employees covered</div>
              <input
                type="text"
                placeholder="0"
                value={empNo}
                onChange={(e) => {
                  if (/^-?\d+$/.test(e?.target?.value)) {
                    setEmpNo(e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    setEmpNo("");
                  }
                }}
              />
            </div>
            {/* No of dependents covered */}
            <div className={styles.inputGroup}>
              <div>No of dependents covered</div>
              <input
                type="text"
                placeholder="0"
                value={dependentNo}
                onChange={(e) => {
                  if (/^-?\d+$/.test(e?.target?.value)) {
                    setDependentNo(e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    setDependentNo("");
                  }
                }}
              />
            </div>
          </div>
          {/* Bokerage commission % */}
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup}>
              <div>Brokerage commission %</div>
              <input
                type="text"
                placeholder="0.00%"
                value={brokerCom}
                onChange={(e) => {
                  if (/^\d*\.?\d*$/.test(e?.target?.value)) {
                    setBrokerCom(e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    setBrokerCom("");
                  }
                }}
              />
            </div>
            <div className={styles.inputGroup}>
              <div>Affiliate commission %</div>
              <input
                type="text"
                placeholder="0.00%"
                value={affCom}
                onChange={(e) => {
                  if (/^\d*\.?\d*$/.test(e?.target?.value)) {
                    setAffCom(e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    setAffCom("");
                  }
                }}
              />
            </div>
          </div>

          {/* Terms */}

          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              {terms.map((term, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>Add employee coverage {term.termNumber}</div>
                    {index !== 0 && (
                      <button
                        onClick={() => handleDeleteTerm(index)}
                        className={styles.addRowBtn1}
                        style={{ float: "right" }}
                      >
                        Delete Term
                      </button>
                    )}
                  </div>

                  <div>
                    {/* <label>Term Description:</label> */}

                    <input
                      placeholder="Term Description"
                      type="text"
                      value={term.termDescription}
                      onChange={(e) =>
                        handleTermChange(
                          index,
                          "termDescription",
                          null,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div>
                    {/* <label>Sub Terms:</label> */}
                    {term.sub_terms.map((subTerm, subIndex) => (
                      <div
                        key={subIndex}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {/* {subIndex !== 0 && ( */}
                        <IoMdCloseCircleOutline
                          onClick={() => handleDeleteSubTerm(index, subIndex)}
                          style={{
                            fontSize: "20px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        />
                        <input
                          placeholder="Sub Term Description"
                          type="text"
                          value={subTerm.termDescription}
                          onChange={(e) =>
                            handleTermChange(
                              index,
                              "subTermDescription",
                              subIndex,
                              e.target.value
                            )
                          }
                        />
                        {/* <input
                          placeholder="Enter Sub Term.."
                          type="text"
                          value={subTerm}
                          onChange={(e) =>
                            handleTermChange(
                              index,
                              "subTerm",
                              subIndex,
                              e.target.value
                            )
                          }
                        /> */}
                        {/* <button
                          onClick={() => handleDeleteSubTerm(index, subIndex)}
                        >
                          Delete Sub Term
                        </button> */}
                      </div>
                    ))}

                    <div
                      className={styles.addRowBtn}
                      onClick={() => handleAddSubTerm(index)}
                    >
                      Add Sub Term
                    </div>
                  </div>
                </div>
              ))}
              <button className={styles.addRowBtn2} onClick={handleAddTerm}>
                Add New Term
              </button>
              {/* <div>
                <strong>All Terms:</strong> {JSON.stringify(terms)}
              </div> */}
            </div>

            <div
              onClick={(e) => {
                handleAddPolicy();
              }}
              className={styles.addBtn}
              style={{
                pointerEvents:
                  policyNumber &&
                  policyType &&
                  uploadFileQCR &&
                  premiumAmount &&
                  policyVariant &&
                  startDate &&
                  endDate &&
                  selectedRaterCardType &&
                  empNo &&
                  dependentNo &&
                  brokerCom &&
                  affCom
                    ? ""
                    : "none",
                opacity:
                  policyNumber &&
                  policyType &&
                  uploadFileQCR &&
                  premiumAmount &&
                  policyVariant &&
                  startDate &&
                  endDate &&
                  selectedRaterCardType &&
                  empNo &&
                  dependentNo &&
                  brokerCom &&
                  affCom
                    ? ""
                    : "0.5",
              }}
            >
              {createPolicyLoading ? "Adding Policy.." : "Add Policy"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPolicy;

export const AddNewRaterCard = ({
  popup,
  setPopup,
  selectedRow,
  setSelectedRow,
}) => {
  const allGrades = [
    ...Array.from(Array(26), (_, i) => String.fromCharCode(i + 65)),
  ];

  const [loading, setLoading] = useState(false);
  const [validationIssue, setValidationIssue] = useState(false);
  const [raterCardAmount, setRaterCardAmount] = useState("");
  const [newRaterCardWhole, setNewRaterCardWhole] = useState({
    policy_id: popup?.selectedpolicy?._id,
    policy_number: popup?.selectedpolicy?.policy_number,
  });
  const [newRaterCard, setNewRaterCard] = useState({});

  //age range
  const [ageRanges, setAgeRanges] = useState([]);

  const handleAddAgeRange = () => {
    setAgeRanges([
      ...ageRanges,
      { min_age: "", max_age: "", rate_card_amount: "" },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedAgeRanges = [...ageRanges];
    updatedAgeRanges[index][field] = value;
    setAgeRanges(updatedAgeRanges);
  };

  async function createNewRaterCard() {
    newRaterCardWhole.rater_card_details = [
      {
        ...newRaterCard,
        age_ranges:
          popup?.selectedpolicy?.rate_card?.toLowerCase() == "family"
            ? [
                {
                  min_age: 0,
                  max_age: 100,
                  rate_card_amount: raterCardAmount,
                },
              ]
            : ageRanges,
      },
    ];
    if (!newRaterCard?.policy_grade) {
      predefinedToast("Policy grade is mandatory!");
      return;
    }
    setLoading(true);

    try {
      let response = await Axios.post(
        "https://api-dev.rahainsure.com/api/v1/policy/policy-rate-card/create",
        newRaterCardWhole
      );
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
        setPopup(null);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      console.log(response, "createNewRaterCard response");
      setLoading(false);
    } catch (error) {
      console.log(error?.message, "createNewRaterCard error");
      setLoading(false);
    }
  }

  //rendering

  useEffect(() => {
    console.log("rendering policy id & number in new rater card popup");
    setNewRaterCardWhole((prev) => {
      return {
        ...prev,
        policy_id: popup?.selectedpolicy?._id,
        policy_number: popup?.selectedpolicy?.policy_number,
      };
    });
  }, [popup?.selectedpolicy]);

  useEffect(() => {
    const isAnyMinGreaterThanMax = ageRanges.some(
      (range) =>
        range.min_age !== "" &&
        range.max_age !== "" &&
        parseInt(range.min_age) > parseInt(range.max_age)
    );
    setValidationIssue(isAnyMinGreaterThanMax);
  }, [ageRanges]);

  return (
    <>
      <div
        className={classNames.overlay}
        onClick={(e) => {
          setPopup(false);
        }}
      ></div>
      <div className={styles.quoteDetailsWrapper} style={{ width: "50vw" }}>
        <IoMdClose
          style={{
            fontSize: "20px",
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() => {
            setPopup(false);
          }}
        />
        <div className={styles.popupWrapper}>
          <div className={styles.popupTitle}>Add new rater card</div>
          <div className={styles.rowWrapper1} style={{ pointerEvents: "none" }}>
            <div
              className={styles.inputGroup}
              style={{
                border: "1px solid #e1e1e1",
                borderRadius: "7px",
                padding: "0.5rem",
              }}
            >
              <div>Policy Number ({newRaterCardWhole?.policy_number})</div>
              <input
                disabled
                type="text"
                placeholder="Policy ID"
                value={newRaterCardWhole?.policy_id}
                style={{ border: "none", padding: "0", height: "unset" }}
                // onChange={(e) =>
                //   setNewRaterCard((prev) => {
                //     return { ...prev, policy_id: e.target.value };
                //   })
                // }
              />
            </div>
          </div>

          {/* select grade */}
          <div className={styles.rowWrapper}>
            <div className={styles.inputGroup1}>
              <div>Select grade</div>
              <div className={styles.leadSourceWrapper}>
                {popup?.selectedpolicy?.policy_variant?.toLowerCase() ==
                "flat" ? (
                  <div
                    className={
                      newRaterCard?.policy_grade === "Flat"
                        ? styles.leadSourceSelected
                        : styles.leadSource
                    }
                    style={{ width: "max-content", padding: "0 1.5rem" }}
                    onClick={() => {
                      setNewRaterCard((prev) => {
                        return { ...prev, policy_grade: "Flat" };
                      });
                    }}
                  >
                    Flat
                  </div>
                ) : (
                  allGrades?.map((item) => (
                    <div
                      className={
                        newRaterCard?.policy_grade === item
                          ? styles.leadSourceSelected
                          : styles.leadSource
                      }
                      style={{ width: "max-content", padding: "0 1.5rem" }}
                      onClick={() => {
                        setNewRaterCard((prev) => {
                          return { ...prev, policy_grade: item };
                        });
                      }}
                    >
                      {item}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          {/* Sum insured */}
          <div className={styles.rowWrapper1}>
            <div className={styles.inputGroup}>
              <div>Sum insured for this grade</div>
              <input
                type="text"
                placeholder="0.00"
                value={newRaterCard?.sum_insured}
                onChange={(e) => {
                  e.persist();
                  if (/^-?\d+$/.test(e?.target?.value)) {
                    console.log(e?.target?.value, "sum_insured");
                    setNewRaterCard((prev) => {
                      return { ...prev, sum_insured: e?.target?.value };
                    });
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    setNewRaterCard((prev) => {
                      return { ...prev, sum_insured: "" };
                    });
                  }
                }}
              />
            </div>
          </div>

          {/* Rater card amount */}
          {popup?.selectedpolicy?.rate_card?.toLowerCase() == "family" && (
            <div className={styles.rowWrapper1}>
              <div className={styles.inputGroup}>
                <div>Rater card amount</div>
                <input
                  type="text"
                  placeholder="0.00"
                  value={raterCardAmount}
                  onChange={(e) => {
                    e.persist();
                    if (/^\d*\.?\d*$/.test(e?.target?.value)) {
                      console.log(e?.target?.value, "sum_insured");
                      setRaterCardAmount(e?.target?.value);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (
                      event?.target?.value.length == 1 &&
                      event.key === "Backspace"
                    ) {
                      setRaterCardAmount("");
                    }
                  }}
                />
              </div>
            </div>
          )}

          {/* age range */}
          {popup?.selectedpolicy?.rate_card?.toLowerCase() == "family" ? (
            ""
          ) : (
            <>
              {ageRanges?.map((range, index) => (
                <div
                  key={index}
                  className={styles.rowWrapper}
                  style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
                >
                  <div className={styles.inputGroup}>
                    <div>Age range {index + 1}</div>
                    <input
                      type="text"
                      placeholder="Min age"
                      value={range.min_age}
                      style={{
                        border:
                          Number(range?.max_age) > Number(range?.min_age)
                            ? ""
                            : range?.max_age && range?.min_age
                            ? "1px solid red"
                            : "",
                      }}
                      onChange={(e) => {
                        if (
                          Number(e?.target?.value) <= 100 &&
                          /^-?\d+$/.test(e?.target?.value) &&
                          e?.target?.value.length <= 3
                        ) {
                          handleInputChange(index, "min_age", e.target.value);
                        }
                      }}
                      onKeyDown={(event) => {
                        if (
                          event?.target?.value.length == 1 &&
                          event.key === "Backspace"
                        ) {
                          handleInputChange(index, "min_age", "");
                        }
                      }}
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <div
                      style={{ visibility: "hidden", pointerEvents: "none" }}
                    >
                      dummy
                    </div>
                    <input
                      type="text"
                      placeholder="Max age"
                      value={range.max_age}
                      onChange={(e) => {
                        if (
                          Number(e?.target?.value) <= 100 &&
                          /^-?\d+$/.test(e?.target?.value) &&
                          e?.target?.value.length <= 3
                        ) {
                          handleInputChange(index, "max_age", e.target.value);
                        }
                      }}
                      onKeyDown={(event) => {
                        if (
                          event?.target?.value.length == 1 &&
                          event.key === "Backspace"
                        ) {
                          handleInputChange(index, "max_age", "");
                        }
                      }}
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <div
                      style={{ visibility: "hidden", pointerEvents: "none" }}
                    >
                      dummy
                    </div>
                    <input
                      type="text"
                      placeholder="Amount"
                      value={range.rate_card_amount}
                      onChange={(e) => {
                        if (/^-?\d+$/.test(e?.target?.value)) {
                          handleInputChange(
                            index,
                            "rate_card_amount",
                            e.target.value
                          );
                        }
                      }}
                      onKeyDown={(event) => {
                        if (
                          event?.target?.value.length == 1 &&
                          event.key === "Backspace"
                        ) {
                          handleInputChange(index, "rate_card_amount", "");
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
              <div
                onClick={handleAddAgeRange}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Add age range
              </div>
            </>
          )}

          <div className={styles.rowWrapper1}>
            <div
              onClick={(e) => {
                createNewRaterCard();
              }}
              className={styles.addBtn}
              style={{
                opacity:
                  checkAllFieldsHaveValuesObjects(newRaterCard) &&
                  checkAllFieldsHaveValuesArrayOfObjects(ageRanges) &&
                  !validationIssue &&
                  !loading
                    ? ""
                    : validationIssue || loading
                    ? "0.5"
                    : "0.5",
                pointerEvents:
                  checkAllFieldsHaveValuesObjects(newRaterCard) &&
                  checkAllFieldsHaveValuesArrayOfObjects(ageRanges) &&
                  !validationIssue &&
                  !loading
                    ? ""
                    : validationIssue || loading
                    ? "none"
                    : "none",
              }}
            >
              {loading ? "Adding new rater card.." : "Add"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const EditNewRaterCard = ({
  popup,
  setPopup,
  setEditRaterCard,
  existingData,
}) => {
  const allGrades = [
    "Flat",
    ...Array.from(Array(26), (_, i) => String.fromCharCode(i + 65)),
  ];

  const [loading, setLoading] = useState(false);
  const [validationIssue, setValidationIssue] = useState(false);
  const [showAllQuotes, setShowAllQuotes] = useState(false);

  const [newRaterCard, setNewRaterCard] = useState({});

  //age range
  const [ageRanges, setAgeRanges] = useState([]);

  const handleAddAgeRange = () => {
    setAgeRanges([
      ...ageRanges,
      { min_age: "", max_age: "", rate_card_amount: "" },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedAgeRanges = [...ageRanges];
    updatedAgeRanges[index][field] = value;
    setAgeRanges(updatedAgeRanges);
  };

  function removeItemAtIndex(index) {
    // Check if the index is valid
    if (index < 0 || index >= ageRanges.length) {
      setAgeRanges(ageRanges);
    }

    // Create a new array with all elements except the one at the specified index
    const newArr = ageRanges.slice(0, index).concat(ageRanges.slice(index + 1));

    setAgeRanges(newArr);
  }

  async function updateRaterCard() {
    let newRaterCardWhole = {
      ...newRaterCard,
      age_ranges: ageRanges,
    };

    setLoading(true);

    try {
      let response = await Axios.put(
        `https://api-dev.rahainsure.com/api/v1/policy/policy-rate-card/update/${existingData?._id}`,
        newRaterCardWhole
      );
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
        setEditRaterCard(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      // console.log(response, "updateRaterCard response");
      setLoading(false);
    } catch (error) {
      console.log(error?.message, "updateRaterCard error");
      setLoading(false);
    }
  }

  //rendering

  useEffect(() => {
    const isAnyMinGreaterThanMax = ageRanges.some(
      (range) =>
        range.min_age !== "" &&
        range.max_age !== "" &&
        parseInt(range.min_age) > parseInt(range.max_age)
    );
    setValidationIssue(isAnyMinGreaterThanMax);
  }, [ageRanges]);

  useEffect(() => {
    console.log(existingData, "Edit rater card details");
    if (existingData) {
      setNewRaterCard({
        sum_insured: existingData?.sum_insured,
        policy_grade: existingData?.policy_grade,
      });
    }
    if (
      Array.isArray(existingData?.age_ranges) &&
      existingData?.age_ranges?.length > 0
    ) {
      setAgeRanges(existingData?.age_ranges);
    }
  }, []);

  return (
    <>
      <div className={styles.editRaterCard}>
        {/* policyType */}
        <div
          className={styles.rowWrapper1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
            width: "100%",
          }}
        >
          <div className={styles.editRaterCardDropdown}>
            <div className={styles.rowWrapper1}>
              {!showAllQuotes ? (
                <div
                  className={styles.dropdown}
                  onClick={(e) => setShowAllQuotes(!showAllQuotes)}
                >
                  <div>
                    {newRaterCard?.policy_grade
                      ? newRaterCard?.policy_grade
                      : "Select Grade"}
                  </div>
                  <img
                    src={arrowDown}
                    alt=""
                    onClick={(e) => setShowAllQuotes(false)}
                  />
                </div>
              ) : (
                <div className={styles.dropdown1}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                    onClick={(e) => setShowAllQuotes(!showAllQuotes)}
                  >
                    <div>Select Grade</div>
                    <img src={arrowDown} alt="" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    {allGrades?.map((item, index) => (
                      <div
                        className={styles.individualQuote}
                        onClick={(e) => {
                          setNewRaterCard((prev) => {
                            return { ...prev, policy_grade: item };
                          });
                          setShowAllQuotes(!showAllQuotes);
                        }}
                      >
                        <div>{item}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.inputBox}>
            <input
              type="text"
              placeholder="0.00"
              value={newRaterCard?.sum_insured}
              onChange={(e) => {
                e.persist();
                if (/^-?\d+$/.test(e?.target?.value)) {
                  console.log(e?.target?.value, "sum_insured");
                  setNewRaterCard((prev) => {
                    return { ...prev, sum_insured: e?.target?.value };
                  });
                }
              }}
              onKeyDown={(event) => {
                if (
                  event?.target?.value.length == 1 &&
                  event.key === "Backspace"
                ) {
                  setNewRaterCard((prev) => {
                    return { ...prev, sum_insured: "" };
                  });
                }
              }}
            />
          </div>
        </div>

        {/* age range */}
        {ageRanges?.map((range, index) => (
          <div
            key={index}
            className={styles.rowWrapper}
            style={{ gridTemplateColumns: "1fr 1fr 1.5fr" }}
          >
            <div className={styles.inputGroup}>
              <div>Min Age</div>
              <input
                type="text"
                placeholder="Min age"
                value={range.min_age}
                style={{
                  border:
                    Number(range?.max_age) > Number(range?.min_age)
                      ? ""
                      : range?.max_age && range?.min_age
                      ? "1px solid red"
                      : "",
                }}
                onChange={(e) => {
                  if (
                    Number(e?.target?.value) <= 100 &&
                    /^-?\d+$/.test(e?.target?.value) &&
                    e?.target?.value.length <= 3
                  ) {
                    handleInputChange(index, "min_age", e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    handleInputChange(index, "min_age", "");
                  }
                }}
              />
            </div>
            <div className={styles.inputGroup}>
              <div>Max Age</div>
              <input
                type="text"
                placeholder="Max age"
                value={range.max_age}
                onChange={(e) => {
                  if (
                    Number(e?.target?.value) <= 100 &&
                    /^-?\d+$/.test(e?.target?.value) &&
                    e?.target?.value.length <= 3
                  ) {
                    handleInputChange(index, "max_age", e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    handleInputChange(index, "max_age", "");
                  }
                }}
              />
            </div>
            <div className={styles.inputGroup}>
              <div>
                Rater Card Amount{" "}
                <MdDelete onClick={() => removeItemAtIndex(index)} />
              </div>
              <input
                type="text"
                placeholder="Amount"
                value={range.rate_card_amount}
                onChange={(e) => {
                  if (/^-?\d+$/.test(e?.target?.value)) {
                    handleInputChange(
                      index,
                      "rate_card_amount",
                      e.target.value
                    );
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event?.target?.value.length == 1 &&
                    event.key === "Backspace"
                  ) {
                    handleInputChange(index, "rate_card_amount", "");
                  }
                }}
              />
            </div>
          </div>
        ))}
        <div
          onClick={handleAddAgeRange}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Add age range
        </div>

        <div
          className={styles.rowWrapper1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "1rem",
            width: "100%",
          }}
        >
          <div
            className={styles.cancelBtn}
            onClick={() => setEditRaterCard(false)}
          >
            Cancel
          </div>
          <div
            onClick={(e) => {
              updateRaterCard();
            }}
            className={styles.saveRaterCard}
            style={{
              opacity:
                checkAllFieldsHaveValuesObjects(newRaterCard) &&
                checkAllFieldsHaveValuesArrayOfObjects(ageRanges) &&
                !validationIssue &&
                !loading
                  ? ""
                  : validationIssue || loading
                  ? "0.5"
                  : "0.5",
              pointerEvents:
                checkAllFieldsHaveValuesObjects(newRaterCard) &&
                checkAllFieldsHaveValuesArrayOfObjects(ageRanges) &&
                !validationIssue &&
                !loading
                  ? ""
                  : validationIssue || loading
                  ? "none"
                  : "none",
            }}
          >
            {loading ? "Updating rater card.." : "Save"}
          </div>
        </div>
      </div>
    </>
  );
};

const EachInputDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (datepickerRef.current) {
      const options = {
        dateFormat: "d-m-Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          console.log("Selected start date:", dateStr, datepickerRef.current);
          setState(`${dateStr}`);
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div className={classNames.eachInputDatePicker} style={{ display: "flex" }}>
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        value={stateValue}
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
      />
      <img
        src={calenderIcon}
        alt="calenderIcon"
        className={classNames.calendarIcon}
        style={{ marginLeft: "-30px" }}
      />
    </div>
  );
};
