import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";

import classNames from "./dashboard.module.scss";

//assets
import fullLogo from "../../assest/images/fullLogoWhite.svg";
import { ReactComponent as DashboardHamburger } from "../../assest/images/icons/dashboardHamburger.svg";
import { ReactComponent as PopularActionHamburger } from "../../assest/images/icons/popularActionHam.svg";
import { ReactComponent as PopularBackHamburger } from "../../assest/images/icons/popularBackHam.svg";
// import logoutIcon from "../../assets/images/icons/logout.svg";
import { BankContext } from "../../context/Context";

import { ReactComponent as ProactiveIcon } from "../../assest/images/icons/dashboard/proactive.svg";
import { ReactComponent as ClaimsIcon } from "../../assest/images/icons/dashboard/claims.svg";
import { ReactComponent as CoverageIcon } from "../../assest/images/icons/dashboard/coverage.svg";
import { ReactComponent as WalletIcon } from "../../assest/images/icons/dashboard/wallet.svg";
import { ReactComponent as HospitalsIcon } from "../../assest/images/icons/dashboard/hospitals.svg";
import { ReactComponent as DependentsIcon } from "../../assest/images/icons/dashboard/dependents.svg";
import { ReactComponent as WellnessIcon } from "../../assest/images/icons/dashboard/wellness.svg";
import { ReactComponent as EngageIcon } from "../../assest/images/icons/dashboard/engage.svg";
import { ReactComponent as CabinetIcon } from "../../assest/images/icons/dashboard/cabinet.svg";
import { ReactComponent as rahastoreIcon } from "../../assest/images/icons/dashboard/rahastore.svg";
import { ReactComponent as pluginIcon } from "../../assest/images/icons/dashboard/plugin.svg";
import { ReactComponent as rewardsIcon } from "../../assest/images/icons/dashboard/rewards.svg";
import { ReactComponent as o2RewardsIcon } from "../../assest/images/icons/dashboard/o2rewards.svg";
import { ReactComponent as brokeragesIcon } from "../../assest/images/icons/dashboard/brokerages.svg";
import { ReactComponent as agentsIcon } from "../../assest/images/icons/dashboard/agents.svg";
import { ReactComponent as AgentsNewIcon } from "../../assest/images/icons/dashboard/agentsnew.svg";
import { ReactComponent as crmIcon } from "../../assest/images/icons/dashboard/crm.svg";
import { ReactComponent as LeadsIcon } from "../../assest/images/icons/dashboard/leads.svg";
import { ReactComponent as LegacyIcon } from "../../assest/images/icons/dashboard/legacy.svg";
import { ReactComponent as growIcon } from "../../assest/images/icons/dashboard/grow.svg";
import { ReactComponent as AssociatesCRMIcon } from "../../assest/images/icons/dashboard/associatesCRM.svg";
import { ReactComponent as EmployeesIcon } from "../../assest/images/icons/dashboard/employees.svg";
import { ReactComponent as HrIcon } from "../../assest/images/icons/dashboard/hr.svg";

//app changes settings icon
import rahaxIcon from "../../assest/images/icons/apps/rahax.svg";
import agencyIcon from "../../assest/images/icons/apps/agency.svg";
import brokerappIcon from "../../assest/images/icons/apps/brokerapp.svg";
import coverfiIcon from "../../assest/images/icons/apps/coverfi.svg";
import lockIcon from "../../assest/images/icons/lock.svg";
import { ReactComponent as LockIcon } from "../../assest/images/icons/lockIcon.svg";
import rahaxFullLogo from "../../assest/images/rahax/fullLogo.svg";
import rahaEngage from "../../assest/images/rahax/rahaengage.svg";

import rahaAlertLogo from "../../assest/images/logos/rahaalert.svg";
import hrefArrow from "../../assest/images/icons/hrefarrow.svg";

import { AiOutlineClose } from "react-icons/ai";
import { predefinedToast } from "../../utils/toast";
import Axios from "axios";

import uploadFileIcon from "../../assest/images/icons/uploadfile.svg";
import { uploadFileFunc, uploadImageFunc } from "../../utils/imageUpload";
import { LoadingAnimationRaha } from "../Custom";
import ToggleButton, { ToggleButtonDashboard } from "../Custom/Toggle";
import {
  affiliatesAppSidebar,
  associatesAppSidebar,
  businessheadAppSidebar,
  employeeAppSidebar,
  teamleadAppSidebar,
  verticalheadAppSidebar,
} from "../../assest/data/dashboard";

const Dashboard = () => {
  const history = useHistory();
  const location = useLocation();
  const { apptype } = useParams();
  const {
    name,
    username,
    email,
    userLoginHandler,
    dashboardHam,
    setDashboardHam,
    popularAction,
    setPopularAction,
    actionBtns,
    setActionBtns,
    setOverlayOpened,
    loggedInApp,
    allLeadsAPI,
    selectedEdit,
    setSelectedEdit,
    setUpdateLoading,
    addNewEmployeeType,
    setAddNewEmployeeType,
    globalHrId,
    globalAgentId,
    dashboardToggle,
    policiesApiGlobal,
    setPoliciesApiGlobal,
  } = useContext(BankContext);

  const allStatus = [
    { name: "New Lead", searchValue: "leadInquired" },
    { name: "Contacted", searchValue: "inConversation" },
    {
      name: "HR Logged In",
      searchValue: "HRAccountLoggedIn",
      step: "true",
    },
    { name: "Policy Requirements Defined", searchValue: "HRConfiguredForm" },
    {
      name: "Employee Forms Partially Filled",
      searchValue: "formsPartiallyFilled",
    },
    {
      name: "Employee Forms Completely Filled",
      searchValue: "formsCompletelyFilled",
    },
    {
      name: "Quote Uploaded",
      searchValue: "quotesUploaded",
      step: "true",
    },
    {
      name: "Proposal Frozen",
      searchValue: "proposalFrozen",
    },
    {
      name: "Policy Activated With Raha Reference Number",
      searchValue: "activeClient_T",
      step: "true",
    },
    {
      name: "Policy Updated With Permanent Number",
      searchValue: "activeClient_P",
      step: "true",
    },
    {
      name: "Policy Updated With Employee Health Cards",
      searchValue: "activeClient_H",
    },
    {
      name: "Lead Lost",
      searchValue: "clientLost",
    },
  ];

  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");

  const [policyNoT, setPolicyNoT] = useState("");

  const [policyNoP, setPolicyNoP] = useState("");
  const [uploadFileP, setUploadFileP] = useState("");
  const [uploadFilePName, setUploadFilePName] = useState("");

  function getCurrentDate() {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const year = now.getFullYear();

    return `${month}/${day}/${year}`;
  }

  function logoutFunction() {
    setDashboardHam(false);
    userLoginHandler();
    localStorage.clear();
    if (window?.location?.pathname?.includes("coverfi")) {
      history.push(`/employer/login`);
    } else if (window?.location?.pathname?.includes("rahax")) {
      history.push(`/employee/login`);
    } else if (window?.location?.pathname?.includes("agency")) {
      history.push(`/associates/login`);
    } else if (window?.location?.pathname?.includes("teamlead")) {
      history.push(`/teamlead/login`);
    } else if (window?.location?.pathname?.includes("verticalhead")) {
      history.push(`/verticalhead/login`);
    } else if (window?.location?.pathname?.includes("businesshead")) {
      history.push(`/businesshead/login`);
    } else if (window?.location?.pathname?.includes("brokerapp")) {
      history.push(`/admin/login`);
    } else if (window?.location?.pathname?.includes("affiliates")) {
      history.push(`/affiliates/login`);
    }
  }

  function deleteBrokerageAPI() {
    setUpdateLoading(true);
    let URL;
    if (popularAction == "deletebrokerage") {
      URL = `https://insurance.apimachine.com/insurance/brokerage/delete?brokerage_id=${globalAgentId}`;
    } else if (popularAction == "deleteassociate") {
      URL = `https://insurance.apimachine.com/insurance/agent/delete?agent_id=${globalAgentId}`;
    } else if (popularAction == "deletelead") {
      URL = `https://insurance.apimachine.com/insurance/lead/deletemultiple`;
    } else {
    }

    if (popularAction == "deletelead") {
      Axios.put(URL, {
        lead_ids: actionBtns,
      })
        .then((response) => {
          // console.log(response, "brokerage deleted");
          setUpdateLoading(false);
          if (response?.data?.status) {
            // predefinedToast(response?.data?.message);
            setTimeout(() => {
              setOverlayOpened(false);
              window.location.reload();
            }, 3000);
            setOverlayOpened(true);
          } else {
            predefinedToast(response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error?.message, "brokerage create error error");
          predefinedToast(error?.message);
          setUpdateLoading(false);
        });
    } else if (URL) {
      Axios.delete(URL)
        .then((response) => {
          // console.log(response, "brokerage deleted");
          setUpdateLoading(false);
          if (response?.data?.status) {
            // predefinedToast(response?.data?.message);
            setTimeout(() => {
              setOverlayOpened(false);
              window.location.reload();
            }, 3000);
            setOverlayOpened(true);
          } else {
            predefinedToast(response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error?.message, "brokerage create error error");
          setUpdateLoading(false);
          predefinedToast(error?.message);
        });
    }
  }

  function editLeadStep() {
    setPopularAction("editleadAPI");
    if (actionBtns?.length > 0) {
      let selectedOne = actionBtns[actionBtns?.length - 1];
      let filterLead = allLeadsAPI?.filter(
        (eachlead) => eachlead?.lead_id == selectedOne
      );
      setActionBtns("");
      console.log(filterLead, "selected edit");
      setSelectedEdit(...filterLead);
    }
  }

  async function uploadRFQFunction() {
    setPopularAction("");
    const currentDate = await getCurrentDate();
    let body = {
      agent_id: globalAgentId,
      lead_id: selectedEdit?.lead_id,
      prq_pdf: [
        {
          quoted_on: currentDate,
          prq_pdf_url: uploadFileRFQ,
        },
      ],
    };
    console.log(selectedEdit, "selectedEdit values", body);
    setUpdateLoading(true);

    Axios.put(
      `https://insurance.apimachine.com/insurance/agent/attachPrqPdftoHRLead`,
      body
    )
      .then((response) => {
        console.log(response, "uploadRFQFunction edited");
        if (response?.data?.status) {
          predefinedToast(response?.data?.message);
          setUpdateLoading(false);
          // setTimeout(() => {
          //   setOverlayOpened(false);
          //   window.location.reload();
          // }, 3000);
          // setOverlayOpened(name);
        } else {
          setUpdateLoading(false);
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "brokerage create error error");
        predefinedToast(error?.message);
        setUpdateLoading(false);
      });
  }

  function editLeadAPI(updatedFollowUp, name, changed) {
    let body = {
      lead_email: selectedEdit?.lead_email,
      follow_up_status: updatedFollowUp,
    };
    if (name == "HR Logged In" && !changed) {
      setPopularAction("HR Logged In");
    } else if (name == "Quote Uploaded" && !changed) {
      setPopularAction("Quote Uploaded");
    } else if (
      name == "Policy Activated With Raha Reference Number" &&
      !changed
    ) {
      setPopularAction("Policy Activated With Raha Reference Number");
    } else if (name == "Policy Updated With Permanent Number" && !changed) {
      setPopularAction("Policy Updated With Permanent Number");
    } else {
      if (name == "Policy Activated With Raha Reference Number") {
        body = {
          lead_email: selectedEdit?.lead_email,
          follow_up_status: updatedFollowUp,
          policy_Number_T: policyNoT,
        };
      } else if (name == "Policy Updated With Permanent Number") {
        body = {
          lead_email: selectedEdit?.lead_email,
          follow_up_status: updatedFollowUp,
          policy_Number_P: policyNoP,
          policy_pdf: uploadFileP,
        };
      }
      // console.log(body, "bsjdnsdd");
      setUpdateLoading(true);
      // console.log(selectedEdit?.lead_email, updatedFollowUp, "editLEADAPI");
      Axios.put(
        `https://insurance.apimachine.com/insurance/agent/followupstatuschange?agent_email=${email}`,
        body
      )
        .then((response) => {
          // console.log(response, "brokerage edited");
          if (response?.data?.status) {
            // predefinedToast(response?.data?.message);
            setUpdateLoading(false);
            setTimeout(() => {
              setOverlayOpened(false);
              window.location.reload();
            }, 3000);
            setOverlayOpened(name);
          } else {
            setUpdateLoading(false);
            predefinedToast(response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error?.message, "brokerage create error error");
          predefinedToast(error?.message);
          setUpdateLoading(false);
        });
    }
  }

  const [seeQuotes, setSeeQuotes] = useState("");

  useEffect(() => {
    Axios.get(
      `https://insurance.apimachine.com/insurance/lead/get?lead_email=${email}`
    )
      .then((response) => {
        let res = response?.data?.data;
        // console.log(res, "get quotes data");
        if (res?.length > 0 && res[0]?.prq_pdf_status == "yetToBeViewed") {
          setSeeQuotes(res[0]?.prq_pdf);
          console.log(res[0]?.prq_pdf_status, "get quotes data statusssss");
        }
        setUpdateLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "brokerage create error error");
        setUpdateLoading(false);
      });
  }, []);

  function updateQuotes(selected) {
    setUpdateLoading(true);
    let body = {
      hr_id: globalHrId,
      action: selected,
    };
    Axios.put(`https://insurance.apimachine.com/insurance/hr/prqstatus`, body)
      .then((response) => {
        // console.log(response, "Update quote alert");
        if (response?.data?.status) {
          // predefinedToast(response?.data?.message);
          setUpdateLoading(false);
          predefinedToast(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message, "Update quote alert error");
        predefinedToast(error?.message);
        setUpdateLoading(false);
      });
  }

  return (
    <div className={classNames.dashboard}>
      <div
        className={classNames.sidebar}
        style={{
          height:
            dashboardToggle == "Employee" &&
            seeQuotes?.length > 0 &&
            seeQuotes[0]?.prq_pdf_url?.includes("http")
              ? "calc(100% - 70px)"
              : "",
        }}
      >
        <div className={classNames.fullLogo}>
          <img src={fullLogo} alt="fullLogo" />
          {/* <div>
            <div
              style={{
                display: window?.location?.pathname?.includes("/coverfi")
                  ? "none"
                  : "",
              }}
              onClick={() => {
                if (
                  popularAction == "add/editpolicyconfiguration" ||
                  popularAction == "healthflat" ||
                  popularAction == "accidentpolicy" ||
                  popularAction == "lifepolicy"
                ) {
                  setPopularAction("policyconfigurations");
                } else {
                  setPopularAction(!popularAction);
                }
              }}
            >
              {popularAction ? (
                <>
                  {(typeof popularAction == "string" &&
                    popularAction?.includes("edit")) ||
                  popularAction == "healthflat" ||
                  popularAction == "accidentpolicy" ||
                  popularAction == "lifepolicy" ? (
                    <PopularBackHamburger />
                  ) : (
                    <div>
                      <AiOutlineClose />
                    </div>
                  )}
                </>
              ) : (
                <PopularActionHamburger />
              )}
            </div>
            <div
              onClick={() => {
                if (popularAction) {
                  setPopularAction(false);
                } else {
                  setDashboardHam(!dashboardHam);
                }
              }}
            >
              {dashboardHam ? (
                <div>
                  <AiOutlineClose />
                </div>
              ) : (
                <DashboardHamburger />
              )}
            </div>
          </div> */}
        </div>
        {popularAction == "deletebrokerage" ||
        popularAction == "deleteassociate" ||
        popularAction == "deletelead" ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title}>
                {popularAction == "deletebrokerage"
                  ? "Delete Brokerage"
                  : popularAction == "deleteassociate"
                  ? "Delete Associate"
                  : popularAction == "deletelead"
                  ? "Delete Lead"
                  : ""}
              </span>
              <div
                style={{
                  pointerEvents: popularAction == "deletelead" ? "" : "none",
                }}
                onClick={() => {
                  deleteBrokerageAPI();
                }}
                // className={
                //   window?.location?.pathname == "/app/${apptype}/newbrokerage"
                //     ? classNames.selectedAction
                //     : ""
                // }
              >
                Confirm & Delete
              </div>
              <div
                onClick={() => {
                  setActionBtns("");
                  if (popularAction) {
                    setPopularAction(false);
                  } else {
                    setDashboardHam(!dashboardHam);
                  }
                }}
                // className={
                //   window?.location?.pathname == "/app/${apptype}/newassociate"
                //     ? classNames.selectedAction
                //     : ""
                // }
              >
                Never mind
              </div>
            </div>
          </div>
        ) : popularAction == "editlead" ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title}>
                {popularAction == "editlead" ? "Edit Lead" : ""}
              </span>
              <div
                style={{
                  pointerEvents: popularAction == "editlead" ? "" : "none",
                }}
                onClick={() => {
                  editLeadStep();
                }}
                // className={
                //   window?.location?.pathname == "/app/${apptype}/newbrokerage"
                //     ? classNames.selectedAction
                //     : ""
                // }
              >
                Change Sales Status
              </div>
            </div>
          </div>
        ) : popularAction == "editleadAPI" ? (
          <div className={classNames.popularActions}>
            <div style={{ overflowY: "scroll" }}>
              <span className={classNames.title}>
                {popularAction == "editleadAPI" ? "Edit Lead" : ""}
              </span>
              <span
                className={`${classNames.changeOption} ${classNames.changeOptionBorder}`}
              >
                <div className={classNames.title}>Current Status</div>
                <div className={classNames.options}>
                  {allStatus
                    ?.filter(
                      (eachlead) =>
                        eachlead?.searchValue == selectedEdit?.follow_up_status
                    )
                    ?.map((eachlead) => {
                      return <div>{eachlead?.name}</div>;
                    })}
                </div>
              </span>
              <span className={classNames.changeOption}>
                <div className={classNames.title}>Change To</div>
                <div className={classNames.options}>
                  {allStatus
                    ?.filter(
                      (eachlead) =>
                        eachlead?.searchValue !== selectedEdit?.follow_up_status
                    )
                    ?.map((eachlead) => {
                      return (
                        <div
                          onClick={() => {
                            editLeadAPI(eachlead?.searchValue, eachlead?.name);
                          }}
                        >
                          {eachlead?.name}
                        </div>
                      );
                    })}
                </div>
              </span>
            </div>
          </div>
        ) : popularAction == "HR Logged In" ? (
          <div className={classNames.popularActions}>
            <div style={{ overflowY: "scroll" }}>
              <span className={classNames.title} style={{ fontSize: "1.2rem" }}>
                Are You Sure You Want To Create An Account For This HR?
              </span>
              <div className={`${classNames.changeOption}`}>
                <div className={classNames.options}>
                  <div
                    onClick={() => {
                      setPopularAction("editleadAPI");
                      editLeadAPI("HRAccountLoggedIn", "HR Logged In", "step");
                    }}
                  >
                    Yes I Am Sure
                  </div>
                  <div onClick={() => setPopularAction("editleadAPI")}>
                    Never mind
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : popularAction == "Quote Uploaded" ? (
          <div className={classNames.popularActions}>
            <div style={{ overflowY: "scroll" }}>
              <span className={classNames.title} style={{ fontSize: "1.2rem" }}>
                Please Upload The QCR To Update The Lead To This Step
              </span>
              <div
                className={`${classNames.changeOption}`}
                style={{ padding: "0" }}
              >
                <label
                  className={classNames.uploadDocument}
                  style={{ padding: "3rem" }}
                  htmlFor="fileUploadRFQ"
                >
                  <img src={uploadFileIcon} alt="uploadFileIcon" />
                  <span>
                    {uploadFileRFQName?.name
                      ? uploadFileRFQName?.name
                      : "Upload QCR"}
                  </span>
                  <input
                    className={classNames.uploadNewPicPlanB}
                    type="file"
                    style={{ display: "none" }}
                    onChange={async (e) => {
                      let resFile = uploadFileFunc(
                        e,
                        setUploadFileRFQName,
                        setUpdateLoading,
                        "name"
                      );
                      console.log(await resFile, "res file link await");
                      setUploadFileRFQ(await resFile);
                    }}
                    id="fileUploadRFQ"
                  />
                </label>
              </div>
            </div>
            <span
              className={classNames.updateBtn}
              onClick={() => {
                setPopularAction("editleadAPI");
                uploadRFQFunction();
              }}
              // style={{
              //   display: popularAction ? "none" : dashboardHam ? "none" : "",
              // }}
            >
              Update Lead
            </span>
          </div>
        ) : popularAction == "Policy Activated With Raha Reference Number" ? (
          <div className={classNames.popularActions}>
            <div style={{ overflowY: "scroll" }}>
              <span className={classNames.title} style={{ fontSize: "1.2rem" }}>
                Enter the temporary reference number to activate this client
              </span>
              <div
                className={`${classNames.changeOption}`}
                style={{ padding: "0" }}
              >
                <div className={classNames.uploadDocument}>
                  <textarea
                    name=""
                    className={classNames.textArea}
                    placeholder="Enter temporary reference number..."
                    onChange={(event) => setPolicyNoT(event?.target?.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <span
              className={classNames.updateBtn}
              onClick={() => {
                setPopularAction("editleadAPI");
                editLeadAPI(
                  "activeClient_T",
                  "Policy Activated With Raha Reference Number",
                  "step"
                );
              }}
              // style={{
              //   display: popularAction ? "none" : dashboardHam ? "none" : "",
              // }}
            >
              Update Lead
            </span>
          </div>
        ) : popularAction == "Policy Updated With Permanent Number" ? (
          <div className={classNames.popularActions}>
            <div style={{ overflowY: "scroll" }}>
              <span className={classNames.title} style={{ fontSize: "1.2rem" }}>
                Enter the permanent policy number & upload the policy document
              </span>
              <div
                className={`${classNames.changeOption}`}
                style={{ padding: "0" }}
              >
                <div className={classNames.uploadDocument}>
                  <textarea
                    name=""
                    className={classNames.textArea}
                    placeholder="Enter permanent policy number..."
                    onChange={(event) => setPolicyNoP(event?.target?.value)}
                  ></textarea>
                </div>
                <label
                  className={classNames.uploadDocument}
                  style={{ padding: "3rem", marginTop: "2rem" }}
                  htmlFor="fileUploadP"
                >
                  <img src={uploadFileIcon} alt="uploadFileIcon" />
                  <span>
                    {uploadFilePName?.name
                      ? uploadFilePName?.name
                      : "Upload Policy"}
                  </span>
                  <input
                    className={classNames.uploadNewPicPlanB}
                    type="file"
                    style={{ display: "none" }}
                    onChange={async (e) => {
                      let resFile = uploadFileFunc(
                        e,
                        setUploadFilePName,
                        setUpdateLoading,
                        "name"
                      );
                      setUploadFileP(await resFile);
                      console.log(await resFile, "res file link P await");
                    }}
                    id="fileUploadP"
                  />
                </label>
              </div>
            </div>
            <span
              className={classNames.updateBtn}
              onClick={() => {
                setPopularAction("editleadAPI");
                editLeadAPI(
                  "activeClient_P",
                  "Policy Updated With Permanent Number",
                  "step"
                );
              }}
              // style={{
              //   display: popularAction ? "none" : dashboardHam ? "none" : "",
              // }}
            >
              Update Lead
            </span>
          </div>
        ) : popularAction == "add/editpolicyconfiguration" ||
          popularAction == "healthflat" ||
          popularAction == "accidentpolicy" ||
          popularAction == "lifepolicy" ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title} style={{ fontSize: "1.1rem" }}>
                What type of insurance would you like to add?
              </span>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setPopularAction("healthflat");
                    history.push(`/app/${findApp[2]}/companyprofile`);
                  }
                }}
              >
                Health
              </div>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setPopularAction("accidentpolicy");
                    history.push(`/app/${findApp[2]}/companyprofile`);
                  }
                }}
              >
                Accident
              </div>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setPopularAction("lifepolicy");
                    history.push(`/app/${findApp[2]}/companyprofile`);
                  }
                }}
              >
                Life
              </div>
            </div>
          </div>
        ) : popularAction == "policyconfigurations" ? (
          <div className={classNames.popularActions}>
            <div>
              <div
                // onClick={() => {
                //   let findApp = window?.location?.pathname?.split("/");
                //   if (findApp?.length > 2) {
                //     setAddNewEmployeeType("spreadsheet");
                //     history.push(`/app/${findApp[2]}/addnewemployee`);
                //   }
                //   // history.push(`/app/${apptype}/addnewemployee`);
                // }}
                // className={
                //   window?.location?.pathname == `/app/coverfi/addnewemployee` &&
                //   addNewEmployeeType == "spreadsheet"
                //     ? classNames.selectedAction
                //     : ""
                // }
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  setPopularAction(!popularAction);
                  if (findApp?.length > 2) {
                    setAddNewEmployeeType("spreadsheet");
                    history.push(`/app/${findApp[2]}/policyrequirements`);
                  }
                }}
              >
                See Policy Configurations
              </div>
              <div
                onClick={() => {
                  setPopularAction("add/editpolicyconfiguration");
                  // history.push(`/app/${apptype}/addnewemployee`);
                }}
                className={
                  window?.location?.pathname == `/app/coverfi/addnewemployee` &&
                  addNewEmployeeType == "email"
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Policy Configurations
              </div>
            </div>
          </div>
        ) : popularAction == "addemployeeptions" &&
          loggedInApp == "employer" &&
          localStorage.getItem("bankState") == "employer" &&
          window?.location?.pathname?.includes("/coverfi") ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title} style={{ fontSize: "1rem" }}>
                Employee Pre-Enrollment
              </span>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setAddNewEmployeeType("spreadsheet");
                    history.push(`/app/${findApp[2]}/addnewemployee`);
                  }
                  // history.push(`/app/${apptype}/addnewemployee`);
                }}
                className={
                  window?.location?.pathname == `/app/coverfi/addnewemployee` &&
                  addNewEmployeeType == "spreadsheet"
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Via Spreadsheet
              </div>
              {/* <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setAddNewEmployeeType("form");
                    history.push(`/app/${findApp[2]}/addnewemployee`);
                  }
                  // history.push(`/app/${apptype}/addnewemployee`);
                }}
                className={
                  window?.location?.pathname == `/app/coverfi/addnewemployee` &&
                  addNewEmployeeType == "form"
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Via Form
              </div> */}
              <span
                className={classNames.title}
                style={{ fontSize: "1rem", marginTop: "1rem" }}
              >
                Straight Upload
              </span>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setAddNewEmployeeType("bulkupload");
                    history.push(`/app/${findApp[2]}/addnewemployee`);
                  }
                  // history.push(`/app/${apptype}/addnewemployee`);
                }}
                className={
                  window?.location?.pathname == `/app/coverfi/addnewemployee` &&
                  addNewEmployeeType == "bulkupload"
                    ? classNames.selectedAction
                    : ""
                }
              >
                Bulk Upload
              </div>
              <span
                className={classNames.title}
                style={{ fontSize: "1rem", marginTop: "1rem" }}
              >
                Endorsements
              </span>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setAddNewEmployeeType("email");
                    history.push(`/app/${findApp[2]}/addnewemployee`);
                  }
                  // history.push(`/app/${apptype}/addnewemployee`);
                }}
                className={
                  window?.location?.pathname == `/app/coverfi/addnewemployee` &&
                  addNewEmployeeType == "email"
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Via Email
              </div>
              <div
                className={classNames.backBtn}
                onClick={() => {
                  if (popularAction) {
                    setPopularAction(false);
                  } else {
                    setDashboardHam(!dashboardHam);
                  }
                }}
              >
                Go Back
              </div>
            </div>
          </div>
        ) : popularAction == "addemployeecoverfi" &&
          loggedInApp == "employer" &&
          localStorage.getItem("bankState") == "employer" &&
          window?.location?.pathname?.includes("/coverfi") ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title}>Add Employees</span>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setAddNewEmployeeType("spreadsheet");
                    history.push(`/app/${findApp[2]}/addnewemployee`);
                  }
                  // history.push(`/app/${apptype}/addnewemployee`);
                }}
                className={
                  window?.location?.pathname == `/app/coverfi/addnewemployee` &&
                  addNewEmployeeType == "spreadsheet"
                    ? classNames.selectedAction
                    : ""
                }
              >
                Upload Employee Data
              </div>
              {/* <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setAddNewEmployeeType("bulkupload");
                    history.push(`/app/${findApp[2]}/addnewemployee`);
                  }
                  
                }}
                className={
                  window?.location?.pathname == `/app/coverfi/addnewemployee` &&
                  addNewEmployeeType == "bulkupload"
                    ? classNames.selectedAction
                    : ""
                }
              >
                Upload To Associate
              </div> */}
              <div
                className={classNames.backBtn}
                onClick={() => {
                  setPopularAction(true);
                }}
              >
                Go Back
              </div>
            </div>
          </div>
        ) : (popularAction == "addendorsementscoverfi" ||
            popularAction == "addendorsementsagency") &&
          (loggedInApp == "employer" || loggedInApp == "associates") &&
          (localStorage.getItem("bankState") == "employer" ||
            localStorage.getItem("bankState") == "associates") &&
          (window?.location?.pathname?.includes("/coverfi") ||
            window?.location?.pathname?.includes("/agency")) ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title}>Endorsements</span>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setAddNewEmployeeType("email");
                    history.push(`/app/${findApp[2]}/addnewemployee`);
                  }
                  // history.push(`/app/${apptype}/addnewemployee`);
                }}
                className={
                  window?.location?.pathname ==
                    `/app/${
                      window?.location?.pathname?.split("/")[2]
                    }/addnewemployee` && addNewEmployeeType == "email"
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Via Email
              </div>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setAddNewEmployeeType("Endorsementspreadsheet");
                    history.push(`/app/${findApp[2]}/addnewemployee`);
                  }
                  // history.push(`/app/${apptype}/addnewemployee`);
                }}
                className={
                  window?.location?.pathname ==
                    `/app/${
                      window?.location?.pathname?.split("/")[2]
                    }/addnewemployee` &&
                  addNewEmployeeType == "Endorsementspreadsheet"
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Via Spreadsheet
              </div>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    setAddNewEmployeeType("EndorsementRemovespreadsheet");
                    history.push(`/app/${findApp[2]}/addnewemployee`);
                  }
                  // history.push(`/app/${apptype}/addnewemployee`);
                }}
                className={
                  window?.location?.pathname ==
                    `/app/${
                      window?.location?.pathname?.split("/")[2]
                    }/addnewemployee` &&
                  addNewEmployeeType == "EndorsementRemovespreadsheet"
                    ? classNames.selectedAction
                    : ""
                }
              >
                Remove Via Spreadsheet
              </div>

              <div
                className={classNames.backBtn}
                onClick={() => {
                  setPopularAction(true);
                }}
              >
                Go Back
              </div>
            </div>
          </div>
        ) : (popularAction &&
            window?.location?.pathname?.includes("/wallet")) ||
          (popularAction && window?.location?.pathname?.includes("/vault")) ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title}>Popular Actions</span>
              <div
                className={
                  window?.location?.pathname == `/app/coverfi/individuallead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Fund Wallet
              </div>
              <div
                className={
                  window?.location?.pathname == `/app/coverfi/corporatelead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Withdraw Funds
              </div>
            </div>
          </div>
        ) : popularAction &&
          loggedInApp == "employee" &&
          localStorage.getItem("bankState") == "employee" &&
          window?.location?.pathname?.includes("/rahax") ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title}>Popular Actions</span>
              {/* <div
                className={
                  window?.location?.pathname == `/app/coverfi/individuallead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Fund Wallet
              </div>
              <div
                className={
                  window?.location?.pathname == `/app/coverfi/corporatelead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Withdraw Funds
              </div>
              <div
                className={
                  window?.location?.pathname == `/app/coverfi/corporatelead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Spend O2
              </div> */}
              <div
                className={
                  window?.location?.pathname == `/app/coverfi/corporatelead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                File Claim
              </div>
              <div
                className={
                  window?.location?.pathname == `/app/coverfi/corporatelead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Family Member
              </div>
            </div>
          </div>
        ) : popularAction &&
          loggedInApp == "admin" &&
          localStorage.getItem("bankState") == "admin" &&
          window?.location?.pathname?.includes("/brokerapp") ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title}>Popular Actions</span>
              {/* <div
                onClick={() => {
                  history.push(`/app/${apptype}/newbrokerage`);
                }}
                className={
                  window?.location?.pathname == `/app/${apptype}/newbrokerage`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Create A Brokerage
              </div> */}
              <div
                onClick={() => {
                  history.push(`/app/${apptype}/newassociate`);
                }}
                className={
                  window?.location?.pathname == `/app/${apptype}/newassociate`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add An Associate
              </div>
              <div
                onClick={() => {
                  history.push(`/app/${apptype}/corporatelead`);
                }}
                className={
                  window?.location?.pathname == `/app/${apptype}/corporatelead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Corporate Lead
              </div>
              <div
                onClick={() => {
                  history.push(`/app/${apptype}/individuallead`);
                }}
                className={
                  window?.location?.pathname == `/app/${apptype}/individuallead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Individual Lead
              </div>
              <div
                className={classNames.backBtn}
                onClick={() => {
                  if (popularAction) {
                    setPopularAction(false);
                  } else {
                    setDashboardHam(!dashboardHam);
                  }
                }}
              >
                Go Back
              </div>
            </div>
          </div>
        ) : popularAction &&
          loggedInApp == "associates" &&
          localStorage.getItem("bankState") == "associates" &&
          window?.location?.pathname?.includes("/agency") ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title}>Popular Actions</span>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    history.push(`/app/${findApp[2]}/individuallead`);
                  }
                  // history.push(`/app/${apptype}/`);
                }}
                className={
                  window?.location?.pathname == `/app/agency/individuallead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Individual Lead
              </div>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    history.push(`/app/${findApp[2]}/corporatelead`);
                  }
                  // history.push(`/app/${apptype}/corporatelead`);
                }}
                className={
                  window?.location?.pathname == `/app/agency/corporatelead`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Corporate Lead
              </div>
              <div
                onClick={() => {
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    history.push(`/app/${findApp[2]}/hrclient`);
                  }
                  // history.push(`/app/${apptype}/corporatelead`);
                }}
                className={
                  window?.location?.pathname == `/app/agency/hrclient`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add Client
              </div>

              <div
                className={classNames.backBtn}
                onClick={() => {
                  if (popularAction) {
                    setPopularAction(false);
                  } else {
                    setDashboardHam(!dashboardHam);
                  }
                }}
              >
                Go Back
              </div>
            </div>
          </div>
        ) : popularAction &&
          loggedInApp == "employer" &&
          localStorage.getItem("bankState") == "employer" &&
          window?.location?.pathname?.includes("/coverfi") ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title}>Popular Actions</span>
              {/* <div
                onClick={() => {
                  setPopularAction("addemployeeptions");
                  // let findApp = window?.location?.pathname?.split("/");
                  // if (findApp?.length > 2) {
                  //   history.push(`/app/${findApp[2]}/addnewemployee`);
                  // }
                  // history.push(`/app/${apptype}/addnewemployee`);
                }}
                className={
                  window?.location?.pathname == `/app/coverfi/addnewemployee`
                    ? classNames.selectedAction
                    : ""
                }
              >
                Add New Employee
              </div> */}
              <div
                onClick={() => {
                  setPopularAction("addemployeecoverfi");
                }}
                // className={
                //   window?.location?.pathname == `/app/coverfi/addnewemployee`
                //     ? classNames.selectedAction
                //     : ""
                // }
                style={{ display: dashboardToggle == "Employee" ? "none" : "" }}
              >
                Add Employees
              </div>{" "}
              <div
                onClick={() => {
                  setPopularAction("addendorsementscoverfi");
                }}
                // className={
                //   window?.location?.pathname == `/app/coverfi/addnewemployee`
                //     ? classNames.selectedAction
                //     : ""
                // }
                style={{ display: dashboardToggle == "Employee" ? "none" : "" }}
              >
                Endorsements
              </div>
              <div
                className={classNames.backBtn}
                onClick={() => {
                  if (popularAction) {
                    setPopularAction(false);
                  } else {
                    setDashboardHam(!dashboardHam);
                  }
                }}
                style={{ display: dashboardToggle == "Employee" ? "none" : "" }}
              >
                Go Back
              </div>
            </div>
          </div>
        ) : popularAction ? (
          <div className={classNames.popularActions}>
            <div>
              <span className={classNames.title}>Popular Actions</span>
              <div
                className={classNames.backBtn}
                onClick={() => {
                  if (popularAction) {
                    setPopularAction(false);
                  } else {
                    setDashboardHam(!dashboardHam);
                  }
                }}
              >
                Go Back
              </div>
            </div>
          </div>
        ) : loggedInApp == "admin" &&
          localStorage.getItem("bankState") == "admin" &&
          window?.location?.pathname?.includes("/brokerapp") &&
          !dashboardHam ? (
          <div className={classNames.navigations}>
            <div className={classNames.eachCategory}>
              <div className={classNames.optionsParent}>
                <Link
                  className={`${classNames.eachOption} ${
                    window.location.pathname?.includes("/staff")
                      ? classNames.selectedDashboardItem
                      : ""
                  }`}
                  to="/app/brokerapp/staff"
                >
                  <div>
                    <AgentsNewIcon />
                    {/* <img src={agentsNewIcon} alt="agentsNewIcon" /> */}
                  </div>
                  <div className={classNames.title}>Staff</div>
                </Link>
                <Link
                  className={`${classNames.eachOption} ${
                    window.location.pathname?.includes("/crm")
                      ? classNames.selectedDashboardItem
                      : ""
                  }`}
                  to="/app/brokerapp/crm"
                >
                  <div>
                    <AssociatesCRMIcon />
                  </div>
                  <div className={classNames.title}>CRM</div>
                </Link>

                <Link
                  className={`${classNames.eachOption} ${
                    window.location.pathname?.includes("/insurance")
                      ? classNames.selectedDashboardItem
                      : ""
                  }`}
                  to="/app/brokerapp/insurance"
                >
                  <div>
                    <LegacyIcon />
                  </div>
                  <div className={classNames.title}>Insurance</div>
                </Link>
              </div>
            </div>
          </div>
        ) : loggedInApp == "employee" &&
          localStorage.getItem("bankState") == "employee" &&
          window?.location?.pathname?.includes("/rahax") &&
          !dashboardHam ? (
          <div className={classNames.navigations}>
            <div className={classNames.eachCategory}>
              <div className={classNames.optionsParent}>
                {loggedInApp == "employee"
                  ? employeeAppSidebar?.map((eachItem, index) => {
                      return (
                        <Link
                          className={`${classNames.eachOption} ${
                            window.location.pathname?.includes(
                              eachItem?.name?.replace(/\s/g, "")?.toLowerCase()
                            )
                              ? classNames.selectedDashboardItem
                              : ""
                          }`}
                          to={eachItem?.link}
                          key={eachItem?.name + index}
                          onClick={() => setActionBtns([])}
                          style={{
                            pointerEvents:
                              eachItem?.name == "Overview" ||
                              eachItem?.name == "Dependents"
                                ? ""
                                : policiesApiGlobal?.length > 0 &&
                                  Array.isArray(policiesApiGlobal)
                                ? ""
                                : "none",
                            opacity:
                              eachItem?.name == "Overview" ||
                              eachItem?.name == "Dependents"
                                ? ""
                                : policiesApiGlobal?.length > 0 &&
                                  Array.isArray(policiesApiGlobal)
                                ? ""
                                : "0.5",
                          }}
                        >
                          <div>{eachItem?.image ? eachItem?.image : ""}</div>
                          <div className={classNames.title}>
                            {eachItem?.name}
                          </div>
                        </Link>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        ) : (loggedInApp == "associates" ||
            loggedInApp == "teamlead" ||
            loggedInApp == "verticalhead" ||
            loggedInApp == "businesshead" ||
            loggedInApp == "affiliates") &&
          (localStorage.getItem("bankState") == "associates" ||
            localStorage.getItem("bankState") == "teamlead" ||
            localStorage.getItem("bankState") == "verticalhead" ||
            localStorage.getItem("bankState") == "businesshead" ||
            localStorage.getItem("bankState") == "affiliates") &&
          (window?.location?.pathname?.includes("/agency") ||
            window?.location?.pathname?.includes("/teamlead") ||
            window?.location?.pathname?.includes("/verticalhead") ||
            window?.location?.pathname?.includes("/businesshead") ||
            window?.location?.pathname?.includes("/affiliates")) &&
          !dashboardHam ? (
          <div className={classNames.navigations}>
            <div className={classNames.eachCategory}>
              <div className={classNames.optionsParent}>
                {loggedInApp == "associates"
                  ? associatesAppSidebar?.map((eachItem, index) => {
                      return (
                        <Link
                          className={`${classNames.eachOption} ${
                            window.location.pathname?.includes(
                              eachItem?.name?.replace(/\s/g, "")?.toLowerCase()
                            )
                              ? classNames.selectedDashboardItem
                              : ""
                          }`}
                          to={eachItem?.link}
                          key={eachItem?.name + index}
                          onClick={() => setActionBtns([])}
                        >
                          <div>{eachItem?.image ? eachItem?.image : ""}</div>
                          <div className={classNames.title}>
                            {eachItem?.name}
                          </div>
                        </Link>
                      );
                    })
                  : loggedInApp == "teamlead"
                  ? teamleadAppSidebar?.map((eachItem, index) => {
                      return (
                        <Link
                          className={`${classNames.eachOption} ${
                            window.location.pathname?.includes(
                              eachItem?.name?.replace(/\s/g, "")?.toLowerCase()
                            )
                              ? classNames.selectedDashboardItem
                              : ""
                          }`}
                          to={eachItem?.link}
                          key={eachItem?.name + index}
                          onClick={() => setActionBtns([])}
                        >
                          <div>{eachItem?.image ? eachItem?.image : ""}</div>
                          <div className={classNames.title}>
                            {eachItem?.name}
                          </div>
                        </Link>
                      );
                    })
                  : loggedInApp == "verticalhead"
                  ? verticalheadAppSidebar?.map((eachItem, index) => {
                      return (
                        <Link
                          className={`${classNames.eachOption} ${
                            window.location.pathname?.includes(
                              eachItem?.name?.replace(/\s/g, "")?.toLowerCase()
                            )
                              ? classNames.selectedDashboardItem
                              : ""
                          }`}
                          to={eachItem?.link}
                          key={eachItem?.name + index}
                          onClick={() => setActionBtns([])}
                        >
                          <div>{eachItem?.image ? eachItem?.image : ""}</div>
                          <div className={classNames.title}>
                            {eachItem?.name}
                          </div>
                        </Link>
                      );
                    })
                  : loggedInApp == "businesshead"
                  ? businessheadAppSidebar?.map((eachItem, index) => {
                      return (
                        <Link
                          className={`${classNames.eachOption} ${
                            window.location.pathname?.includes(
                              eachItem?.name?.replace(/\s/g, "")?.toLowerCase()
                            )
                              ? classNames.selectedDashboardItem
                              : ""
                          }`}
                          to={eachItem?.link}
                          key={eachItem?.name + index}
                          onClick={() => setActionBtns([])}
                        >
                          <div>{eachItem?.image ? eachItem?.image : ""}</div>
                          <div className={classNames.title}>
                            {eachItem?.name}
                          </div>
                        </Link>
                      );
                    })
                  : loggedInApp == "affiliates"
                  ? affiliatesAppSidebar?.map((eachItem, index) => {
                      return (
                        <Link
                          className={`${classNames.eachOption} ${
                            window.location.pathname?.includes(
                              eachItem?.name?.replace(/\s/g, "")?.toLowerCase()
                            )
                              ? classNames.selectedDashboardItem
                              : ""
                          }`}
                          to={eachItem?.link}
                          key={eachItem?.name + index}
                          onClick={() => setActionBtns([])}
                        >
                          <div>{eachItem?.image ? eachItem?.image : ""}</div>
                          <div className={classNames.title}>
                            {eachItem?.name}
                          </div>
                        </Link>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        ) : loggedInApp == "employer" &&
          localStorage.getItem("bankState") == "employer" &&
          window?.location?.pathname?.includes("/coverfi") &&
          !dashboardHam ? (
          <div className={classNames.navigations}>
            <div className={classNames.eachCategory}>
              <div className={classNames.optionsParent}>
                <Link
                  className={`${classNames.eachOption} ${
                    window.location.pathname?.includes("/overview")
                      ? classNames.selectedDashboardItem
                      : ""
                  }`}
                  to="/app/coverfi/overview"
                >
                  <div>
                    <ProactiveIcon />
                  </div>
                  <div className={classNames.title}>Overview</div>
                </Link>
                <Link
                  className={`${classNames.eachOption} ${
                    window.location.pathname?.includes("/members")
                      ? classNames.selectedDashboardItem
                      : ""
                  }`}
                  to="/app/coverfi/members"
                  onClick={() => setActionBtns([])}
                >
                  <div>
                    <HrIcon />
                  </div>
                  <div className={classNames.title}>Members</div>
                </Link>

                <Link
                  className={`${classNames.eachOption} ${
                    window.location.pathname?.includes("/claims")
                      ? classNames.selectedDashboardItem
                      : ""
                  }`}
                  to="/app/coverfi/claims"
                  style={{
                    pointerEvents:
                      policiesApiGlobal?.length > 0 &&
                      Array.isArray(policiesApiGlobal)
                        ? ""
                        : "none",
                    opacity:
                      policiesApiGlobal?.length > 0 &&
                      Array.isArray(policiesApiGlobal)
                        ? ""
                        : "0.5",
                  }}
                >
                  <div>
                    <CoverageIcon />
                  </div>
                  <div className={classNames.title}>Claims</div>
                </Link>
                <Link
                  className={`${classNames.eachOption} ${
                    window.location.pathname?.includes("/hospitals")
                      ? classNames.selectedDashboardItem
                      : ""
                  }`}
                  to="/app/coverfi/hospitals"
                  style={{
                    pointerEvents:
                      policiesApiGlobal?.length > 0 &&
                      Array.isArray(policiesApiGlobal)
                        ? ""
                        : "none",
                    opacity:
                      policiesApiGlobal?.length > 0 &&
                      Array.isArray(policiesApiGlobal)
                        ? ""
                        : "0.5",
                  }}
                >
                  <div>
                    <HospitalsIcon />
                  </div>
                  <div className={classNames.title}>Hospitals</div>
                </Link>

                <Link
                  className={`${classNames.eachOption} ${
                    window.location.pathname?.includes("/wellness")
                      ? classNames.selectedDashboardItem
                      : ""
                  }`}
                  to="/app/coverfi/wellness"
                  style={{
                    pointerEvents:
                      policiesApiGlobal?.length > 0 &&
                      Array.isArray(policiesApiGlobal)
                        ? ""
                        : "none",
                    opacity:
                      policiesApiGlobal?.length > 0 &&
                      Array.isArray(policiesApiGlobal)
                        ? ""
                        : "0.5",
                  }}
                >
                  <div>
                    <WellnessIcon />
                    {/* <img src={wellnessIcon} alt="wellnessIcon" /> */}
                  </div>
                  <div className={classNames.title}>Wellness</div>
                </Link>
                {/* <Link
                  className={`${classNames.eachOption} ${
                    window.location.pathname?.includes("/engage")
                      ? classNames.selectedDashboardItem
                      : ""
                  }`}
                  to="/app/coverfi/engage"
                >
                  <div>
                    <EngageIcon />
                    
                  </div>
                  <div className={classNames.title}>Engage</div>
                </Link> */}
              </div>
            </div>
            {seeQuotes?.length > 0 &&
            seeQuotes[0]?.prq_pdf_url?.includes("http") ? (
              <div
                className={classNames.alert}
                style={{
                  display:
                    seeQuotes?.length > 0 &&
                    seeQuotes[0]?.prq_pdf_url?.includes("http")
                      ? ""
                      : "none",
                }}
              >
                <img src={rahaAlertLogo} alt="rahaAlert" />
                <div className={classNames.title}>
                  Your Associate Has Sent You A New Quote
                </div>
                <div
                  className={classNames.seeQuotes}
                  onClick={() => {
                    console.log(seeQuotes, "seeQuotes");
                    if (
                      seeQuotes?.length > 0 &&
                      seeQuotes[0]?.prq_pdf_url?.includes("http")
                    ) {
                      window.open(seeQuotes[0]?.prq_pdf_url, "_blank");
                    }
                  }}
                >
                  <img src={hrefArrow} alt="hrefArrow" />
                  <span>See Quote</span>
                </div>
                <div className={classNames.btnsContainer}>
                  <div className={classNames.confirmBtns}>
                    <div onClick={() => updateQuotes("approved")}>Accept</div>
                    <div onClick={() => updateQuotes("rejected")}>Reject</div>
                  </div>
                  <div
                    className={classNames.newQuoteBtn}
                    onClick={() => updateQuotes("newQuote")}
                  >
                    Ask For New Quote
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : dashboardHam &&
          window?.location?.pathname?.includes("/app/coverfi") ? (
          <div className={classNames.dashboardHam}>
            <div>
              {dashboardToggle == "Employee" ? (
                <div
                  onClick={() => {
                    setDashboardHam(!dashboardHam);
                    let findApp = window?.location?.pathname?.split("/");
                    if (findApp?.length > 2) {
                      history.push(`/app/${findApp[2]}/myprofile`);
                    }
                  }}
                >
                  My Profile
                </div>
              ) : (
                <div
                  onClick={() => {
                    setDashboardHam(!dashboardHam);
                    let findApp = window?.location?.pathname?.split("/");
                    if (findApp?.length > 2) {
                      history.push(`/app/${findApp[2]}/companyprofile`);
                    }
                  }}
                >
                  Company Profile
                </div>
              )}
              {dashboardToggle == "Employer" && (
                <div
                  onClick={() => {
                    // window.open(
                    //   "https://forms.gle/vpdvyQnhrDuLkT6R6",
                    //   "_blank"
                    // );
                    setDashboardHam(!dashboardHam);
                    let findApp = window?.location?.pathname?.split("/");
                    if (findApp?.length > 2) {
                      history.push(`/app/${findApp[2]}/feedbackform`);
                    }
                  }}
                >
                  Feedback Form
                </div>
              )}
              {/* <span className={classNames.logoutBtn} onClick={logoutFunction}>
                Logout
              </span> */}
            </div>
          </div>
        ) : dashboardHam &&
          window?.location?.pathname?.includes("/app/agency") ? (
          <div className={classNames.dashboardHam}>
            <div>
              <div
                onClick={() => {
                  setDashboardHam(!dashboardHam);
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    history.push(`/app/${findApp[2]}/myprofile`);
                  }
                }}
              >
                My Profile
              </div>
              {/* <span className={classNames.logoutBtn} onClick={logoutFunction}>
                Logout
              </span> */}
            </div>
          </div>
        ) : dashboardHam ? (
          <div className={classNames.dashboardHam}>
            <div>
              {/* <div
                onClick={() => {
                  setDashboardHam(!dashboardHam);
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    history.push(`/app/${findApp[2]}/myprofile`);
                  }
                }}
              >
                My Profile
              </div>
              <div
                onClick={() => {
                  setDashboardHam(!dashboardHam);
                  let findApp = window?.location?.pathname?.split("/");
                  if (findApp?.length > 2) {
                    history.push(`/app/${findApp[2]}/companyprofile`);
                  }
                }}
              >
                Company Profile
              </div> */}
            </div>
          </div>
        ) : (
          <div className={classNames.navigations}>
            <div className={classNames.eachCategory}>
              <div className={classNames.optionsParent}>
                {loggedInApp == "employee"
                  ? employeeAppSidebar?.map((eachItem, index) => {
                      return (
                        <Link
                          className={`${classNames.eachOption} ${
                            window.location.pathname?.includes(
                              eachItem?.name?.replace(/\s/g, "")?.toLowerCase()
                            )
                              ? classNames.selectedDashboardItem
                              : ""
                          }`}
                          to={eachItem?.link}
                          key={eachItem?.name + index}
                          onClick={() => setActionBtns([])}
                        >
                          <div>{eachItem?.image ? eachItem?.image : ""}</div>
                          <div className={classNames.title}>
                            {eachItem?.name}
                          </div>
                        </Link>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={classNames.dashboardToggle}>
        <span
          onClick={logoutFunction}
          style={{ border: "none", borderRadius: "35px" }}
        >
          <LockIcon />
          Logout
        </span>
      </div>
    </div>
  );
};

export default Dashboard;
