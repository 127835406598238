import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./adminresetpassword.module.scss";
import Axios from "axios";
import Skeleton from "react-loading-skeleton";
import { EachCustomInput } from "../../Custom/Input";
import { BankContext } from "../../../context/Context";
import { predefinedToast } from "../../../utils/toast";

const AdminResetPassword = ({
  popup,
  setPopup,
  selectedRow,
  setSelectedRow,
  from,
}) => {
  const { email, accessToken, idToken } = useContext(BankContext);
  const [localStep, setLocalStep] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [userProfileLoading, setUserProfileLoading] = useState(false);
  const [userUpdationLoading, setUserUpdationLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState({});
  const formContainerRef = useRef(null);

  //functions

  async function getUserStatus(selectedEmail) {
    setUserProfileLoading(true);
    try {
      let response = await Axios.get(
        `https://gxauth.apimachine.com/gx/disabled/users/get?email=${selectedEmail}`
      );
      if (response?.data?.status) {
        setUserProfile(response?.data?.user);
      }
      setUserProfileLoading(false);
      console.log(response, "Get user admin password response");
    } catch (error) {
      console.log(error?.message, "Get user admin password reset error!");
      setUserProfileLoading(false);
    }
  }

  async function submitResetPassword() {
    setUserUpdationLoading(true);
    try {
      let response = await Axios.post(
        `https://gxauth.apimachine.com/raha/admin/set/user/password`,
        {
          token: idToken ? idToken : localStorage.getItem("nvestBankIdToken"),
          userEmail:
            from === "cdbalance"
              ? selectedRow?.user_company_email
              : selectedRow?.lead_email,
          permanent: true,
          password: resetPassword?.confirmPassword,
          email: email,
        }
      );

      if (response?.data?.status) {
        setLocalStep("success");
        predefinedToast("Successfully reset password!");
      } else {
        predefinedToast("Error while trying to reset password!");
      }

      setUserUpdationLoading(false);
      console.log(response, "Submit Reset Password response");
    } catch (error) {
      predefinedToast("Error while trying to reset password!");
      console.log(error?.message, "Submit Reset Password reset error!");
      setUserUpdationLoading(false);
    }
  }

  const handleClickOutside = (event) => {
    if (
      formContainerRef.current &&
      !formContainerRef.current.contains(event.target)
    ) {
      setPopup(false);
    }
  };

  //renderings

  useEffect(() => {
    if (popup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popup]);

  useEffect(() => {
    console.log(selectedRow, "selectedRow");
    if (
      from === "cdbalance"
        ? selectedRow?.user_company_email
        : selectedRow?.lead_email
    ) {
      getUserStatus(
        from === "cdbalance"
          ? selectedRow?.user_company_email
          : selectedRow?.lead_email
      );
    }
  }, [selectedRow]);

  return (
    <div className={classNames.adminResetPasswordContainer}>
      {localStep == "success" ? (
        <div className={classNames.formContainer} ref={formContainerRef}>
          <div className={classNames.title}>
            You have successfully reset the password
          </div>

          <div
            className={classNames.inputFields}
            style={{ gridTemplateColumns: "repeat(1,1fr)" }}
          >
            <div
              className={classNames.passwordInput}
              style={{ pointerEvents: "none", marginBottom: "6rem" }}
            >
              <div className={classNames.title}>New password</div>
              <div className={classNames.inputContainer}>
                <input
                  className={classNames.inputBox}
                  type="password"
                  placeholder="Confirm Password..."
                  value={resetPassword?.confirmPassword}
                />
              </div>
            </div>
          </div>

          <div
            className={`${classNames.submitBtn}`}
            onClick={() => {
              navigator.clipboard.writeText(resetPassword?.confirmPassword);
              predefinedToast("Copied");
            }}
          >
            Copy to clipboard
          </div>
        </div>
      ) : (
        <div className={classNames.formContainer} ref={formContainerRef}>
          <div className={classNames.title}>Admin reset users</div>

          <div className={classNames.inputFields}>
            {userProfileLoading ? (
              <>
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
              </>
            ) : (
              <>
                <div className={classNames.noInput}>
                  <EachCustomInput
                    title="Username"
                    placeholder="-"
                    name="Username"
                    stateValue={userProfile}
                    setState={setUserProfile}
                  />
                </div>
                <div className={classNames.noInput}>
                  <EachCustomInput
                    title="Login status"
                    placeholder="-"
                    name="Enabled"
                    stateValue={userProfile}
                    setState={setUserProfile}
                  />
                </div>
                <div className={classNames.noInput}>
                  <EachCustomInput
                    title="Email verification status"
                    placeholder="false"
                    name="Value"
                    stateValue={userProfile}
                    setState={setUserProfile}
                  />
                </div>
                <div className={classNames.noInput}>
                  <EachCustomInput
                    title="Password status"
                    placeholder="-"
                    name="UserStatus"
                    stateValue={userProfile}
                    setState={setUserProfile}
                  />
                </div>

                <div className={classNames.passwordInput}>
                  <div className={classNames.title}>Password</div>
                  <div className={classNames.inputContainer}>
                    <input
                      className={classNames.inputBox}
                      type="password"
                      placeholder="New Password..."
                      onChange={(event) => {
                        const newPassword = event.target.value;
                        setResetPassword((prev) => {
                          return { ...prev, password: newPassword };
                        });
                      }}
                    />
                    <div
                      className={classNames.validationIcon}
                      style={{
                        background:
                          /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])(?=.{8,})/.test(
                            resetPassword?.password || ""
                          )
                            ? "var(--main-color)"
                            : "",
                      }}
                    ></div>
                  </div>
                </div>
                <div className={classNames.passwordInput}>
                  <div className={classNames.title}>Confirm password</div>
                  <div className={classNames.inputContainer}>
                    <input
                      className={classNames.inputBox}
                      type="password"
                      placeholder="Confirm Password..."
                      onChange={(event) => {
                        const newPassword = event.target.value;
                        setResetPassword((prev) => {
                          return { ...prev, confirmPassword: newPassword };
                        });
                      }}
                    />
                    <div
                      className={classNames.validationIcon}
                      style={{
                        background:
                          resetPassword?.password ===
                            resetPassword?.confirmPassword &&
                          resetPassword?.confirmPassword
                            ? "var(--main-color)"
                            : "",
                      }}
                    ></div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div
            className={`${classNames.submitBtn} ${
              (resetPassword?.password === resetPassword?.confirmPassword &&
                resetPassword?.confirmPassword) ||
                 !userUpdationLoading
                ? ""
                : classNames.notClickable
               }`}
            onClick={submitResetPassword}
          >
            {userUpdationLoading ? "Loading..." : "Submit"}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminResetPassword;
